import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Btn, H5 } from "../../../../AbstractElements";
import {
  deleteWorkingAreaShiftAPI,
  getWorkingAreaDetailsAPI,
} from "../../../../Library/apis";
import { Link } from "react-router-dom";

const Shifts = () => {
  const { workingAreaId } = useParams();

  const [loading, setLoading] = useState(false);
  const [workingArea, setWorkingArea] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsDeleting(true);

        try {
          const res = await deleteWorkingAreaShiftAPI(id);
          toast.success(res.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchWorkingArea();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  const fetchWorkingArea = async () => {
    setLoading(true);
    try {
      console.log(workingAreaId);
      const response = await getWorkingAreaDetailsAPI(workingAreaId);
      setWorkingArea(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    if (workingAreaId) {
      fetchWorkingArea();
    }
  }, [workingAreaId]);
  const { t } = useTranslation();
  const mainTitle = "Shifts";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} title="Shifts" />
      <Container fluid={true}>
        <Col sm="12">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10,left:10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardHeader className="d-flex align-items-center">
              <H5>{`${t('Working Area')}: ${
                !loading ? `${workingArea.name}` : ""
              }`}</H5>
              <Link to={`/working-areas/${workingAreaId}/shifts/create`}>
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "ms-2",
                  }}
                >
                  <i className="fa fa-plus"></i> {t("New shift")}
                </Btn>
              </Link>
            </CardHeader>

            <CardBody>
              {workingArea?.shifts?.length > 0 && (
                <div className="table-responsive">
                  <Table className="table-dashed">
                    <thead>
                      <tr className="dashed  bg-clear">
                        <th scope="col">{t("Shifts")}</th>
                        <th scope="col">{t("Interval")}</th>
                        <th scope="col">{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workingArea?.shifts?.map((shift) => (
                        <tr className="dashed bg-clear" key={shift.id}>
                          <td>
                            <span>{`${shift.days.join(", ")}: ${
                              shift.startTime
                            } - ${shift.endTime}`}</span>
                          </td>
                          <td>{shift.interval}</td>
                          <td>
                            <Link
                              to={`/working-areas/${workingAreaId}/shifts/${shift._id}/edit`}
                            >
                              <Btn
                                attrBtn={{
                                  className: "px-2 py-1 me-2",
                                  color: "info",
                                  size: "sm",
                                  "data-tooltip-id": "my-tooltip",
                                  "data-tooltip-content": t("Edit"),
                                  "data-tooltip-place": "top",
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Btn>
                            </Link>

                            <Btn
                              attrBtn={{
                                className: "px-2 py-1",
                                color: "danger",
                                size: "sm",
                                onClick: () => handleDelete(shift._id),
                                "data-tooltip-id": "my-tooltip",
                                "data-tooltip-content": t("Delete"),
                                "data-tooltip-place": "top",
                              }}
                            >
                              {isDeleting ? (
                                <Spinner color="light" size="sm" />
                              ) : (
                                <i className="fa fa-close" />
                              )}
                            </Btn>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>

      <Tooltip id="my-tooltip" />
    </Fragment>
  );
};

export default Shifts;
