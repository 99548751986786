import React, { useState, useEffect } from "react";
import { getAllEmployeesListAPI } from "../../../Library/apis";
import moment from "moment"; // Assuming moment is already installed
import { Card, Container, Row, Col } from "react-bootstrap"; // Import React-Bootstrap components
import { H6 } from "../../../AbstractElements";
import { useTranslation } from "react-i18next";

const BirthdayCard = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const todayMonth = moment().format("MM");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchEmployees = async () => {
      const allEmployees = await getAllEmployeesListAPI();
      setEmployees(allEmployees);
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    const filterEmployeesByBirthdayMonth = employees.filter(
      (employee) => moment(employee.birthday).format("MM") === todayMonth
    );
    setFilteredEmployees(filterEmployeesByBirthdayMonth);
  }, [employees, todayMonth]);

  return (
    <Container>
      <Row xs={1} md={2} lg={4} className="g-0">
        {" "}
        {/* Smaller gap */}
        {filteredEmployees.map((employee) => (
          <Col
            key={employee._id}
            style={{
              paddingRight: "8px",
              paddingLeft: "8px",
              textAlign: "center",
            }}
          >
            <Card style={{ width: "100%" }}>
              {" "}
              {/* Use 100% width for responsive sizing */}
              <Card.Body>
                <Card.Title>{`${employee.firstName} ${employee.lastName}`}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {t("Birthday")}:{" "}
                  {moment(employee.birthday).format("DD.MM.YYYY")}
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BirthdayCard;
