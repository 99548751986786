import React from "react";
import { Row } from "reactstrap";
import Fade from "react-reveal/Fade";

import { H1 } from "../../../../AbstractElements";
import TopHeader from "../TopHeader";
import Footer from "../Footer";

import landing_bg from "../../../../assets/images/landing/landing_top_banner.jpg";

import "../index.css";

const TermsPage = () => {
  const sessionData = [
    {
      title: `General terms and conditions of business
            As of: January 1, 2024`,
      subTitle: `§ 1 Scope, application of the General Terms and Conditions`,
      texts: [
        "These Terms are to be read in conjunction with any terms, conditions, or disclaimers provided on the pages of our HR survey system website. By accessing and using this website, including its survey tools and resources, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, you are not authorized to access our website, use any of its services, or submit any information.",
      ],
    },
    {
      subTitle: "Use of our Website",
      texts: [
        `(1) Rosterrocket S.R.L., Pustnicu 127c, 014042 Bucuresti, Romania, J40/23895/2023
        (hereinafter “Rosterrocket”) offers its customers services in this area via
        www.rosterrocket.de and www.timetact.de (hereinafter “Internet platform”) of
        personnel planning. After registering via Rosterrocket's internet platform, customers
        can subscribe to the services offered by Rosterrocket in accordance with the
        currently valid service packages.
        (2) These General Terms and Conditions apply to all services offered by
        Rosterrocket via this internet platform.
        (3) Terms and conditions on the part of the customer that conflict with or deviate from
        these General Terms and Conditions are expressly not recognized unless
        Rosterrocket expressly agrees to their validity in writing. This also applies if the
        customer accepts an offer from Rosterrocket with reference to its own different
        contractual conditions and Rosterrocket does not object to this. Even if Rosterrocket
        refers to a (possibly electronic) letter that contains or refers to the customer's or a
        third party's terms and conditions, this does not constitute an agreement to the
        validity of those terms and conditions. The inclusion of such customer conditions is
        already objected to.`,
      ],
    },
    {
      subTitle: `§ 2 Subject of the service, addressee`,
      texts: [
        `(1) The subject of the service offered by Rosterrocket is the functionalities of the
      Internet platform in the area of personnel planning in their current version; Depending
      on the platform version and the service packages subscribed, these can include, for
      example, duty and shift planning, absence planning and time recording (collectively
      “contractual services”).
      (2) Data backup is not part of the contractual services. The customer remains
      responsible at all times for securing data and content that he, his employees or other
      third parties acting on his behalf upload or create on the Internet platform (hereinafter
      “customer data”) and will do so himself and regularly with care Download and save
      from a regular businessman. Rosterrocket provides the customer with tools for
      collective downloading of data via the customer account, depending on the services
      booked. The customer will store the data backups in such a way that he can access
      them at any time and can access them independently of the Internet platform.
      Rosterrocket is expressly not liable for the loss of customer data.
      (3) Rosterrocket’s services are aimed exclusively at entrepreneurs.`,
      ],
    },
    {
      subTitle: `§ 3 Registration, conclusion of contract, storage of the contract text
      `,
      texts: [
        `(1) The prerequisite for concluding a contract for the use of the Internet platform
      (“User Agreement”) is registration by the customer. By registering, the customer
      guarantees that they are an entrepreneur and that they will register on the internet
      platform in this capacity. A violation of the aforementioned assurance entitles
      Rosterrocket to terminate the user contract with the customer without notice for good
      cause, without prejudice to its other rights.
      (2) The customer may only carry out the registration through an authorized person or
      an authorized representative. Rosterrocket reserves the right to make the conclusion
      of a user contract dependent on the sending of proof of the power of representation
      without, however, being subject to verification obligations with regard to the identity
      and authorization of the person acting on behalf of the customer.
      (3) The registration process is carried out by the customer by filling out and
      submitting the registration form on the internet platform. The data required for
      registration must be provided completely and truthfully by the customer. By clicking
      on the registration button, the customer submits an offer for the usage contract in
      accordance with the provisions of these General Terms and Conditions. The usage
      contract is concluded upon acceptance of this offer by Rosterrocket in accordance
      with the following paragraph (4). The customer has no right to conclude the usage
      contract.
      (4) The contract is accepted by registering on the internet platform. Rosterrocket
      does not store the contractual provisions for the customer.`,
      ],
    },
    {
      subTitle: `§ 4 Customer account, subscription to service packages`,
      texts: [
        `(1) By registering the desired service package, the customer gains access to the
      Internet platform by providing a customer account. The benefit packages depend on
      the number of employees.
      (2) If the customer would like to subscribe to a higher or smaller service package, the
      customer can make this booking independently within their account using the
      settings. The subscription comes about through acceptance of this offer by
      Rosterrocket.
      (3) Subscription to service packages is no longer possible as soon as one party has
      declared the termination of this user agreement.`,
      ],
    },
    {
      subTitle: `§ 5 Contract term, termination
      `,
      texts: [
        `(1) The usage contract is concluded for an indefinite period. It exists independently of
      any subscriptions. The termination of one or all current subscriptions does not affect
      the existence of the user agreement.
      (2) An ordinary termination of this user agreement can be declared by both parties at
      any time in text form by email without giving reasons. The notice period is 4 weeks to
      the end of the month, but the contract ends at the earliest when the last subscribed
      service package is terminated. The customer can terminate their contract digitally in
      the customer account settings.
      (3) The right to extraordinary termination for good cause remains unaffected. In the
      event of extraordinary termination of this user agreement for good cause, all current
      subscriptions will also end upon termination of this user agreement.
      `,
      ],
    },
    {
      subTitle: `§ 6 Prices and payment
      `,
      texts: [
        `(1) Prices for service packages are based on the conditions stated there. Unless
      expressly stipulated otherwise:
      • a) Prices that relate to services subscribed to during a specific period are due at the
      beginning of this period.
      • b) Relevant for compliance with payment times and deadlines is the crediting of the
      respective amount to the bank account specified by Rosterrocket.
      • c) Invoices from Rosterrocket must be paid without deductions within 2 (two) weeks.
      • d) All prices are net prices plus the VAT applicable at the time of payment.
      `,
      ],
    },
    {
      subTitle: `§ 7 Availability of the Internet platform, place of performance`,
      texts: [
        `(1) The availability of the internet platform is determined as follows:
      • a) Rosterrocket will carry out the necessary maintenance work on the Internet
      platform between 11 p.m. and 6 a.m. Central European Time (“maintenance
      window”). During these maintenance windows, the Platform may be temporarily
      unavailable in whole or in relation to some services on the Internet.
      • b) Rosterrocket provides the Internet platform with an availability of at least 99% per
      calendar quarter on the Internet. The times of the maintenance windows are not
      included in the calculation of availability.
      • c) Rosterrocket can also restrict access to the Internet platform outside the
      maintenance windows if the security of the platform operation, the maintenance of
      network integrity, in particular the avoidance of serious disruptions to the network, the
      software or stored data require this.
      • d) Rosterrocket will inform the customer of planned service work that affects
      availability and lasts longer than 10 (ten) minutes at least 4 (four) hours in advance
      by notifying them in the customer account.
      (2) The transfer point for Rosterrocket's services is the interface between the servers
      on which the Internet platform is hosted and the Internet.`,
      ],
    },
    {
      subTitle: `§ 8 Changes to services, updates`,
      texts: [
        `In the interest of all platform users, Rosterrocket always strives to improve and
      meaningfully expand the Internet platform in order to continually increase its benefits
      for its customers. Rosterrocket therefore expressly reserves the right to further
      develop the internet platform during the contract term. As part of the further
      developments, Rosterrocket can, for example, change the appearance of the front
      end and modify, add and remove functionalities as well as adapt designs, input
      masks and other components of the front and back end (collectively “platform
      updates”). The customer is not entitled to platform updates, nor is there a right to
      continued use of a specific version of the Internet platform. The customer cannot
      derive any claims against Rosterrocket from platform updates carried out, as long as
      the functionalities he has booked are not eliminated or significantly restricted as a
      result of platform updates and he does not suffer any unreasonable disadvantages
      as a result of using the platform.
      `,
      ],
    },
    {
      subTitle: `§ 9 Obligations when using the customer account`,
      texts: [
        `(1) The customer is obliged to choose a secure access password for his customer
      account when registering. A strong password contains upper and lower case letters,
      as well as special characters and numeric characters.
      (2) The customer must keep the access data to his customer account secret and
      protect it from access by unauthorized persons by taking measures in accordance
      with the current state of the art. If the customer becomes aware of improper access
      to his access data or his customer account or if facts give rise to suspicion in this
      regard, he must inform Rosterrocket immediately.
      (3) The customer is entitled to provide third parties (hereinafter “employees”) with
      employee access to their customer account in accordance with the service packages
      they have booked. Rosterrocket's sole contractual partner remains the customer. A
      contractual relationship between the employees and Rosterrocket is not established.
      (4) The customer is liable to Rosterrocket for the behavior of the employees to whom
      he grants access to his customer account. Violations of customer obligations under
      these General Terms and Conditions by such employees will be attributed to the
      customer. He is responsible to Rosterrocket for ensuring that these employees
      behave in accordance with the contract and do not violate these terms and
      conditions, laws and/or the rights of third parties in connection with the use of our
      contractual services. Violations of these provisions by employees will be attributed to
      the customer; in these cases, the customer is Rosterrocket's defendant.`,
      ],
    },
    {
      subTitle: `§ 10 Customer data, rights of use`,
      texts: [
        `(1) Rosterrocket operates the Internet platform as a technology service provider as
      part of a Software as a Service offer. Rosterrocket will not have any influence on or
      review customer data or its creation, transmission and use and therefore considers
      such data and content to be external.
      (2) In order to enable Rosterrocket to provide the contractual services, the customer
      grants Rosterrocket the spatially unrestricted and non-exclusive right to store,
      reproduce and make publicly available customer data that can be protected by
      copyright for the period of validity of the usage contract and any retention obligations
      of Rosterrocket including the right to sublicense to the extent necessary to fulfill
      Rosterrocket's contractual obligations.
      (3) The customer is obliged to
      • a) only upload and create data on the internet platform that does not violate legal
      requirements or the rights of third parties,
      • b) to ensure that the uploading of data and the granting of usage rights in
      accordance with paragraph (2) and the use of the Internet platform by him do not
      violate legal requirements or the rights of third parties.
      (4) The internet platform is expressly not used to store and manage special
      categories of personal data within the meaning of Article 9 GDPR. The customer
      undertakes not to store or process any such data on the internet platform. If the
      customer violates the aforementioned obligation and incurs damages (e.g. due to
      loss of data, transmission, changes or consequences of actions that violate data
      protection law), Rosterrocket is not liable for this.
      `,
      ],
    },
    {
      subTitle: `§ 11 Data protection`,
      texts: [
        `Rosterrocket strictly adheres to the provisions of the applicable data protection law.
      The customer is responsible for personal data that the customer collects, processes
      or uses.`,
      ],
    },
    {
      subTitle: `§ 12 Subcontractors, assignment of rights and obligations`,
      texts: [
        `(1) Rosterrocket is entitled to transfer its performance obligations from the user
      agreement to third parties or to engage subcontractors and to transfer claims against
      the customer to third parties. Rosterrocket remains responsible to the customer for
      the provision of services in accordance with the contract.
      (2) A transfer of rights and obligations from this contract by the customer to third
      parties is only permitted with the prior express consent of Rosterrocket.`,
      ],
    },
    {
      subTitle: `§ 13 Offsetting`,
      texts: [
        `The customer only has the right to offset if his counterclaims have been legally
      established by a court or are undisputed.
      `,
      ],
    },

    {
      subTitle: `§ 14 General limitation of liability`,
      texts: [
        `Rosterrocket is liable, regardless of the legal basis, exclusively in accordance with
      the following regulations.
      (1) Rosterrocket is only liable for intent and gross negligence. Rosterrocket is not
      liable for slight negligence.
      (2) The limitation of Rosterrocket's liability does not apply in the event of injury to life,
      body and/or health and in the event of liability under the Product Liability Act.
      (3) Rosterrocket is not liable for events of force majeure that make the contractual
      services impossible or even significantly complicate or temporarily hinder the proper
      execution of the contract. All circumstances that are independent of the will and
      influence of the contracting parties are considered force majeure, such as terrorist
      attacks, embargoes, confiscation, natural disasters, strikes, decisions by authorities
      or other serious and unforeseeable circumstances for which the contracting parties
      are not responsible. A circumstance is only considered force majeure if it occurred
      after the contract was concluded.
      (4) Furthermore, Rosterrocket is not liable for disruptions and loss of quality in data
      transmission on the Internet, for which Rosterrocket is not responsible and which
      make the use of functions of the Internet platform or services available via it more
      difficult or impossible.
      (5) To the extent that Rosterrocket's liability is excluded or limited, this also applies to
      the liability of Rosterrocket's employees, other employees, representatives and
      vicarious agents.`,
      ],
    },
    {
      subTitle: `§ 15 Changes to these General Terms and Conditions`,
      texts: [
        `Rosterrocket reserves the right to change these General Terms and Conditions
      without justification and in compliance with the following procedure: The changed
      General Terms and Conditions will be sent to the customer, indicating the respective
      changes, to the email address provided during registration. If the customer does not
      object within 2 (two) weeks of receipt of the above-mentioned email, his consent to
      the respective changes is deemed to have been granted.`,
      ],
    },

    {
      subTitle: `§ 16 Final provisions`,
      texts: [
        `(1) The contractual language is German, all declarations and communication are in
      German. The parties ensure that their contact persons speak German.
      (2) Romanian law applies to the user agreement and all contracts concluded between
      Rosterrocket and its customers.
      (3) To the extent permitted by law, the courts in Romania, Bucharest at
      Rosterrocket's registered office are exclusively responsible for all disputes arising
      from or in connection with the contractual cooperation between the parties.
      (4) Should individual provisions of these General Terms and Conditions or of
      contracts or agreements made between the parties be wholly or partially invalid or
      unenforceable or become invalid or unenforceable after the conclusion of the
      contract, the validity of the rest of the regulations remains unaffected. Instead, the
      parties will work towards agreeing a replacement regulation that comes closest to the
      invalid or unenforceable one in a legally permissible and economical manner. The
      above provisions apply accordingly in the event that regulations prove to be
      incomplete.`,
      ],
    },
  ];

  return (
    <div className="landing-page">
      <div
        className="first-section"
        style={{ backgroundImage: `url(${landing_bg})`, height: 120 }}
      >
        <TopHeader />
      </div>

      {sessionData.map((item) => (
        <Fade right>
          <Row className="about-section" style={{ margin: "40px" }}>
            {item.title && (
              <H1
                attrH1={{
                  className: "font40-bold font-outfit text-dark",
                  style: { marginTop: "20px" },
                }}
              >
                {item.title}
              </H1>
            )}
            {item.subTitle && (
              <H1
                attrH1={{
                  className: "font32-bold font-outfit text-dark",
                  style: { marginTop: "20px" },
                }}
              >
                {item.subTitle}
              </H1>
            )}
            {item.texts.map((text) => (
              <H1 attrH1={{ className: "font24_outfit text-dark", style: {} }}>
                {text}
              </H1>
            ))}
          </Row>
        </Fade>
      ))}

      <Footer />
    </div>
  );
};

export default TermsPage;
