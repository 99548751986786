import React, { Fragment, useContext, useState } from "react";
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  InputGroup,
  Input,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Files from "react-files";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, H5, Btn, Image } from "../../../AbstractElements";
import { submitReferenceAPI } from "../../../Library/apis";
import UserContext from "../../../_helper/User";

const formInitialValues = {
  reference: "",
  description: "",
  files1: [],
  files2: [],
  files3: [],
};

const Support = () => {
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { userInfo } = useContext(UserContext);

  const deleteFile = (fileType) => {
    formik.setFieldValue(fileType, []);
  };

  const onFilesChange = (files, fileType) => {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const oversizedFiles = files.filter((file) => file.size > maxSize);

    if (oversizedFiles.length > 0) {
      toast.error("File size exceeds the maximum limit (10MB).", {
        autoClose: 2000,
      });
    } else {
      formik.setFieldValue(fileType, files);
    }
  };

  const onFilesError = (error, file) => {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    toast.error(
      file.size > maxSize
        ? "File size exceeds the maximum limit (10MB)."
        : error.message,
      {
        autoClose: 2000,
      }
    );
  };

  const validationSchema = Yup.object().shape({
    reference: Yup.string().required("Reference is required"),
    // ... other validations ...
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const formData = new FormData();
      formData.append("reference", values.reference);
      formData.append("description", values.description);
      formData.append("email", userInfo.email);

      if (values.files1[0]) {
        formData.append("files1", values.files1[0]);
      }
      if (values.files2[0]) {
        formData.append("files2", values.files2[0]);
      }
      if (values.files3[0]) {
        formData.append("files3", values.files3[0]);
      }

      try {
        await submitReferenceAPI(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Successfully submitted!", {
          autoClose: 2000,
        });

        setInitialValues(formInitialValues);
        resetForm();
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    isSubmitting,
    handleSubmit,
    resetForm,
  } = formik;
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Support" title="Support" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardBody>
              <H5 attrH5={{ className: "mb-5" }}>
                {t(
                  "Please write in details what is the problem? Our support will answer you within 48 hours."
                )}
              </H5>

              <Row className="mb-4 g-3">
                <Label className="col-sm-1 col-form-labe required">
                  {t("Reference")}
                </Label>
                <Col sm="5">
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                  >
                    <Input
                      className="digits"
                      type="text"
                      name="reference"
                      value={values.reference}
                      onChange={handleChange}
                      invalid={errors.reference && touched.reference}
                    />
                  </InputGroup>
                  {errors.reference && touched.reference && (
                    <span className="mt-2 text-danger">{errors.reference}</span>
                  )}
                </Col>
              </Row>

              <Row className="mb-5">
                <Col sm="6">
                  <Input
                    className="digits"
                    type="textarea"
                    rows="4"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row className="mb-5">
                <Col sm="12">
                  <div className="d-flex align-items-center">
                    <div className="me-2">
                      <Files
                        className="files-dropzone fileContainer"
                        onChange={(files) => onFilesChange(files, "files1")}
                        onError={onFilesError}
                        accepts={["image/*"]}
                        multiple={false}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        {values.files1.length > 0 ? (
                          <div className="files-gallery">
                            {values.files1.map((file, index) => (
                              <div key={index}>
                                <Image
                                  attrImage={{
                                    className: "files-gallery-item",
                                    alt: "img",
                                    src: `${file.preview.url}`,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Btn
                              attrBtn={{
                                type: "button",
                                color: "primary",
                              }}
                            >
                              {t("Upload Picture 1")}
                            </Btn>
                          </div>
                        )}
                      </Files>
                      {values.files1.length > 0 ? (
                        <div className="d-flex justify-content-center">
                          <Btn
                            attrBtn={{
                              className: "mt-2",
                              color: "danger",
                              type: "button",
                              onClick: () => deleteFile("files1"),
                            }}
                          >
                            Delete
                          </Btn>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="me-2">
                      <Files
                        className="files-dropzone fileContainer"
                        onChange={(files) => onFilesChange(files, "files2")}
                        onError={onFilesError}
                        accepts={["image/*"]}
                        multiple={false}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        {values.files2.length > 0 ? (
                          <div className="files-gallery">
                            {values.files2.map((file, index) => (
                              <div key={index}>
                                <Image
                                  attrImage={{
                                    className: "files-gallery-item",
                                    alt: "img",
                                    src: `${file.preview.url}`,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Btn
                              attrBtn={{
                                type: "button",
                                color: "primary",
                              }}
                            >
                              {t("Upload Picture 2")}
                            </Btn>
                          </div>
                        )}
                      </Files>
                      {values.files2.length > 0 ? (
                        <div className="d-flex justify-content-center">
                          <Btn
                            attrBtn={{
                              className: "mt-2",
                              color: "danger",
                              type: "button",
                              onClick: () => deleteFile("files2"),
                            }}
                          >
                            Delete
                          </Btn>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Files
                        className="files-dropzone fileContainer"
                        onChange={(files) => onFilesChange(files, "files3")}
                        onError={onFilesError}
                        accepts={["image/*"]}
                        multiple={false}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        {values.files3.length > 0 ? (
                          <div className="files-gallery">
                            {values.files3.map((file, index) => (
                              <div key={index}>
                                <Image
                                  attrImage={{
                                    className: "files-gallery-item",
                                    alt: "img",
                                    src: `${file.preview.url}`,
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Btn
                              attrBtn={{
                                type: "button",
                                color: "primary",
                              }}
                            >
                              {t("Upload Picture 3")}
                            </Btn>
                          </div>
                        )}
                      </Files>
                      {values.files3.length > 0 ? (
                        <div className="d-flex justify-content-center">
                          <Btn
                            attrBtn={{
                              className: "mt-2",
                              color: "danger",
                              type: "button",
                              onClick: () => deleteFile("files3"),
                            }}
                          >
                            Delete
                          </Btn>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              </Row>

              <Btn
                attrBtn={{
                  color: "success",
                  disabled: isSubmitting,
                  onClick: handleSubmit,
                }}
              >
                {isSubmitting && <Spinner size="sm" color="light" />}
                {t("Send")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Support;
