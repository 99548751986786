import React, { useState, Fragment, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import TodoContext from "../../../../_helper/Todo";
import { H4, H5, LI, UL, Btn } from "../../../../AbstractElements";
import { updateTodoAPI } from "../../../../Library/apis";

const TodoList = () => {
  const { allTodos, removeItems, selectedItem, fetchTodo } =
    useContext(TodoContext);
  const [status, setStatus] = useState("pending");
  const [currentPage, setCurrentPage] = useState(1);
  const todosPerPage = 4; // Display 4 todos per page

  const handleRemoveTodo = (todoId) => {
    removeItems(todoId);
    toast.success("Deleted Task !");
    fetchTodo().then(() => {
      // Adjust currentPage if the last item on the last page was removed
      const newTotalPages = Math.ceil(allTodos.length / todosPerPage);
      if (currentPage > newTotalPages) {
        setCurrentPage(currentPage - 1 || 1);
      }
    });
  };

  const handleMarkedTodo = async (itemId, itemStatus) => {
    setStatus("completed");
    selectedItem(itemId, status);
    toast.success("Task Completed !");
    await updateTodoAPI({ id: itemId, status: "completed" });
  };

  // Calculate the todos to display on the current page
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = allTodos.slice(indexOfFirstTodo, indexOfLastTodo);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Ensure currentPage is updated when allTodos changes
  useEffect(() => {
    const totalNumberOfPages = Math.ceil(allTodos.length / todosPerPage);
    if (currentPage > totalNumberOfPages) {
      setCurrentPage(totalNumberOfPages || 1);
    }
  }, [allTodos, currentPage, todosPerPage]);

  return (
    <Fragment>
      <div className="todo-list-body todo-list-container">
        {" "}
        {/* Apply the class here */}
        <UL attrUL={{ className: "simple-list", id: "todo-list" }}>
          {currentTodos.length > 0 &&
            currentTodos.map((todo, index) => (
              <LI
                attrLI={{ className: "task border-0 " + todo.status }}
                key={index}
              >
                <div className="task-container">
                  <H4 attrH4={{ className: "task-label" }}>{todo.title}</H4>
                  <div className="d-flex align-items-center gap-4">
                    <span className={`badge badge-light-${todo.statusCode}`}>
                      {todo.status}
                    </span>
                    <H5 attrH5={{ className: "assign-name m-0" }}>
                      {todo.date}
                    </H5>
                    <span className="task-action-btn">
                      <span
                        className="action-box large delete-btn"
                        title="Delete Task"
                        onClick={() => handleRemoveTodo(todo._id)}
                      >
                        <i className="icon">
                          <i className="icon-trash"></i>
                        </i>
                      </span>
                      <span
                        className="action-box large complete-btn"
                        title="Mark Complete"
                        onClick={() => handleMarkedTodo(todo._id, todo.status)}
                      >
                        <i className="icon">
                          <i className="icon-check"></i>
                        </i>
                      </span>
                    </span>
                  </div>
                </div>
              </LI>
            ))}
        </UL>
      </div>
      {allTodos.length > todosPerPage && (
        <div className="pagination">
          <Btn
            attrBtn={{
              className: "page-link",
              onClick: () => paginate(currentPage - 1),
              disabled: currentPage === 1,
            }}
          >
            Previous
          </Btn>
          {[...Array(Math.ceil(allTodos.length / todosPerPage)).keys()].map(
            (number) => (
              <Btn
                key={number + 1}
                attrBtn={{
                  className: `page-link ${
                    number + 1 === currentPage ? "active" : ""
                  }`,
                  onClick: () => paginate(number + 1),
                }}
              >
                {number + 1}
              </Btn>
            )
          )}
          <Btn
            attrBtn={{
              className: "page-link",
              onClick: () => paginate(currentPage + 1),
              disabled:
                currentPage === Math.ceil(allTodos.length / todosPerPage),
            }}
          >
            Next
          </Btn>
        </div>
      )}
    </Fragment>
  );
};
export default TodoList;
