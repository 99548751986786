import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { Breadcrumbs, Btn, H5, UL, LI } from "../../../AbstractElements";
import {
  getAllEmployeesListAPI,
  getAllWorkingAreasListAPI,
  insertShiftPlanAPI,
  getShiftPlansAPI,
  deleteShiftPlansAPI,
  clearShiftPlansAPI,
  getNationalHolidaysAPI,
  insertShiftPlanAutomaticallyAPI,
  removeOffTimeEmployeesAPI,
  getHolidayIllnessesListAPI,
} from "../../../Library/apis";
import AssignEmployeesModal from "./AssignEmployeesModal";
import UpdateShiftPlanModal from "./UpdateShiftPlanModal";
import useDebounce from "../../../Hooks/useDebounce";
import UserContext from "../../../_helper/User";
import ListView from "./ListView";
import DropOutAssignModal from "./DropOutAssignModal";

import "./styles.css";
import { getOffTimeTitles } from "../../../Library/custom_utils";
import SingleShiftPlanModal from "./SingleShiftPlanModal";
import UpdateSingleShiftModal from "./UpdateSingleShiftModal";

const ShiftPlan = () => {
  const { t } = useTranslation();
  const { userInfo, setIsLoading } = useContext(UserContext);

  const [listView, setListView] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [workingAreas, setWorkingAreas] = useState([]);
  const [offTimes, setOffTimes] = useState([]);
  const [selectedWorkingArea, setSelectedWorkingArea] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [searchWorkingArea, setSearchWorkingArea] = useState("");
  const [unconfirmed, setUnconfirmed] = useState(false);
  const [month, setMonth] = useState({ start: "", end: "" });
  const [holidayEvent, setHolidayEvent] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [openAssignEmployeesModal, setOpenAssignEmployeesModal] =
    useState(false);
  const [openUpdateShiftPlanModal, setOpenUpdateShiftPlanModal] =
    useState(false);
  const [openUpdateSingleShiftModal, setOpenUpdateSingleShiftModal] =
    useState(false);
  const [openSingleShiftPlanModal, setSingleShiftPlanModal] = useState(false);
  const [openDropOutAssignModal, setOpenDropOutAssignModal] = useState(false);
  const [selectedShiftPlan, setSelectedShiftPlan] = useState(null);
  const [selectedDropOut, setSelectedDropOut] = useState(null);

  const debouncedSearchEmployee = useDebounce(searchEmployee, 500);
  const debouncedSearchWorkingArea = useDebounce(searchWorkingArea, 500);

  const isInitialRender = useRef(true);

  const toggleDropOutAssignModal = () =>
    setOpenDropOutAssignModal(!openDropOutAssignModal);

  const toggleAssignEmployeesModal = () =>
    setOpenAssignEmployeesModal(!openAssignEmployeesModal);

  const toggleUpdateShiftPlanModal = () =>
    setOpenUpdateShiftPlanModal(!openUpdateShiftPlanModal);

  const toggleUpdateSingleShiftModal = () =>
    setOpenUpdateSingleShiftModal(!openUpdateSingleShiftModal);

  const toggleSingleShiftPlanModal = () =>
    setSingleShiftPlanModal(!openSingleShiftPlanModal);

  const fetchNationalHolidays = async () => {
    try {
      const response = await getNationalHolidaysAPI({ ...month });

      if (!response) {
        return []; // Return an empty array if the response is undefined
      }

      const eventData = response.map((event) => {
        const startTime = moment(event.startDate).toISOString();
        const backgroundColor = "#2EC5F6";

        return {
          id: event.id.toString(), // Ensure id is a string
          title:
            event?.name?.find((obj) => obj?.language === "EN")?.text ||
            "Holiday", // Default title if not found
          start: startTime,
          backgroundColor,
          borderColor: backgroundColor,
          extendedProps: {
            type: "holiday", // Use an appropriate type
          },
        };
      });

      return eventData;
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
      return []; // Return an empty array in case of error
    }
  };
  const fetchOffTimes = async () => {
    try {
      const response = await getHolidayIllnessesListAPI({
        pageSize: 1000,
        state: "approved",
        mode: "calendar",
      });

      if (!response?.holidayIllnesses) return [];

      const groupedByDate = response.holidayIllnesses.reduce((acc, event) => {
        const startDate = moment(event.startTime).format("YYYY-MM-DD");
        if (!acc[startDate]) {
          acc[startDate] = [];
        }
        acc[startDate].push(event);
        return acc;
      }, {});

      const eventData = Object.keys(groupedByDate).map((date) => {
        const events = groupedByDate[date];
        const backgroundColor = selectedEmployee ? "#3085d6" : "#7366FF";
        const title = events
          .map((event) => {
            if (event.employee._id === selectedEmployee || !selectedEmployee)
              return `${event.fullName} - ${getOffTimeTitles(event.type)}`;
          })
          .join("\n")
          .trim();

        const titleWithTime = events
          .map((event) => {
            const startTime = moment.utc(event.startTime).format("HH:mm");
            const endTime = moment.utc(event.endTime).format("HH:mm");

            if (event.employee._id === selectedEmployee || !selectedEmployee)
              return `${event.fullName} - ${getOffTimeTitles(
                event.type
              )} (${startTime} ~ ${endTime})`;
          })
          .join("\n")
          .trim();

        return {
          id: `offtime-${date}`,
          title: title,
          start: date,
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          display: title ? "unset" : "none",
          extendedProps: {
            type: "offtimes",
            titleWithTime,
          },
        };
      });
      return eventData;
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await getAllEmployeesListAPI({
        search: searchEmployee,
      });
      setEmployees(response);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const fetchWorkingAreas = async () => {
    try {
      const response = await getAllWorkingAreasListAPI({
        search: searchWorkingArea,
      });
      setWorkingAreas(response);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const handleSearchEmployeeChange = (event) => {
    setSearchEmployee(event.target.value);
  };

  const handleSearchWorkingAreaChange = (event) => {
    setSearchWorkingArea(event.target.value);
  };

  const handleSelectEmployee = (value) => {
    if (selectedEmployee === value) {
      setSelectedEmployee("");
    } else {
      setSelectedEmployee(value);
    }
  };

  const handleSelectWorkingArea = (value) => {
    if (selectedWorkingArea === value) {
      setSelectedWorkingArea("");
    } else {
      setSelectedWorkingArea(value);
    }
  };

  const handleClickPlanEdit = (e, planEvent) => {
    e.stopPropagation();
    setOpenAssignEmployeesModal(true);
    setSelectedShiftPlan(planEvent);
  };

  const handleClickDropOutEdit = (e, planEvent) => {
    e.stopPropagation();
    setOpenDropOutAssignModal(true);
    setSelectedDropOut(planEvent);
  };

  const handleClickPlanSetting = (e, planEvent) => {
    e.stopPropagation();
    if (planEvent.extendedProps.workingShiftId !== null) {
      setOpenUpdateShiftPlanModal(true);
      setSelectedShiftPlan(planEvent);
    } else {
      setOpenUpdateSingleShiftModal(true);
      setSelectedShiftPlan(planEvent);
    }
  };

  const handleClickPlanDelete = async (e, planEvent) => {
    e.stopPropagation();

    Swal.fire({
      title: t("Are you sure?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"), // Translate the Cancel button text,
      input: "radio",
      inputOptions: {
        one: ` ${t("This event")} `,
        following: `${t("This and following events")}`,
      },

      inputValue: "one", // Set the default value to 'one'
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteShiftPlansAPI(planEvent?.id, { type: result.value });
          toast.success("Successfully deleted!", { autoClose: 2000 });
          fetchShiftPlans();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleClickOpenCardDelete = async (e, planEvent) => {
    e.stopPropagation();

    Swal.fire({
      title: t("Are you sure?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteShiftPlansAPI(planEvent?.id, { type: result.value });
          toast.success("Successfully deleted!", {
            autoClose: 2000,
          });
          fetchShiftPlans();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const eventContent = (event) => {
    return event.event.extendedProps?.type === "offtimes" ? (
      <div>
        <div style={{ whiteSpace: "pre-wrap" }}>{`${event.event.title} `}</div>
      </div>
    ) : event.event.extendedProps?.type === "holiday" ? (
      <div>
        <div>{`${event.event.title} `}</div>
      </div>
    ) : (
      <div
        className="text-center"
        style={{ display: event.event.conflict ? "none" : "unset" }}
      >
        <div className="d-flex align-items-center justify-content-between">
          <i
            className="fa fa-cog cursor-pointer"
            onClick={(e) => handleClickPlanSetting(e, event.event)}
          />
          <div>{`${event.event.extendedProps.startTime} - ${event.event.extendedProps.endTime} `}</div>
          <i
            className="fa fa-edit cursor-pointer"
            onClick={(e) => handleClickPlanEdit(e, event.event)}
          />
        </div>
        <div>{`${event.event.title} `}</div>
        <div>{`${event.event.extendedProps.assignedEmployeesCount} ${t(
          "from"
        )}${event.event.extendedProps.assignableEmployeesCount} `}</div>
        <div className="text-truncate">
          {event.event.extendedProps?.assignedEmployees.length > 0
            ? `${event.event.extendedProps?.assignedEmployees
                ?.map((obj) => {
                  if (obj.hasOffTime) return "";
                  return `${obj.firstName} ${obj.lastName}`;
                })
                ?.join(", ")} `
            : ""}
        </div>
        <div className="text-truncate">
          {event.event.extendedProps?.attributes.length > 0
            ? `${t("Attributes")}: ${event.event.extendedProps?.attributes
                ?.map((obj) => obj.name)
                ?.join(", ")} `
            : ""}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div></div>
          <div className="text-truncate">
            {event.event.extendedProps?.skills.length > 0
              ? `${t("Skills")}: ${event.event.extendedProps?.skills
                  ?.map((obj) => obj.name)
                  ?.join(", ")} `
              : ""}
          </div>
          <div>{event.event.address && `${event.event.address} `}</div>

          <i
            className="fa fa-trash cursor-pointer"
            onClick={(e) => handleClickPlanDelete(e, event.event)}
          />
        </div>
      </div>
    );
  };

  const eventClick = (eventClick) => {
    if (
      eventClick.jsEvent.target.classList.contains("fa-edit") ||
      eventClick.jsEvent.target.classList.contains("fa-cog") ||
      eventClick.jsEvent.target.classList.contains("fa-trash")
    ) {
      return;
    }

    const assignEmployees =
      eventClick.event.extendedProps.assignedEmployees || [];
    const isOffTimes = eventClick.event.extendedProps?.type == "offtimes";
    // Assuming eventClick.event.extendedProps.address holds the address you want to link to
    const address = eventClick.event.extendedProps.address;
    const googleMapsUrl = `http://maps.google.com/?q=${encodeURIComponent(
      address
    )}`;
    Swal.fire({
      title: isOffTimes
        ? `Holiday & Illness (${moment(eventClick.event.start).format(
            "YYYY-MM-DD"
          )})`
        : eventClick.event.title,
      html: `
      <div className="table-responsive">
            <table className="table" style="width: 100%">
            ${
              eventClick.event.extendedProps?.type === "holiday"
                ? `<tbody>
              <tr>
                <td>Start Time</td>
                <td style="text-align: right;"><strong>${moment(
                  eventClick.event.start
                ).format("YYYY-MM-DD")}</strong></td>
              </tr>
            </tbody>`
                : eventClick.event.extendedProps?.type === "offtimes"
                ? `<tbody>
              <tr>
                <td style="text-align: left;"><strong>${eventClick.event.extendedProps.titleWithTime}</strong></td>
              </tr>
            </tbody>`
                : `<tbody>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Working Area"
                )}</td>
                <td style="text-align: right;"><strong>${
                  eventClick.event.title
                }</strong></td>
              </tr>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Description"
                )}</td>
                <td style="text-align: right;"><strong>${
                  eventClick.event.extendedProps.description
                }</strong></td>
              </tr>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Start Time"
                )}</td>
                <td style="text-align: right;"><strong>${
                  eventClick.event.extendedProps.startTime
                }</strong></td>
              </tr>
              <tr style="border: 0 0 1px 0 red solid">
                <td class="text-nowrap" style="text-align: left;">${t(
                  "End Time"
                )}</td>
                <td style="text-align: right;"><strong>${
                  eventClick.event.extendedProps.endTime
                }</strong></td>
              </tr>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Assigned employees"
                )}</td>
                <td style="text-align: right;"><strong>${assignEmployees
                  ?.filter((item) => !item.hasOffTime)
                  .map((item) => `${item.firstName} ${item.lastName}`)
                  ?.join(", ")}</strong></td>
              </tr>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Attributes"
                )}</td>
                <td style="text-align: right;"><strong>${eventClick.event.extendedProps?.attributes
                  ?.map((obj) => obj.name)
                  ?.join(", ")}</strong></td>
              </tr>
              <tr>
                <td class="text-nowrap" style="text-align: left;">${t(
                  "Skills"
                )}</td>
                <td style="text-align: right;"><strong>${eventClick.event.extendedProps?.skills
                  ?.map((obj) => obj.name)
                  ?.join(", ")}</strong></td>
              </tr>
              <tr style="border: 0 0 1px 0 red solid">
              <td class="text-nowrap" style="text-align: left;">${t(
                "Address"
              )}</td>
              <td style="text-align: right;"><strong><a href="${googleMapsUrl}" target="_blank">${address}</a></strong></td>
            </tr>
          </tbody>`
            }
        </table >
      </div >
    `,
      showCancelButton: false,
      confirmButtonColor: "#d33",
      confirmButtonText: t("Close"),
    }).then((result) => {
      if (result.value) {
        // eventClick.event.remove();
        // Swal.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const fetchData = async () => {
      try {
        await fetchShiftPlans();
      } catch (error) {
        console.error("Error fetching shift plans:", error.message);
      }
    };

    fetchData();
  }, [unconfirmed]);

  const fetchShiftPlans = async () => {
    setIsLoading(true);
    try {
      const response = await getShiftPlansAPI({
        ...month,
        employee: selectedEmployee,
        workingArea: selectedWorkingArea,
      });
      const today = new Date();
      let eventData = [];
      if (unconfirmed)
        eventData = response.filter(
          (event) => !event?.confirmed && new Date(event.endTime) > today
        );
      else eventData = response;

      eventData = eventData.map((event) => {
        const endTime = new Date(event.endTime);
        const conflictEmployeeCount = event.assignedEmployees.filter(
          (employee) => employee.hasOffTime
        ).length;
        const eventConflict =
          event.conflict &&
          event?.assignedEmployees?.length - conflictEmployeeCount == 0;
        const backgroundColor =
          endTime < today
            ? "#95A5A6"
            : event?.confirmed && !eventConflict
            ? "#00831A"
            : "#FE413E";

        const isSelectedEmployeeInConflict = event.assignedEmployees.some(
          (employee) => employee.hasOffTime && employee._id === selectedEmployee
        );

        if (eventConflict) setHolidayEvent(event);
        return {
          id: event._id,
          title: event.workingArea.name,
          start: event.startTime,
          end: event.endTime,
          backgroundColor,
          borderColor: backgroundColor,
          display:
            eventConflict || isSelectedEmployeeInConflict ? "none" : "unset",
          extendedProps: {
            workingAreaId: event.workingArea._id,
            description: event.workingArea.description,
            workingShiftId: event.workingAreaShift
              ? event.workingAreaShift._id
              : null, // or any other default value or handling
            startTime: event.startTimeStr,
            endTime: event.endTimeStr,
            day: endTime.toLocaleDateString("en-US", { weekday: "short" }),
            skills: event.workingArea.skills,
            address: event.workingArea.address,
            attributes: event.workingArea.attributes,
            assignedEmployees: event.assignedEmployees.filter(
              (employee) => !employee.hasOffTime
            ),
            conflictEmployees: event.assignedEmployees
              .filter((employee) => employee.hasOffTime)
              .map((employee) => employee._id),
            assignedEmployeesCount:
              event?.assignedEmployees?.length - conflictEmployeeCount || 0,
            assignableEmployeesCount: event?.assignableEmployeesCount || 0,
            state: event.confirmed,
            conflict: event.conflict,
          },
        };
      });

      let nationalHolidays = [];
      let offTimes = [];

      if (!listView) {
        nationalHolidays = await fetchNationalHolidays();
        const uniqueTitles = new Map();

        // Iterate over nationalHolidays and add them to the map if not already present
        nationalHolidays.forEach((holiday) => {
          if (!uniqueTitles.has(holiday.title)) {
            uniqueTitles.set(holiday.title, holiday);
          }
        });

        // Convert the Map back into an array
        nationalHolidays = Array.from(uniqueTitles.values());
        offTimes = await fetchOffTimes();
      }
      //setCalendarEvents([...nationalHolidays, ...offTimes]);
      setCalendarEvents([...eventData, ...nationalHolidays, ...offTimes]);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
      console.log(error);
    }
    setIsLoading(false);
  };

  const [isDropping, setIsDropping] = useState(false);

  const handleDropEvent = async (info) => {
    const shiftId = info.draggedEl.getAttribute("shiftId");
    const hasOffTimeEmployees = info.draggedEl.getAttribute(
      "hasOffTimeEmployees"
    );

    if (shiftId && !isDropping) {
      setIsDropping(true);
      removeOffTimeEmployeesAPI(shiftId, { hasOffTimeEmployees })
        .then((res) => {
          fetchShiftPlans();
          toast.success("Succesfully moved to the calendar view", {
            autoClose: 1000,
          });
          setIsDropping(false);
        })
        .catch((error) => {
          toast.error(
            "Something else on moving shifts to the calender view, please try again",
            { autoClose: 1000 }
          );
          console.log(error);
          setIsDropping(false);
        });
      return;
    }

    const draggedDay = info.draggedEl.getAttribute("day");
    const droppedDay = info.date.toLocaleDateString("en-US", {
      weekday: "short",
    });

    if (draggedDay.toLowerCase() !== droppedDay.toLowerCase()) {
      toast.error("Invalid drop: Day mismatch", { autoClose: 2000 });
      return;
    }

    const date = info.dateStr;
    const startTime = `${info.draggedEl.getAttribute("start-time")} `;
    const endTime = `${info.draggedEl.getAttribute("end-time")} `;
    const day = `${info.draggedEl.getAttribute("day")} `;
    const workingAreaId = info.draggedEl.getAttribute("working-area-id");
    const workingAreaShiftId = info.draggedEl.getAttribute(
      "working-area-shift-id"
    );

    try {
      if (isDropping) return;
      setIsDropping(true);
      await insertShiftPlanAPI({
        workingAreaId,
        workingAreaShiftId,
        startTime,
        endTime,
        date,
        day,
      });
      setIsDropping(false);
      fetchShiftPlans();
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const handleClearMonthlyPlan = () => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t(
        "All data for the current month will be deleted, but not the past data."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, clear it!"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await clearShiftPlansAPI({
            month: moment(month.start)
              .add(10, "days")
              .startOf("month")
              .format("YYYY-MM-DD"),
          });
          toast.success(res.message || "Successfully cleared!", {
            autoClose: 2000,
          });
          fetchShiftPlans();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleSingleShiftButtonClick = (e) => {
    e.stopPropagation();
    setSingleShiftPlanModal(true);
  };

  const handleExportToExcel = () => {
    try {
      const data = calendarEvents.map((event) => ({
        Date: moment(event.start).format("YYYY-MM-DD"),
        Start: moment(event.start).format("HH:mm"),
        End: moment(event.end).format("HH:mm"),
        AssignedEmployees: event.extendedProps.assignedEmployees
          ?.map((item) => `${item.firstName} ${item.lastName} `)
          ?.join(", "),
        WorkingArea: event.title,
        Skills: event.extendedProps.skills?.map((obj) => obj.name)?.join(", "),
        Attributes: event.extendedProps.attributes
          ?.map((obj) => obj.name)
          ?.join(", "),
        State: event.extendedProps.state ? "confirmed" : "open",
      }));

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "data");
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([new Uint8Array(excelBuffer)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });

      FileSaver.saveAs(blob, "shift-plan.xlsx");
    } catch (error) {
      console.error("Error during export:", error);
    }
  };

  const handleCreateShiftPlanAutomatically = async () => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t(
        "Attention! Rosterrocket create a new shift plan automatically for you."
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Ok, create it"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          setIsCreating(true);
          try {
            await insertShiftPlanAutomaticallyAPI();
            toast.success("Successfully created!", { autoClose: 2000 });
            fetchShiftPlans();
          } catch (error) {
            const message =
              error?.response?.data?.message || "Something went wrong!";
            toast.error(message, { autoClose: 2000 });
          } finally {
            setIsCreating(false);
          }
        }
      }
    });
  };

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, { itemSelector: ".fc-event" });

    let draggableEl2 = document.getElementById("external-events2");
    new Draggable(draggableEl2, { itemSelector: ".fc-event" });
  }, []);

  useEffect(() => {
    fetchEmployees();
  }, [debouncedSearchEmployee]);

  useEffect(() => {
    fetchWorkingAreas();
  }, [debouncedSearchWorkingArea]);

  useEffect(() => {
    if (month.start && month.end) {
      fetchShiftPlans();
      fetchOffTimes();
    }
  }, [month, selectedEmployee, selectedWorkingArea]);

  const maintTitleTran = t("Shift plan");

  const [sortedCalendarEvents, setSortedCalendarEvents] = useState([]);

  const getDayOfWeek = (date) => {
    return new Date(date).getDay();
  };

  useEffect(() => {
    setSortedCalendarEvents(() => {
      return calendarEvents.sort((a, b) => {
        return getDayOfWeek(a.start) - getDayOfWeek(b.start);
      });
    });
  }, [calendarEvents]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} title={maintTitleTran} />
      <Container fluid={true} className="calendar-basic">
        <Col sm="12">
          <Card>
            <div className="d-flex justify-content-end align-items-center p-3">
              <Btn
                attrBtn={{
                  className: "px-2 py-1 me-2",
                  color: "primary",
                  onClick: handleCreateShiftPlanAutomatically,
                  disabled: isCreating,
                }}
              >
                {isCreating && (
                  <Spinner size="sm" color="light" className="me-1" />
                )}
                {t("Create automatically")}
                <i
                  className="fa fa-info-circle ms-1"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html={t(
                    "The shift plan will be created for 1 year from now. This process can take up to 3 minutes."
                  )}
                  data-tooltip-place="top"
                />
              </Btn>
              {userInfo?.role === "admin" && (
                <Btn
                  attrBtn={{
                    className: "px-2 py-1 me-5",
                    color: "primary",
                    onClick: handleClearMonthlyPlan,
                  }}
                >
                  {t("Clear monthly plan")}
                </Btn>
              )}
              <Btn
                attrBtn={{
                  className: "px-2 py-1",
                  color: "primary",
                  onClick: handleExportToExcel,
                }}
              >
                {t("Export to excel")}
              </Btn>
            </div>
          </Card>
        </Col>

        <Col sm="12">
          <Card className="p-2">
            <Row>
              <Col sm="2"></Col>
              <Col sm="8">
                <H5 attrH5={{ className: "text-center" }}>
                  {t("Ready to be Managed!")}
                  <i
                    className="fa fa-info-circle ms-1"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html={t(
                      "These are open shifts which are ready to manage. <br /> Please click on the shift and assign a new employee. <br /> You can move the open shift with drag & and drop to the calendar. <br /> You can delete it, if the shift is not more needed."
                    )}
                    data-tooltip-place="top"
                  />
                </H5>
                <div
                  id="external-events2"
                  style={{ position: "static", padding: 0, border: "none" }}
                >
                  <div
                    className="p-3 mb-2 border"
                    style={{
                      borderRadius: 16,
                      display: "flex",
                      gap: "10px",
                      overflow: "auto",
                    }}
                  >
                    {sortedCalendarEvents.length > 0 &&
                      sortedCalendarEvents.map((e) => {
                        const skillNames =
                          e.extendedProps?.skills &&
                          e.extendedProps.skills
                            .map((skill) => skill.name)
                            .join(", ");
                        const hasOffTimeEmployees =
                          e.extendedProps.assignedEmployees &&
                          e.extendedProps.assignedEmployees
                            .filter((employee) => employee.hasOffTime)
                            .map((employee) => employee);
                        return (
                          e.extendedProps.conflict && (
                            <LI
                              key={e.id}
                              attrLI={{
                                className: `list-group-item fc-event cursor-pointer border-0`,
                                shiftId: e.id,
                                hasOffTimeEmployees: hasOffTimeEmployees,
                              }}
                            >
                              <div
                                className="text-center p-2 scale-button "
                                onClick={(event) =>
                                  handleClickDropOutEdit(event, e)
                                }
                                style={{ minWidth: 200 }}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ flexDirection: "column" }}
                                >
                                  <strong>
                                    {moment(e.start).format("DD.MM.YYYY")}
                                  </strong>
                                  <div>
                                    {e.extendedProps.startTime} -{" "}
                                    {e.extendedProps.endTime}
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      right: 3,
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash cursor-pointer"
                                      onClick={(ee) =>
                                        handleClickOpenCardDelete(ee, e)
                                      }
                                    />
                                  </div>
                                </div>
                                <div>{e.title}</div>
                                <div>{`0 from ${
                                  e.extendedProps?.assignableEmployeesCount -
                                  e.extendedProps.assignedEmployees.length
                                }`}</div>
                                {skillNames && (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-truncate">
                                      {`Skills: ${skillNames} `}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </LI>
                          )
                        );
                      })}
                  </div>
                </div>
              </Col>
              <Col className="d-flex align-items-center" sm="2">
                <Btn
                  attrBtn={{
                    className: "px-1 py-1",
                    color: "primary",
                    onClick: () => setListView(!listView),
                    style: {
                      width: "100px",
                      height: "50px",
                      marginRight: "5px",
                    },
                  }}
                >
                  {listView ? t("Calendar view") : t("List view")}
                </Btn>
                <Btn
                  attrBtn={{
                    className: "px-1 py-1 ml-2",
                    color: "primary",
                    onClick: () => setUnconfirmed(!unconfirmed),
                    style: { width: "100px", height: "50px" },
                  }}
                >
                  {unconfirmed ? t("Unconfirmed") : t("All")}
                </Btn>
              </Col>
            </Row>

            <Row>
              <Col sm="2">
                <H5 attrH5={{ className: "text-center mb-2" }}>
                  {t("Employees")}
                  <i
                    className="fa fa-info-circle ms-1"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html={t(
                      "With click on the employee, all working areas and <br /> shifts which are assigned will be load."
                    )}
                    data-tooltip-place="top"
                  />
                </H5>
                <div
                  className="px-2 py-4 border mb-3"
                  style={{ borderRadius: 16 }}
                >
                  <InputGroup className="mb-3">
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={t("Search by name...")}
                      name="search"
                      value={searchEmployee}
                      onChange={handleSearchEmployeeChange}
                    />
                    {searchEmployee && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearchEmployee("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                  <UL>
                    <div>
                      {employees?.map((employee) => (
                        <LI
                          key={employee._id}
                          attrLI={{
                            className: `list-group-item border-0 p-2 cursor-pointer ${
                              selectedEmployee === employee._id
                                ? "bg-primary"
                                : ""
                            } `,
                            onClick: () => handleSelectEmployee(employee._id),
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </LI>
                      ))}
                    </div>
                  </UL>
                </div>

                <Link to="/employees/create">
                  <Btn
                    attrBtn={{ className: "px-2 py-1 w-100", color: "primary" }}
                  >
                    <i className="fa fa-plus" />
                    {t("New employee")}
                  </Btn>
                </Link>
              </Col>
              <Col sm="8">
                {listView ? (
                  <ListView
                    calendarEvents={calendarEvents}
                    handleClickPlanSetting={handleClickPlanSetting}
                    handleClickPlanEdit={handleClickPlanEdit}
                    handleClickPlanDelete={handleClickPlanDelete}
                    handleMonthChange={(type) => {
                      const newMonth =
                        type === "prev"
                          ? moment(month.start).subtract(1, "month")
                          : moment(month.start).add(1, "month");
                      setMonth({
                        start: newMonth.startOf("month").format("YYYY-MM-DD"),
                        end: newMonth.endOf("month").format("YYYY-MM-DD"),
                      });
                    }}
                    startMonth={month.start}
                  />
                ) : (
                  <div className="basic-calendar">
                    <div className="demo-app-calendar" id="mycalendartest">
                      <FullCalendar
                        headerToolbar={{
                          left: "prev,next,today,singleShift",
                          center: "title",
                          right: "",
                        }}
                        buttonText={{ today: t("today") }}
                        customButtons={{
                          singleShift: {
                            text: "Single Shift",
                            click: handleSingleShiftButtonClick,
                          },
                        }}
                        rerenderDelay={10}
                        eventDurationEditable={false}
                        editable={false}
                        droppable={false}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        events={calendarEvents}
                        eventClick={eventClick}
                        eventDisplay="block"
                        eventContent={eventContent}
                        drop={handleDropEvent}
                        datesSet={(info) => {
                          setMonth({
                            start: moment(info.start).format("YYYY-MM-DD"),
                            end: moment(info.end).format("YYYY-MM-DD"),
                          });
                        }}
                        initialDate={
                          month?.start
                            ? moment(month.start)
                                .add(10, "days")
                                .startOf("month")
                                .format("YYYY-MM-DD")
                            : undefined
                        }
                      />
                    </div>
                  </div>
                )}
              </Col>
              <Col sm="2">
                <H5 attrH5={{ className: "text-center" }}>
                  {t("Working areas")}
                  <i
                    className="fa fa-info-circle ms-1"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html={t(
                      "With click on the working area, all assigned employees will be load. <br /> With Drag & Drop, you can move the selected working area to the shift plan."
                    )}
                    data-tooltip-place="top"
                  />
                </H5>

                <div
                  className="px-2 py-4 border mb-3"
                  style={{ borderRadius: 16 }}
                >
                  <InputGroup className="mb-3">
                    <InputGroupText>
                      <i className="fa fa-search" />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={t("Search by name...")}
                      name="search"
                      value={searchWorkingArea}
                      onChange={handleSearchWorkingAreaChange}
                    />
                    {searchWorkingArea && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearchWorkingArea("")}
                        />
                      </InputGroupText>
                    )}
                  </InputGroup>

                  <div
                    id="external-events"
                    style={{ position: "static", padding: 0, border: "none" }}
                  >
                    <UL>
                      <div className="scroll-container">
                        {workingAreas?.map((workingArea) =>
                          workingArea.shifts.map((shift) =>
                            shift.days.map((day, index) => {
                              const shiftKey = `${workingArea._id} -${shift._id} -${index} `;

                              return (
                                <LI
                                  key={shiftKey}
                                  attrLI={{
                                    className: `list-group-item border p-2 cursor-pointer fc-event ${
                                      selectedWorkingArea ===
                                      `${workingArea._id}-${day}-${shift.startTime}-${shift.endTime}`
                                        ? "bg-primary"
                                        : ""
                                    } `,
                                    onClick: () =>
                                      handleSelectWorkingArea(
                                        `${workingArea._id}-${day}-${shift.startTime}-${shift.endTime}`
                                      ),
                                    title: workingArea.name,
                                    day: day,
                                    "start-time": shift.startTime,
                                    "end-time": shift.endTime,
                                    day: day,
                                    "working-area-id": workingArea._id,
                                    "working-area-shift-id": shift._id,
                                  }}
                                >
                                  <div>{workingArea.name}</div>
                                  <div>
                                    {day}: {shift.startTime} - {shift.endTime}/
                                    {shift.interval}
                                  </div>
                                </LI>
                              );
                            })
                          )
                        )}
                      </div>
                    </UL>
                  </div>
                </div>
                <Link to="/working-areas/create">
                  <Btn
                    attrBtn={{
                      className: "px-2 py-1 w-100",
                      color: "primary",
                    }}
                  >
                    <i className="fa fa-plus"></i> {t("New working area")}
                  </Btn>
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />

      <AssignEmployeesModal
        isOpen={openAssignEmployeesModal}
        toggler={toggleAssignEmployeesModal}
        shiftPlan={selectedShiftPlan}
        fetchShiftPlans={fetchShiftPlans}
      />

      <UpdateShiftPlanModal
        isOpen={openUpdateShiftPlanModal}
        toggler={toggleUpdateShiftPlanModal}
        shiftPlan={selectedShiftPlan}
        fetchShiftPlans={fetchShiftPlans}
      />

      <UpdateSingleShiftModal
        isOpen={openUpdateSingleShiftModal}
        toggler={toggleUpdateSingleShiftModal}
        shiftPlan={selectedShiftPlan}
        fetchShiftPlans={fetchShiftPlans}
      />

      <SingleShiftPlanModal
        isOpen={openSingleShiftPlanModal}
        toggler={toggleSingleShiftPlanModal}
        fetchShiftPlans={fetchShiftPlans}
      />
      <DropOutAssignModal
        isOpen={openDropOutAssignModal}
        toggler={toggleDropOutAssignModal}
        shiftPlan={selectedDropOut}
        fetchShiftPlans={fetchShiftPlans}
      />
    </Fragment>
  );
};

export default ShiftPlan;
