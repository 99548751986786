import React from "react";
import { Card, CardBody } from "reactstrap";
import SvgIcon from "../Component/SvgIcon";
import { useTranslation } from "react-i18next";
const Widgets1 = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Card className="widget-1">
      <CardBody>
        <div className="widget-content">
          <div className={`widget-round ${data.color}`}>
            <div className="bg-round">
              <SvgIcon className="svg-fill" iconId={`${data.icon}`} />
              <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
            </div>
          </div>
          <div>
            <p className="custom-p">
              {t(data.title)}: {data.total}
            </p>
            {data.title_1 && (
              <>
                <p className="custom-p">
                  {t(data.title_1)}: {data.total_1}
                </p>
              </>
            )}
            {data.title_2 && (
              <>
                <p className="custom-p">
                  {t(data.title_2)}: {data.total_2}
                </p>
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Widgets1;
