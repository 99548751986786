import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Spinner as ReactStrapSpinner,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn, H5, Spinner } from "../../../AbstractElements";
import {
  getSettingDetailsAPI,
  getCountriesAPI,
  getFederalStatesAPI,
  saveNationalHolidays,
} from "../../../Library/apis";
import CustomizerContext from "../../../_helper/Customizer";

const Form = () => {
  const { mixLayout } = useContext(CustomizerContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [settings, setSettings] = useState({});
  const [countries, setCountries] = useState([]);
  const [nationalHolidays, setNationalHolidays] = useState([]);
  const [federalStateOptions, setFederalStateOptions] = useState([]);
  // Add validation error states
  const [validationErrors, setValidationErrors] = useState([]);

  const fetchSettingDetails = async () => {
    setLoading(true);
    try {
      const response = await getSettingDetailsAPI();
      setSettings(response);
      const savedNationalHolidays = response?.nationalHolidays || [];
      setNationalHolidays(savedNationalHolidays);

      // Fetch federal states for each country-state combination
      await Promise.all(
        savedNationalHolidays.map(async (item, index) => {
          const response = await getFederalStatesAPI({
            countryCode: item.country,
          });
          const federalStateOptionsForIndex = response?.map((stateItem) => ({
            label: stateItem?.name?.find((obj) => obj?.language === "EN")?.text,
            value: stateItem?.code,
          }));

          setFederalStateOptions((prevOptions) => {
            const updatedOptions = [...prevOptions];
            updatedOptions[index] = federalStateOptionsForIndex;
            return updatedOptions;
          });
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountriesAPI();

      const countryOptions = response?.map((item) => ({
        label: item?.name?.[0]?.text,
        value: item?.isoCode,
      }));

      setCountries(countryOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchFederalStates = async (countryCode, index) => {
    try {
      const response = await getFederalStatesAPI({ countryCode });

      const federalStateOptionsForIndex = response?.map((item) => ({
        label: item?.name?.find((obj) => obj?.language === "EN")?.text,
        value: item?.code,
      }));

      const updatedFederalStateOptions = [...federalStateOptions];
      updatedFederalStateOptions[index] = federalStateOptionsForIndex;
      setFederalStateOptions(updatedFederalStateOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddNationalHoliday = () => {
    setNationalHolidays([...nationalHolidays, { country: "", state: "" }]);
    setFederalStateOptions([...federalStateOptions, []]);
  };

  const handleCountryChange = async (selectedOption, index) => {
    const updatedNationalHolidays = [...nationalHolidays];
    updatedNationalHolidays[index].country = selectedOption.value;
    updatedNationalHolidays[index].state = ""; // Reset the state when the country changes
    setNationalHolidays(updatedNationalHolidays);

    // Clear validation errors for the country field
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = {
      ...updatedValidationErrors[index],
      country: false,
    };
    setValidationErrors(updatedValidationErrors);

    // Fetch federal states for the selected country
    await fetchFederalStates(selectedOption.value, index);
  };

  const handleFederalStateChange = (selectedOption, index) => {
    const updatedNationalHolidays = [...nationalHolidays];
    updatedNationalHolidays[index].state = selectedOption.value;
    setNationalHolidays(updatedNationalHolidays);

    // Clear validation errors for the state field
    const updatedValidationErrors = [...validationErrors];
    updatedValidationErrors[index] = {
      ...updatedValidationErrors[index],
      state: false,
    };
    setValidationErrors(updatedValidationErrors);
  };

  const handleRemoveNationalHoliday = (index) => {
    const updatedNationalHolidays = [...nationalHolidays];
    updatedNationalHolidays.splice(index, 1);
    setNationalHolidays(updatedNationalHolidays);

    const updatedFederalStateOptions = [...federalStateOptions];
    updatedFederalStateOptions.splice(index, 1);
    setFederalStateOptions(updatedFederalStateOptions);
  };

  const handleBack = () => {
    window.history.back();
  };

  const handleSubmit = async () => {
    // Check if there are any empty countries or states in the nationalHolidays array
    const hasEmptyFields = nationalHolidays.some(
      (holiday) => !holiday.country || !holiday.state
    );

    if (hasEmptyFields) {
      // Set validation errors for each field
      const errors = nationalHolidays.map((holiday) => ({
        country: !holiday.country,
        state: !holiday.state,
      }));
      setValidationErrors(errors);
      return;
    }

    // Clear validation errors if there are no issues
    setValidationErrors([]);

    setSubmitting(true);
    try {
      await saveNationalHolidays({ ...settings, nationalHolidays });

      toast.success("Successfully saved!", {
        autoClose: 2000,
      });
      handleBack();
    } catch (error) {
      console.log(error.response.data);

      toast.error(error?.response?.data?.message || "Something went wrong!", {
        autoClose: 2000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchSettingDetails();
    fetchCountries();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={t("Import national holidays")}
        title="Import national holidays"
      />
      <Container fluid={true}>
        <Col sm="6">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <CardBody>
              <div className="d-flex align-items-center justify-content-center mb-3">
                <H5>{t("Import national holidays")}</H5>
                <Btn
                  attrBtn={{
                    className: "ms-4 px-2 py-1",
                    color: "light",
                    onClick: handleAddNationalHoliday,
                  }}
                >
                  <i className="fa fa-plus" />
                </Btn>
              </div>

              {loading ? (
                <div className="loader-box">
                  <Spinner attrSpinner={{ className: "loader-30" }} />
                </div>
              ) : (
                /* List of national holidays area */
                nationalHolidays.map((holiday, index) => (
                  <Row className="mb-3 g-3" key={index}>
                    <Col sm="5">
                      <Row>
                        <Label className="col-sm-4 col-form-label required">
                          {t("Country")}
                        </Label>
                        <Col sm="7">
                          <Select
                            name={`nationalHolidays[${index}].country`}
                            options={countries}
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: state.isFocused
                                  ? "#80bdff" // Set focused border color
                                  : validationErrors[index]?.country
                                  ? "#dc3545" // Set invalid border color
                                  : mixLayout
                                  ? provided.borderColor
                                  : "#1d1e26", // Use default border
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                                ...(validationErrors[index]?.country
                                  ? {
                                      paddingRight: "calc(1.5em + 0.75rem)",
                                      backgroundImage:
                                        'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition:
                                        "right calc(0.375em + 0.1875rem) center",
                                      backgroundSize:
                                        "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
                                    }
                                  : {}),
                              }),

                              menu: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              singleValue: (provided, state) => ({
                                ...provided,
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              container: (provided, state) => ({
                                ...provided,
                                borderColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e26",
                              }),
                              multiValue: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e6",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              multiValueLabel: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e29",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                            }}
                            onChange={(selectedOption) =>
                              handleCountryChange(selectedOption, index)
                            }
                            value={
                              countries.find(
                                (option) => option.value === holiday.country
                              ) || null
                            }
                          />
                          {validationErrors[index]?.country && (
                            <span className="mt-2 text-danger">
                              Country is required
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6">
                      <Row>
                        <Label className="col-sm-5 col-form-label required">
                          {t("Federal state")}
                        </Label>
                        <Col sm="7">
                          <Select
                            name={`nationalHolidays[${index}].state`}
                            options={federalStateOptions[index] || []} // Add a check for undefined
                            onChange={(selectedOption) =>
                              handleFederalStateChange(selectedOption, index)
                            }
                            value={
                              (federalStateOptions[index] || []).find(
                                (option) => option.value === holiday.state
                              ) || null
                            }
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                borderColor: state.isFocused
                                  ? "#80bdff" // Set focused border color
                                  : validationErrors[index]?.state
                                  ? "#dc3545" // Set invalid border color
                                  : mixLayout
                                  ? provided.borderColor
                                  : "#1d1e26", // Use default border
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                                ...(validationErrors[index]?.state
                                  ? {
                                      paddingRight: "calc(1.5em + 0.75rem)",
                                      backgroundImage:
                                        'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition:
                                        "right calc(0.375em + 0.1875rem) center",
                                      backgroundSize:
                                        "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
                                    }
                                  : {}),
                              }),

                              menu: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "white"
                                  : "#1d1e26",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              placeholder: (provided, state) => ({
                                ...provided,
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              singleValue: (provided, state) => ({
                                ...provided,
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              container: (provided, state) => ({
                                ...provided,
                                borderColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e26",
                              }),
                              multiValue: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e6",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                              multiValueLabel: (provided, state) => ({
                                ...provided,
                                backgroundColor: mixLayout
                                  ? "#ffffffaa"
                                  : "#1d1e29",
                                color: mixLayout ? "#1d1e26" : "#ffffffaa",
                              }),
                            }}
                          />
                          {validationErrors[index]?.state && (
                            <span className="mt-2 text-danger">
                              State is required
                            </span>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col sm="1" className="d-flex align-items-center"></Col> */}
                    <Col sm="1" className="d-flex align-items-center">
                      {holiday.hasOwnProperty("status") && (
                        <i
                          className={`me-4 fa fa-lg ${
                            holiday.status
                              ? "fa-check-circle text-success"
                              : "fa-times-circle text-danger"
                          }`}
                        />
                      )}

                      <i
                        className="fa fa-times cursor-pointer red-icon"
                        onClick={() => handleRemoveNationalHoliday(index)}
                      />
                    </Col>
                  </Row>
                ))
              )}

              <Btn
                attrBtn={{
                  color: "success",
                  disabled: submitting,
                  onClick: handleSubmit,
                }}
              >
                {submitting && <ReactStrapSpinner size="sm" color="light" />}
                {t("Save")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Form;
