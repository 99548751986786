import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import ProfileTab from './ProfileTab';
import { useTranslation } from 'react-i18next'
const AccountSettings = () => {
  const { t } = useTranslation();
  const maintTitle = "Edit Profile";
  const maintTitleTran = t(maintTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} parent='Users' title='Edit Profile' />
      <Container fluid={true}>
        <div className='edit-profile'>
          <Col xl='12'>
            <ProfileTab />
          </Col>
        </div>
      </Container>
    </Fragment>
  );
};
export default AccountSettings;
