import React, { useContext } from "react";
import { Row, Col, Card } from "reactstrap";
import UserContext from "../../../_helper/User";
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";
import { H3, H5 } from "../../../AbstractElements";
import { wiki } from "../../../Constant";
const Wiki = () => {
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation();

  // Create an array of 12 elements to map over
  const cards = Array.from({ length: 10 }, (_, index) => index);
  console.log(wiki);

  const extractVideoId = (url) => {
    const videoId = url.split("/").pop();
    console.log(videoId);
    return videoId;
  };

  return (
    <Row>
      {wiki.map((item, index) => (
        <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6} xs={12}>
          <Card style={{ marginTop: "50px", position: "relative" }}>
            {/* Title */}
            <div
              className="profile-title"
              style={{ padding: "10px", textAlign: "center" }}
            >
              <H5 attrH3={{ className: "mb-1" }}>{t(item?.title)}</H5>
            </div>
            {/* Video */}
            <div
              style={{
                position: "relative",
                paddingTop: "56.25%", // This maintains a 16:9 aspect ratio
                width: "100%",
                height: 0,
              }}
            >
              <iframe
                title="YouTube Video"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={`https://www.youtube.com/embed/${extractVideoId(
                  item.videoLink
                )}?autoplay=0&mute=0&controls=1`}
                frameBorder="0"
                allowFullScreen
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>

          </Card>
        </Col>
      ))}
    </Row>

    // <Row>
    //   {wiki.map((item, index) => (
    //     <Col key={index} xxl={3} xl={3} lg={4} md={6} sm={6} xs={12}>
    //       <Card style={{ marginTop: "50px" }}>
    //         {" "}
    //         <Row className="mb-2 mt-4 ms-4">
    //           <div className="profile-title">
    //             <div className="media">
    //               {/* <Avatar name={'A'} size="120" round /> */}
    //               <H5 attrH3={{ className: "mb-1" }}>{t(item?.title)}</H5>
    //             </div>
    //           </div>
    //         </Row>
    //       </Card>
    //     </Col>
    //    ))}
    // </Row>
  );
};

export default Wiki;
