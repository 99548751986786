import axios from "axios";
import Cookies from "js-cookie";
import qs from "query-string";
export function logOut() {
  if (window.refreshTokenInterval) {
    clearInterval(window.refreshTokenInterval);
    window.refreshTokenInterval = null;
  }
  if (window.refreshDataInterval) {
    clearInterval(window.refreshDataInterval);
    window.refreshDataInterval = null;
  }

  localStorage.removeItem("token");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("login");
  localStorage.removeItem("authenticated");
  localStorage.removeItem("authenticated");
}

export const checkUserAuth = () => localStorage.getItem("token");

export function getHeaders() {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}` || "",
    "Content-Type": "application/json",
  };
  return headers;
}

export function getHeaderForFile() {
  let token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}` || "",
    "Content-Type": "multipart/form-data",
  };
  return headers;
}

export function apiReq(
  endPoint,
  data,
  method,
  headers,
  requestOptions = {},
  shouldRefreshToken = true
) {
  var inFifteenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
  Cookies.set("apiCalled", "true", { expires: inFifteenMinutes });

  return new Promise((resolve, reject) => {
    headers = {
      ...getHeaders(),
      ...headers,
    };
    if (method === "get" || method === "delete") {
      data = {
        ...requestOptions,
        params: data,
        headers,
        data: {},
      };
      data.paramsSerializer = (params) =>
        qs.stringify(params, { arrayFormat: "repeat" });
    }
    axios({
      url: endPoint,
      method: method.toUpperCase(),
      data,
      headers,
      ...requestOptions,
    })
      .then((result) => {
        const { data } = result;
        if (data.status === false) {
          return reject(data);
        }

        return resolve(data);
      })
      .catch((error) => {
        return reject(error);
      });
  });
}
export function apiGet(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "get", headers, requestOptions);
}
export function apiPost(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "post", headers, requestOptions);
}
export function apiPatch(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "patch", headers, requestOptions);
}
export function apiPut(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "put", headers, requestOptions);
}
export function apiDelete(endPoint, data, headers = {}, requestOptions) {
  return apiReq(endPoint, data, "delete", headers, requestOptions);
}

export function apiPost1(endPoint, data, headers = {}, requestOptions) {
  return apiReq1(endPoint, data, "post", headers, requestOptions);
}

export function apiReq1(
  endPoint,
  data,
  method,
  headers = {},
  requestOptions = {}
) {
  return fetch(endPoint, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
    ...requestOptions,
  });
}
