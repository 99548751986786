import React, { Fragment, useEffect, useState } from "react";
import { Container, Col, Card, CardHeader, CardBody } from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn, UL, LI } from "../../../../AbstractElements";
import {
  deleteWorkingAreaSkillAPI,
  getWorkingAreaSkillsListAPI,
  createWorkingAreaSkillAPI,
} from "../../../../Library/apis";
import FormModal from "./FormModal";

const Skills = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skillModal, setSkillModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const skillToggle = () => {
    setSkillModal(!skillModal);
    resetForm();
  };

  const fetchSkills = async () => {
    setLoading(true);

    try {
      const response = await getWorkingAreaSkillsListAPI();

      setData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsDeleting(true);

        try {
          const res = await deleteWorkingAreaSkillAPI(id);
          toast.success(res.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchSkills();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // ... other validations ...
  });

  const formik = useFormik({
    initialValues: { name: "" },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        await createWorkingAreaSkillAPI(values);
        toast.success(`Successfully added!`, { autoClose: 2000 });
        skillToggle();
        fetchSkills();
      } catch (error) {
        console.log(error.response.data);
        if (
          error.response &&
          error.response.data?.includes(
            "E11000 duplicate key error collection: roster_rocket.workingareaskills index: name_1_companyId_1 dup key"
          )
        ) {
          // Handle the duplicate name error
          setFieldError("name", "This name is already taken");
        }

        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const { resetForm } = formik;
  const { t, i18n } = useTranslation();
  const mainTitle = "Skills";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} title="Skills" />
      <Container fluid={true}>
        <Col sm="12">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10, left: 10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardHeader>
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: skillToggle,
                }}
              >
                <i className="fa fa-plus"></i> {t("New skill")}
              </Btn>
            </CardHeader>

            <CardBody>
              <UL attrUL={{ className: "simple-list border-0" }}>
                {data?.map((item) => (
                  <LI
                    key={item._id}
                    attrLI={{
                      className: "border-0 p-2 list-group-item-action",
                    }}
                  >
                    {item.name}
                    <i
                      className="fa fa-close ms-2 cursor-pointer"
                      onClick={() => handleDelete(item._id)}
                    ></i>
                  </LI>
                ))}
              </UL>
            </CardBody>
          </Card>
        </Col>
      </Container>

      <FormModal
        skillModal={skillModal}
        skillToggle={skillToggle}
        formik={formik}
      />
    </Fragment>
  );
};

export default Skills;
