import React from "react";
import { Row } from "reactstrap";
import Fade from "react-reveal/Fade";

import { H1 } from "../../../../AbstractElements";
import TopHeader from "../TopHeader";
import Footer from "../Footer";

import landing_bg from "../../../../assets/images/landing/landing_top_banner.jpg";

import "../index.css";

const PrivacyPage = () => {
  const sessionData = [
    {
      title: "1. An overview of data protection",
      subTitle: "General information",
      texts: [
        "The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.",
      ],
    },
    {
      subTitle: "Data recording on this website",
      texts: [
        "Who is the responsible party for the recording of data on this website (i.e., the “controller”)? The data on this website is processed by the operator of the website, whose contact information is available under section “Information about the responsible party (referred to as the “controller” in the GDPR)” in this Privacy Policy. How do we record your data? We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form. Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time the site was accessed). This information is recorded automatically when you access this website. What are the purposes we use your data for? A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.  What rights do you have as far as your information is concerned? You have the right to receive information about the source, recipients, and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency. Please do not hesitate to contact us at any time if you have questions about this or any other data protection related issues",
      ],
    },
    {
      subTitle: "Analysis tools and tools provided by third parties",
      texts: [
        "There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs. For detailed information about these analysis programs please consult our Data Protection Declaration below.",
      ],
    },
    {
      title: "2. Hosting and Content Delivery Networks (CDN)",
      subTitle: "Data processing",
      texts: [
        "We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our instructions and in compliance with the GDPR",
      ],
    },
    {
      title: "Cloudflare",
      subTitle: "Information We Collect",
      texts: [
        `We use the “Cloudflare” service provided by Cloudflare Inc., 101 Townsend St., San Francisco, CA 94107, USA. (hereinafter
            referred to as “Cloudflare”).
            Cloudflare offers a content delivery network with DNS that is available worldwide. As a result, the information transfer that
            occurs between your browser and our website is technically routed via Cloudflare’s network. This enables Cloudflare to analyze
            data transactions between your browser and our website and to work as a filter between our servers and potentially malicious
            data traffic from the Internet. In this context, Cloudflare may also use cookies or other technologies deployed to recognize
            Internet users, which shall, however, only be used for the herein described purpose.
            The use of Cloudflare is based on our legitimate interest in a provision of our website offerings that is as error free and secure
            as possible (Art. 6(1)(f) GDPR).
            Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
            found here: https://www.cloudflare.com/privacypolicy/.
            For more information on Cloudflare’s security precautions and data privacy policies, please follow this
            link: https://www.cloudflare.com/privacypolicy/`,
      ],
    },
    {
      title: "3. General information and mandatory information",
      subTitle: "Data protection",
      texts: [
        `The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your
        personal data as confidential information and in compliance with the statutory data protection regulations and this Data
        Protection Declaration.
        Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be
        used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use
        this data for. It also explains how, and for which purpose the information is collected.
        We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be prone to
        security gaps. It is not possible to completely protect data against third-party access`,
      ],
    },
    {
      subTitle:
        "Information about the responsible party (referred to as the “controller” in the GDPR)",
      texts: [
        `The data processing controller on this website is:`,
        `Rosterrocket S.R.L`,
        `Benjamin Nieß`,
        `Drumul Padurea Pustnicu Nr. 127c`,
        `0114042 Bucharest`,
        `Romania`,
        `Contact`,
        `Mobil: +49 (0) 15566954084`,
        `Fax: 0049 (0) 351 / 85074536`,
        `E-Mail: info@rosterrocket.de`,
        `The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes
        of and resources for the processing of personal data (e.g., names, e-mail addresses, etc.).`,
      ],
    },
    {
      subTitle: "Storage duration",
      texts: [
        `Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the
        purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data
        processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g., tax
        or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.`,
      ],
    },
    {
      subTitle:
        "General information on the legal basis for the data processing on this website",
      texts: [
        `If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or Art. 9 (2)(a)
      GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of explicit consent to the transfer
      of personal data to third countries, the data processing is also based on Art. 49 (1)(a) GDPR. If you have consented to the
      storage of cookies or to the access to information in your end device (e.g., via device fingerprinting), the data processing is
      additionally based on § 25 (1) TTDSG. The consent can be revoked at any time. If your data is required for the fulfillment of a
      contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR.
      Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6(1)(c) GDPR.
      Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6(1)(f) GDPR.
      Information on the relevant legal basis in each individual case is provided in the following paragraphs of this privacy policy.`,
      ],
    },
    {
      subTitle: `Information on data transfer to the USA and other non-EU countries`,
      texts: [
        `Among other things, we use tools of companies domiciled in the United States or other from a data protection perspective nonsecure non-EU countries. If these tools are active, your personal data may potentially be transferred to these non-EU countries
      and may be processed there. We must point out that in these countries, a data protection level that is comparable to that in the
      EU cannot be guaranteed. For instance, U.S. enterprises are under a mandate to release personal data to the security agencies
      and you as the data subject do not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S.
      agencies (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for surveillance
      purposes. We have no control over these processing activities.`,
      ],
    },
    {
      subTitle: `Revocation of your consent to the processing of data`,
      texts: [
        `A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any
      time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that
      occurred prior to your revocation.`,
      ],
    },
    {
      subTitle: `Right to object to the collection of data in special cases; right to object to direct advertising
      (Art. 21 GDPR)`,
      texts: [
        `IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO
      AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR
      UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE
      LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION
      DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA,
      UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
      PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE
      OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION
      PURSUANT TO ART. 21(1) GDPR).
      IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE
      RIGHT TO OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH
      ADVERTISING AT ANY TIME. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH
      DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR
      DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).`,
      ],
    },
    {
      subTitle: `Right to log a complaint with the competent supervisory agency
      `,
      texts: [
        `In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in
      the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred.
      The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.`,
      ],
    },
    {
      subTitle: `Right to data portability
      `,
      texts: [
        `You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed
      over to you or to a third party in a common, machine-readable format. If you should demand the direct transfer of the data to
      another controller, this will be done only if it is technically feasible.`,
      ],
    },
    {
      subTitle: `Information about, rectification and eradication of data`,
      texts: [
        `Within the scope of the applicable statutory provisions, you have the right to demand information about your archived personal
      data, their source and recipients as well as the purpose of the processing of your data at any time. You may also have a right to
      have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal
      data, please do not hesitate to contact us at any time.`,
      ],
    },
    {
      subTitle: `Right to demand processing restrictions`,
      texts: [
        `You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do
      so, you may contact us at any time. The right to demand restriction of processing applies in the following cases:
       In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this
      claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your
      personal data.
       If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction
      of the processing of your data instead of demanding the eradication of this data.
       If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the
      right to demand the restriction of the processing of your personal data instead of its eradication.
       If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed against each
      other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the
      processing of your personal data.
      If you have restricted the processing of your personal data, these data  with the exception of their archiving may be
      processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural
      persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.`,
      ],
    },
    {
      subTitle: `SSL and/or TLS encryption`,
      texts: [
        `For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries you submit to
      us as the website operator, this website uses either an SSL or a TLS encryption program. You can recognize an encrypted
      connection by checking whether the address line of the browser switches from “http://” to “https://” and also by the appearance
      of the lock icon in the browser line. If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.
      `,
      ],
    },
    {
      subTitle: `Rejection of unsolicited e-mails`,
      texts: [
        `We herewith object to the use of contact information published in conjunction with the mandatory information to be provided in
      our Site Notice to send us promotional and information material that we have not expressly requested. The operators of this
      website and its pages reserve the express right to take legal action in the event of the unsolicited sending of promotional
      information, for instance via SPAM messages.`,
      ],
    },
    {
      title: `4. Recording of data on this website
        `,
      subTitle: `Cookies`,
      texts: [
        `Our websites and pages use what the industry refers to as “cookies.” Cookies are small data packages that do not cause any
      damage to your device. They are either stored temporarily for the duration of a session (session cookies) or they are
      permanently archived on your device (permanent cookies). Session cookies are automatically deleted once you terminate your
      visit. Permanent cookies remain archived on your device until you actively delete them, or they are automatically eradicated by
      your web browser.
      Cookies can be issued by us (first-party cookies) or by third-party companies (so-called third-party cookies). Third-party cookies
      enable the integration of certain services of third-party companies into websites (e.g., cookies for handling payment services).
      Cookies have a variety of functions. Many cookies are technically essential since certain website functions would not work in the
      absence of these cookies (e.g., the shopping cart function or the display of videos). Other cookies may be used to analyze user
      behavior or for promotional purposes.
      Cookies, which are required for the performance of electronic communication transactions, for the provision of certain functions
      you want to use (e.g., for the shopping cart function) or those that are necessary for the optimization (required cookies) of the
      website (e.g., cookies that provide measurable insights into the web audience), shall be stored on the basis of Art. 6(1)(f)
      GDPR, unless a different legal basis is cited. The operator of the website has a legitimate interest in the storage of required
      cookies to ensure the technically error-free and optimized provision of the operator’s services. If your consent to the storage of
      the cookies and similar recognition technologies has been requested, the processing occurs exclusively on the basis of the
      consent obtained (Art. 6(1)(a) GDPR and § 25 (1) TTDSG); this consent may be revoked at any time.
      You have the option to set up your browser in such a manner that you will be notified any time cookies are placed and to permit
      the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in certain cases or in general
      or activate the delete-function for the automatic eradication of cookies when the browser closes. If cookies are deactivated, the
      functions of this website may be limited.
      Which cookies and services are used on this website can be found in this privacy policy.`,
      ],
    },
    {
      subTitle: `Consent with ConsentManager`,
      texts: [
        `Our website uses the ConsentManager consent technology to obtain your consent to the storage of certain cookies on your
      device or for the use of certain technologies and data protection legislation compliant documentation of the former. The party
      offering this technology is Jaohawi AB, Håltegelvägen 1b, 72348 Västerås, Sweden,
      website: https://www.consentmanager.de (hereinafter referred to as “ConsentManager”).
      Whenever you visit our website, a connection to ConsentManager’s servers will be established to obtain your consent and other
      declarations regarding the use of cookies.
      Moreover, ConsentManager shall store a cookie in your browser to be able to allocate your declaration(s) of consent or any
      revocations of the former. The data that are recorded in this manner shall be stored until you ask us to eradicate them, delete
      the ConsentManager cookie or until the purpose for archiving the data no longer exists. This shall be without prejudice to any
      mandatory legal retention periods.
      ConsentManager uses cookies to obtain the declarations of consent mandated by law. The legal basis for the use of such
      cookies is Art. 6(1)(c) GDPR.`,
      ],
    },
    {
      subTitle: `Consent with Cookie Notice & Compliance`,
      texts: [
        `Our website uses the consent technology of Cookie Notice & Compliance for GDPR to obtain your consent for the archiving of
      certain cookies on your device or for the use of certain technologies and for the data protection compliant documentation of the
      former.
      Cookie Notice & Compliance for GDPR is installed locally on our servers so that a connection with third party servers does not
      occur. Cookie Notice & Compliance for GDPR stores a cookie in your browser to be able to allocate the granted consent and
      their revocation to you. The cookie stays active for 1 month. Your data is stored until you ask us to delete it, delete the consent
      cookie yourself or the purpose of data archiving is no longer valid. This does not affect mandatory statutory retention periods.
      Cookie Notice & Compliance for GDPR is being used to obtain the consent for the use of cookies required by law. The legal
      basis is Art. 6(1)(c) GDPR.
      `,
      ],
    },
    {
      subTitle: `Contact form`,
      texts: [
        `If you submit inquiries to us via our contact form, the information provided in the contact form as well as any contact information
      provided therein will be stored by us in order to handle your inquiry and in the event that we have further questions. We will not
      share this information without your consent.
      The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a contract or if it is
      necessary to carry out pre-contractual measures. In all other cases the processing is based on our legitimate interest in the
      effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on your agreement (Art. 6(1)(a) GDPR) if this has
      been requested; the consent can be revoked at any time.
      The information you have entered into the contact form shall remain with us until you ask us to eradicate the data, revoke your
      consent to the archiving of data or if the purpose for which the information is being archived no longer exists (e.g., after we have
      concluded our response to your inquiry). This shall be without prejudice to any mandatory legal provisions, in particular retention
      periods.
      `,
      ],
    },
    {
      subTitle: `Request by e-mail, telephone, or fax
      `,
      texts: [
        `If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request) will be stored
      and processed by us for the purpose of processing your request. We do not pass these data on without your consent.
      These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a contract or is required
      for the performance of pre-contractual measures. In all other cases, the data are processed on the basis of our legitimate
      interest in the effective handling of inquiries submitted to us (Art. 6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a)
      GDPR) if it has been obtained; the consent can be revoked at any time.
      The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent to the storage
      or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory statutory provisions – in particular
      statutory retention periods – remain unaffected.
      `,
      ],
    },
    {
      title: `5. Social media
        `,
      subTitle: `Twitter`,
      texts: [
        `We have integrated functions of the social media platform Twitter into this website. These functions are provided by Twitter
      International Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07, Ireland.
      If the social media element has been activated, a direct connection between your device and Twitter’s server will be established.
      As a result, Twitter will receive information on your visit to this website. While you use Twitter and the “Re-Tweet” function,
      websites you visit are linked to your Twitter account and disclosed to other users. We must point out, that we, the providers of
      the website and its pages do not know anything about the content of the data transferred and the use of this information by
      Twitter. For more details, please consult Twitter’s Data Privacy Declaration at: https://twitter.com/en/privacy.
      If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6(1)(a)
      GDPR and § 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not
      obtained, the use of the service will occur on the basis of our legitimate interest in making our information as comprehensively
      visible as possible on social media.
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
      found here: https://gdpr.twitter.com/en/controller-to-controller-transfers.html.
      You have the option to reset your data protection settings on Twitter under the account settings
      at https://twitter.com/account/settings.
      .`,
      ],
    },
    {
      subTitle: `LinkedIn`,
      texts: [
        `This website uses elements of the LinkedIn network. The provider is LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
      Place, Dublin 2, Ireland.
      Any time you access a page of this website that contains elements of LinkedIn, a connection to LinkedIn’s servers is
      established. LinkedIn is notified that you have visited this website with your IP address. If you click on LinkedIn’s “Recommend”
      button and are logged into your LinkedIn account at the time, LinkedIn will be in a position to allocate your visit to this website to
      your user account. We have to point out that we as the provider of the websites do not have any knowledge of the content of the
      transferred data and its use by LinkedIn.
      If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6 (1)(a)
      GDPR and § 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not
      obtained, the use of the service will occur on the basis of our legitimate interest in making our information as comprehensively
      visible as possible on social media.
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
      found here: https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-derschweiz?lang=en.
      For further information on this subject, please consult LinkedIn’s Data Privacy Declaration
      at: https://www.linkedin.com/legal/privacy-policy.`,
      ],
    },
    {
      title: `6. Analysis tools and advertising`,
      subTitle: `Google Tag Manager`,
      texts: [
        `We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland
      The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other technologies on our website.
      The Google Tag Manager itself does not create any user profiles, does not store cookies, and does not carry out any
      independent analyses. It only manages and runs the tools integrated via it. However, the Google Tag Manager does collect your
      IP address, which may also be transferred to Google’s parent company in the United States.
      The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in the quick
      and uncomplicated integration and administration of various tools on his website. If appropriate consent has been obtained, the
      processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR and § 25 (1) TTDSG, insofar the consent includes the
      storage of cookies or the access to information in the user’s end device (e.g., device fingerprinting) within the meaning of the
      TTDSG. This consent can be revoked at any time.
      `,
      ],
    },
    {
      subTitle: `Google Analytics
      `,
      texts: [
        `This website uses functions of the web analysis service Google Analytics. The provider of this service is Google Ireland Limited
      (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end, the website
      operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized operating system and the
      user’s origin. This data is summarized in a user-ID and assigned to the respective end device of the website visitor.
      Furthermore, Google Analytics allows us to record your mouse and scroll movements and clicks, among other things. Google
      Analytics uses various modeling approaches to augment the collected data sets and uses machine learning technologies in data
      analysis.
      Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior
      patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is, as a rule transferred to a
      Google server in the United States, where it is stored.
      The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may
      revoke your consent at any time.
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
      found here: https://privacy.google.com/businesses/controllerterms/mccs/.`,
      ],
    },
    {
      subTitle: `Browser plug-in`,
      texts: [
        `You can prevent the recording and processing of your data by Google by downloading and installing the browser plugin
      available under the following link: https://tools.google.com/dlpage/gaoptout?hl=en.
      For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration
      at: https://support.google.com/analytics/answer/6004245?hl=en.
      `,
      ],
    },
    {
      subTitle: `Google Signals`,
      texts: [
        `We use Google Signals. Whenever you visit our website, Google Analytics records, among other things, your location, the
      progression of your search and YouTube progression as well as demographic data (site visitor data). This data may be used for
      customized advertising with the assistance of Google Signal. If you have a Google account, your site visitor information will be
      linked to your Google account by Google Signal and used to send you customized promotional messages. The data is also
      used to compile anonymized statistics of our users’ online patterns.
      Contract data processing
      We have executed a contract data processing agreement with Google and are implementing the stringent provisions of the
      German data protection agencies to the fullest when using Google Analytics.
      `,
      ],
    },
    {
      subTitle: `Data processing`,
      texts: [
        `We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract
      mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our
      instructions and in compliance with the GDPR.
      `,
      ],
    },
    {
      subTitle: `Google Ads
      `,
      texts: [
        `The website operator uses Google Ads. Google Ads is an online promotional program of Google Ireland Limited (“Google”),
      Gordon House, Barrow Street, Dublin 4, Ireland.
      Google Ads enables us to display ads in the Google search engine or on third-party websites, if the user enters certain search
      terms into Google (keyword targeting). It is also possible to place targeted ads based on the user data Google has in its
      possession (e.g., location data and interests; target group targeting). As the website operator, we can analyze these data
      quantitatively, for instance by analyzing which search terms resulted in the display of our ads and how many ads led to
      respective clicks.
      The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may
      revoke your consent at any time.
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
      found here: https://policies.google.com/privacy/frameworks and https://privacy.google.com/businesses/controllerterms/mccs/.`,
      ],
    },
    {
      subTitle: `Google Ads Remarketing
      `,
      texts: [
        `This website uses the functions of Google Ads Remarketing. The provider of these solutions is Google Ireland Limited
      (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
      With Google Ads Remarketing, we can assign people who interact with our online offering to specific target groups in order to
      subsequently display interest-based advertising to them in the Google advertising network (remarketing or retargeting).
      Moreover, it is possible to link the advertising target groups generated with Google Ads Remarketing to device encompassing
      functions of Google. This makes it possible to display interest-based customized advertising messages, depending on your prior
      usage and browsing patterns on a device (e.g., cell phone) in a manner tailored to you as well as on any of your devices (e.g.,
      tablet or PC).
      If you have a Google account, you have the option to object to personalized advertising under the following
      link: https://www.google.com/settings/ads/onweb/.
      The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may
      revoke your consent at any time.
      For further information and the pertinent data protection regulations, please consult the Data Privacy Policies of Google
      at: https://policies.google.com/technologies/ads?hl=en.
      .`,
      ],
    },
    {
      subTitle: `Google Conversion-Tracking`,
      texts: [
        `This website uses Google Conversion Tracking. The provider of this service is Google Ireland Limited (“Google”), Gordon
      House, Barrow Street, Dublin 4, Ireland.
      With the assistance of Google Conversion Tracking, we are in a position to recognize whether the user has completed certain
      actions. For instance, we can analyze the how frequently which buttons on our website have been clicked and which products
      are reviewed or purchased with particular frequency. The purpose of this information is to compile conversion statistics. We
      learn how many users have clicked on our ads and which actions they have completed. We do not receive any information that
      would allow us to personally identify the users. Google as such uses cookies or comparable recognition technologies for
      identification purposes.
      The use of these services occurs on the basis of your consent pursuant to Art. 6(1)(a) GDPR and § 25(1) TTDSG. You may
      revoke your consent at any time.
      For more information about Google Conversion Tracking, please review Google’s data protection policy
      at: https://policies.google.com/privacy?hl=en
      `,
      ],
    },
    {
      subTitle: `LinkedIn Insight Tag
      `,
      texts: [
        `This website uses the Insight tag from LinkedIn. This service is provided by LinkedIn Ireland Unlimited Company, Wilton Plaza,
      Wilton Place, Dublin 2, Ireland.`,
      ],
    },
    {
      subTitle: `Data processing by LinkedIn Insight tag
      `,
      texts: [
        `We use the LinkedIn Insight tag to obtain information about visitors to our website. Once a website visitor is registered with
      LinkedIn, we can analyze the key occupational data (e.g., career level, company size, country, location, industry, job title) of our
      website visitors to help us better target our site to the relevant audience. We can also use LinkedIn Insight tags to measure
      whether visitors to our websites make a purchase or perform other actions (conversion measurement). Conversion
      measurement can also be carried out across devices (e.g. from PC to tablet). LinkedIn Insight Tag also features a retargeting
      function that allows us to display targeted advertising to visitors to our website outside of the website. According to LinkedIn, no
      identification of the advertising addressee takes place.
      LinkedIn itself also collects log files (URL, referrer URL, IP address, device and browser characteristics and time of access). The
      IP addresses are shortened or (if they are used to reach LinkedIn members across devices) hashed (pseudonymized). The
      direct identifiers of LinkedIn members are deleted by LinkedIn after seven days. The remaining pseudonymized data will then be
      deleted within 180 days.
      The data collected by LinkedIn cannot be assigned by us as a website operator to specific individuals. LinkedIn will store the
      personal data collected from website visitors on its servers in the USA and use it for its own promotional activities. For details,
      please see LinkedIn’s privacy policy at https://www.linkedin.com/legal/privacy-policy#choices-oblig.`,
      ],
    },
    {
      subTitle: `Legal basis`,
      texts: [
        `If your approval (consent) has been obtained the use of the abovementioned service shall occur on the basis of Art. 6(1)(a)
      GDPR and § 25 TTDSG (German Telecommunications Act). Such consent may be revoked at any time. If your consent was not
      obtained, the use of the service will occur on the basis of Art. 6(1)(f) GDPR; the website operator has a legitimate interest in
      effective advertising promotions that include the utilization of social media.
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be
      found here: https://www.linkedin.com/legal/l/dpa and https://www.linkedin.com/legal/l/eu-sccs.`,
      ],
    },
    {
      subTitle: `Objection to the use of LinkedIn Insight Tag`,
      texts: [
        `You can object to LinkedIn’s analysis of user behavior and targeted advertising at the following
      link: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
      In addition, LinkedIn members can control the use of their personal information for promotional purposes in the account settings.
      To prevent LinkedIn from linking information collected on our site to your LinkedIn account, you must log out of your LinkedIn
      account before you visit our site.`,
      ],
    },
    {
      subTitle: `Data processing`,
      texts: [
        `We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract
      mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our
      instructions and in compliance with the GDPR.
      `,
      ],
    },
    {
      title: `7. Newsletter`,
      subTitle: `Newsletter data
      `,
      texts: [
        `If you would like to subscribe to the newsletter offered on this website, we will need from you an e-mail address as well as
      information that allow us to verify that you are the owner of the e-mail address provided and consent to the receipt of the
      newsletter. No further data shall be collected or shall be collected only on a voluntary basis. We shall use such data only for the
      sending of the requested information and shall not share such data with any third parties.
      The processing of the information entered into the newsletter subscription form shall occur exclusively on the basis of your
      consent (Art. 6(1)(a) GDPR). You may revoke the consent you have given to the archiving of data, the e-mail address, and the
      use of this information for the sending of the newsletter at any time, for instance by clicking on the “Unsubscribe” link in the
      newsletter. This shall be without prejudice to the lawfulness of any data processing transactions that have taken place to date.
      The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you unsubscribe from the
      newsletter or the newsletter service provider and deleted from the newsletter distribution list after you unsubscribe from the
      newsletter or after the purpose has ceased to apply. We reserve the right to delete or block e-mail addresses from our
      newsletter distribution list at our own discretion within the scope of our legitimate interest in accordance with Art. 6(1)(f) GDPR.
      Data stored for other purposes with us remain unaffected.
      After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the newsletter service
      provider in a blacklist, if such action is necessary to prevent future mailings. The data from the blacklist is used only for this
      purpose and not merged with other data. This serves both your interest and our interest in complying with the legal requirements
      when sending newsletters (legitimate interest within the meaning of Art. 6(1)(f) GDPR). The storage in the blacklist is
      indefinite. You may object to the storage if your interests outweigh our legitimate interest.`,
      ],
    },
    {
      title: `8. Plug-ins and Tools`,
      subTitle: `Google Fonts (local embedding)
      `,
      texts: [
        `This website uses so-called Google Fonts provided by Google to ensure the uniform use of fonts on this site. These Google
      fonts are locally installed so that a connection to Google’s servers will not be established in conjunction with this application.
      For more information on Google Fonts, please follow this link: https://developers.google.com/fonts/faq and consult Google’s
      Data Privacy Declaration under: https://policies.google.com/privacy?hl=en.
      `,
      ],
    },
    {
      title: `9. eCommerce and payment service providers`,
      subTitle: `Processing of Customer and Contract Data`,
      texts: [
        `We collect, process, and use personal customer and contract data for the establishment, content arrangement and modification
      of our contractual relationships. Data with personal references to the use of this website (usage data) will be collected,
      processed, and used only if this is necessary to enable the user to use our services or required for billing purposes. The legal
      basis for these processes is Art. 6(1)(b) GDPR.
      The collected customer data shall be deleted upon completion of the order or termination of the business relationship and upon
      expiration of any existing statutory archiving periods. This shall be without prejudice to any statutory archiving periods.
      `,
      ],
    },
    {
      title: `10. Online-based Audio and Video Conferences (Conference tools)`,
      subTitle: `Data processing
      `,
      texts: [
        `We use online conference tools, among other things, for communication with our customers. The tools we use are listed in detail
      below. If you communicate with us by video or audio conference using the Internet, your personal data will be collected and
      processed by the provider of the respective conference tool and by us. The conferencing tools collect all information that you
      provide/access to use the tools (email address and/or your phone number). Furthermore, the conference tools process the
      duration of the conference, start and end (time) of participation in the conference, number of participants and other “context
      information” related to the communication process (metadata).
      Furthermore, the provider of the tool processes all the technical data required for the processing of the online communication.
      This includes, in particular, IP addresses, MAC addresses, device IDs, device type, operating system type and version, client
      version, camera type, microphone or loudspeaker and the type of connection.
      Should content be exchanged, uploaded, or otherwise made available within the tool, it is also stored on the servers of the tool
      provider. Such content includes, but is not limited to, cloud recordings, chat/ instant messages, voicemail uploaded photos and
      videos, files, whiteboards, and other information shared while using the service.
      Please note that we do not have complete influence on the data processing procedures of the tools used. Our possibilities are
      largely determined by the corporate policy of the respective provider. Further information on data processing by the conference
      tools can be found in the data protection declarations of the tools used, and which we have listed below this text.
      `,
      ],
    },
    {
      subTitle: `Purpose and legal bases`,
      texts: [
        `The conference tools are used to communicate with prospective or existing contractual partners or to offer certain services to
      our customers (Art. 6(1)(b) GDPR). Furthermore, the use of the tools serves to generally simplify and accelerate communication
      with us or our company (legitimate interest in the meaning of Art. 6(1)(f) GDPR). Insofar as consent has been requested, the
      tools in question will be used on the basis of this consent; the consent may be revoked at any time with effect from that date.
      `,
      ],
    },
    {
      subTitle: `Duration of storage`,
      texts: [
        `Data collected directly by us via the video and conference tools will be deleted from our systems immediately after you request
      us to delete it, revoke your consent to storage, or the reason for storing the data no longer applies. Stored cookies remain on
      your end device until you delete them. Mandatory legal retention periods remain unaffected.
      We have no influence on the duration of storage of your data that is stored by the operators of the conference tools for their own
      purposes. For details, please directly contact the operators of the conference tools.
      `,
      ],
    },
    {
      subTitle: `Conference tools used`,
      texts: [`We employ the following conference tools:`],
    },
    {
      subTitle: `Microsoft Teams
      `,
      texts: [
        `We use Microsoft Teams. The provider is the Microsoft Ireland Operations Limited, One Microsoft Place, South County
      Business Park, Leopardstown, Dublin 18, Ireland. For details on data processing, please refer to the Microsoft Teams privacy
      policy: https://privacy.microsoft.com/en-us/privacystatement.`,
      ],
    },
    {
      subTitle: `Data processing
      `,
      texts: [
        `We have concluded a data processing agreement (DPA) for the use of the above-mentioned service. This is a contract
      mandated by data privacy laws that guarantees that they process personal data of our website visitors only based on our
      instructions and in compliance with the GDPR.`,
      ],
    },
  ];

  return (
    <div className="landing-page">
      <div
        className="first-section"
        style={{ backgroundImage: `url(${landing_bg})`, height: 120 }}
      >
        <TopHeader />
      </div>

      {sessionData.map((item) => (
        <Fade right>
          <Row className="about-section" style={{ margin: "40px" }}>
            {item.title && (
              <H1
                attrH1={{
                  className: "font40-bold font-outfit text-dark",
                  style: { marginTop: "20px" },
                }}
              >
                {item.title}
              </H1>
            )}
            {item.subTitle && (
              <H1
                attrH1={{
                  className: "font32-bold font-outfit text-dark",
                  style: { marginTop: "20px" },
                }}
              >
                {item.subTitle}
              </H1>
            )}
            {item.texts.map((text) => (
              <H1 attrH1={{ className: "font24_outfit text-dark", style: {} }}>
                {text}
              </H1>
            ))}
          </Row>
        </Fade>
      ))}

      <Footer />
    </div>
  );
};

export default PrivacyPage;
