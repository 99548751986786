import React, { useContext, useState } from "react";
import { Grid } from "react-feather";
import { Link } from "react-router-dom";
import { H1, Image } from "../../AbstractElements";
import CubaIcon from "../../assets/images/logo/logo.png";
import CustomizerContext from "../../_helper/Customizer";
import logoIMG from "../../assets/images/logo/logo.png";
import logo_blackIMG from "../../assets/images/logo/logo_black.png";

const SidebarLogo = () => {
  const { mixLayout, toggleSidebar, layout, layoutURL } =
    useContext(CustomizerContext);

  const [toggle, setToggle] = useState(false);

  const openCloseSidebar = () => {
    const toggleSideBar = !toggle;
    setToggle(toggleSideBar);
    toggleSidebar(toggleSideBar);
  };

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  return (
    <div className="logo-wrapper">
      <img
        src={mixLayout ? `${logoIMG}` : `${logo_blackIMG}`}
        alt="Logo"
        width={80}
      />
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
