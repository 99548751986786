import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Card,
  CardHeader,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  getTimeTrackingsListAPI,
  deleteTimeTrackingAPI,
  confirmByManagerTimeTrackingAPI,
  revertConfirmByManagerTimeTrackingAPI,
  getAllTimeTrackingsAPI,
} from "../../../Library/apis";
import useDebounce from "../../../Hooks/useDebounce";
import CustomizerContext from "../../../_helper/Customizer";
import moment from "moment";

const RenderItem = (
  item,
  handleConfirmByManager,
  handleRevertConfirmByManager,
  handleDelete,
  t
) => {
  return {
    name: <span>{item?.fullName}</span>,
    workingArea: <span>{item?.workingAreaName}</span>,
    date: <span>{moment(item?.date).format("DD.MM.YYYY")}</span>,
    startTime: <span>{item?.startTime}</span>,
    endTime: <span>{item?.endTime}</span>,
    breakTime: <span>{item?.breakTime}</span>,
    timeCross: <span>{item?.timeCross}</span>,
    timeNet: <span>{item?.timeNet}</span>,
    confirmedByEmployee: item?.confirmedByEmployee ? (
      <span className="f-w-700 font-success">
        <i className="icofont icofont-tick-mark"></i>
      </span>
    ) : (
      <span className="f-w-700 font-danger">
        <i className="icofont icofont-close"></i>
      </span>
    ),
    confirmedByManager: item?.confirmedByManager ? (
      <span className="f-w-700 font-success">
        <i className="icofont icofont-tick-mark"></i>
      </span>
    ) : (
      <span className="f-w-700 font-danger">
        <i className="icofont icofont-close"></i>
      </span>
    ),
    actions: (
      <Fragment>
        <Btn
          attrBtn={{
            className: "me-2 px-2 py-1",
            color: item.confirmedByManager ? "primary" : "success",
            size: "sm",
            onClick: () =>
              item.confirmedByManager
                ? handleRevertConfirmByManager(item._id)
                : handleConfirmByManager(item._id),
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": item.confirmedByManager
              ? t("Revert")
              : t("Confirm"),
            "data-tooltip-place": "top",
          }}
        >
          {item.confirmedByManager ? (
            <i className="fa fa-undo"></i>
          ) : (
            <i className="fa fa-magic"></i>
          )}
        </Btn>
        <Link to={`/time-trackings/${item._id}/edit`}>
          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "info",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Edit"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-pencil"></i>
          </Btn>
        </Link>
        <Btn
          attrBtn={{
            className: "px-2 py-1",
            color: "danger",
            size: "sm",
            onClick: () => handleDelete(item._id),
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": t("Delete"),
            "data-tooltip-place": "top",
          }}
        >
          <i className="fa fa-trash"></i>
        </Btn>
      </Fragment>
    ),
  };
};

const TimeTrackings = () => {
  const { mixLayout } = useContext(CustomizerContext);
  const { t } = useTranslation();
  const filterOption = [
    { value: "all", label: t("All") },
    { value: "confirmed-from-employees", label: t("Confirmed from employees") },
    { value: "open-from-employees", label: t("Open from employees") },
  ];
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? "#80bdff" // Set focused border color
        : mixLayout
        ? provided.borderColor
        : "#1d1e26", // Use default border
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    container: (provided, state) => ({
      ...provided,
      borderColor: mixLayout ? "#ffffffaa" : "#1d1e26",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e6",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e29",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [search, setSearch] = useState("");
  const [confirmedByEmployee, setConfirmedByEmployee] = useState("all");
  const debouncedSearchTerm = useDebounce(search, 500); // 500 ms delay
  const location = useLocation();
  const [selectedMonth, setSelectedMonth] = useState();
  const [workingAreaName, setWorkingAreaName] = useState();
  const [filterFlag, setFilterFlag] = useState(false);
  const fetchTimeTrackings = async () => {
    setLoading(true);

    try {
      const response = await getTimeTrackingsListAPI({
        page: currentPage,
        pageSize: perPage,
        search: search,
        sortField: sortField,
        sortOrder: sortOrder,
        confirmedByEmployee,
      });

      setData(response.timeTrackings);
      updateTimeCross(data);

      setTotalRows(response.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const workingAreaNameQuery = query.get("workingAreaName");
    const selectedMonthQuery = query.get("selectedMonth");
    setWorkingAreaName(workingAreaNameQuery);
    setSelectedMonth(selectedMonthQuery); // Store selectedMonth in statedebouncedSearchTerm
    setFilterFlag(true);
  }, [location.search]);
  useEffect(() => {
    if ((selectedMonth || workingAreaName) && filterFlag) {
      const filteredData = data.filter((item) => {
        const itemMonth = moment(item.date).format("YYYY-MM");

        return (
          itemMonth === selectedMonth &&
          item.workingAreaName === workingAreaName
        );
      });
      setData(filteredData);
      setFilterFlag(false);
    }
  }, [data]);
  function updateTimeCross(dataArray) {
    dataArray.forEach((item) => {
      const startTimeArray = item.startTime.split(":");
      const endTimeArray = item.endTime.split(":");

      const startDate = new Date(
        0,
        0,
        0,
        startTimeArray[0],
        startTimeArray[1],
        0
      );
      const endDate = new Date(0, 0, 0, endTimeArray[0], endTimeArray[1], 0);

      // Calculate the difference in milliseconds
      let difference = endDate - startDate;

      // If the end time is before the start time, assume it's the next day
      if (difference < 0) {
        difference += 24 * 60 * 60 * 1000; // Add a day in milliseconds
      }

      // Convert milliseconds to hours and minutes
      const hours = Math.floor(difference / 1000 / 60 / 60);
      const minutes = Math.floor((difference / 1000 / 60) % 60);

      // Update timeCross with the calculated duration in format "Xh Ym"
      item.timeCross = `${hours}h ${minutes}m`;
    });
  }
  useEffect(() => {
    fetchTimeTrackings();
  }, [
    debouncedSearchTerm,
    currentPage,
    perPage,
    sortField,
    sortOrder,
    confirmedByEmployee,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.pseudoField);
    setSortOrder(sortDirection);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t("Are you sure to delete?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"), // Translate the Cancel button text
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await deleteTimeTrackingAPI(id);
          toast.success(res.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchTimeTrackings();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleConfirmByManager = (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You can revert this, if you want!"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, confirm it!"),
      cancelButtonText: t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await confirmByManagerTimeTrackingAPI(id);
          toast.success(res.message || "Successfully confirmed!", {
            autoClose: 2000,
          });
          fetchTimeTrackings();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleRevertConfirmByManager = (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You can confirm this, if you want!"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, revert it!"),
      cancelButtonText: t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await revertConfirmByManagerTimeTrackingAPI(id);
          toast.success(res.message || "Successfully reverted!", {
            autoClose: 2000,
          });
          fetchTimeTrackings();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleExportTimeTrackings = async () => {
    try {
      // Make a request to the server endpoint that generates the Excel file
      const response = await getAllTimeTrackingsAPI();
      const ws = XLSX.utils.json_to_sheet(response);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, "time-trackings.xlsx");
    } catch (error) {
      console.error("Error during export:", error);
      // Handle any errors that occur during the request
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Employee",
        selector: (row) => row["name"],
        sortable: true,
        pseudoField: "employee",
      },
      {
        name: "Working Area",
        selector: (row) => row["workingArea"],
        sortable: true,
        pseudoField: "workingAreaName",
      },
      {
        name: "Date",
        selector: (row) => row["date"],
        sortable: true,
        pseudoField: "date",
      },
      {
        name: "Start",
        selector: (row) => row["startTime"],
        sortable: true,
        pseudoField: "startTime",
      },
      {
        name: "End",
        selector: (row) => row["endTime"],
        sortable: true,
        pseudoField: "endTime",
      },
      {
        name: "Break",
        selector: (row) => row["breakTime"],
        sortable: true,
        pseudoField: "breakTime",
      },
      {
        name: "Time Cross",
        selector: (row) => row["timeCross"],
        sortable: true,
        pseudoField: "timeCross",
      },
      {
        name: "Confirmed by employee",
        selector: (row) => row["confirmedByEmployee"],
        sortable: true,
        pseudoField: "confirmedByEmployee",
      },
      {
        name: "Confirmed by manager",
        selector: (row) => row["confirmedByManager"],
        sortable: true,
        pseudoField: "confirmedByManager",
      },
      {
        name: "Actions",
        selector: (row) => row["actions"],
      },
      // Add more columns as needed
    ],
    []
  );

  const mainTitle = "Time tracking";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <InputGroup style={{ width: 250 }}>
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={t("Search by name...")}
                      name="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    {search && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearch("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/time-trackings/create">
                    <Btn
                      attrBtn={{
                        className: "me-2 px-2 py-1",
                        color: "primary",
                      }}
                    >
                      <i className="fa fa-plus"></i> {t("New working time")}
                    </Btn>
                  </Link>

                  <div style={{ width: 220 }}>
                    <Select
                      styles={customSelectStyles}
                      options={filterOption}
                      className="js-example-basic-single col-sm-12"
                      onChange={(item) => setConfirmedByEmployee(item.value)}
                    />
                  </div>

                  <Btn
                    attrBtn={{
                      className: "ms-2 px-2 py-1",
                      color: "primary",
                      onClick: handleExportTimeTrackings,
                    }}
                  >
                    {t("Export all to excel")}
                  </Btn>
                </div>
              </div>
            </CardHeader>
            <div className={`custom-table${data.length ? "" : " no-data"}`}>
              <DataTable
                columns={columns.map((column) => ({
                  ...column,
                  name: t(column.name), // Translate the column name
                }))}
                data={data?.map((item) =>
                  RenderItem(
                    item,
                    handleConfirmByManager,
                    handleRevertConfirmByManager,
                    handleDelete,
                    t
                  )
                )}
                striped={true}
                center={true}
                // progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                paginationDefaultPage={currentPage}
                paginationComponentOptions={{
                  rowsPerPageText: "",
                  rangeSeparatorText: "of",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </div>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
    </Fragment>
  );
};

export default TimeTrackings;
