export function formatCurrency(value) {
  let num = Number(value);
  if (isNaN(num)) num = 0;
  const roundedNum = Math.floor(num * 100) / 100;
  return new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(roundedNum);
}

export function convertDate(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based

  // Format the date string as "YYYY-DD-MM"
  return `${year}-${month}-${day}`;
}

export const convertDate2 = (createdAt) => {
  const date = new Date(createdAt);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export function convertDateTime(inputDate) {
  // Parse the inputDate string into a Date object
  const date = new Date(inputDate);

  // Extract date components
  const year = date.getUTCFullYear();
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Format the date and time string as "MM/DD/YYYY hh:mm"
  return `${month}/${day}/${year} ${hours}:${minutes}`;
}


export function isLocalServer() {
  return window.location.hostname == 'localhost'
}

export const getTimeDiff = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h ago`;
  } else if (diffInDays < 7) {
    return `${diffInDays}d ago`;
  } else {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Example: "3 Sep 2022"
  }
};

export const getOffTimeTitles = (key) => {
  const offTimesWithKey = {
    "holiday-payed": "Holiday Payed",
    "holiday-unpayed": "Holiday Unpayed",
    illness: "Illness",
    "illness-child": "Illness Child",
    school: "School",
    miscellaneous: "Miscellaneous",
  };
  return offTimesWithKey[key];
}
