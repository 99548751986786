import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  getWorkingAreaDetailsAPI,
  updateWorkingAreaAPI,
  createWorkingAreaAPI,
  getWorkingAreaSkillsListAPI,
} from "../../../Library/apis";
import CustomizerContext from "../../../_helper/Customizer";

const formInitialValues = {
  name: "",
  description: "",

  street: "",
  city: "",
  zipCode: "",
  countryCode: "",
  skills: [],
  email: "",
  mobile: "",
  memo: "",
  //
};

const Form = () => {
  const { workingAreaId } = useParams();
  const { mixLayout } = useContext(CustomizerContext);
  const navigate = useNavigate();

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? "#80bdff" // Set focused border color
        : errors[state.selectProps.name] && touched[state.selectProps.name]
        ? "#dc3545" // Set invalid border color
        : mixLayout
        ? provided.borderColor
        : "#1d1e26", // Use default border
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      ...(errors[state.selectProps.name] && touched[state.selectProps.name]
        ? {
            paddingRight: "calc(1.5em + 0.75rem)",
            backgroundImage:
              'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right calc(0.375em + 0.1875rem) center",
            backgroundSize: "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
          }
        : {}),
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    container: (provided, state) => ({
      ...provided,
      borderColor: mixLayout ? "#ffffffaa" : "#1d1e26",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e6",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e29",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
  };

  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [skillOptions, setSkillOptions] = useState([]);

  const fetchSkills = async () => {
    try {
      const skillsRes = await getWorkingAreaSkillsListAPI();
      setSkillOptions(
        skillsRes?.map((item) => ({ value: item._id, label: item.name }))
      );
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // ... other validations ...0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        const formData = {
          ...values,
          skills: values.skills.map((item) => item.value),
        };
        workingAreaId
          ? await updateWorkingAreaAPI(workingAreaId, formData)
          : await createWorkingAreaAPI(formData);

        toast.success(`Successfully ${workingAreaId ? "updated" : "added"}!`, {
          autoClose: 2000,
        });
        navigate("/working-areas/list");
      } catch (error) {
        console.log(error.response.data);
        if (
          error.response &&
          (error.response.data?.includes(
            "E11000 duplicate key error collection: roster_rocket.workingareas index: name_1_companyId_1 dup key"
          ) ||
            error.response.data?.includes(
              "Plan executor error during findAndModify :: caused by :: E11000 duplicate key error collection: roster_rocket.workingareas index: name_1_companyId_1 dup key"
            ))
        ) {
          // Handle the duplicate name error
          setFieldError("name", "This name is already taken");
        }

        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchWorkingArea = async () => {
    try {
      const workingArea = await getWorkingAreaDetailsAPI(workingAreaId);
      const { skills, ...other } = workingArea;
      setInitialValues({
        ...other,
        skills: skills.map((item) => ({ value: item._id, label: item.name })),
      });
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange,
  } = formik;

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchSkills();
    setInitialValues(formInitialValues);

    if (workingAreaId) {
      fetchWorkingArea();
    }

    return () => {
      setInitialValues({});
      setSkillOptions([]);
    };
  }, [workingAreaId]);
  const { t, i18n } = useTranslation();
  const mainTitle = workingAreaId ? "Edit Working Area" : "Add Working Area";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={maintTitleTran}
        title={`${workingAreaId ? "Edit" : "Add"} working area`}
      />
      <Container fluid={true}>
        <Col sm="5">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-20",
                style: { zIndex: 1, top: 10, left: 10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardBody>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label required">
                  {t("Name")}
                </Label>
                <Col sm="5">
                  <Input
                    className="form-control digits"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    invalid={errors.name && touched.name}
                  />
                  {errors.name && touched.name && (
                    <span className="mt-2 text-danger">{errors.name}</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">
                  {t("Description")}
                </Label>
                <Col sm="9">
                  <Input
                    className="form-control digits"
                    type="textarea"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">{t("Street")}</Label>
                <Col sm="9">
                  <Input
                    className="form-control digits"
                    type="textarea"
                    rows="3"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">{t("City")}</Label>
                <Col sm="9">
                  <Input
                    className="form-control digits"
                    type="textarea"
                    rows="3"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">{t("Zip")}</Label>
                <Col sm="9">
                  <Input
                    className="form-control digits"
                    type="text"
                    rows="3"
                    name="zipCode"
                    value={values.zipCode}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">
                  {t("Country Code")}
                </Label>
                <Col sm="9">
                  <Input
                    className="form-control digits"
                    type="text"
                    rows="3"
                    name="countryCode"
                    value={"DE"}
                    // value={values.countryCode}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mb-5 g-3">
                <Label className="col-sm-3 col-form-label">{t("Skills")}</Label>
                <Col sm="9">
                  <Select
                    name="skills"
                    options={skillOptions}
                    styles={customSelectStyles}
                    className="js-example-basic-single col-sm-12"
                    value={values.skills}
                    onChange={(value) => setFieldValue("skills", value)}
                    isMulti
                  />
                </Col>
              </Row>

              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">{t("Email")}</Label>
                <Col sm="9">
                  <Input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    invalid={errors.email && touched.email}
                  />
                </Col>
              </Row>
              <Row className="mb-3 g-3">
                <Label className="col-sm-3 col-form-label">{t("Mobile")}</Label>
                <Col sm="9">
                  <Input
                    type="text"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleChange}
                    invalid={errors.mobile && touched.mobile}
                  />
                </Col>
              </Row>
              <Row className="mb-5 g-3">
                <Label className="col-sm-3 col-form-label">
                  {t("Important Memo")}
                </Label>
                <Col sm="9">
                  <Input
                    type="textarea"
                    name="memo"
                    rows="3"
                    value={values.memo}
                    onChange={handleChange}
                  />
                </Col>
              </Row>

              <Btn
                attrBtn={{
                  color: "success",
                  disabled: isSubmitting,
                  onClick: handleSubmit,
                }}
              >
                {isSubmitting && <Spinner size="sm" color="light" />}
                {t("Save")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Form;
