import React from "react";
import { ButtonGroup } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Btn } from "../../../AbstractElements";


const ListView = ({
  calendarEvents,
  handleClickPlanSetting,
  handleClickPlanEdit,
  handleClickPlanDelete,
  handleMonthChange,
  startMonth,
}) => {
  const { t } = useTranslation();
  const sortedCalendarEvents = calendarEvents
    ? [...calendarEvents].sort((a, b) => {
        const dateA = moment(a.start).valueOf(); // Convert to timestamp for comparison
        const dateB = moment(b.start).valueOf(); // Convert to timestamp for comparison
        return dateB - dateA;
      })
    : [];
  return (
    <div>
      <div className="fc">
        <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
          <div className="fc-toolbar-chunk">
            <ButtonGroup className="fc-button-group">
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "fc-prev-button fc-button fc-button-primary",
                  onClick: () => handleMonthChange("prev"),
                }}
              >
                <i className="fc-icon fc-icon-chevron-left"></i>
              </Btn>
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "fc-prev-button fc-button fc-button-primary",
                  onClick: () => handleMonthChange("next"),
                }}
              >
                <i className="fc-icon fc-icon-chevron-right"></i>
              </Btn>
            </ButtonGroup>
          </div>
          <div className="fc-toolbar-chunk">
            <h2 className="fc-toolbar-title" id="fc-dom-10873">
              {startMonth &&
                moment(startMonth)
                  .add(10, "days")
                  .startOf("month")
                  .format("MMMM YYYY")}
            </h2>
          </div>
          <div></div>
        </div>
      </div>

      <table className="table bg-clear">
        <thead>
          <tr className="text-nowrap">
            <th>{t("Date")}</th>
            <th>{t("Start")}</th>
            <th>{t("End")}</th>
            <th>{t("Assigned Employees")}</th>
            <th>{t("Working Area")}</th>
            <th>{t("Skills")}</th>
            <th>{t("Attributes")}</th>
            <th>{t("State")}</th>
            <th>{t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {sortedCalendarEvents
            ?.filter((event) => event.extendedProps?.type !== "holiday")
            ?.map((event) => {
              const today = moment().format("YYYY-MM-DD");
              const eventStartDate = moment(event.start).format("YYYY-MM-DD");

              // Check if the event's start date is today
              const isToday = today === eventStartDate;
              return (
                <tr key={event.id}>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {eventStartDate}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {moment(event.start).format("YYYY-MM-DD")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {moment(event.start).format("HH:mm")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {moment(event.end).format("HH:mm")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {event.extendedProps.assignedEmployees
                      ?.map(
                        (item) => `${t(item.firstName)} ${t(item.lastName)}`
                      )
                      ?.join(", ")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {event.title}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {event.extendedProps.skills
                      ?.map((obj) => obj.name)
                      ?.join(", ")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {event.extendedProps.attributes
                      ?.map((obj) => obj.name)
                      ?.join(", ")}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    {event.extendedProps.state ? (
                      <span className="badge badge-success">confirmed</span>
                    ) : (
                      <span className="badge badge-danger">open</span>
                    )}
                  </td>
                  <td
                    style={{
                      backgroundColor:
                        isToday && event.extendedProps.state
                          ? "green"
                          : "transparent",
                    }}
                  >
                    <i
                      className="fa fa-cog cursor-pointer"
                      onClick={(e) => handleClickPlanSetting(e, event)}
                    />
                    <i
                      className="fa fa-edit cursor-pointer ms-1 me-1"
                      onClick={(e) => handleClickPlanEdit(e, event)}
                    />
                    <i
                      className="fa fa-trash cursor-pointer"
                      onClick={(e) => handleClickPlanDelete(e, event)}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ListView;
