import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Media,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, H5, H6, Btn, UL, LI } from "../../../AbstractElements";
import {
  getAllEmployeesListAPI,
  getSettingDetailsAPI,
  saveSettings,
  requestCloseSubscriptionAPI,
} from "../../../Library/apis";
import SettingContext from "../../../_helper/Setting";
import CustomizerContext from "../../../_helper/Customizer";

const shortNames = [
  { label: "Holiday Payed", name: "holidayPayed" },
  { label: "Holiday Unpayed", name: "holidayUnpayed" },
  { label: "Illness", name: "illness" },
  { label: "Illness Child", name: "illnessChild" },
  { label: "School", name: "school" },
  { label: "Miscellaneous", name: "miscellaneous" },
];

const companyInfo = [
  { label: "Company Name", name: "companyName" },
  { label: "First Name", name: "firstName" },
  { label: "Last Name", name: "lastName" },
  { label: "Street & House Nr", name: "street" },
  { label: "PLZ", name: "postalCode" },
  { label: "City", name: "city" },
  { label: "Land", name: "land" },
  { label: "E-Mail", name: "email" },
  { label: "Vat Nr", name: "vat" },
  { label: "Telephone Nr", name: "telephone" },
  { label: "Fax", name: "fax" },
];

const initialValues = {
  allowedEnterOffTimes: false,
  activateFeatureTimeTracking: false,
  importNationalHolidays: false,
  activateLexoffice: false,
  displaywiki: false,
  shortNames: {
    holidayPayed: "",
    holidayUnpayed: "",
    illness: "",
    illnessChild: "",
    school: "",
    miscellaneous: "",
  },
  dropoutManagers: [],
  companyInfo: {
    companyName: "",
    firstName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    land: "",
    vat: "",
    telephone: "",
    fax: "",
  },
};

const Settings = () => {
  const { settingsInfo, setSettingsInfo } = useContext(SettingContext);
  const { mixLayout } = useContext(CustomizerContext);
  const [uploadedImage, setUploadedImage] = useState(null);
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? "#80bdff" // Set focused border color
        : mixLayout
        ? provided.borderColor
        : "#1d1e26", // Use default border
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    container: (provided, state) => ({
      ...provided,
      borderColor: mixLayout ? "#ffffffaa" : "#1d1e26",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e6",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e29",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
  };

  const [settings, setSettings] = useState(initialValues);
  const [dropoutManagers, setDropoutManagers] = useState([]);
  const [isClosingSubscription, setIsClosingSubscription] = useState(false);
  const maxFileSize = 100000; // 1MB in bytes
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await getSettingDetailsAPI();
        setSettingsInfo(
          Object.keys(response).length === 0 ? initialValues : response
        );
      } catch (error) {
        console.error("Failed to fetch settings:", error);

        setSettings(initialValues);
      }
    };

    fetchSettings();
  }, []);

  const fetchDropoutManagers = async () => {
    try {
      const response = await getAllEmployeesListAPI({
        role: "workspace-admin",
      });
      setDropoutManagers(response);
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleInsert = (item) => {
    setSettings((prevDetails) => ({
      ...prevDetails,
      dropoutManagers: [...prevDetails.dropoutManagers, item],
    }));
  };

  const handleRevert = (managerId) => {
    setSettings((prevDetails) => ({
      ...prevDetails,
      dropoutManagers: prevDetails.dropoutManagers.filter(
        (item) => item._id !== managerId
      ),
    }));
  };

  const fetchSettingDetails = async () => {
    try {
      const response = await getSettingDetailsAPI();

      setSettings(
        Object.keys(response).length === 0 ? initialValues : response
      );
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const handleChange = (name, value) => {
    setSettings((prevDetails) => {
      const updatedDetails = { ...prevDetails };

      // Split the nested keys and update the state
      const keys = name.split(".");
      const lastKey = keys.pop();

      let currentObject = updatedDetails;
      keys.forEach((key) => {
        currentObject[key] = { ...currentObject[key] };
        currentObject = currentObject[key];
      });

      currentObject[lastKey] = value;

      return updatedDetails;
    });
  };

  const handleSubmit = async () => {
    try {
      const savedata = await saveSettings(settings);

      setSettingsInfo(settings);

      toast.success("Successfully saved!", {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error.response.data);
      toast.error(error?.response?.data?.message || "Something went wrong!", {
        autoClose: 2000,
      });
    }
  };

  const handleUpdateSubscription = () => {
    window.open(
      "https://billing.stripe.com/p/login/cN29Cm8cI9TY2ju4gg",
      "_blank"
    );
  };

  const handleCloseSubscription = async () => {
    Swal.fire({
      title: "Are you sure to close your subscription?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsClosingSubscription(true);
        try {
          const res = await requestCloseSubscriptionAPI();
          toast.success(
            res.message ||
              "Your subscription canceling was successfully send to the RosterRocket support.",
            {
              autoClose: 2000,
            }
          );
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsClosingSubscription(false);
        }
      }
    });
  };

  useEffect(() => {
    fetchDropoutManagers();
    fetchSettingDetails();
  }, []);

  const filteredAdmins = dropoutManagers.filter(
    (manager) =>
      !settings?.dropoutManagers?.some((item) => item._id === manager._id)
  );

  const handleImageUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.size > maxFileSize) {
      toast.error("File size exceeds the maximum limit of 1MB");
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const uploadImageToServer = async () => {
    try {
      setSettings((prevDetails) => ({
        ...prevDetails,
        companyInfo: {
          ...prevDetails.companyInfo,
          logo: uploadedImage,
        },
      }));
      toast.success("Image uploaded successfully");
    } catch (error) {
      toast.error("Failed to upload image");
      console.error(error);
    }
  };
  // Inside the component, add the useDropzone hook
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: handleImageUpload,
  });
  const { t } = useTranslation();
  const maintTitle = "Settings";

  const mainTitleTran = t(maintTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={mainTitleTran} title="Settings" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row className="mb-4">
                <Col sm="4">
                  <H5 attrH5={{ className: "mb-4" }}>{t("Mobile App")}</H5>

                  <Media className="mb-2">
                    <div className="me-2">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={settings?.allowedEnterOffTimes}
                          onChange={(e) =>
                            handleChange(
                              "allowedEnterOffTimes",
                              e.target.checked
                            )
                          }
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </div>
                    <Media body>
                      <Label className="col-form-label">
                        {t("Allowed enter off times")}
                      </Label>
                    </Media>
                  </Media>

                  <Media className="mb-2">
                    <div className="me-2">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={settings?.activateFeatureTimeTracking}
                          onChange={(e) =>
                            handleChange(
                              "activateFeatureTimeTracking",
                              e.target.checked
                            )
                          }
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </div>
                    <Media body>
                      <Label className="col-form-label">
                        {t("Allowed feature time tracking")}
                      </Label>
                    </Media>
                  </Media>
                </Col>

                <Col sm="4">
                  <H5 attrH5={{ className: "mb-4" }}>API'S</H5>

                  <Media className="mb-2">
                    <div className="me-2">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={settings?.importNationalHolidays}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "Attention! If you set the API connection inactive, national holidays will dissapear on your shift plan!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  handleChange("importNationalHolidays", false);
                                }
                              });
                            } else {
                              handleChange("importNationalHolidays", true);
                            }
                          }}
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </div>
                    <Media body className="d-flex align-items-center">
                      <Label className="col-form-label">
                        {t("Import national holidays")}
                      </Label>

                      {settingsInfo?.importNationalHolidays && (
                        <Link to="/settings/import-national-holidays">
                          <Btn
                            attrBtn={{
                              className: "ms-4 px-2 py-0",
                              color: "light",
                            }}
                          >
                            {t("Edit")}
                          </Btn>
                        </Link>
                      )}
                    </Media>
                  </Media>

                  <Media className="mb-2">
                    <div className="me-2">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={settings?.activateLexoffice}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "Attention! If you set the API connection inactive, invoice can not send to your lexoffice account!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  handleChange("activateLexoffice", false);
                                }
                              });
                            } else {
                              handleChange("activateLexoffice", true);
                            }
                          }}
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </div>
                    <Media body className="d-flex align-items-center">
                      <Label className="col-form-label">
                        {t("Activate lexoffice")}
                      </Label>

                      {settingsInfo?.activateLexoffice && (
                        <Link to="/settings/lexoffice">
                          <Btn
                            attrBtn={{
                              className: "ms-4 px-2 py-0",
                              color: "light",
                            }}
                          >
                            Edit
                          </Btn>
                        </Link>
                      )}
                    </Media>
                  </Media>
                  <Media className="mb-2">
                    <div className="me-2">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={settings?.displaywiki}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "Attention! If you set inactive, wiki can disappear your account!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  handleChange("displaywiki", false);
                                }
                              });
                            } else {
                              handleChange("displaywiki", true);
                            }
                          }}
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </div>
                    <Media body className="d-flex align-items-center">
                      <Label className="col-form-label">{t("Wiki")}</Label>

                      {settingsInfo?.displaywiki && (
                        <Link to="/settings/wiki">
                          <Btn
                            attrBtn={{
                              className: "ms-4 px-2 py-0",
                              color: "light",
                            }}
                          >
                            Edit
                          </Btn>
                        </Link>
                      )}
                    </Media>
                  </Media>
                </Col>
                <Col sm="4">
                  <Media className="mt-5">
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      className="me-2"
                    >
                      <Input {...getInputProps()} />
                      <Btn
                        attrBtn={{
                          color: "primary",
                        }}
                      >
                        {t("Upload Image")}
                      </Btn>
                    </div>
                    {(uploadedImage || settings?.companyInfo?.logo) && (
                      <Media
                        body
                        className="d-flex align-items-center mt-0 mb-0 mx-5"
                      >
                        <img
                          src={uploadedImage || settings.companyInfo.logo}
                          alt="Uploaded"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                        />
                      </Media>
                    )}
                    {uploadedImage && (
                      <Btn
                        attrBtn={{
                          color: "success",
                          onClick: () => {
                            uploadImageToServer();
                          },
                        }}
                      >
                        {t("Save Image")}
                      </Btn>
                    )}
                  </Media>
                  <div className="mt-2">
                    <H6>File Format:PNG</H6>
                  </div>
                  <div className="mt-2">
                    <H6>Max file size: 100KByte</H6>
                  </div>
                </Col>
              </Row>

              {/* <Row className="mb-4">
                <Col sm="10">
                  <H5 attrH5={{ className: "mb-1" }}>
                    {t("Shift plan display settings for mobile app")}
                  </H5>
                  <div className="mb-3 custom-div">
                    {t("Short names for the off times, which are display in the shift plan")}
                  </div>

                  <Row>
                    {shortNames.map((item) => (
                      <Col sm="4" key={item.name}>
                        <Row className="mb-2 g-3">
                          <Label className="col-sm-4 col-form-label">
                            {t(item.label)}
                          </Label>
                          <Col sm="5">
                            <Input
                              className="form-control digits"
                              type="text"
                              name={item.name}
                              value={settings?.shortNames?.[item.name]}
                              onChange={(e) =>
                                handleChange(
                                  `shortNames.${item.name}`,
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row> */}

              <Row className="mb-4">
                <Col sm="12">
                  <H5 attrH5={{ className: "mb-1" }}>{t("Dropout manager")}</H5>
                </Col>
                <Row>
                  <Col sm="5">
                    <div className="mb-2  custom-div">
                      {t(
                        "This persons get an email if a shift must be managed because of dropouts"
                      )}
                    </div>

                    <UL
                      attrUL={{
                        className: "simple-list border-0",
                        style: { maxHeight: "200px", overflow: "auto" },
                      }}
                    >
                      {settings?.dropoutManagers?.map((item) => (
                        <LI
                          key={item._id}
                          attrLI={{
                            className: "border-0 mb-2 list-group-item-action",
                          }}
                        >
                          <Btn
                            attrBtn={{
                              color: "primary",
                              className: "px-3 py-1",
                              onClick: () => handleRevert(item._id),
                            }}
                          >
                            {`${item.firstName} ${item.lastName}`}
                            <i className="fa fa-arrow-right ms-2 cursor-pointer" />
                          </Btn>
                        </LI>
                      ))}
                    </UL>
                  </Col>
                  <Col sm="4">
                    <H6 attrH6={{ className: "mb-2" }}>
                      {t("Workspace Admins")}
                    </H6>

                    <UL
                      attrUL={{
                        className: "simple-list border-0",
                        style: { maxHeight: "200px", overflow: "auto" },
                      }}
                    >
                      {filteredAdmins?.map((item) => (
                        <LI
                          key={item._id}
                          attrLI={{
                            className: "border-0 mb-2 list-group-item-action",
                          }}
                        >
                          <Btn
                            attrBtn={{
                              color: "primary mr-1",
                              className: "px-3 py-1 me-2",
                              onClick: () => handleInsert(item),
                            }}
                          >
                            <i className="fa fa-arrow-left me-2 cursor-pointer" />
                            {`${item.firstName} ${item.lastName}`}
                          </Btn>
                        </LI>
                      ))}
                    </UL>
                  </Col>
                </Row>
              </Row>

              <Row className="mb-4">
                <Col sm="12">
                  <H5 attrH5={{ className: "mb-1" }}>{t("Your License")}</H5>

                  <Row>
                    <Col sm="6">
                      <Row>
                        {companyInfo.map((item) => (
                          <Col sm="4" key={item.name}>
                            <FormGroup>
                              <Label>{t(item.label)}</Label>
                              <Input
                                type="text"
                                name={item.name}
                                value={settings?.companyInfo?.[item.name]}
                                onChange={(e) =>
                                  handleChange(
                                    `companyInfo.${item.name}`,
                                    e.target.value
                                  )
                                }
                              />
                            </FormGroup>
                          </Col>
                        ))}
                      </Row>
                    </Col>

                    <Col sm="3">
                      <FormGroup>
                        <Label>{t("Your Subscription Plan")}</Label>

                        <div className="d-flex align-items-center">
                          {/* <Select
                            styles={customSelectStyles}
                            className="js-example-basic-single me-3 flex-fill"
                            name="subscriptionPlan"
                          /> */}

                          <Btn
                            attrBtn={{
                              color: "warning",
                              onClick: handleUpdateSubscription,
                            }}
                          >
                            {t("Update")}
                          </Btn>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col sm="3" className="text-center custom-div">
                      <div className="mb-1 ">
                        {t("I will close my account")}
                      </div>

                      <Btn
                        attrBtn={{
                          color: "danger",
                          onClick: handleCloseSubscription,
                          disabled: isClosingSubscription,
                        }}
                      >
                        {isClosingSubscription && (
                          <Spinner size="sm" color="light" className="me-1" />
                        )}
                        {t("Close subscription")}
                      </Btn>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Btn
                attrBtn={{
                  color: "success",
                  onClick: handleSubmit,
                }}
              >
                {t("Save")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Settings;
