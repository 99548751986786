import React from "react";
import "./index.css";
import { useNavigate } from "react-router";
import { Col, Row } from "reactstrap";
import { H1, Image } from "../../../AbstractElements";
import { IMAGES } from "../../../Library/theme";
import IndustriesFooter from "./IndustriesFooter";

const Footer = () => {
  const navigate = useNavigate();

  const SectionData = {
    SessionFooter: {
      address: {
        title: "Roster Rocket",
        data: [
          "Directions to Branch Office",
          "Appointment upon request",
          "Enderstraße 59",
          "01277 Dresden",
          "Germany",
        ],
      },
      parts: [
        {
          title: "Products",
          data: [
            {
              title: "Shifts Plan",
              route: "savings-plan",
            },
          ],
        },
        {
          title: "Legal",
          data: [
            {
              title: "Privacy",
              route: "privacy",
            },
            {
              title: "Terms",
              route: "terms",
            },
          ],
        },

        {
          title: "Contact US",
          data: [
            {
              title: "WhatsApp: +49 (0)15566954084",
              link: `https://wa.me/message/SH3QJ42HQRPJN1`,
            },
            {
              title: "Phone: +49 (0)155 / 66954084",
            },
            {
              title: "Fax: +49 (0)351 / 85074536",
            },
            {
              title: "Email: info@rosterrocket.de",
            },
          ],
        },
      ],
    },
  };

  return (
    <>
      <IndustriesFooter />
      {/* Footer */}
      <Row className="landing_footer">
        <Col xxl={4} xl={4}>
          <div
            style={{ margin: "0px 0 0 0px", zIndex: 1, cursor: "pointer" }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/home");
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/svg/logo.svg`}
              alt="Logo"
              width={200}
            />
          </div>
          <div style={{ gap: 5, display: "flex", flexDirection: "column" }}>
            {SectionData.SessionFooter.address.data.map((text, i) => {
              // Check if the text is part of the specific address
              const isAddress = [
                "Enderstraße 59",
                "01277 Dresden",
                "Germany",
              ].includes(text);

              if (isAddress) {
                return (
                  <a
                    key={i}
                    href={`http://maps.google.com/?q=${encodeURIComponent(
                      "Enderstraße 59,01277 Dresden,Germany"
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`font16_outfit text-gray ${i === 0 ? "m-t-20" : ""
                      }`}
                    style={{
                      fontWeight: 400,
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {text}
                  </a>
                );
              } else {
                return (
                  <div
                    key={i}
                    className={`font16_outfit text-gray ${i === 0 ? "m-t-20" : ""
                      }`}
                    style={{
                      fontWeight: 400,
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {text}
                  </div>
                );
              }
            })}
          </div>
        </Col>
        <Col xxl={6} xl={8} xs={12}>
          <Row>
            {SectionData.SessionFooter.parts.map((item, index) => (
              <Col md={3} key={index}>
                <H1 attrH1={{ className: "font24_outfit text-dark text-bold" }}>
                  {item.title}
                </H1>
                <div
                  style={{ gap: 10, display: "flex", flexDirection: "column", marginBottom: '20px' }}
                >
                  {item.data.map((element, i) => {
                    const isClickable = element?.route || element?.link;

                    const handleClick = () => {
                      if (element?.route) {
                        navigate(`/${element.route}`);
                      } else if (element?.link) {
                        window.open(element?.link, "_blank");
                      } else if (element.title.startsWith("Email:")) {
                        navigator.clipboard.writeText(
                          element.title.replace("Email: ", "")
                        );
                        alert("Email address copied to clipboard.");
                      } else if (
                        element.title.startsWith("Phone:") ||
                        element.title.startsWith("Fax:")
                      ) {
                        navigator.clipboard.writeText(
                          element.title.split(": ")[1]
                        );
                        alert("Number copied to clipboard.");
                      }
                      window.scrollTo(0, 0);
                    };

                    return (
                      <div
                        key={i}
                        onClick={isClickable ? handleClick : null}
                        className={`font16_outfit text-gray ${i === 0 ? "m-t-20" : ""
                          }`}
                        style={{
                          fontWeight: 400,
                          cursor: isClickable ? "pointer" : "default",
                          width: "max-content",
                        }}
                      >
                        {element.title}
                      </div>
                    );
                  })}
                </div>
              </Col>
            ))}
          </Row>

        </Col>
        <Col xs={12}>
          <div
            style={{
              background: "#00000017",
              height: "1px",
              marginTop: "120px",
              width: "mzx-content",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "16px",
              fontFamily: "Outfit",
              color: "black",
              marginTop: "8px",
            }}
          >
            <div>
              <i className="fa fa-regular fa-copyright fa-xl me-1"></i>
              {`${new Date().getFullYear()} Roster Rocket. All rights reserved`}
            </div>
            <div>
              <a
                href="https://wa.me/message/SH3QJ42HQRPJN1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/WeAcceptBitcoin.png`}
                  alt="We Accept Bitcoin"
                  width={50}
                  style={{ marginRight: "150px" }}
                />
                <strong>{`If you want to pay with Bitcoin, please contact us via email or WhatsApp. Thanks.`}</strong>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
