import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import {
  Container,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  createEmployeeAPI,
  getEmployeeSkillsListAPI,
  getEmployeeDetailsAPI,
  updateEmployeeAPI,
} from "../../../Library/apis";
import CustomSelect from "../../Common/CustomSelect";



const formInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  birthday: "",
  contractBegin: "",
  contractHoliday: "",
  contractHoursWeek: "",
  activeShiftPlan: false,
  active: false,
  role: "",
  jobTypes: [],
  skills: [],
};

const EmployeeForm = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams();
  const roleOptions = [
    { value: "workspace-admin", label: t("Workspace Admin") },
    { value: "employee", label: t("Employee") },
  ];
  
  const jobTypeOptions = [
    { value: "part-time", label: t("Part Time") },
    { value: "full-time", label: t("Full Time") },
    { value: "mini-job", label: t("Mini Job") },
    { value: "jumper", label: t("Jumper") },
    { value: "extern", label: t("Extern") },
  ];
  const [skillOptions, setSkillOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(formInitialValues);

  const fetchSkills = async () => {
    try {
      const skillsRes = await getEmployeeSkillsListAPI();
      setSkillOptions(
        skillsRes?.map((item) => ({ value: item._id, label: item.name }))
      );
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const fetchEmployee = async () => {
    try {
      const employee = await getEmployeeDetailsAPI(employeeId);
      const { password, birthday, contractBegin, skills, ...other } = employee;
      setInitialValues({
        ...other,
        birthday: birthday ? moment(birthday).format("YYYY-MM-DD") : "",
        contractBegin: moment(contractBegin).format("YYYY-MM-DD"),
        skills: skills.map((item) => ({ value: item._id, label: item.name })),
      });
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    fetchSkills();
    setInitialValues(formInitialValues);

    if (employeeId) {
      fetchEmployee();
    }

    return () => {
      setSkillOptions([]);
      setInitialValues({});
    };
  }, [employeeId]);

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    contractBegin: Yup.string().required("Contract begin is required"),
    role: Yup.string().required("Role is required"),
    jobTypes: Yup.array()
      .required("Job types are required")
      .min(1, "Select at least one job type"), // Adjust the min value as needed
    password: Yup.string().test(
      "required",
      "Password is required",
      function (value) {
        return Boolean(employeeId) || Boolean(value);
      }
    ),
    // ... other validations ...0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = {
          ...values,
          skills: values.skills.map((item) => item.value),
        };
        employeeId
          ? await updateEmployeeAPI(employeeId, formData)
          : await createEmployeeAPI(formData);
        toast.success(`Successfully ${employeeId ? "updated" : "added"}!`, {
          autoClose: 2000,
        });
        handleBack();
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleBack = () => {
    window.history.back();
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleChange,
    isSubmitting,
  } = formik;

  const handleSelectChange = (name, value, isFromDb) => {
    if (Array.isArray(value)) {
      // Multi-select: Extract values from the array
      const values = value.map((option) => option.value);
      setFieldValue(name, isFromDb ? value : values);
    } else {
      // Single select: Set the value directly
      setFieldValue(name, value ? value : null);
    }

    // Mark the field as touched to trigger validation
    setFieldTouched(name, true, false);
  };

  const mainTitleKey = employeeId ? 'Edit employee' : 'Add employee';
  const mainTitle = t(mainTitleKey);
  return (
    <Fragment>
       <Breadcrumbs mainTitle={mainTitle} title={mainTitle} />
      <Container fluid={true}>
        <Col sm="12">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10 ,left:10},
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardBody>
              <Form className="form-bookmark" onSubmit={handleSubmit}>
                <div>
                  <Row>
                    <Col sm="6">
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label className="required">{t("First Name")}</Label>
                            <Input
                              type="text"
                              name="firstName"
                              onChange={handleChange}
                              value={values.firstName}
                              invalid={errors.firstName && touched.firstName}
                            />
                            {errors.firstName && touched.firstName && (
                              <span className="mt-2 text-danger">
                                {errors.firstName}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label className="required">{t("Last Name")}</Label>
                            <Input
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              value={values.lastName}
                              invalid={errors.lastName && touched.lastName}
                            />
                            {errors.lastName && touched.lastName && (
                              <span className="mt-2 text-danger">
                                {errors.lastName}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>{t("Birthday")}</Label>
                            <Input
                              className="form-control digits"
                              type="date"
                              name="birthday"
                              value={values.birthday}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{t("Active Shift Plan")}</Label>
                            <div>
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  name="activeShiftPlan"
                                  checked={values.activeShiftPlan}
                                  onChange={handleChange}
                                />
                                <span className="switch-state"></span>
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label>{t("Active Login")}</Label>
                            <div>
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  name="active"
                                  checked={values.active}
                                  onChange={handleChange}
                                />
                                <span className="switch-state"></span>
                              </Label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row>
                    <Col sm="6">
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label className="required">{t("Email")}</Label>
                            <Input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              invalid={errors.email && touched.email}
                            />
                            {errors.email && touched.email && (
                              <span className="mt-2 text-danger">
                                {errors.email}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label className="required">{t("Password")}</Label>
                            <Input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              value={values.password}
                              invalid={errors.password && touched.password}
                            />
                            {errors.password && touched.password && (
                              <span className="mt-2 text-danger">
                                {errors.password}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6">
                      <Row>
                        <Col sm="6">
                          <CustomSelect
                            name="role"
                            options={roleOptions}
                            label="Role"
                            onChange={handleSelectChange}
                            errors={errors}
                            touched={touched}
                            value={roleOptions.find(
                              (option) => option.value === values["role"]
                            )}
                            required
                          />
                        </Col>

                        <Col sm="6">
                          <CustomSelect
                            name="jobTypes"
                            options={jobTypeOptions}
                            label="Job Types"
                            onChange={handleSelectChange}
                            errors={errors}
                            touched={touched}
                            value={jobTypeOptions.filter((option) =>
                              values?.jobTypes?.includes(option.value)
                            )}
                            isMulti
                            async
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <div>
                  <Row>
                    <Col sm="6">
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <Label className="required">{t("Contract Begin")}</Label>
                            <Input
                              className="form-control digits"
                              type="date"
                              name="contractBegin"
                              value={values.contractBegin}
                              onChange={handleChange}
                              invalid={
                                errors.contractBegin && touched.contractBegin
                              }
                            />
                            {errors.contractBegin && touched.contractBegin && (
                              <span className="mt-2 text-danger">
                                {errors.contractBegin}
                              </span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                          <FormGroup>
                            <Label>{t("Contract Holiday")}</Label>
                            <Input
                              type="text"
                              name="contractHoliday"
                              onChange={handleChange}
                              value={values.contractHoliday}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                          <FormGroup>
                            <Label>{t("Contract Hours Week")}</Label>
                            <Input
                              type="text"
                              name="contractHoursWeek"
                              onChange={handleChange}
                              value={values.contractHoursWeek}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm="6">
                      <Row>
                        <Col sm="12">
                          <CustomSelect
                            name="skills"
                            options={skillOptions}
                            label="Skills"
                            onChange={handleSelectChange}
                            errors={errors}
                            touched={touched}
                            value={values.skills}
                            isMulti
                            async
                            isFromDb
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>

                <Row>
                  <Col>
                    <div className="text-end">
                      <Btn
                        attrBtn={{
                          color: "success",
                          className: "me-3",
                          disabled: isSubmitting,
                          type: "submit",
                        }}
                      >
                        {isSubmitting && (
                          <Spinner size="sm" color="light" className="me-1" />
                        )}
                        {t("Save")}
                      </Btn>
                      <Link to={`/employees/list`}>
                        <Btn
                          attrBtn={{ color: "danger", disabled: isSubmitting }}
                        >
                          {t("Cancel")}
                        </Btn>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default EmployeeForm;
