import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          // 'General': 'General',
          // 'Dashboards,Widgets': 'Dashboards,Widgets',
          // 'Dashboard': 'Dashboard',
          // 'Default': 'Default',
          // 'Ecommerce': 'Ecommerce',
          // 'Widgets': 'Widgets',
          // 'Chart': 'Chart',
          // 'Applications': 'Applications',
          // 'Ready to use Apps': 'Ready to use Apps'
          Dashboard: "Dashboard",
          "Shift plan": "Shift plan",
          Employees: "Employees",
          "Time tracking": "Time tracking",
          "Holiday & Illness": "Holiday & Illness",
          "Account Settings": "Account Settings",
          Profile: "Profile",
          Support: "Support",
          today: "Today",
          wiki: {
            new_Working: "Insert new working area",
            new_Employees: "Insert new employees",
            shifts_At_Employees: "Insert shifts at employees",
            shifts_At_Working_Area: "Insert shifts at working area",
            skills_At_Employees: "Insert skills at employees",
            attributes_At_Employee: "Insert attributes at employee",
            Skill_At_Working_Area: "Insert Skill at working area",
            attributes_At_Working_Area: "Insert attributes at working area",
            shifts_For_Your_Employees:
              "Plan manually shifts for your employees",
            automatically_Shift_Plan_For_Your_Employees:
              "Create automatically shift plan for your employees",
          },
        },
      },
      cn: {
        translations: {
          General: "一般",
          Dashboards: "仪表板",
          Widgets: "小部件",
          Dashboard: "仪表板",
          Default: "默认",
          Ecommerce: "电子商务",
          Chart: "图表",
          Applications: "应用领域",
          "Ready to use Apps": "准备使用的应用程序",
        },
      },
      ae: {
        translations: {
          General: "جنرال لواء",
          Dashboards: "لوحات القيادة والحاجيات",
          Widgets: "لوحات القيادة",
          Dashboard: "لوحة القيادة",
          Default: "إفتراضي",
          Ecommerce: "التجارة الإلكترونية",
          Chart: "مخطط",
          Applications: "التطبيقات",
          "Ready to use Apps": "جاهز لاستخدام التطبيقات",
        },
      },
      du: {
        translations: {
          General: "Allgemein",
          Dashboard: "Übersicht",
          "Shift plan": "Dienstplan",
          Employees: "Mitarbeiter",
          "Working areas": "Arbeitsbereich",
          "Time tracking": "Zeiterfassungen",
          "Holiday & Illness": "Urlaub & Krankhei",
          Settings: "Einstellungen",
          Support: "Support",
          Content: "inhoud",

          "Create automatically": "Automatisch erstellen",
          "Clear monthly plan": "Lösche Dienstplan (Diesen Monat)",
          "Export to excel": "Nach Excel exportieren",
          "Ready to be Managed!": "Bereit zum Managen!",
          "Card 1": "Karte 1",
          "Card 2": "Karte 2",
          "Calendar view": "Kalenderansicht",
          "List View": "Listenansicht",
          Unconfirmed: "Unbestätigt",
          All: "Alle",
          "New employee": "Neuer Mitarbeiter anlegen",
          "The shift plan will be created for 1 year from now. This process can take up to 3 minutes.":
            "Der Dienstplan wird für 1 Jahr im Voraus erstellt. Dieser Prozess kann bis zu 3 Minuten dauern.",
          "With click on the employee, all working areas and <br /> shifts which are assigned will be load.":
            "Mit Klick auf den Mitarbeiter, werden alle Arbeitsbereiche und <br /> Dienste von diesem Mitarbeiter angezeigt.",
          "These are open shifts which are ready to manage. <br /> Please click on the shift and assign a new employee. <br /> You can move the open shift with drag & and drop to the calendar. <br /> You can delete it, if the shift is not more needed.":
            "Diese Dienste sind alle offen und müssen neu organisiert werden. <br /> Mit Klick auf den Dienst, können Sie einen neuen Mitarbeiter zuweisen. <br /> Es ist auch möglich den Dienst mit Hilfe von Drag & Drop auf den jeweiligen Kalendertag zu ziehen. <br /> Löschen Sie den Dienst, falls dieser nicht neu besetzt werden muss.",
          Skills: "Fähigkeiten",
          Attributes: "Attribute",
          from: "aus",
          "Search by name...": "Suche...",
          "New working area": "Neuer Arbeitsbereich anlegen",
          today: "Heute",

          Create: "Anlegen",
          List: "Liste",
          "Show active user": "Aktive Benutzer anzeigen",
          Name: "Name",
          "Job Types": "Vertragsart",
          Role: "Rolle",
          Attributes: "Attribute",
          Shifts: "Dienste",
          "Working Areas": "Arbeitsbereich",
          Edit: "Ändern",
          "Contract Hours Week": "Vertragsstunden pro Woche",
          "Contract Holiday": "Urlaub gemäß Vertrag",
          "First Name": "Vorname",
          "Last Name": "Nachname",
          Birthday: "Geburtstag",
          "Active Shift Plan": "Aktiver Schichtplan",
          "Active Login": "Aktiver Login",
          Email: "E-Mail",
          Password: "Passwort",
          "Contract Begin": "Vertragsbeginn",
          "Contract Holiday": "Urlaub gemäß Vertrag",
          "Contract Hours Week": "Vertragsstunden pro Woche",
          "Workspace Admin": "Teamleiter",
          Employee: "Mitarbeiter",
          "Part Time": "Teilzeit",
          "Full Time": "Vollzeit",
          "Mini Job": "Minijob",
          Save: "speichern",
          Cancel: "abbrechen",
          Back: "zurück",
          "Add employee": "Mitarbeiter hinzufügen",
          "Edit employee": "Mitarbeiter bearbeiten",
          "Show active working areas": "Aktive Arbeitsbereiche anzeigen",
          of: "von",
          Description: "Beschreibung",
          "New skill": "Neue Fähigkeit anlegen",
          "Add skill": "Fähigkeit hinzufügen",
          "Working Area": "Arbeitsbereich",
          "Add Working Area": "Arbeitsbereich hinzufügen",
          "Edit Working Area": "Arbeitsbereich bearbeiten",
          "New working time": "Neue Arbeitszeit erfassen",
          "Export all to excel": "Alles in Excel exportieren",
          "Edit Working Time": "Arbeitszeit bearbeiten",
          "Add Working Time": "Arbeitszeit hinzufügen",
          Date: "Datum",
          "Date Create": "Datum Create",
          Start: "Start",
          End: "Ende",
          Break: "Pause",
          "Holiday & Illness": "Urlaub & Krankheit",
          "Calendar View": "Kalenderansicht",
          Listenansicht: "Listenansicht",
          "New off time": "Neue Abwesenheit anlegen",
          "All Type": "Alle Arten",
          "Holiday Payed": "bezahlter Urlaub",
          "Holiday Unpayed": "unbezahlter Urlaub",
          Illness: "Krankheit",
          "Illness Child": "Krankes Kind",
          School: "Schule",
          Miscellaneous: "Verschiedenes",
          "All State": "Alle Statusmeldungen",
          Open: "Offen",
          Declined: "Abgelehnt",
          Approved: "Genehmigt",

          Type: "Art der Abwesenheit",
          State: "Status",
          "Edit Profile": "Profil bearbeiten",
          "Email Address": "E-Mail-Adresse",
          "Mobile App": "Mobilanwendung",
          "Allowed enter off times": "Erlaubt Eintragung von Abwesenheiten",
          "Allowed feature time tracking":
            "Erlaubt Eintragung von Arbeitszeiten",
          "Activate lexoffice": "Lexoffice aktivieren",
          "Import national holidays": "Feiertage importieren",
          "Shift plan(web app & mobile app)":
            "Schichtplan (Webanwendung & Mobilanwendung)",
          "Shift plan display settings for mobile app":
            "Dienstplan Darstellung für Mobil",
          "Short names for the off times, which are display in the shift plan":
            "Kurznamen für die Abwesenheiten, die im Dienstplan angezeigt werden",
          "Dropout manager": "Ausfallmanager",
          "This persons get an email if a shift must be managed because of dropouts":
            "Diese Personen erhalten eine E-Mail, wenn eine Schicht aufgrund von Ausfällen gemanagt werden muss.",
          "Workspace Admins": "Administratoren des Arbeitsbereichs",
          "Your License": "Ihre Lizenz",
          "I will close my account": "Ich werde mein Konto schließen",
          "Close subscription": "Abonnement kündigen",
          Update: "Aktualisierung",
          "Company Name": "Firmenname",
          "Your Subscription Plan": "Ihr Abonnementplan",
          "Street & House Nr": "Straße und Hausnummer",
          City: "Stadt",
          "Please write in details what is the problem? Our support will answer you within 48 hours.":
            "Bitte beschreiben Sie ausführlich, worin das Problem besteht. Unser Support wird Ihnen innerhalb von 48 Stunden antworten.",
          Reference: "Betreff",
          Send: "Senden",
          "Upload Picture 1": "Bild 1 hochladen",
          "Upload Picture 2": "Bild 2 hochladen",
          "Upload Picture 3": "Bild 3 hochladen",
          Dashboard: "Übersicht",
          Account: "Kundenaccount",
          "Log Out": "Abmelden",
          "New attribute": "Neues Attribut anlegen",
          "List of all attributes in the system":
            "Liste aller Attribute im System",
          "New shift": "Neuer Dienst anlegen",
          Interval: "Intervall",
          "QR Code generated and email sent successfully!":
            "QR-Code generiert und E-Mail erfolgreich versendet!",
          "Error sending email. Please try again later.":
            "Fehler beim Senden der E-Mail. Bitte versuchen Sie es später noch einmal.",
          Actions: "Aktionen",
          "QR Code generated and email sent successfully!":
            "QR-Code generiert und E-Mail erfolgreich versendet!",
          "Error sending email. Please try again later.":
            "Fehler beim Senden der E-Mail. Bitte versuchen Sie es später noch einmal.",
          Delete: "Löschen",
          Monday: "Montag",
          Tuesday: "Dienstag",
          Wednesday: "Mittwoch",
          Thursday: "Donnerstag",
          Friday: "Freitag",
          Saturday: "Samstag",
          Sunday: "Sonntag",
          "Time Cross": "Bruttozeit",
          "Time Net": "Nettozeit",
          "Confirmed by employee": "Bestätigt / Mitarbeiter",
          "Confirmed by manager": "Bestätigt / Manager",
          "Confirmed from employees": "Bestätigt durch Mitarbeiter",
          "Open from employees": "Offen",
          "Are you sure to delete?":
            "Sind Sie sicher, dass Sie die Arbeitszeit löschen möchten ?",
          "Yes, delete it!": "Ja, löschen!",
          "Are you sure?": "Sind Sie sicher ?",
          "You can revert this, if you want!":
            "Sie können die Bestätigung nachträglich wieder rückgängig machen, wenn Sie möchten!",
          "Yes, confirm it!": "Ja, bestätigen !",
          "You can confirm this, if you want!":
            "Sie können dies bestätigen, wenn Sie möchten!",
          "Add Off Time": "Abwesenheit hiinzufügen",
          "Edit off Time": "Abwesenheit hiinzufügen",
          Types: "Art der Abwesenheit",
          "Holiday Payed": "Bezahlter Urlaub",
          "Holiday Unpayed": "Unbezahlter Urlaub",
          Illness: "Krankheit",
          "Illness Child": "Krankes Kind",
          School: "Schule",
          Miscellaneous: "Verschiedenes",
          Open: "Offen",
          Approved: "Genehmigt",
          States: "Status",
          month: "Monat",
          week: "Woche",
          day: "Tag",
          "Edit Password": "Paswort ändern",
          "Update Profile": "speichern",
          "Old Password": "Altes Passwort",
          "New Password": "Neues Passwort",
          "Confirm Password": "Neues Passwort bestätigen",

          "Telephone Nr": "Telefon Nr",
          "Vat Nr": "Umsatz - Steuernummer",

          Country: "Land",
          "Federal state": "Bundesland",
          "List of all Working Areas in the system":
            "Liste aller Arbeitsbereiche im System",
          "Add Shift": "Dienst hinzufügen",
          "Edit Shift": "Dienst bearbeiten",
          "With click on the working area, all assigned employees will be load. <br /> With Drag & Drop, you can move the selected working area to the shift plan.":
            "Mit Klick auf den Arbeitsbereich, werden alle Mitarbeiter angezeigt,  <br /> die auf diesem Arbeitsbereich eingeplant sind. Mit Hilfe von Drag & Drop können  <br /> Sie den Arbeitsbereich mit dem vorhandenen Dienst auf einen Kalendertag Ihrer Wahl ziehen.",
          "Add attribute": "Attribut hinzufügen",
          Deactivate: "Deaktivieren",
          Revert: "Rückgängig machen",
          Confirm: "Bestätigen",
          "You can reactivate this, if you want!":
            "Als je wilt, kun je dit opnieuw activeren!",
          "This event": "Diesen Dienst",
          "This and following events":
            "Diesen Dienst und alle weiteren Dienste",
          "Change Password": "Passwort ändern",
          "Assign Employees to working area":
            "Mitarbeiter zum Arbeitsbereich hinzufügen:",
          "Assigned employees": "Zugewiesene Mitarbeiter",
          "Employees which are free": "Verfügbare Mitarbeiter",
          "Attention: Skills and Attributes does not match with the requirments of the working area.":
            "Let op: Vaardigheden en eigenschappen komen niet overeen met de eisen van het werkgebied.",
          "Sorry, no employee is free in this time range and on this day":
            "Entschuldigen Sie, leider sind keine Mitarbeiter an diesem Tag und zu dieser Uhrzeit zur Verfügung",
          Confirm: "Geselecteerde datum",
          "Selected Date": "Bestätigen",
          "End Date": "Einddatum",
          Duration: "Dauer",
          Intervall: "Interval",
          "Yes, revert it!": "Ja, rückgängig machen !",
          "Are you sure to add?": "Weet je zeker dat je wilt toevoegen?",
          Ok: "OK",
          "Start Time": "Startdatum",
          "End Time": "Enddatum",
          Close: "Schließen",
          "Rosterrocket recommends the following employees for the open shift":
            "Rosterrocket empfiehlt folgende Mitarbeiter für diesen offenen Dienst",
          January: "Januar",
          February: "Februar",
          March: "März",
          April: "April",
          May: "Mai",
          June: "Juni",
          July: "Juli",
          August: "August",
          September: "September",
          October: "oktober",
          November: "November",
          December: "Dezember",
          "Edit recurring event": "Bearbeiten Sie wiederkehrende Ereignisse",
          Address: "Adresse",
          email: "E-Mail",
          Mobile: "Mobil",
          Memo: "Wichtige Notizen",
          "Employees All": "Mitarbeiter Alle",
          "Employees extern": "Mitarbeiter Extern",
          "Planned this month": "Geplante Stunden diesen Monat",
          "Contract this month": "Laut Vertrag",
          "Dropouts this month": "Ausfälle an Schichten diesen Monat",
          "Successful managed": "Davon erfolgreich gemanaget",
          "Illness this month in days": "Krankheit diesen Monat gesamt",
          "Working Employees": "Arbeitende Mitarbeiter diesen Monat gesamt",
          "Planned holidays": "Geplante Urlaubstage diesen Monat",
          "Recent Rate": "Erfolgsrate",
          "Insert new working area": "Arbeitsbereich anlegen",
          "Insert new employees": "Mitarbeiter anlegen",
          "Insert shifts at employees": "Schichten für Mitarbeiter anlegen",
          "Insert shifts at working area":
            "Schichten für Arbeitsbereich anlegen",
          "Insert skills at employees":
            "Fähigkeiten beim Mitarbeiter hinterlegen",
          "Insert attributes at employee":
            "Attribute beim Mitarbeiter hinterlegen",
          "Insert Skill at working area":
            "Fähigkeiten beim Arbeitsbereich hinterlegen",
          "Insert attributes at working area":
            "Attribute beim Arbeitsbereich hinterlegen",
          "Plan manually shifts for your employees":
            "Dienstplan manuell für Mitarbeiter erstellen",
          "Create automatically shift plan for your employees":
            "Dienstplan automatisch für Mitarbeiter erstellen",
          Declined: "Abgelehnt",
          "Current Month": "Diesen Monat",
          Confirmed: "Bestätigt",
          "Mark all as finished": "Alle Aufgaben als erledigt setzen",
          "Lexoffice ID": "",
          Tax: "Steuer",
          "Article Name": "Artikelname",
          "Welcome to lexoffice API Template":
            "Willkommen bei der Lexoffice Anbindung",
          "New Service": "Neuer Artikel",
          Amount: "Menge",
          Price: "Preis",
          "Please enter at least 1 shift!":
            "Bitte tragen Sie mindestens 1 Schicht ein!",
          "Otherwise no roster can be created!":
            "Ansonsten kann der Schichtplan nicht generiert werden!",
          Street: "Straße",
          Name: "Name",
          Zip: "PLZ",
          "Country Code": "Länderkürzel",
          "Important Mem": "Wichtige Zusatznotizen",
          "Lexoffice Invoice": "Lexoffice Rechnungen",
          "Lexoffice Payroll": "Lexoffice Mitarbeiter",
          "API Key": "API Key",
          Test: "Test",
          "Invoice text introduction": "Einleitung Rechnungstext",
          "Invoice text footer": "Fußzeile Rechnungstext",
          Overview: "Übersicht",
          "Amount Invoices": "Menge Rechnungen",
          "Amount Hours": "Menge Stunden",
          Errors: "Fehler",
          "Overview about all working area shifts from Roster Rocket. Please select your month and load all datas.":
            "Übersicht über alle Arbeitsbereiche von Roster Rocket. Bitte wählen Sie das Jahr und den Monat aus, um alle Daten zu laden.",
          "Show log files": "Zeige alle relevanten Systeminformationen an",
          "Transfer all to lexoffice":
            "Alle Rechnungsdaten an Lexoffice senden",
          "Download all Invoices as PDF":
            "Alle Rechnungen als PDF herunterladen",
          "Set Month & Year": "Wählen Sie den Monat / Jahr",
          Year: "Jahr",
          Month: "Monat",
          "Lexoffice API Connection Details":
            "Lexoffice API Verbindungsdetails",
          "Organization ID": "Organisations ID",
          "Created By": "Erstellt durch",
          "User Email": "Benutzer E-Mail",
          "Connection ID": "Verbindungs - ID",
          "Business Features": "Feature",
          "Tax Type": "Steuer Typ",
          "Distance Sales Principle": "Distance Sales Principle",
          "Small Business": "Small Business",
          "Payment Method": "Zahlungsmethode",
          "Invoice Nr": "Rechnungs - NR",
          Status: "Status",
          Hours: "Stunden",
          Sum: "Summe",
          "Workflow State": "Status",
          Error: "Fehler",
          Pos: "Pos",
          "Product/Service": "Produkt / Service",
          "Total Sum": "Gesamtsumme",
          "Create new single invoice": "Neue einzelne Rechnung erzeugen",
          "Transfer all data to lexoffice":
            "Alle Rechnungsdaten an Lexoffice übertragen",
          "Invoice Data View": "Ansicht Rechnung",
          "Download Invoice as PDF": "Rechnung als PDF herunterladen",
          Delete: "Löschen",
          "Successfully saved!": "Erfolgreich gespeichert!",
          "Something went wrong!": "Etwas ist schief gelaufen!",
          "Invalid data or ID": "Ungültige Daten oder ID",
          "Lexoffice Payroll": "Lexoffice Mitarbeiter",
          "Overview about all time trackings from Roster Rocket. Please select your month and load all datas.":
            "Übersicht über alle Arbeitszeiterfassungen in Roster Rocket. Bitte wählen Sie den Monat und das Jahr aus.",
          Surname: "Vorname",
          Lastname: "Nachname",
          "Working hours": "Geleistete Stunden",
          "Paid holiday": "Bezahlter Urlaub",
          Sickness: "Krank",
          "Sickness Child": "Kind krank",
          invoice: "Zahlbar sofort, rein Brutto",
          "Set Month & Year": "Monat & Jahr auswählen",
          "Export to Excel": "Export nach Excel",
          "Payed Holiday": "Bezahlter Urlaub",
          "Appointments in current month": "Termine im aktuellen Monat",
          "Appointments in new month": "Termine im neuen Monat",
          "direct-debit": "Wird automatisch per Lastschrift eingegzogen",
        },
      },
      es: {
        translations: {
          General: "General",
          "Dashboards,Widgets": "Cuadros de mando, widgets",
          Dashboard: "Tablero",
          Default: "Defecto",
          Ecommerce: "Comercio electrónico",
          Widgets: "Widgets",
          Chart: "Gráfico",
          Applications: "Aplicaciones",
          "Ready to use Apps": "Gereed om programme te gebruik",
        },
      },
      fr: {
        translations: {
          General: "Générale",
          "Dashboards,Widgets": "Tableaux de bord, widgets",
          Dashboard: "Tableau de bord",
          Default: "Défaut",
          Ecommerce: "Commerce électronique",
          Widgets: "Widgets",
          Chart: "Graphique",
          Applications: "Applications",
          "Ready to use Apps": `Applications prêtes à l'emploi`,
        },
      },
      pt: {
        translations: {
          General: "Geral",
          "Dashboards,Widgets": "Painéis, Widgets",
          Dashboard: "painel de controle",
          Default: "Padrão",
          Ecommerce: "Comércio eletrônico",
          Widgets: "Widgets",
          Chart: "Gráfico",
          Applications: "Formulários",
          "Ready to use Apps": "Aplicativos prontos para usar",
        },
      },
    },
    fallbackLng: "en",
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
