const LIVE_MENUITEMS = [
  {
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        path: "/dashboard",
        title: "Dashboard",
        icon: "home",
        type: "link",
        pseudoLink: "/dashboard",
      },
      {
        path: "/shift-plan",
        title: "Shift plan",
        icon: "project",
        type: "link",
        pseudoLink: "/shift-plan",
      },
      {
        title: "Employees",
        icon: "user",
        type: "sub",
        active: false,
        children: [
          {
            path: "/employees/list",
            type: "link",
            title: "List",
            pseudoLink: "/employees/list",
          },
          {
            path: "/employees/create",
            type: "link",
            title: "Create",
            pseudoLink: "/employees/create",
          },
        ],
      },
      {
        title: "Working areas",
        icon: "job-search",
        type: "sub",
        active: false,
        children: [
          {
            path: "/working-areas/list",
            type: "link",
            title: "List",
            pseudoLink: "/working-areas/list",
          },
          {
            path: "/working-areas/create",
            type: "link",
            title: "Create",
            pseudoLink: "/working-areas/create",
          },
        ],
      },
      {
        title: "Time tracking",
        icon: "ui-kits",
        type: "sub",
        active: false,
        children: [
          {
            path: "/time-trackings/list",
            type: "link",
            title: "List",
            pseudoLink: "/time-trackings/list",
          },
          {
            path: "/time-trackings/create",
            type: "link",
            title: "Create",
            pseudoLink: "/time-trackings/create",
          },
        ],
      },
      {
        title: "Holiday & Illness",
        icon: "calendar",
        type: "sub",
        active: false,
        children: [
          {
            path: "/holiday-illnesses/list",
            type: "link",
            title: "List",
            pseudoLink: "/holiday-illnesses/list",
          },
          {
            path: "/holiday-illnesses/create",
            type: "link",
            title: "Create",
            pseudoLink: "/holiday-illnesses/create",
          },
        ],
      },
    ],
  },
];

export const MENUITEMS = LIVE_MENUITEMS;
