import React, { Fragment } from 'react';
// import { Row, Col, CardBody, Card } from 'reactstrap';
import { useNavigate } from 'react-router';

import { Btn, H3, LI, P, UL } from '../../../AbstractElements';
import { COLORS } from '../../../Library/theme';
import { formatCurrency } from '../../../Library/custom_utils';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const StandardPricingcard = () => {

  const navigate = useNavigate();

  const pricingData = [
    {
      title: 'Free trial',
      priceValues: {
        amount: formatCurrency(5),
        duration: '/mo'
      },
      pricingContent: [
        'Time tracking',
        'Unlimited access to work',
        'iPhone & Android App',
        'Shift Schedule Helper',
        'Customer service',
        'Outage Manager',
        'Leadership',
        'Skill-Manager',
        'Attribute management',
        'Multilingual',

      ],
      button: "SUBSCRIBE"
    },
    // {
    //   title: 'Free trial',
    //   priceValues: {
    //     amount: formatCurrency(0),
    //     duration: '/mo'
    //   },
    //   pricingContent: [
    //     '30 Days Free trial',
    //     '1 Workspace Admin',
    //     '1 ~ 2 Employees'
    //   ],
    //   button: "Let's try"
    // },
    // {
    //   title: 'Standard',
    //   priceValues: {
    //     amount: formatCurrency(30),
    //     duration: '/mo'
    //   },
    //   pricingContent: [
    //     '30 Days',
    //     '1 ~ 3 Workspace Admin',
    //     '1 ~ 10 Employees'
    //   ],
    //   button: 'Upgrade'
    // },
    // {
    //   title: 'Premium',
    //   priceValues: {
    //     amount: formatCurrency(60),
    //     duration: '/mo'
    //   },
    //   pricingContent: [
    //     '30 Days',
    //     '1 ~ 5 Workspace Admin',
    //     '1 ~ 30 Employees'
    //   ],
    //   button: 'Upgrade'
    // },
    // {
    //   title: 'Business',
    //   priceValues: {
    //     amount: formatCurrency(100),
    //     duration: '/mo'
    //   },
    //   pricingContent: [
    //     '30 Days',
    //     'No Workspace Admin',
    //     'No Limit Employees'
    //   ],
    //   button: 'Upgrade'
    // },
  ];

  const handleLogIn = () => {
    navigate('/login', { state: { type: 'payment' } });
  }

  return (
    <Fragment>
      <Row className='pricing-block justify-content-center' >
        {pricingData.map((item, index) => (
          <Col lg='3' md='6' key={index}> {/* Added key here for each Col */}
            <Card className='m-3 survey-button' style={{ marginRight: '10px' }}>
              <div className='pricingtable'>
                <div className='bg-black mt-0 mb-5 py-5' style={{ background: 'linear-gradient(to right, #4A1114, #91141A)' }}>
                  <div className='pricingtable-header'>
                    <img
                      src={`${process.env.PUBLIC_URL}/svg/logo.svg`}
                      alt="Logo"
                      width={120}
                    />
                  </div>

                  <div className={'font-outfit font50-bold mb-4'} style={{ color: COLORS.primary }}>
                    <span className='amount'>{item.priceValues.amount}</span>
                    <span className='duration font30'>{item.priceValues.duration}</span>
                  </div>
                  <div className='pricingtable-signup'>
                    <a href='https://buy.stripe.com/9AQ3gadf798C6Gs144'>
                      <Btn attrBtn={{ color: 'primary', size: 'lg' }}>SUBSCRIBE</Btn>
                    </a>
                  </div>
                </div>

                <UL attrUL={{ className: 'pricing-content flex-row' }}>
                  {item.pricingContent.map((text, liIndex) => (
                    <LI key={liIndex} attrLI={{ className: `border-0` }}>{text}</LI>  // Added key to LI elements
                  ))}
                </UL>

              </div>
            </Card>
          </Col>
        ))}
      </Row>


    </Fragment >
  );
};
export default StandardPricingcard;
