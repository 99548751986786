import React, { Fragment, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SvgIcon from "../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../_helper/Customizer";
import { MENUITEMS } from "./Menu";
import UserContext from "../../_helper/User";
import SettingContext from "../../_helper/Setting";
//const { t, i18n } = useTranslation();
const SidebarMenuItems = ({
  setMainMenu,
  sidebartoogle,
  setNavActive,
  activeClass,
}) => {
  const navigate = useNavigate();
  const { layout } = useContext(CustomizerContext);
  const { userInfo } = useContext(UserContext);
  const { settingsInfo } = useContext(SettingContext);

  const [menuItems, setMenuItems] = useState(MENUITEMS);

  useEffect(() => {
    setMenuItems((prevMenuItems) => {
      const updatedMenuItems = [...prevMenuItems];

      if (userInfo.role === "admin" || userInfo.role === "workspace-admin") {
        const settingIndex = updatedMenuItems[0].Items.findIndex(
          (item) => item.title === "Settings"
        );

        // Check if Settings submenu needs to be added
        if (settingIndex === -1) {
          const settingsSubMenu = {
            title: "Settings",
            icon: "social",
            type: "sub",
            active: false,
            children: [],
          };

          // Add General menu item
          settingsSubMenu.children.push(
            ...[
              {
                path: "/settings/account",
                title: "Account",
                type: "link",
                pseudoLink: "/settings/account",
              },
              {
                path: "/settings/general",
                title: "General",
                type: "link",
                pseudoLink: "/settings/general",
              },
              {
                path: "/settings/wiki",
                title: "Wiki",
                type: "link",
                pseudoLink: "/settings/wiki",
              },
            ]
          );

          // Add Import National Holidays menu item if activated in settingsInfo
          if (settingsInfo.importNationalHolidays) {
            settingsSubMenu.children.push({
              path: "/settings/import-national-holidays",
              title: "Import national holidays",
              type: "link",
              pseudoLink: "/settings/import-national-holidays",
            });
          }

          // Add Lexoffice menu item if activated in settingsInfo
          if (settingsInfo.activateLexoffice) {
            settingsSubMenu.children.push({
              path: "/settings/lexofficeinvoice",
              title: t("Lexoffice Invoice"),
              type: "link",
              pseudoLink: "/settings/lexofficeinvoice",
            });
            settingsSubMenu.children.push({
              path: "/settings/lexofficepayroll",
              title: t("Lexoffice Payroll"),
              type: "link",
              pseudoLink: "/settings/lexofficepayroll",
            });
          }

          if (settingsInfo.displaywiki) {
            settingsSubMenu.children.push({
              path: "/settings/wiki",
              title: "Wiki",
              type: "link",
              pseudoLink: "/settings/wiki",
            });
          }
          // Add Settings submenu to menu items
          updatedMenuItems[0].Items.push(settingsSubMenu);
        } else {
          // Settings submenu exists, update its children based on settingsInfo
          const settingsSubMenu = updatedMenuItems[0].Items[settingIndex];

          // Update or remove Import National Holidays menu item
          const importNationalHolidaysIndex =
            settingsSubMenu.children.findIndex(
              (item) => item.title === "Import national holidays"
            );

          if (settingsInfo.importNationalHolidays) {
            // Add or update Import National Holidays menu item
            if (importNationalHolidaysIndex === -1) {
              settingsSubMenu.children.push({
                path: "/settings/import-national-holidays",
                title: "Import national holidays",
                type: "link",
                pseudoLink: "/settings/import-national-holidays",
              });
            }
          } else {
            // Remove Import National Holidays menu item
            if (importNationalHolidaysIndex !== -1) {
              settingsSubMenu.children.splice(importNationalHolidaysIndex, 1);
            }
          }

          // Update or remove Lexoffice menu item
          // Update or remove Lexoffice menu item
          const lexofficeInvoiceIndex = settingsSubMenu.children.findIndex(
            (item) => item.title === t("Lexoffice Invoice")
          );

          if (settingsInfo.activateLexoffice) {
            // Add or update Lexoffice menu item
            if (lexofficeInvoiceIndex === -1) {
              settingsSubMenu.children.push({
                path: "/settings/lexofficeinvoice",
                title: t("Lexoffice Invoice"),
                type: "link",
                pseudoLink: "/settings/lexofficeinvoice",
              });
              settingsSubMenu.children.push({
                path: "/settings/lexofficepayroll",
                title: t("Lexoffice Payroll"),
                type: "link",
                pseudoLink: "/settings/lexofficepayroll",
              });
            }
          } else {
            // Remove Lexoffice menu item
            if (lexofficeInvoiceIndex !== -1) {
              settingsSubMenu.children.splice(lexofficeInvoiceIndex, 1);
            }
          }

          // Update or remove Lexoffice menu item
          const lexofficePayrollIndex = settingsSubMenu.children.findIndex(
            (item) => item.title === t("Lexoffice Payroll")
          );

          if (settingsInfo.activateLexoffice) {
            // Add or update Lexoffice menu item
            if (lexofficePayrollIndex === -1) {
              settingsSubMenu.children.push({
                path: "/settings/lexofficeinvoice",
                title: t("Lexoffice Invoice"),
                type: "link",
                pseudoLink: "/settings/lexofficeinvoice",
              });
              settingsSubMenu.children.push({
                path: "/settings/lexofficepayroll",
                title: t("Lexoffice Payroll"),
                type: "link",
                pseudoLink: "/settings/lexofficepayroll",
              });
            }
          } else {
            // Remove Lexoffice menu item
            if (lexofficePayrollIndex !== -1) {
              settingsSubMenu.children.splice(lexofficePayrollIndex, 1);
            }
          }
          const displaywikiIndex = settingsSubMenu.children.findIndex(
            (item) => item.title === "Wiki"
          );
          if (settingsInfo.displaywiki) {
            // Add or update Lexoffice menu item
            if (displaywikiIndex === -1) {
              settingsSubMenu.children.push({
                path: "/settings/wiki",
                title: "Wiki",
                type: "link",
                pseudoLink: "/settings/wiki",
              });
            }
          } else {
            // Remove Lexoffice menu item
            if (displaywikiIndex !== -1) {
              settingsSubMenu.children.splice(displaywikiIndex, 1);
            }
          }
        }

        // Check if Support menu item needs to be added
        const supportIndex = updatedMenuItems[0].Items.findIndex(
          (item) => item.title === "Support"
        );

        if (supportIndex === -1) {
          updatedMenuItems[0].Items.push({
            path: "/support",
            title: "Support",
            icon: "support-tickets",
            type: "link",
            pseudoLink: "/support",
          });
        }
        // Check if Support menu item needs to be added
        const notificationIndex = updatedMenuItems[0].Items.findIndex(
          (item) => item.title === "Notification"
        );
        if (notificationIndex === -1) {
          updatedMenuItems[0].Items.push({
            path: "/notifications",
            title: "Notification",
            icon: "knowledgebase",
            type: "link",
            pseudoLink: "/notifications",
          });
        }
      }

      return updatedMenuItems;
    });
  }, [userInfo, settingsInfo]);

  const layout1 = localStorage.getItem("sidebar_layout") || layout;

  const CurrentPath = window.location.pathname;

  const { t } = useTranslation();
  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      document
        .querySelector(".mega-menu-container")
        .classList.remove("d-block");
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper";
      }
    }
    if (!item.active) {
      menuItems.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: menuItems });
  };

  return (
    <>
      {menuItems.map((Item, i) => (
        <Fragment key={i}>
          <li className="sidebar-main-title">
            <div>
              <h6 className="lan-1">{t(Item.menutitle)}</h6>
            </div>
          </li>
          {Item.Items.map((menuItem, i) => (
            <li className="sidebar-list" key={i}>
              {menuItem.type === "sub" ? (
                <a
                  href="javascript"
                  style={{ padding: "15px 20px" }}
                  className={`sidebar-link sidebar-title ${
                    CurrentPath === menuItem.pseudoLink ? "active" : ""
                  } ${menuItem.active && "active"}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setNavActive(menuItem);
                    activeClass(menuItem.active);
                    navigate(menuItem?.children[0]?.path);
                  }}
                >
                  <SvgIcon
                    className="stroke-icon"
                    iconId={`stroke-${menuItem.icon}`}
                  />
                  <SvgIcon
                    className="fill-icon"
                    iconId={`fill-${menuItem.icon}`}
                  />
                  <span style={{ fontSize: 17 }}>{t(menuItem.title)}</span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>
                      {menuItem.badgetxt}
                    </label>
                  ) : (
                    ""
                  )}
                  <div className="according-menu">
                    {menuItem.active ? (
                      <i className="fa fa-angle-down"></i>
                    ) : (
                      <i className="fa fa-angle-right"></i>
                    )}
                  </div>
                </a>
              ) : (
                ""
              )}

              {menuItem.type === "link" ? (
                <Link
                  to={menuItem.path}
                  className={`sidebar-link sidebar-title link-nav  ${
                    CurrentPath === menuItem.pseudoLink ? "active" : ""
                  }`}
                  onClick={() => toggletNavActive(menuItem)}
                  style={{ padding: "15px 20px" }}
                >
                  <SvgIcon
                    className="stroke-icon w-[20px] h-[20px]"
                    iconId={`stroke-${menuItem.icon}`}
                  />
                  <SvgIcon
                    className="fill-icon"
                    iconId={`fill-${menuItem.icon}`}
                  />
                  <span style={{ fontSize: 17 }}>{t(menuItem.title)}</span>
                  {menuItem.badge ? (
                    <label className={menuItem.badge}>
                      {menuItem.badgetxt}
                    </label>
                  ) : (
                    ""
                  )}
                </Link>
              ) : (
                ""
              )}

              {menuItem.children ? (
                <ul
                  className="sidebar-submenu"
                  style={
                    layout1 !== "compact-sidebar compact-small"
                      ? menuItem?.active || CurrentPath === menuItem?.pseudoLink
                        ? sidebartoogle
                          ? { opacity: 1, transition: "opacity 500ms ease-in" }
                          : { display: "block" }
                        : { display: "none" }
                      : { display: "none" }
                  }
                >
                  {menuItem.children.map((childrenItem, index) => {
                    return (
                      <li key={index}>
                        {childrenItem.type === "sub" ? (
                          <a
                            href="javascript"
                            className={`${
                              CurrentPath === childrenItem?.pseudoLink
                                ? "active"
                                : ""
                            }`}
                            // className={`${childrenItem.active ? 'active' : ''}`}
                            onClick={(event) => {
                              event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}
                          >
                            {t(childrenItem.title)}
                            <span className="sub-arrow">
                              <i className="fa fa-chevron-right"></i>
                            </span>
                            <div className="according-menu">
                              {childrenItem.active ? (
                                <i className="fa fa-angle-down"></i>
                              ) : (
                                <i className="fa fa-angle-right"></i>
                              )}
                            </div>
                          </a>
                        ) : (
                          ""
                        )}

                        {childrenItem.type === "link" ? (
                          <Link
                            to={childrenItem.path}
                            className={`${
                              CurrentPath === childrenItem?.pseudoLink
                                ? "active"
                                : ""
                            }`}
                            // className={`${childrenItem.active ? 'active' : ''}`} bonusui
                            onClick={() => toggletNavActive(childrenItem)}
                          >
                            {t(childrenItem.title)}
                          </Link>
                        ) : (
                          ""
                        )}

                        {childrenItem.children ? (
                          <ul
                            className="nav-sub-childmenu submenu-content"
                            style={
                              CurrentPath === childrenItem?.pseudoLink ||
                              childrenItem.active
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            {childrenItem.children.map(
                              (childrenSubItem, key) => (
                                <li key={key}>
                                  {childrenSubItem.type === "link" ? (
                                    <Link
                                      to={childrenSubItem.path}
                                      className={`${
                                        CurrentPath ===
                                        childrenSubItem?.pseudoLink
                                          ? "active"
                                          : ""
                                      }`}
                                      // className={`${childrenSubItem.active ? 'active' : ''}`}
                                      onClick={() =>
                                        toggletNavActive(childrenSubItem)
                                      }
                                    >
                                      {t(childrenSubItem.title)}
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ""
              )}
            </li>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;
