import React, { Fragment, useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {

  return (
    <Fragment>
      <Container fluid={true}>
        <div className='p-4'>
          <Row>
            <Col xs='6'>
              <H3>{props.mainTitle}</H3>
            </Col>
            {props?.showRightbar && <Col xs='6'>
              <ol className='breadcrumb'>
                <li onClick={props?.onClickRightBar} className='breadcrumb-item active text-warning' style={{ cursor: 'pointer' }}>{props.title}</li>
              </ol>
            </Col>}
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;
