import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  Media,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  CardText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  getWorkingAreasListAPI,
  deleteWorkingAreaAPI,
  reactivateWorkingAreaAPI,
  getSettingDetailsAPI,
  getWorkinaAreaLexofficeAPI,
  updateWorkinaAreaLexofficeAPI,
} from "../../../Library/apis";
import useDebounce from "../../../Hooks/useDebounce";
import SettingContext from "../../../_helper/Setting";

const RenderItem = (
  item,
  handleDeactivate,
  handleReactivate,
  t,
  settingsInfo,
  handleLexofficeTemplate
) => {
  return {
    name: <span>{item.name}</span>,
    skills: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.skills.map((skill) => (
          <span key={skill._id}>{skill?.name}</span>
        ))}
      </Media>
    ),
    attributes: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.attributes.map((attribute) => (
          <span key={attribute._id}>{attribute?.name}</span>
        ))}
      </Media>
    ),
    address: (
      <span>
        {item.street},{item.zipCode} {item.city}
      </span>
    ),
    shifts: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.shifts.map((shift) => (
          <span key={shift._id}>{`${shift.days.join(", ")}: ${
            shift.startTime
          } - ${shift.endTime}/${shift.interval}`}</span>
        ))}
        {item.shifts.length === 0 && (
          <span className="text-danger">
            {t("Please enter at least 1 shift!")} <br />{" "}
            {t("Otherwise no roster can be created!")}
          </span>
        )}
      </Media>
    ),
    actions: (
      <Fragment>
        <Btn
          attrBtn={{
            className: "me-2 px-2 py-1",
            color: item.active ? "danger" : "info",
            size: "sm",
            onClick: () =>
              item.active
                ? handleDeactivate(item._id)
                : handleReactivate(item._id),
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": item.active
              ? t("Deactivate")
              : t("Reactivate"),
            "data-tooltip-place": "top",
          }}
        >
          {item.active ? (
            <i className="fa fa-trash"></i>
          ) : (
            <i className="fa fa-magic"></i>
          )}
        </Btn>
        {item.active && (
          <>
            <Link to={`/working-areas/${item._id}/attributes`}>
              <Btn
                attrBtn={{
                  className: "focuse-none me-2 px-2 py-1",
                  color: "primary",
                  size: "sm",
                  "data-tooltip-id": "my-tooltip",
                  "data-tooltip-content": t("Attributes"),
                  "data-tooltip-place": "top",
                }}
              >
                <i className="fa fa-tags"></i>
              </Btn>
            </Link>
            <Link to={`/working-areas/${item._id}/shifts`}>
              <Btn
                attrBtn={{
                  className: "me-2 px-2 py-1",
                  color: "success",
                  size: "sm",
                  "data-tooltip-id": "my-tooltip",
                  "data-tooltip-content": t("Shifts"),
                  "data-tooltip-place": "top",
                }}
              >
                <i className="fa fa-clock-o"></i>
              </Btn>
            </Link>
            <Link to={`/working-areas/${item._id}/edit`}>
              <Btn
                attrBtn={{
                  className: "me-2 px-2 py-1",
                  color: "info",
                  size: "sm",
                  "data-tooltip-id": "my-tooltip",
                  "data-tooltip-content": t("Edit"),
                  "data-tooltip-place": "top",
                }}
              >
                <i className="fa fa-pencil"></i>
              </Btn>
            </Link>
            {settingsInfo?.activateLexoffice && (
              <Btn
                attrBtn={{
                  className: "px-2 py-1",
                  color: "warning",
                  size: "sm",
                  "data-tooltip-id": "my-tooltip",
                  "data-tooltip-content": t("Lexoffice Template"),
                  "data-tooltip-place": "top",
                  onClick: () => handleLexofficeTemplate(item),
                }}
              >
                <i className="fa fa-building-o"></i>
              </Btn>
            )}
          </>
        )}
      </Fragment>
    ),
  };
};

const WorkingAreas = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [newService, setNewService] = useState({
    articleName: "",
    description: "",
    amount: "",
    price: "",
    isFavorited: false, // Include isFavorited in the new service state
  });
  const [services, setServices] = useState([]);
  const [lexofficeId, setLexofficeId] = useState("");
  const [tax, setTax] = useState("");
  const [type, setType] = useState("invoice");
  const debouncedSearchTerm = useDebounce(search, 500); // 500 ms delay
  const { settingsInfo, setSettingsInfo } = useContext(SettingContext);
  const [editServiceIndex, setEditServiceIndex] = useState(null);
  const [editServiceModalOpen, setEditServiceModalOpen] = useState(false);
  const location = useLocation();
  const fetchWorkingAreas = async () => {
    setLoading(true);

    try {
      const response = await getWorkingAreasListAPI({
        page: currentPage,
        pageSize: perPage,
        search: search,
        sortField: sortField,
        sortOrder: sortOrder,
        active: active,
      });

      setData(response.workingAreas);
      setTotalRows(response.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchWorkingAreaData = async (workingAreaName) => {
    try {
      const response = await getWorkinaAreaLexofficeAPI(workingAreaName);
      setSelectedItem(response);
      setLexofficeId(response.lexofficeID || "");
      setTax(response.tax || "");
      setType(response.paymenttype || "invoice");
      setServices(response.services || []);
      setModalOpen(true);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const workingAreaName = query.get("workingAreaName");
    setSearch(workingAreaName);
  }, [location.search]);
  useEffect(() => {
    fetchWorkingAreas();
    fetchSettings();
  }, [debouncedSearchTerm, currentPage, perPage, sortField, sortOrder, active]);

  const fetchSettings = async () => {
    try {
      const response = await getSettingDetailsAPI();
      setSettingsInfo(response);
    } catch (error) {
      console.error("Failed to fetch settings:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.pseudoField);
    setSortOrder(sortDirection);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleActiveChange = () => {
    setActive(!active);
  };

  const handleDeactivate = (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You can reactivate this, if you want!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
      cancelButtonText: t("Cancel"), // Translate the Cancel button text,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsDeleting(true);

        try {
          const res = await deleteWorkingAreaAPI(id);
          toast.success(res.message || "Successfully deactivated!", {
            autoClose: 2000,
          });
          fetchWorkingAreas();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  const handleEditServiceSave = () => {
    const updatedServices = [...services];
    updatedServices[editServiceIndex] = newService;
    setServices(updatedServices);
    handleEditServiceModalClose();
  };

  const handleReactivate = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will reactivate this!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reactivate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await reactivateWorkingAreaAPI(id);
          toast.success(res.message || "Successfully reactivated!", {
            autoClose: 2000,
          });
          setActive(true);
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleLexofficeTemplate = async (item) => {
    setSelectedItem(item);
    setModalOpen(true);
    try {
      const response = await getWorkinaAreaLexofficeAPI(item._id);
      setLexofficeId(response.lexofficeID || "");
      setTax(response.tax || "");
      setType(response.paymenttype || "invoice");
      setServices(response.services || []);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      // toast.error(message, { autoClose: 2000 });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setLexofficeId("");
    setTax("");
    setType("invoice");
    setServices([]);
    setSelectedItem(null);
  };

  const handleModalSave = async () => {
    setModalOpen(false);
    let name = selectedItem.name;
    try {
      setLexofficeId("");
      setTax("");
      setType("invoice");
      setServices([]);
      const res = await updateWorkinaAreaLexofficeAPI(selectedItem._id, {
        name,
        lexofficeId,
        tax,
        type,
        services: JSON.stringify(services), // Ensure services is an array of objects
      });

      toast.success(res.message || "Successfully updated!", {
        autoClose: 2000,
      });

      setActive(true);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      if (error.response && error.response.data && error.response.data.errors) {
        error.response.data.errors.forEach((err) => {
          toast.error(err.msg, { autoClose: 2000 });
        });
      } else {
        toast.error(message, { autoClose: 2000 });
      }
    }

    setSelectedItem(null);
  };

  const handleServiceModalOpen = () => {
    setServiceModalOpen(true);
  };

  const handleServiceModalClose = () => {
    setServiceModalOpen(false);
    setNewService({
      articleName: "",
      description: "",
      amount: "",
      price: "",
      isFavorited: false,
    });
  };

  const handleServiceSave = () => {
    setServices([...services, newService]);
    handleServiceModalClose();
  };

  const handleEditServiceModalOpen = (index) => {
    setEditServiceIndex(index);
    setNewService(services[index]);
    setEditServiceModalOpen(true);
  };

  const handleEditServiceModalClose = () => {
    setEditServiceModalOpen(false);
    setNewService({
      articleName: "",
      description: "",
      amount: "",
      price: "",
      isFavorited: false,
    });
    setEditServiceIndex(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewService((prevService) => ({
      ...prevService,
      [name]: value,
    }));
  };

  const handleLexofficeInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "lexofficeId") setLexofficeId(value);
    if (name === "tax") setTax(value);
    if (name === "type") setType(value);
  };

  const handleFavoriteClick = (index) => {
    const updatedServices = [...services];
    updatedServices[index].isFavorited = !updatedServices[index].isFavorited;
    setServices(updatedServices);
  };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row["name"],
        sortable: true,
        pseudoField: "name",
      },
      {
        name: "Skills",
        selector: (row) => row["skills"],
        sortable: true,
        pseudoField: "skills",
      },
      {
        name: "Attributes",
        selector: (row) => row["attributes"],
        sortable: true,
        pseudoField: "attributes",
      },
      {
        name: "Address",
        selector: (row) => row["address"],
        sortable: true,
        pseudoField: "address",
      },
      {
        name: "Shifts",
        selector: (row) => row["shifts"],
        sortable: true,
        pseudoField: "shifts",
      },
      {
        name: "Actions",
        selector: (row) => row["actions"],
      },
      // Add more columns as needed
    ],
    []
  );

  const handleDeleteService = (index) => {
    setServices(services.filter((_, i) => i !== index));
  };

  const { t } = useTranslation();
  const mainTitle = "Working Area";
  const maintTilteTran = t(mainTitle);

  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTilteTran} title="Working Areas" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <InputGroup style={{ width: 250 }}>
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder={t("Search by name...")}
                      name="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    {search && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearch("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/working-areas/create">
                    <Btn
                      attrBtn={{
                        className: "me-2 px-2 py-1",
                        color: "primary",
                      }}
                    >
                      <i className="fa fa-plus"></i> {t("New working area")}
                    </Btn>
                  </Link>

                  <Media className="me-3">
                    <Label className="col-form-label m-r-10">
                      {t("Show active working areas")}
                    </Label>
                    <Media body className="text-end switch-sm">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={active}
                          onChange={handleActiveChange}
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </Media>
                  </Media>

                  <Link to="/working-areas/skills">
                    <Btn
                      attrBtn={{
                        className: "me-2 px-2 py-1",
                        color: "primary",
                      }}
                    >
                      <i className="fa fa-book"></i> {t("Skills")}
                    </Btn>
                  </Link>
                </div>
              </div>
            </CardHeader>

            <div className={`custom-table${data.length ? "" : " no-data"}`}>
              <DataTable
                columns={columns.map((column) => ({
                  ...column,
                  name: t(column.name), // Translate the column name
                }))}
                data={data?.map((item) =>
                  RenderItem(
                    item,
                    handleDeactivate,
                    handleReactivate,
                    t,
                    settingsInfo,
                    handleLexofficeTemplate
                  )
                )}
                striped={true}
                center={true}
                // progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                paginationDefaultPage={currentPage}
                paginationComponentOptions={{
                  rowsPerPageText: "",
                  rangeSeparatorText: t("of"),
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: t("All"),
                }}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </div>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
      <Modal
        isOpen={modalOpen}
        toggle={handleModalClose}
        style={{ maxWidth: "800px", height: "1000px", borderRadius: "0.5rem" }}
        className="d-flex flex-column"
      >
        <ModalHeader
          toggle={handleModalClose}
          className="justify-content-center"
          style={{ borderBottom: "none" }}
        >
          <i
            className="fa fa-arrow-left cursor-pointer me-auto"
            onClick={handleModalClose}
          ></i>
          <span>{t("Welcome to lexoffice API Template")}</span>
        </ModalHeader>
        <ModalBody className="flex-grow-1 d-flex flex-column">
          <FormGroup>
            <div className="d-flex">
              <h6 style={{ width: "150px" }}>{t("Working Area")}:</h6>
              <h6>{selectedItem?.name}</h6>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <h6 style={{ width: "100px" }}>{t("Lexoffice ID")}:</h6>
              <Input
                type="text"
                style={{ flex: "1" }}
                name="lexofficeId"
                value={lexofficeId}
                onChange={handleLexofficeInputChange}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <h6 style={{ width: "100px" }}>{t("Tax")}:</h6>
              <Input
                type="text"
                placeholder=""
                style={{ flex: "1" }}
                name="tax"
                value={tax}
                onChange={handleLexofficeInputChange}
              />
              <Btn
                attrBtn={{
                  className: "px-2 py-1",
                  color: "infor",
                  size: "lg",
                  "data-tooltip-id": "tooltip-tax",
                  "data-tooltip-content": t(
                    "Please insert your tax for the tax item. Example: 19 percent tax--> insert 19."
                  ),
                  "data-tooltip-place": "top",
                }}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Btn>
              <Tooltip
                id="tooltip-tax"
                place="top"
                type="dark"
                effect="solid"
                style={{ whiteSpace: "pre-wrap", zIndex: 1050 }}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <div className="d-flex justify-content-between">
              <h6 style={{ width: "100px" }}>{t("Type")}:</h6>
              <Input
                type="select"
                style={{ flex: "1" }}
                name="type"
                value={type}
                onChange={handleLexofficeInputChange}
              >
                <option value="invoice">{t("invoice")}</option>
                <option value="direct-debit">{t("direct debit")}</option>
              </Input>
            </div>
          </FormGroup>
          <Button
            color="primary"
            className="me-2"
            onClick={handleServiceModalOpen}
            style={{ marginBottom: "20px" }}
          >
            {t("New Service")}
          </Button>
          <div className="d-flex gap-1" style={{ overflowX: "auto" }}>
            {services.map((service, index) => (
              <div key={index}>
                <div className="mb-2">
                  <Btn
                    attrBtn={{
                      style: { marginLeft: "200px" },
                      className: "me-2 px-2 py-1",
                      color: "info",
                      size: "sm",
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content": "Edit",
                      "data-tooltip-place": "top",
                      onClick: () => handleEditServiceModalOpen(index),
                    }}
                  >
                    <i className="fa fa-pencil"></i>
                  </Btn>
                  <Btn
                    attrBtn={{
                      className: "me-2 px-2 py-1",
                      color: "danger",
                      size: "sm",
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content": "Delete",
                      "data-tooltip-place": "top",
                      onClick: () => handleDeleteService(index),
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </Btn>
                  <Btn
                    attrBtn={{
                      className: "me-2 px-2 py-1",
                      color: service.isFavorited ? "success" : "secondary",
                      size: "sm",
                      "data-tooltip-id": "my-tooltip",
                      "data-tooltip-content": "Favorite",
                      "data-tooltip-place": "top",
                      onClick: () => handleFavoriteClick(index),
                    }}
                  >
                    <i className="fa fa-star"></i>
                  </Btn>
                </div>

                <Card
                  className="d-flex mb-2 me-2 mx-3 position-relative"
                  style={{ minWidth: "300px", border: "1px solid #ccc" }}
                >
                  <CardBody>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px",
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <CardText className="font-weight-bold">
                        <Label> {t("Article Name")}:</Label>
                        {service.articleName}
                      </CardText>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px",
                      }}
                    >
                      <CardText>
                        <Label>{t("Description")}:</Label>
                        {service.description}
                      </CardText>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px",
                      }}
                    >
                      <CardText>
                        <Label>{t("Amount")}:</Label>
                        {service.amount}
                      </CardText>
                    </div>
                    <div style={{ padding: "10px" }}>
                      <CardText>
                        <Label>{t("Price")}:</Label>
                        {service.price}
                      </CardText>
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }}>
          <Button color="primary" onClick={handleModalSave}>
            {t("Save")}
          </Button>
          <Button color="secondary" onClick={handleModalClose}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={serviceModalOpen} toggle={handleServiceModalClose}>
        <ModalHeader toggle={handleServiceModalClose}>New Service</ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <Label for="articleName"> {t("Article Name")}</Label>
              <Input
                type="text"
                name="articleName"
                id="articleName"
                value={newService.articleName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("Description")}</Label>
              <Input
                type="text"
                name="description"
                id="description"
                value={newService.description}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">{t("Amount")}</Label>
              <Input
                type="number"
                name="amount"
                id="amount"
                value={newService.amount}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">{t("Price")}</Label>
              <Input
                type="number"
                name="price"
                id="price"
                value={newService.price}
                onChange={handleInputChange}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleServiceSave}>
            {t("Save")}
          </Button>
          <Button color="secondary" onClick={handleServiceModalClose}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editServiceModalOpen} toggle={handleEditServiceModalClose}>
        <ModalHeader toggle={handleEditServiceModalClose}>
          {t("Edit Service")}
        </ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <Label for="articleName">{t("Article Name")}</Label>
              <Input
                type="text"
                name="articleName"
                id="articleName"
                value={newService.articleName}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t("Description")}</Label>
              <Input
                type="text"
                name="description"
                id="description"
                value={newService.description}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">{t("Amount")}</Label>
              <Input
                type="number"
                name="amount"
                id="amount"
                value={newService.amount}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="price">{t("Price")}</Label>
              <Input
                type="number"
                name="price"
                id="price"
                value={newService.price}
                onChange={handleInputChange}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleEditServiceSave}>
            {t("Save")}
          </Button>
          <Button color="secondary" onClick={handleEditServiceModalClose}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default WorkingAreas;
