import React, { Fragment, useContext, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, H5, Btn } from "../../../../AbstractElements";
import { AddNewTask, AddTask, Close, ToDo } from "../../../../Constant";
import TodoContext from "../../../../_helper/Todo";
import TodoCheckbox from "./TodoCheckbox";
import TodoList from "./TodoList";
import RecentOrders from "../RecentOrders";
import { useTranslation } from "react-i18next";

const TodoContain = () => {
  const { addNewTodo, fetchTodo } = useContext(TodoContext);
  const { t } = useTranslation();
  const [task, setTask] = useState("");
  const [isAddTaskVisible, setIsAddTaskVisible] = useState(false);
  const [borderDanger, setBorderDanger] = useState("");

  const toggleTaskWrapperVisibility = () => {
    setIsAddTaskVisible(!isAddTaskVisible);
  };

  const onTaskChanged = (e) => {
    setTask(e.target.value);
    // Remove border-danger class if user starts typing again
    if (borderDanger && e.target.value.trim() !== "") {
      setBorderDanger("");
    }
  };

  const addNewTask = () => {
    if (task.trim() === "") {
      setBorderDanger("border-danger");
    } else {
      addNewTodo({
        task: task.trim(),
      });
      setIsAddTaskVisible(false); // Hide add task wrapper
      setTask(""); // Clear the task input
      setBorderDanger(""); // Remove any border danger class
    }
  };
  useEffect(() => {
    fetchTodo();
  }, []);
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Todo" parent="Apps" title="Todo" />
      <Container fluid={true} className="email-wrap bookmark-wrap todo-wrap">
        <Row>
          <Col xl="8" className="xl-70 box-col-7">
            <Card>
              <CardHeader className="d-flex align-items-center justify-content-between">
                <H5>{ToDo}</H5>
                <div className="d-flex align-items-center gap-3">
                  <div className="todo">
                    <div className="todo-list-wrapper">
                      <TodoCheckbox />
                    </div>
                  </div>
                  <Btn
                    attrBtn={{
                      color: "primary d-flex align-items-center",
                      onClick: toggleTaskWrapperVisibility,
                    }}
                  >
                    <Plus
                      style={{ width: "18px", height: "18px" }}
                      className="me-2"
                    />{" "}
                    {isAddTaskVisible ? Close : AddNewTask}
                  </Btn>
                </div>
              </CardHeader>
              <CardBody>
                <div className="todo">
                  <div className="todo-list-wrapper">
                    <div className="todo-list-container todo-list-footer">
                      {isAddTaskVisible && (
                        <div
                          className={`new-task-wrapper mb-4 visible ${borderDanger}`}
                        >
                          <textarea
                            className="ng-untouched ng-pristine"
                            id="newtask"
                            placeholder="Enter new task here. . ."
                            value={task}
                            onChange={onTaskChanged}
                          ></textarea>
                          <Btn
                            attrBtn={{
                              color: "danger",
                              className: "cancel-btn",
                              onClick: toggleTaskWrapperVisibility,
                            }}
                          >
                            {Close}
                          </Btn>
                          <Btn
                            attrBtn={{
                              color: "success",
                              className: "ms-3 add-new-task-btn",
                              onClick: addNewTask,
                            }}
                          >
                            {AddTask}
                          </Btn>
                        </div>
                      )}
                      <TodoList />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <RecentOrders />
        </Row>
      </Container>
    </Fragment>
  );
};

export default TodoContain;
