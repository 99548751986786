import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col } from "reactstrap";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn, UL, LI, H6, H5, H4, H3 } from "../../../AbstractElements";
import {
  getShiftPlanDetailsAPI,
  getAssignableShiftPlanEmployeesAPI,
  assignEmployeesToShiftPlanAPI,
} from "../../../Library/apis";

const AssignEmployeesModal = ({
  isOpen,
  toggler,
  shiftPlan,
  fetchShiftPlans,
}) => {
  const [shiftPlanDetails, setShiftPlanDetails] = useState({});
  const [assignableEmployees, setAssignableEmployees] = useState([]);

  const fetchShiftPlanDetails = async () => {
    try {
      const response = await getShiftPlanDetailsAPI(shiftPlan.id);
      setShiftPlanDetails(response);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };
  const { t } = useTranslation();
  const fetchAssignableEmployees = async () => {
    try {
      const response = await getAssignableShiftPlanEmployeesAPI(shiftPlan?.id);
      setAssignableEmployees(response);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const handleInsert = (employee) => {
    if (!employee?.match) {
      Swal.fire({
        title: t("Are you sure to add?"),
        text: t(
          "Attention: Skills and Attributes does not match with the requirments of the working area."
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("Ok"),
        cancelButtonText: t("Cancel"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          setShiftPlanDetails((prevDetails) => ({
            ...prevDetails,
            assignedEmployees: [...prevDetails.assignedEmployees, employee],
          }));
        }
      });
    } else {
      setShiftPlanDetails((prevDetails) => ({
        ...prevDetails,
        assignedEmployees: [...prevDetails.assignedEmployees, employee],
      }));
    }
  };

  const handleRevert = (employeeId) => {
    setShiftPlanDetails((prevDetails) => ({
      ...prevDetails,
      assignedEmployees: prevDetails.assignedEmployees.filter(
        (item) => item._id !== employeeId
      ),
    }));
  };

  const handleSave = async () => {
    Swal.fire({
      title: "Edit recurring event",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
      input: "radio",
      inputOptions: {
        one: "This event",
        following: "This and following events",
      },
      inputValue: "one", // Set the default value to 'one'
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account

        try {
          await assignEmployeesToShiftPlanAPI(shiftPlan?.id, {
            ...shiftPlanDetails,
            type: result.value,
          });

          toast.success(`Successfully saved!`, {
            autoClose: 2000,
          });
          fetchShiftPlans();
          toggler();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  useEffect(() => {
    if (shiftPlan?.id && isOpen) {
      const assignedEmployees =
        shiftPlan?.extendedProps?.assignedEmployees || [];

      setShiftPlanDetails({ ...shiftPlan?.extendedProps, assignedEmployees });

      fetchAssignableEmployees();
    }
  }, [shiftPlan, isOpen]);

  const filteredAssignableEmployees = assignableEmployees.filter(
    (employee) =>
      !shiftPlanDetails?.assignedEmployees?.some(
        (item) => item._id === employee._id
      )
  );

  const hasNoMatchingEmployee = assignableEmployees.some((employee) =>
    shiftPlanDetails?.assignedEmployees?.some(
      (item) => item._id === employee._id && !employee.match
    )
  );

  return (
    <Modal isOpen={isOpen} toggle={toggler} size="lg" centered>
      <ModalHeader toggle={toggler}>
        {t("Assign Employees to working area")}:{" "}
        {shiftPlanDetails?.workingArea?.name}
        <H6>
          {hasNoMatchingEmployee &&
            t(
              "Attention: Skills and Attributes does not match with the requirments of the working area."
            )}
        </H6>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="6">
            <H6 attrH6={{ className: "mb-2 text-center" }}>
              {t("Assigned employees")}
            </H6>

            <UL
              attrUL={{
                className: "simple-list border-0 text-center",
                style: { maxHeight: "200px", overflow: "auto" },
              }}
            >
              {shiftPlanDetails?.assignedEmployees?.map((item) => (
                <div style={{ display: item.hasOffTime ? "none" : "unset" }}>
                  <LI
                    key={item._id}
                    attrLI={{
                      className: "border-0 mb-2 list-group-item-action",
                    }}
                  >
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "px-3 py-1",
                        onClick: () => handleRevert(item._id),
                      }}
                    >
                      {`${item.firstName} ${item.lastName}`}
                      <i className="fa fa-arrow-right ms-2 cursor-pointer" />
                    </Btn>
                  </LI>
                </div>
              ))}
            </UL>
          </Col>
          <Col sm="6">
            <H6 attrH6={{ className: "mb-2 text-center" }}>
              {t("Employees which are free")}
            </H6>

            <UL
              attrUL={{
                className: "simple-list border-0 text-center",
                style: { maxHeight: "200px", overflow: "auto" },
              }}
            >
              {filteredAssignableEmployees?.map((item) => (
                <LI
                  key={item._id}
                  attrLI={{
                    className: "border-0 mb-2 list-group-item-action",
                  }}
                >
                  <Btn
                    attrBtn={{
                      color: "primary mr-1",
                      className: "px-3 py-1 me-2",
                      onClick: () => handleInsert(item),
                    }}
                  >
                    <i className="fa fa-arrow-left me-2 cursor-pointer" />
                    {`${item.firstName} ${item.lastName}`}
                  </Btn>
                </LI>
              ))}
            </UL>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <H6>
            {filteredAssignableEmployees.length
              ? ""
              : t(
                  "Sorry, no employee is free in this time range and on this day"
                )}
          </H6>
        </div>
        <div className="d-flex gap-1">
          <Btn attrBtn={{ color: "secondary", onClick: toggler }}>
            {t("Cancel")}
          </Btn>
          <Btn attrBtn={{ color: "success", onClick: handleSave }}>
            {t("Save")}
          </Btn>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AssignEmployeesModal;
