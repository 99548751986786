import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// import cubaimg from "../../assets/images/logo/logo-icon.png"
// import CustomizerContext from '../../_helper/Customizer';

const SidebarIcon = () => {
  
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
        {/* <img
          className="img-fluid"
          src={cubaimg}
          alt=""
        /> */}
        <img
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}/svg/mini-logo.svg`}
          alt=""
          width={35}
        />
      </Link>
    </div>
  );
};

export default SidebarIcon;