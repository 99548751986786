import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Col,
  Card,
  CardHeader,
  Media,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Avatar from "react-avatar";
import { Tooltip } from "react-tooltip";

import { Breadcrumbs, Btn } from "../../../AbstractElements";
import { getEmployeesListAPI } from "../../../Library/apis";

import { sendEmailEmployeeAPI } from "../../../Library/apis";

import { JobTypes, Roles } from "../../../Constant";
import useDebounce from "../../../Hooks/useDebounce";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RenderItem = (item, t, handleGenerateQR) => {
  const handleQRClick = () => {
    handleGenerateQR(item._id);
  };

  return {
    fullName: (
      <Media className="d-flex">
        <Avatar
          name={item.fullName}
          size="35"
          textMarginRatio={0.3}
          round
          src={item.profileImageUrl}
          color="#7366FF"
        />
        <Media body className="align-self-center ms-3">
          <div>{item.fullName}</div>
        </Media>
      </Media>
    ),
    jobTypes: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.jobTypes.map((jobType) => (
          <span key={jobType}>{JobTypes[jobType]}</span>
        ))}
      </Media>
    ),
    skills: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.skills.map((skill) => (
          <span key={skill._id}>{skill.name}</span>
        ))}
      </Media>
    ),
    attributes: (
      <Media
        body
        className="d-flex ms-2"
        style={{ flexDirection: "column", gap: 3 }}
      >
        {item.attributes.map((attribute) => (
          <span key={attribute._id}>{attribute.name}</span>
        ))}
      </Media>
    ),
    role: <span>{Roles[item.role]}</span>,
    contractHoursWeek: <span>{item.contractHoursWeek}</span>,
    contractHoliday: <span>{item.contractHoliday}</span>,
    actions: (
      <Fragment>
        <Link to={`/employees/${item._id}/attributes`}>
          <Btn
            attrBtn={{
              className: "focuse-none me-2 px-2 py-1",
              color: "primary",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Attributes"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-tags"></i>
          </Btn>
        </Link>
        <Link to={`/employees/${item._id}/shifts`}>
          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "success",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Shifts"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-clock-o"></i>
          </Btn>
        </Link>
        <Link to={`/employees/${item._id}/working-areas`}>
          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "warning",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Working Areas"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-building-o"></i>
          </Btn>
        </Link>
        <Link to={`/employees/${item._id}/edit`}>
          <Btn
            attrBtn={{
              className: "px-2 py-1",
              color: "info",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Edit"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-pencil"></i>
          </Btn>
        </Link>
        <span style={{ marginRight: "8px" }}></span>
        <button
          style={{
            padding: "0.25rem 0.5rem",
            backgroundColor: "#6c757d",
            color: "#fff",
            border: "none",
            borderRadius: "0.25rem",
            fontSize: "1rem",
            cursor: "pointer",
          }}
          onClick={handleQRClick}
          data-tooltip-id="my-tooltip"
          data-tooltip-content={t("QR Code")}
          data-tooltip-place="top"
        >
          <i className="fa fa-qrcode"></i>
        </button>
      </Fragment>
    ),
  };
};

const Employees = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(true);
  const debouncedSearchTerm = useDebounce(search, 500); // 500 ms delay
  const { t, i18n } = useTranslation();

  const handleGenerateQR = async (employeeId, t) => {
    try {
      const response = await sendEmailEmployeeAPI({
        empId: employeeId,
        language: i18n.language,
      });

      toast.success(t("QR Code generated and email sent successfully!"));
    } catch (error) {
      console.error("Error Sending Email:", error);

      toast.error(t("Error sending email. Please try again later."));
    }
  };

  const fetchEmployees = async () => {
    setLoading(true);

    try {
      const response = await getEmployeesListAPI({
        page: currentPage,
        pageSize: perPage,
        search: search,
        sortField: sortField,
        sortOrder: sortOrder,
        active: active,
      });
      setData(response.employees);
      setTotalRows(response.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [debouncedSearchTerm, currentPage, perPage, sortField, sortOrder, active]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.pseudoField);
    setSortOrder(sortDirection);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleActiveChange = () => {
    setActive(!active);
  };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row["fullName"],
        sortable: true,
        pseudoField: "fullName",
      },
      {
        name: "Job Types",
        selector: (row) => row["jobTypes"],
        sortable: true,
        pseudoField: "jobTypes",
      },
      {
        name: "Skills",
        selector: (row) => row["skills"],
        sortable: true,
        pseudoField: "skills",
      },
      {
        name: "Attributes",
        selector: (row) => row["attributes"],
        sortable: true,
        pseudoField: "attributes",
      },
      {
        name: "Role",
        selector: (row) => row["role"],
        sortable: true,
        pseudoField: "role",
      },
      {
        name: "Contract Hours Week",
        selector: (row) => row["contractHoursWeek"],
        sortable: true,
        pseudoField: "contractHoursWeek",
      },
      {
        name: "Contract Holiday",
        selector: (row) => row["contractHoliday"],
        sortable: true,
        pseudoField: "contractHoliday",
      },
      {
        name: "Actions",
        selector: (row) => row["actions"],
      },
      // Add more columns as needed
    ],
    []
  );

  return (
    <Fragment>
      <Breadcrumbs mainTitle={t("Employees")} title="Employees" />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <InputGroup style={{ width: 250 }}>
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      id="employees-search-input"
                      placeholder={t("Search by name...")}
                      name="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    {search && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearch("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                </div>
                <div className="d-flex align-items-center">
                  <Link to="/employees/create">
                    <Btn
                      attrBtn={{
                        className: "me-2 px-2 py-1",
                        color: "primary",
                      }}
                    >
                      <i className="fa fa-plus"></i> {t("New employee")}
                    </Btn>
                  </Link>

                  <Media className="me-3">
                    <Label className="col-form-label m-r-10">
                      {t("Show active user")}
                    </Label>
                    <Media body className="text-end switch-sm">
                      <Label className="switch">
                        <Input
                          type="checkbox"
                          checked={active}
                          onChange={handleActiveChange}
                        />
                        <span className="switch-state"></span>
                      </Label>
                    </Media>
                  </Media>

                  <Link to="/employees/skills">
                    <Btn
                      attrBtn={{
                        className: "me-2 px-2 py-1",
                        color: "primary",
                      }}
                    >
                      <i className="fa fa-book"></i> {t("Skills")}
                    </Btn>
                  </Link>
                </div>
              </div>
            </CardHeader>

            <div className={`custom-table${data.length ? "" : " no-data"}`}>
              <DataTable
                columns={columns.map((column) => ({
                  ...column,
                  name: t(column.name), // Translate the column name
                }))}
                // data={data.map((item) => RenderItem(item, t))}
                data={data.map((item) =>
                  RenderItem(item, t, (employeeId) =>
                    handleGenerateQR(employeeId, t)
                  )
                )}
                striped={true}
                center={true}
                // progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                paginationDefaultPage={currentPage}
                paginationComponentOptions={{
                  rowsPerPageText: "",
                  rangeSeparatorText: "of",
                  noRowsPerPage: true,
                  selectAllRowsItem: false,
                  selectAllRowsItemText: "All",
                }}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                sortServer
              />
            </div>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
    </Fragment>
  );
};

export default Employees;
