import Dashboard from "../Components/Mothrift/Dashboard";
import Employees from "../Components/Mothrift/Employees";
import EmployeeForm from "../Components/Mothrift/Employees/EmployeeForm";
import EmployeeSkills from "../Components/Mothrift/Employees/Skills";

import EditProfile from "../Components/Mothrift/AccountSettings/EditProfile";
import Attributes from "../Components/Mothrift/Attributes";
import EmployeeWorkingAreas from "../Components/Mothrift/Employees/WorkingAreas";
import EmployeeShifts from "../Components/Mothrift/Employees/Shifts";
import EmployeeShiftForm from "../Components/Mothrift/Employees/Shifts/Form";

import WorkingAreas from "../Components/Mothrift/WorkingAreas";
import WorkingAreaForm from "../Components/Mothrift/WorkingAreas/WorkingAreaForm";
import WorkingAreaSkills from "../Components/Mothrift/WorkingAreas/Skills";
import WorkingAreaShifts from "../Components/Mothrift/WorkingAreas/Shifts";
import WorkingAreaShiftForm from "../Components/Mothrift/WorkingAreas/Shifts/Form";

import TimeTrackings from "../Components/Mothrift/TimeTrackings";
import TimeTrackingForm from "../Components/Mothrift/TimeTrackings/TimeTrackingForm";

import HolidayIllnesses from "../Components/Mothrift/HolidayIllnesses";
import HolidayIllnessForm from "../Components/Mothrift/HolidayIllnesses/HolidayIllnessForm";

import Settings from "../Components/Mothrift/Settings";
import ImportNationalHolidays from "../Components/Mothrift/Settings/ImportNationalHolidays";
import LexOffice from "../Components/Mothrift/Settings/Lexoffice";
import LexOfficePayroll from "../Components/Mothrift/Settings/LexofficePayroll";
import LexOfficeInvoice from "../Components/Mothrift/Settings/LexofficeInvoice";

import Support from "../Components/Mothrift/Support";
import Notifications from "../Components/Mothrift/Notifications";
import ShiftPlan from "../Components/Mothrift/ShiftPlan";
import Wiki from "../Components/Mothrift/Wiki/Wiki";

const MothriftRoutes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
  // { path: `${process.env.PUBLIC_URL}/notifications`, Component: <Notifications /> },

  {
    path: `${process.env.PUBLIC_URL}/employees/list`,
    Component: <Employees />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employees/create`,
    Component: <EmployeeForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/edit`,
    Component: <EmployeeForm />,
  },

  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/attributes`,
    Component: <Attributes />,
  },

  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/working-areas`,
    Component: <EmployeeWorkingAreas />,
  },

  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/shifts`,
    Component: <EmployeeShifts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/shifts/create`,
    Component: <EmployeeShiftForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/employees/:employeeId/shifts/:shiftId/edit`,
    Component: <EmployeeShiftForm />,
  },

  {
    path: `${process.env.PUBLIC_URL}/employees/skills`,
    Component: <EmployeeSkills />,
  },

  {
    path: `${process.env.PUBLIC_URL}/working-areas/list`,
    Component: <WorkingAreas />,
  },
  {
    path: `${process.env.PUBLIC_URL}/working-areas/create`,
    Component: <WorkingAreaForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/working-areas/:workingAreaId/edit`,
    Component: <WorkingAreaForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/working-areas/:workingAreaId/attributes`,
    Component: <Attributes />,
  },

  {
    path: `${process.env.PUBLIC_URL}/working-areas/skills`,
    Component: <WorkingAreaSkills />,
  },

  {
    path: `${process.env.PUBLIC_URL}/working-areas/:workingAreaId/shifts`,
    Component: <WorkingAreaShifts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/working-areas/:workingAreaId/shifts/create`,
    Component: <WorkingAreaShiftForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/working-areas/:workingAreaId/shifts/:shiftId/edit`,
    Component: <WorkingAreaShiftForm />,
  },

  {
    path: `${process.env.PUBLIC_URL}/time-trackings/list`,
    Component: <TimeTrackings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/time-trackings/create`,
    Component: <TimeTrackingForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/time-trackings/:timeTrackingId/edit`,
    Component: <TimeTrackingForm />,
  },

  {
    path: `${process.env.PUBLIC_URL}/holiday-illnesses/list`,
    Component: <HolidayIllnesses />,
  },
  {
    path: `${process.env.PUBLIC_URL}/holiday-illnesses/create`,
    Component: <HolidayIllnessForm />,
  },
  {
    path: `${process.env.PUBLIC_URL}/holiday-illnesses/:holidayIllnessId/edit`,
    Component: <HolidayIllnessForm />,
  },

  {
    path: `${process.env.PUBLIC_URL}/settings/account`,
    Component: <EditProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/wiki`,
    Component: <Wiki />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/general`,
    Component: <Settings />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/import-national-holidays`,
    Component: <ImportNationalHolidays />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/lexofficeinvoice`,
    Component: <LexOfficeInvoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/settings/lexofficepayroll`,
    Component: <LexOfficePayroll />,
  },
  {
    path: `${process.env.PUBLIC_URL}/support`,
    Component: <Support />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notifications`,
    Component: <Notifications />,
  },
  {
    path: `${process.env.PUBLIC_URL}/shift-plan`,
    Component: <ShiftPlan />,
  },
];

// const isLocalhost = window.location.hostname == "localhost";
// export const routes = isLocalhost ? AllRoutes : MothriftRoutes;
export const routes = MothriftRoutes;
