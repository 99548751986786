import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Avatar from "react-avatar";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { Btn, H4 } from "../../../../AbstractElements";
import { EditProfile } from "../../../../Constant";
import UserContext from "../../../../_helper/User";
import { changePasswordAPI, updateProfileAPI } from "../../../../Library/apis";
import { useNavigate } from "react-router";
import axios from "axios";
import { PROFILE_IMG_BASE_URL } from "../../../../Library/constant";
import { useTranslation } from 'react-i18next'
const EditMyProfile = ({ handleBack }) => {
  const { userInfo, updateUserInfo } = useContext(UserContext);
  const { handleSubmit } = useForm();
  const navigate = useNavigate();
  const [isEditPassWord, setIsEditPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const fileInputRef = useRef();
  const { t } = useTranslation();

  const [passwordValues, setPasswordValues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileImageUrl: "",
  });

  const onEditSubmit = () => {
    // setIsUpdating(true);
    updateProfileAPI(formValues)
      .then((res) => {
        toast.success(res.message, { autoClose: 2000 });
        updateUserInfo();
        // setIsUpdating(false);
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || "Update Profile Error";
        toast.error(message, { autoClose: 2000 });
        // setIsUpdating(false);
      });
  };

  // const handleDeleteAccount = () => {
  //     Swal.fire({
  //         title: "Are you sure to delete?",
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonColor: '#3085d6',
  //         cancelButtonColor: '#d33',
  //         confirmButtonText: 'Yes, delete it!'
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             // If user confirmed, continue deleting the account
  //             setIsDeleting(true);
  //             deleteAccountAPI(userInfo._id).then((res) => {
  //                 toast.success(res.message, { autoClose: 2000 })
  //                 logOut();
  //                 setIsDeleting(false);
  //                 navigate('/login');
  //             }).catch((error) => {
  //                 const message = error?.response?.data?.message || 'Delete Account Error'
  //                 toast.error(message, { autoClose: 2000 });
  //                 setIsDeleting(false);
  //             });
  //         }
  //     })
  // }

  const handleEditPassword = () => {
    if (isEditPassWord) {
      if (!passwordValues.oldPassword)
        toast.warning("Old Password is empty.", { autoClose: 2000 });
      else if (!passwordValues.newPassword)
        toast.warning("New Password is empty.", { autoClose: 2000 });
      else if (!passwordValues.confirmPassword)
        toast.warning("Confirm Password is empty.", { autoClose: 2000 });
      else if (passwordValues.newPassword !== passwordValues.confirmPassword)
        toast.warning("Password is not match", { autoClose: 2000 });
      else {
        Swal.fire({
          title: "Are you sure to change?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Change it!",
        }).then((result) => {
          if (result.isConfirmed) {
            setIsloading(true);
            changePasswordAPI({
              oldPassword: passwordValues.oldPassword,
              newPassword: passwordValues.newPassword,
            })
              .then((res) => {
                toast.success(res.message, { autoClose: 2000 });
                setIsloading(false);
              })
              .catch((e) => {
                if (e?.response?.data?.message === "Wrong password!") {
                  toast.warn("Wrong old password!", { autoClose: 2000 });
                } else {
                  toast.error(
                    e?.response?.data?.message || "Password Change Error!",
                    { autoClose: 2000 }
                  );
                }
                setIsloading(false);
              });
          }
        });
      }
    } else {
      setIsEditPassword(true);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPasswordValues({ ...passwordValues, [name]: value });
  };

  const [profileImageUrl, setProfileImageUrl] = useState(
    userInfo.profileImageUrl
  );

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = async (_event) => {
      setProfileImageUrl(reader.result);

      const formData = new FormData();
      formData.append("image", event.target.files[0], "1.png");
      try {
        let token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}` || "",
          "Content-Type": "multipart/form-data",
        };
        const response = await axios.post(
          `${PROFILE_IMG_BASE_URL}/upload`,
          formData,
          { headers }
        );
        setFormValues({
          ...formValues,
          profileImageUrl: `${PROFILE_IMG_BASE_URL}\\api\\${response.data.filePath}`,
        });
        toast.success(response.data.filePath);
      } catch (error) {
        toast.error("upload error");
      }
    };
  };

  useEffect(() => {
    if (userInfo) {
      setFormValues({
        firstName: userInfo.firstName || "",
        middleName: userInfo.middleName || "",
        lastName: userInfo.lastName || "",
        phoneNumber: userInfo.phoneNumber || "",
        profileImageUrl: userInfo.profileImageUrl || "",
        email: userInfo.email || "",
        bankVerificationNumber: userInfo.bankVerificationNumber || "",
      });
    }
  }, [userInfo]);

  return (
    <Fragment>
      <Form className="card" onSubmit={handleSubmit(onEditSubmit)}>
        <CardHeader>
          <div className="d-flex">
            <Btn
              attrBtn={{
                className: "p-0 m-0",
                color: "transparent",
                style: { borderWidth: 0 },
                onClick: handleBack,
              }}
            >
              <i
                className="fa fa-angle-left"
                style={{ color: "black", fontSize: 30, fontWeight: 100 }}
              ></i>
            </Btn>
            <H4 attrH4={{ className: "card-title mb-0 ms-3 mt-1" }}>
              {t(EditProfile)}
            </H4>
          </div>
        </CardHeader>
        <CardBody>
          <Row>
            <Row className="mb-2">
              <div className="profile-title">
                <div className="media">
                  <Avatar
                    name={userInfo.userName}
                    value="86%"
                    size="80"
                    round
                    src={profileImageUrl || userInfo.profileImageUrl}
                    onClick={handleAvatarClick}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    ref={fileInputRef}
                    className="upload"
                    style={{ display: "none" }}
                    type="file"
                    onChange={(e) => readUrl(e)}
                  />
                  <div className="media-body">
                    <H4 attrH4={{ className: "mb-1" }}>
                      {userInfo.userName || "--"}
                    </H4>
                  </div>
                </div>
              </div>
            </Row>
            <Col md="4">
              <FormGroup className="mb-3">
                <Label className="form-label">{t("First Name")}</Label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder= {t("First Name")}
                  name="firstName"
                  required
                  value={formValues.firstName || ""}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                {" "}
                <Label className="form-label">{t("Last Name")}</Label>
                <Input
                  className="form-control"
                  type="text"
                  value={formValues.lastName || ""}
                  name="lastName"
                  onChange={handleChange}
                  placeholder="Last Name"
                  required
                />
              </FormGroup>
            </Col>
            <Col sm="6" md="4">
              <FormGroup>
                <Label className="form-label">{t("Email Address")}</Label>
                <Input
                  className="form-control"
                  type="email"
                  value={formValues.email || ""}
                  onChange={handleChange}
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </FormGroup>
            </Col>

            {isEditPassWord && (
              <>
                <Col sm="6" md="4">
                  <FormGroup className="mb-3">
                    <Label className="form-label">{t("Old Password")}</Label>
                    <Input
                      className="form-control"
                      type="password"
                      name="oldPassword"
                      value={passwordValues.oldPassword}
                      onChange={handlePasswordChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm="6" md="4">
                  <FormGroup className="mb-3">
                    <Label className="form-label">{t("New Password")}</Label>
                    <Input
                      className="form-control"
                      type="password"
                      name="newPassword"
                      value={passwordValues.newPassword}
                      onChange={handlePasswordChange}
                    />
                  </FormGroup>
                </Col>
                <Col sm="6" md="4">
                  <FormGroup className="mb-3">
                    <Label className="form-label">{t("Confirm Password")}</Label>
                    <Input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      value={passwordValues.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
        </CardBody>
        <CardFooter className="text-end">
          <Button color="warning" onClick={handleEditPassword} className="me-3">
            {isLoading ? (
              <>
                <Spinner size="sm" color="light" className="me-2" />
                {" Loading..."}
              </>
            ) : isEditPassWord ? (
              t("Change Password")
            ) : (
              t("Edit Password")
            )}
          </Button>
          <Button color="primary" type="submit">
            {isUpdating && <Spinner size="sm" color="light" className="me-2" />}
            {t("Update Profile")}
          </Button>
        </CardFooter>
      </Form>
    </Fragment>
  );
};
export default EditMyProfile;
