import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Btn, UL, LI, H5 } from "../../../AbstractElements";
import {
  getAllWorkingAreasListAPI,
  getEmployeeDetailsAPI,
  updateEmployeeAPI,
} from "../../../Library/apis";

const WorkingAreas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useParams();

  const [employee, setEmployee] = useState({});
  const [workingAreas, setWorkingAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEmployee, setLoadingEmployee] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [search, setSearch] = useState("");

  const fetchWorkingAreas = async () => {
    setLoading(true);

    try {
      const response = await getAllWorkingAreasListAPI();

      setWorkingAreas(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployee = async () => {
    setLoadingEmployee(true);
    try {
      const response = await getEmployeeDetailsAPI(employeeId);
      setEmployee(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingEmployee(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleInsert = (workingArea) => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      workingAreas: [...prevEmployee.workingAreas, workingArea],
    }));
  };

  const handleRevert = (workingAreaId) => {
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      workingAreas: prevEmployee.workingAreas.filter(
        (item) => item._id !== workingAreaId
      ),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await updateEmployeeAPI(employeeId, employee);

      navigate("/employees/list");
      toast.success("Successfully updated!", { autoClose: 2000 });
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchWorkingAreas();
  }, []);

  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  const filteredWorkingAreas = workingAreas?.filter((workingArea) =>
    workingArea?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const employeeWorkingAreaIds = employee?.workingAreas?.map(
    (item) => item._id
  );

  const notSelectedWorkingAreas = filteredWorkingAreas?.filter(
    (workingArea) => !employeeWorkingAreaIds?.includes(workingArea._id)
  );

  return (
    <Fragment>
      <Breadcrumbs mainTitle={t("Working Areas")} title="Working Areas" />
      <Container fluid={true}>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <Card className="position-relative">
                <Btn
                  attrBtn={{
                    className: "position-absolute left-0",
                    style: { zIndex: 1, top: 10 ,left:10},
                    color: "light",
                    onClick: handleBack,
                  }}
                >
                  {t("Back")}
                </Btn>
                <br></br>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H5>{`${t('Employee')}: ${
                    !loadingEmployee
                      ? `${employee.firstName} ${employee.lastName}`
                      : ""
                  }`}</H5>
                  <Btn
                    attrBtn={{
                      color: "success",
                      onClick: handleSave,
                      disabled: isSaving,
                    }}
                  >
                    {isSaving && (
                      <Spinner size="sm" color="light" className="me-1" />
                    )}
                    {t("Save")}
                  </Btn>
                </CardHeader>

                <CardBody>
                  <UL attrUL={{ className: "simple-list border-0 mb-4" }}>
                    {employee?.workingAreas?.map((item) => (
                      <LI
                        key={item._id}
                        attrLI={{
                          className: "border-0 p-2 list-group-item-action",
                        }}
                      >
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "px-3 py-1",
                            onClick: () => handleRevert(item._id),
                          }}
                        >
                          {item.name}
                          <i className="fa fa-arrow-right ms-2 cursor-pointer" />
                        </Btn>
                      </LI>
                    ))}
                  </UL>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardHeader>
                  <H5 attrH5={{ style: { textTransform: "none" } }}>
                    {t("List of all Working Areas in the system")}
                  </H5>
                </CardHeader>

                <CardBody>
                  <InputGroup style={{ width: 250 }} className="mb-2">
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder= {t("Search by name...")}
                      name="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    {search && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearch("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                  <UL attrUL={{ className: "simple-list border-0" }}>
                    {notSelectedWorkingAreas?.map((item) => (
                      <LI
                        key={item._id}
                        attrLI={{
                          className: "border-0 p-2 list-group-item-action",
                        }}
                      >
                        <Btn
                          attrBtn={{
                            color: "primary mr-1",
                            className: "px-3 py-1",
                            onClick: () => handleInsert(item),
                          }}
                        >
                          <i className="fa fa-arrow-left me-2 cursor-pointer" />
                          {item.name}
                        </Btn>
                      </LI>
                    ))}
                  </UL>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Container>
    </Fragment>
  );
};

export default WorkingAreas;
