import React, { Fragment, useContext, useEffect } from "react";
import { Container, Row, Card, CardBody, Col } from "reactstrap";
import { Breadcrumbs, H3, H5, H7 } from "../../../AbstractElements";
import { UL, LI, P, H6 } from "../../../AbstractElements";

import DataTable from "react-data-table-component";

import { getTimeDiff } from "../../../Library/custom_utils";

import "./index.css";
import { makeReadNotificationAPI } from "../../../Library/apis";
import { toast } from "react-toastify";
import UserContext from "../../../_helper/User";
const renderItem = ({ item, handleMakeRead }) => {
  let status = "success";
  if (item.event.includes("alert")) status = "secondary";
  else if (item.event.includes("normal")) status = "primary";
  else if (item.event.includes("Deposited Fund")) status = "info";
  else if (item.event.includes("warning")) status = "warning";
  else if (item.title == "") status = "primary";

  const iconColor =
    status == "primary"
      ? "#2f84f4"
      : status == "success"
      ? "#0cd71e"
      : status == "warning"
      ? "#ffaa05"
      : "#f42f2f";

  return (
    <div
      className="p-l-10 d-flex"
      style={{ alignItems: "center" }}
      onClick={() => {
        if (!item?.isReaded) handleMakeRead(item._id);
      }}
    >
      <div
        style={{ minWidth: "120px", display: "flex", justifyContent: "end" }}
      >
        <span className={`date-content light-background font-${status} m-r-25`}>
          {getTimeDiff(item.createdAt)}
        </span>
      </div>
      <div className={`activity-dot-${status} m-r-50`} />
      <div
        className="m-2 card"
        style={{ width: "60vw", display: "flex", flexDirection: "row" }}
      >
        {!item.isReaded && (
          <div
            style={{
              width: 5,
              height: 5,
              background: "#FEBA05",
              borderRadius: 10,
              marginTop: 15,
              position: "absolute",
            }}
          />
        )}
        <div
          className={`social-circle ms-4`}
          style={{ borderColor: iconColor, backgroundColor: `${iconColor}0a` }}
        >
          <i className={`fa fa-${"check"}`} style={{ color: iconColor }} />
        </div>
        <div className="m-l-50">
          <H6 className="f-light">{item.title}</H6>
          <p className="f-light mb-3">{item.content}</p>
        </div>
      </div>
    </div>
  );
};

const customStyles = {
  header: {
    style: {
      borderBottom: "0px !important",
    },
  },
  rows: {
    style: {
      borderBottom: "0px !important",
    },
  },
};

const Notifications = () => {
  const { userNotifications, updateNotificationsInfo } =
    useContext(UserContext);

  const handleMakeRead = (id) => {
    makeReadNotificationAPI({ id })
      .then(() => {
        updateNotificationsInfo();
      })
      .catch((e) => {
        toast.error("Error on make notifi as read", { autoClose: 5000 });
      });
  };

  const notificationTableData = () => {
    return userNotifications.map((item, i) => {
      return {
        id: i,
        item: renderItem({ item, handleMakeRead }),
      };
    });
  };

  const hadleMakeAsRead = () => {
    makeReadNotificationAPI()
      .then(() => {
        updateNotificationsInfo();
      })
      .catch((e) => {
        toast.error("Error on make notifi as read", { autoClose: 5000 });
      });
  };

  useEffect(() => {
    updateNotificationsInfo();
  }, []);

  const updatedUserNotification = userNotifications.filter(
    (item) => item.isReaded === false
  );

  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={`Notifications (${
          updatedUserNotification && updatedUserNotification.length
        })`}
        title="Mark as read"
        showRightbar
        onClickRightBar={hadleMakeAsRead}
      />
      <Container fluid={true}>
        <Row className="size-column">
          <Col xxl="12" xl="12" md="12">
            <Card>
              <CardBody className="card-body pt-0">
                <div>
                  <div className="recent-activity notification mt-3">
                    <DataTable
                      data={notificationTableData()}
                      columns={[
                        { name: "Item", selector: (row) => row["item"] },
                      ]}
                      center={true}
                      pagination
                      paginationPerPage={20}
                      noTableHead
                      dense
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Notifications;
