import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  Label,
  InputGroup,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import {
  getWorkingAreaShiftDetailsAPI,
  updateWorkingAreaShiftAPI,
  createWorkingAreaShiftAPI,
} from "../../../../Library/apis";
import { Days, WorkingAreaIntervals } from "../../../../Constant";
import CustomSelect from "../../../Common/CustomSelect";

const formInitialValues = {
  startTime: "",
  endTime: "",
  interval: "1",
  days: [],
};

const Form = () => {
  const { workingAreaId, shiftId } = useParams();

  const [initialValues, setInitialValues] = useState(formInitialValues);

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-greater",
        "End time must be later than start time",
        function (value) {
          const { startTime } = this.parent;
          if (startTime && value) {
            return (
              new Date(`1970-01-01T${value}`) >
              new Date(`1970-01-01T${startTime}`)
            );
          }
          return true; // Allow validation to pass if start time or end time is not set yet
        }
      ),
    interval: Yup.string().required("Interval is required"),
    days: Yup.array()
      .min(1, "At least one day is required")
      .required("Days is required"),
    // ... other validations ...0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = {
          ...values,
          workingArea: workingAreaId,
        };
        shiftId
          ? await updateWorkingAreaShiftAPI(shiftId, formData)
          : await createWorkingAreaShiftAPI(formData);

        toast.success(`Successfully ${workingAreaId ? "updated" : "added"}!`, {
          autoClose: 2000,
        });
        handleBack();
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchShift = async () => {
    try {
      const shift = await getWorkingAreaShiftDetailsAPI(shiftId);
      setInitialValues(shift);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldTouched,
  } = formik;

  const handleBack = () => {
    window.history.back();
  };

  const handleSelectChange = (name, value) => {
    setFieldValue(name, value);

    // Manually mark the field as touched to trigger validation
    setFieldTouched(name, true, false);
  };

  useEffect(() => {
    if (shiftId) {
      fetchShift();
    }

    return () => {
      setInitialValues({});
    };
  }, [workingAreaId, shiftId]);
  const { t } = useTranslation();
  const mainTitle = shiftId? "Edit Shift" : "Add Shift";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={maintTitleTran}
        title={`${shiftId ? "Edit" : "Add"} Shift`}
      />
      <Container fluid={true}>
        <Col sm="5">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10,left:10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardBody>
              <Row className="mb-3 g-3">
                <Label className="col-sm-2 col-form-label required">
                  {t("Start")}
                </Label>
                <Col sm="5">
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                    style={{ width: 140 }}
                  >
                    <Input
                      className="form-control digits"
                      type="time"
                      name="startTime"
                      value={values.startTime}
                      onChange={handleChange}
                      invalid={errors.startTime && touched.startTime}
                    />
                  </InputGroup>
                  {errors.startTime && touched.startTime && (
                    <span className="mt-2 text-danger">{errors.startTime}</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-5 g-3">
                <Label className="col-sm-2 col-form-label required">{t("End")}</Label>
                <Col sm="5">
                  <InputGroup
                    className="date flex-nowrap"
                    id="dt-minimum"
                    data-target-input="nearest"
                    style={{ width: 140 }}
                  >
                    <Input
                      className="form-control digits"
                      type="time"
                      name="endTime"
                      value={values.endTime}
                      min={values.startTime || "00:00"} // Set min time equal to the selected start time
                      onChange={handleChange}
                      invalid={errors.endTime && touched.endTime}
                    />
                  </InputGroup>
                  {errors.endTime && touched.endTime && (
                    <span className="mt-2 text-danger">{errors.endTime}</span>
                  )}
                </Col>
              </Row>

              <Row className="mb-5 g-3">
                <Label className="col-sm-2 col-form-label required">
                  {t("Interval")}
                </Label>
                <Col sm="5">
                  <CustomSelect
                    name="interval"
                    options={WorkingAreaIntervals}
                    onChange={handleSelectChange}
                    errors={errors}
                    touched={touched}
                    value={WorkingAreaIntervals.find(
                      (option) => option.value === values["interval"]
                    )}
                    required
                  />
                </Col>
              </Row>

              <Row className="mb-5 g-3">
                {Object.entries(Days).map(([abbr, full]) => (
                  <Col sm={3} key={abbr}>
                    <Label className="d-block required" for={abbr}>
                      <Input
                        className="checkbox_animated"
                        id={abbr}
                        type="checkbox"
                        value={abbr}
                        checked={values?.days?.includes(abbr)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFieldValue("days", [...values.days, abbr]);
                          } else {
                            setFieldValue(
                              "days",
                              values.days.filter((day) => day !== abbr)
                            );
                          }
                        }}
                      />
                      {t(full)}
                    </Label>
                  </Col>
                ))}
                {errors.days && touched.days && (
                  <span className="mt-2 text-danger">{errors.days}</span>
                )}
              </Row>

              <Btn
                attrBtn={{
                  color: "success",
                  disabled: isSubmitting,
                  onClick: handleSubmit,
                }}
              >
                {isSubmitting && <Spinner size="sm" color="light" />}
                {t("Save")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Form;
