import React, { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Btn, H4, Image } from "../../../../AbstractElements";
import {
  activeUserAPI,
  sendVerificationRequestAPI,
} from "../../../../Library/apis";
import { toast } from "react-toastify";

const CodeVerification = ({ logoClassMain }) => {
  const location = useLocation();
  const userInfo = location.state?.userInfo;
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { userInfo, activationCode: code };
    console.log(data);
    activeUserAPI(data)
      .then((res) => {
        toast.success("Successfully Activated!", { autoClose: 2000 });
        navigate("/login");
      })
      .catch((error) => {
        console.log(error?.response?.data);
        toast.error(
          error?.response?.data?.message ||
            "Some thing else may be validation error",
          { autoClose: 2000 }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    sendVerificationRequestAPI({ userInfo: userInfo })
      .then(() => {
        toast.success("Verification code sent again.", { autoClose: 2000 });
      })
      .catch((e) => console.log(e));
  };

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <Fragment>
      <section>
        <div className="login-card">
          <Card
            className="p-5 m-4"
            position-relative
            style={{ width: "500px" }}
          >
            <span
              className="position-absolute"
              style={{ right: 20, top: 20, cursor: "pointer" }}
              onClick={handleClose}
            >
              <i className="fa fa-times fa-lg"></i>
            </span>
            <Form className="theme-form login-form" onSubmit={handleSubmit}>
              <Btn
                attrBtn={{
                  className: "p-0 m-0 pb-3",
                  color: "transparent",
                  style: { borderWidth: 0 },
                  type: "submit",
                  onClick: () => {
                    navigate("/login");
                  },
                }}
              >
                <i
                  className="fa fa-thin fa-arrow-left"
                  style={{ color: "black", fontSize: 30, fontWeight: 100 }}
                />
              </Btn>
              <H4 attrH4={{ className: "text-warning" }}>
                Verify Your Account
              </H4>
              <FormGroup>
                <Label className="m-0 col-form-label w-100">
                  A one-time passcode was sent to you via email
                </Label>
                <Label className="m-0 col-form-label w-100">
                  Please enter the 6 digit OTP below
                </Label>
                <Label className="m-0 col-form-label">Enter OTP here</Label>
                <Input
                  className="form-control text-center"
                  type="text"
                  placeholder="00000"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-4 mt-4">
                <span className="reset-password-link">
                  Did not receive OTP?
                  <Btn
                    attrBtn={{
                      color: "transparent text-warning",
                      type: "button",
                      onClick: handleReset,
                    }}
                  >
                    Resend
                  </Btn>
                </span>
              </FormGroup>
              <FormGroup className="w-100">
                <Btn
                  attrBtn={{
                    className: "w-100",
                    color: "warning",
                    type: "submit",
                  }}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" color="light" />
                      {" Loading..."}
                    </>
                  ) : (
                    "Verify"
                  )}
                </Btn>
              </FormGroup>
            </Form>
          </Card>
        </div>
      </section>
    </Fragment>
  );
};

export default CodeVerification;
