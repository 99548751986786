import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col, Label, Input,   FormGroup} from "reactstrap";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn, H6 } from "../../../AbstractElements";
import {
  getShiftPlanDetailsAPI,
  updateSingleShiftPlanAPI,
  getWorkingAreaDetailsAPI,
} from "../../../Library/apis";
import CustomSelect from "../../Common/CustomSelect";
import { WorkingAreaIntervals } from "../../../Constant";
import { useTranslation } from 'react-i18next'
const formInitialValues = {
  selectedDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  interval: "1",
};

// Function to format hours or minutes with leading zero if needed
const formatWithLeadingZero = (value) => (value < 10 ? `0${value}` : value);

const UpdateSingleShiftModal = ({
  isOpen,
  toggler,
  shiftPlan,
  fetchShiftPlans,
}) => {
  const [shiftPlanDetails, setShiftPlanDetails] = useState({});
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const { t } = useTranslation();

  const fetchShiftPlanDetails = async () => {
   
    try {
      const response = await getShiftPlanDetailsAPI(shiftPlan.id);

      setShiftPlanDetails(response);


      const startDateTime = new Date(response.startTime.trim());
      const endDateTime = new Date(response.endTime.trim());

      

      const selectedDate = startDateTime.toISOString().slice(0, 10);
      //const endDate = new Date(response.endDate).toISOString().slice(0, 10);
      const endDate = selectedDate
     
      const interval = response.interval;
      const startTime = response.startTimeStr.trim();
      const endTime = response.endTimeStr.trim();
      // Update initialValues
      setInitialValues({
        selectedDate,
        endDate,
        startTime,
        endTime,
        interval,
      });

    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const handleSave = async (values) => {
    Swal.fire({
      title: t("Edit recurring event"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Ok"),
      cancelButtonText:t("Cancel"),
      input: "radio",
      inputOptions: {
        one: t("This event"),
        following: t("This and following events"),
      },
      inputValue: "one", // Set the default value to 'one'
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        console.log(shiftPlan)

        try {
          await updateSingleShiftPlanAPI(shiftPlan?.id, {
            ...values,
            type: result.value,
            workingAreaShiftId:  shiftPlanDetails.workingArea._id
          });

          toast.success(`Successfully saved!`, {
            autoClose: 2000,
          });
          fetchShiftPlans();
          toggler();
        } catch (error) {
         
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  useEffect(() => {
  
    if (shiftPlan?.id && isOpen) {
      fetchShiftPlanDetails();
    }
  }, [shiftPlan, isOpen]);

  useEffect(() => {
    resetForm();
  }, [isOpen]);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-greater",
        "End time must be later than start time",
        function (value) {
          const { startTime } = this.parent;
          if (startTime && value) {
            return (
              new Date(`1970-01-01T${value}`) >
              new Date(`1970-01-01T${startTime}`)
            );
          }
          return true; // Allow validation to pass if start time or end time is not set yet
        }
      ),
    interval: Yup.string().required("Interval is required"),
    // ... other validations ...0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleSave(values);
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSelectChange = (name, value) => {
    setFieldValue(name, value);

    // Manually mark the field as touched to trigger validation
    setFieldTouched(name, true, false);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldTouched,
    resetForm,
  } = formik;

  const duration =
    (new Date(`2023-01-01T${values.endTime}:00`) -
      new Date(`2023-01-01T${values.startTime}:00`)) /
    (60 * 60 * 1000);
    const { workingAreaId } = useParams();
  return (
    
    <Modal isOpen={isOpen} toggle={toggler} size="lg" centered>
      <ModalBody className="pt-5">
        <Row className="align-items-center mb-2">
          <Col sm="6">
            <Row className="align-items-center">
              <Label className="col-sm-4 col-form-label required">
                {t("Selected Date")}
              </Label>
              <Col sm="8">
                <Input
                  className="modal_input"
                  type="date"
                  name="selectedDate"
                  value={values.selectedDate}
                  onChange={handleChange}
                  invalid={errors.selectedDate && touched.selectedDate}
                />
                {errors.selectedDate && touched.selectedDate && (
                  <span className="mt-2 text-danger">
                    {errors.selectedDate}
                  </span>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <H6 attrH6={{ className: "mb-0" }}>
              {t("Working Area")}: {shiftPlanDetails?.workingArea?.name}
            </H6>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Row className="align-items-center">
              <Label className="col-sm-4 col-form-label required">
                {t("End Date")}
              </Label>
              <Col sm="8">
                <Input
                  className="modal_input"
                  type="date"
                  name="endDate"
            
                  value={values.endDate}
                  onChange={handleChange}
                  invalid={errors.endDate && touched.endDate}
                />
                {errors.endDate && touched.endDate && (
                  <span className="mt-2 text-danger">{errors.endDate}</span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />

        <Row className="mb-2">
          <Col sm="6">
            <Row className="align-items-center">
              <Label className="col-sm-4 col-form-label required">{t("Start")}</Label>
              <Col sm="8">
                <Input
                  style={{ width: 130 }}
                  className="modal_input"
                  type="time"
                  name="startTime"
                  value={values.startTime}
                  onChange={handleChange}
                  invalid={errors.startTime && touched.startTime}
                />
                {errors.startTime && touched.startTime && (
                  <span className="mt-2 text-danger">{errors.startTime}</span>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm="6">
            <Row className="align-items-center">
            <Label className="col-sm-4 col-form-label required">{t("End")}</Label>

              <Col sm="8">
      

                <Input
                  style={{ width: 130 }}
                  className="modal_input"
                  type="time"
                  name="endTime"
                  value={values.endTime}
                  onChange={handleChange}
                  invalid={errors.endTime && touched.endTime}
                />
                {errors.endTime && touched.endTime && (
                  <span className="mt-2 text-danger">{errors.endTime}</span>
                )}
  
              </Col>

            </Row>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col sm="6">
            <Row className="align-items-center">
              <Label className="col-sm-4 col-form-label">{t("Duration")}</Label>
              <Col sm="8">{duration} hours</Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Row className="align-items-center">
              <Label className="col-sm-4 col-form-label required mb-3">
                {t("Interval")}
              </Label>
              <Col sm="8">
                <CustomSelect
                  name="interval"
                  options={WorkingAreaIntervals}
                  onChange={handleSelectChange}
                  errors={errors}
                  touched={touched}
                  value={WorkingAreaIntervals.find(
                    (option) => option.value === values["interval"]
                  )}
                  required
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "secondary", onClick: toggler }}>{t("Cancel")}</Btn>
        <Btn attrBtn={{ color: "success", onClick: handleSubmit }}>{t("Save")}</Btn>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateSingleShiftModal;
