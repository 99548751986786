import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { TodoApi } from "../../api";
import Tododatas from "./todo.json";
import Context from "./index";
import {
  addNewTodoAPI,
  deleteTodoAPI,
  getTodoListAPI,
  updateTodoAPI,
} from "../../Library/apis";
import UserContext from "../User";
const TodoProvider = (props) => {
  const [allTodos, setAllTodo] = useState([]);
  const [todoItem, setTodoItem] = useState([]);
  const userInfo = useContext(UserContext);
  const [company_Id, setCompanyId] = useState(userInfo.userInfo.companyId);
  const fetchTodo = async () => {
  
    try {
      const response = await getTodoListAPI();
      setAllTodo(response);
      setTodoItem(response);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchTodo();
  }, [setTodoItem, setAllTodo]);

  const addNewTodo = async (task) => {
    try {
      // Create a new Date object for today's date
      const today = new Date();
      // Format the date as "dd MMMM" (e.g., "05 March")
      const dateOptions = { day: "2-digit", month: "long" };
      const formattedDate = today.toLocaleDateString("en-US", dateOptions);

      const newTodo = await addNewTodoAPI({
        title: task.task,
        status: "pending",
        statusCode: "success",
        date: formattedDate, // Use the dynamically generated date
        companyId: userInfo.userInfo.companyId,
      });
      // Update your component state or context with the new todo
      await fetchTodo(); // Fetch the updated list of todos
      console.log("Todo added successfully:", newTodo);
    } catch (error) {
      console.error("Failed to add new todo:", error);
    }
  };

  const selectItem = async (id, status) => {
    const temp = allTodos.reduce((todoAcc, item) => {
      if (item._id === id) {
        todoAcc.push({ ...item, status: status });
      } else todoAcc.push(item);
      return todoAcc;
    }, []);

    setTodoItem(temp);
    setAllTodo(temp);
  };

  const markAllItems = (markAll) => {
    const updateStatus = allTodos.reduce((cartAcc, item) => {
      if (markAll === false) {
        cartAcc.push({ ...item, status: "completed" });
      } else {
        cartAcc.push({ ...item, status: "pending" });
      }
      return cartAcc;
    }, []);

    setAllTodo(updateStatus);
    setTodoItem(updateStatus);
    fetchTodo();
  };

  const removeItems = async (id) => {
    try {
      await deleteTodoAPI(id);
      await fetchTodo(); // Fetch the updated list of todos
      // Update your component state or context to remove the deleted todo
      console.log("Todo deleted successfully");
    } catch (error) {
      console.error("Failed to delete todo:", error);
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        allTodos,
        todoItem,
        addNewTodo: addNewTodo,
        fetchTodo: fetchTodo,
        selectedItem: selectItem,
        markAllItems: markAllItems,
        removeItems: removeItems,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default TodoProvider;
