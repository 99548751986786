import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Btn, UL, LI, H5 } from "../../../../AbstractElements";
import {
  deleteEmployeeShiftAPI,
  getEmployeeDetailsAPI,
} from "../../../../Library/apis";
import { Link } from "react-router-dom";

const Shifts = () => {
  const { employeeId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsDeleting(true);

        try {
          const res = await deleteEmployeeShiftAPI(id);
          toast.success(res.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchEmployee();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  const fetchEmployee = async () => {
    setLoading(true);
    try {
      const response = await getEmployeeDetailsAPI(employeeId);
      setEmployee(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    if (employeeId) {
      fetchEmployee();
    }
  }, [employeeId]);

  return (
    <Fragment>
      <Breadcrumbs mainTitle={t("Shifts")} title="Shifts" />
      <Container fluid={true}>
        <Col sm="12">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10 ,left:10},
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardHeader className="d-flex align-items-center">
              <H5>{`${t('Employee')}: ${
                !loading ? `${employee.firstName} ${employee.lastName}` : ""
              }`}</H5>
              <Link to={`/employees/${employeeId}/shifts/create`}>
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "ms-2",
                  }}
                >
                  <i className="fa fa-plus"></i> {t("New shift")}
                </Btn>
              </Link>
            </CardHeader>

            <CardBody>
              <UL attrUL={{ className: "simple-list border-0" }}>
                {employee?.shifts?.map((shift) => (
                  <LI
                    key={`${shift._id}`}
                    attrLI={{
                      className: "border-0 p-2 list-group-item-action",
                    }}
                  >
                    <span>{`${shift.days.join(", ")}: ${shift.startTime} - ${
                      shift.endTime
                    }`}</span>
                    <Link
                      to={`/employees/${employeeId}/shifts/${shift._id}/edit`}
                    >
                      <i className="fa fa-edit ms-2 cursor-pointer" />
                    </Link>
                    {isDeleting ? (
                      <Spinner color="light" size="sm" />
                    ) : (
                      <i
                        className="fa fa-close ms-2 cursor-pointer"
                        onClick={() => handleDelete(shift._id)}
                      />
                    )}
                  </LI>
                ))}
              </UL>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Shifts;
