import { apiGet, apiPost, apiPut, apiDelete, apiPost1 } from "../api_utils";
import { API_URL, PROFILE_IMG_BASE_URL } from "../constant";

export const uploadProfileImageAPI = (data) =>
  apiPost(`${PROFILE_IMG_BASE_URL}/upload`, data);

// Auth Api
export const signUpAPI = (data) => apiPost(`${API_URL}/auth/signup`, data);
export const loginAPI = (data) => apiPut(`${API_URL}/auth/login`, data);
export const activeUserAPI = (data) =>
  apiPut(`${API_URL}/auth/activate-user`, data);
export const changePasswordAPI = (data) =>
  apiPut(`${API_URL}/auth/change-password`, data);
export const resetPasswordAPI = (data) =>
  apiPut(`${API_URL}/auth/reset-password`, data);
export const getProfileAPI = () => apiGet(`${API_URL}/auth/get-profile`);
export const updateProfileAPI = (data) =>
  apiPut(`${API_URL}/auth/update-profile`, data);
export const verifyCustomerTokenAPI = () =>
  apiPut(`${API_URL}/auth/verify-customer-token`);
export const sendVerificationRequestAPI = (data) =>
  apiPut(`${API_URL}/auth/send-verification-request`, data);
export const confirmOTPCodeAPI = (data) =>
  apiPut(`${API_URL}/auth/confirm-OTP-code`, data);
export const confirmResetPasswordAPI = (data) =>
  apiPut(`${API_URL}/auth/confirm-reset-password`, data);

// Employees Api
export const getAllEmployeesListAPI = (data) =>
  apiPost(`${API_URL}/employees/all`, data);
export const getEmployeesListAPI = (data) =>
  apiPost(`${API_URL}/employees/getall`, data);
export const createEmployeeAPI = (data) =>
  apiPost(`${API_URL}/employees`, data);
export const sendEmailEmployeeAPI = (data) =>
  apiPost(`${API_URL}/employees/email`, data);
export const updateEmployeeAPI = (id, data) =>
  apiPut(`${API_URL}/employees/${id}`, data);
export const getEmployeeDetailsAPI = (id) =>
  apiGet(`${API_URL}/employees/${id}`);
// export const deleteAccountAPI = (id) => apiDelete(`${API_URL}/employees/${id}`);

// Employee Skills Api
export const getEmployeeSkillsListAPI = () =>
  apiGet(`${API_URL}/employees/skills`);
export const createEmployeeSkillAPI = (data) =>
  apiPost(`${API_URL}/employees/skills`, data);
export const deleteEmployeeSkillAPI = (id) =>
  apiDelete(`${API_URL}/employees/skills/${id}`);

// Employee Attributes Api
export const getEmployeeAttributesListAPI = (data) =>
  apiGet(`${API_URL}/employees/attributes`, data);
export const createEmployeeAttributeAPI = (data) =>
  apiPost(`${API_URL}/employees/attributes`, data);
export const deleteEmployeeAttributeAPI = (id) =>
  apiDelete(`${API_URL}/employees/attributes/${id}`);

// Employee Shifts Api
export const createEmployeeShiftAPI = (data) =>
  apiPost(`${API_URL}/employees/shifts`, data);
export const updateEmployeeShiftAPI = (id, data) =>
  apiPut(`${API_URL}/employees/shifts/${id}`, data);
export const deleteEmployeeShiftAPI = (id) =>
  apiDelete(`${API_URL}/employees/shifts/${id}`);
export const getEmployeeShiftDetailsAPI = (id) =>
  apiGet(`${API_URL}/employees/shifts/${id}`);

// Working Areas Api
export const getAllWorkingAreasListAPI = (data) =>
  apiPost(`${API_URL}/working-areas/all`, data);
export const getWorkingAreasListAPI = (data) =>
  apiPost(`${API_URL}/working-areas/getall`, data);
export const getWorkingAreaDetailsAPI = (id) =>
  apiGet(`${API_URL}/working-areas/${id}`);
export const createWorkingAreaAPI = (data) =>
  apiPost(`${API_URL}/working-areas`, data);
export const updateWorkingAreaAPI = (id, data) =>
  apiPut(`${API_URL}/working-areas/${id}`, data);
export const deleteWorkingAreaAPI = (id) =>
  apiDelete(`${API_URL}/working-areas/${id}`);
export const reactivateWorkingAreaAPI = (id) =>
  apiPut(`${API_URL}/working-areas/${id}/reactivate`);

export const updateWorkinaAreaLexofficeAPI = (id, data) =>
  apiPut(`${API_URL}/working-areas/lexoffice/${id}`, data);
export const getAllWorkingAreaLexofficeAPI = (data) =>
  apiPost(`${API_URL}/working-areas/lexoffice/all`, data);
export const getWorkinaAreaLexofficeAPI = (id) =>
  apiGet(`${API_URL}/working-areas/lexoffice/${id}`);

// Working Area Skills Api
export const getWorkingAreaSkillsListAPI = () =>
  apiGet(`${API_URL}/working-areas/skills`);
export const createWorkingAreaSkillAPI = (data) =>
  apiPost(`${API_URL}/working-areas/skills`, data);
export const deleteWorkingAreaSkillAPI = (id) =>
  apiDelete(`${API_URL}/working-areas/skills/${id}`);

// Working Area Attributes Api
export const getWorkingAreaAttributesListAPI = (data) =>
  apiGet(`${API_URL}/working-areas/attributes`, data);
export const createWorkingAreaAttributeAPI = (data) =>
  apiPost(`${API_URL}/working-areas/attributes`, data);
export const deleteWorkingAreaAttributeAPI = (id) =>
  apiDelete(`${API_URL}/working-areas/attributes/${id}`);

// Working Area Shifts Api
export const createWorkingAreaShiftAPI = (data) =>
  apiPost(`${API_URL}/working-areas/shifts`, data);
export const updateWorkingAreaShiftAPI = (id, data) =>
  apiPut(`${API_URL}/working-areas/shifts/${id}`, data);
export const deleteWorkingAreaShiftAPI = (id) =>
  apiDelete(`${API_URL}/working-areas/shifts/${id}`);
export const getWorkingAreaShiftDetailsAPI = (id) =>
  apiGet(`${API_URL}/working-areas/shifts/${id}`);

// Time trackings Api
export const getTimeTrackingsListAPI = (data) =>
  apiPost(`${API_URL}/time-trackings/getall`, data);
export const getAllTimeTrackingsAPI = () =>
  apiGet(`${API_URL}/time-trackings/get-all-time-trackings`);
export const createTimeTrackingAPI = (data) =>
  apiPost(`${API_URL}/time-trackings`, data);
export const updateTimeTrackingAPI = (id, data) =>
  apiPut(`${API_URL}/time-trackings/${id}`, data);
export const confirmByManagerTimeTrackingAPI = (id) =>
  apiPut(`${API_URL}/time-trackings/${id}/confirm-by-manager`);
export const revertConfirmByManagerTimeTrackingAPI = (id) =>
  apiPut(`${API_URL}/time-trackings/${id}/revert-confirm-by-manager`);
export const deleteTimeTrackingAPI = (id) =>
  apiDelete(`${API_URL}/time-trackings/${id}`);
export const getTimeTrackingDetailsAPI = (id) =>
  apiGet(`${API_URL}/time-trackings/${id}`);

// Holiday/Illness Api
export const getHolidayIllnessesListAPI = (data) =>
  apiPost(`${API_URL}/holiday-illnesses/getall`, data);
export const getHolidayIllnessCalendarListAPI = (data) =>
  apiGet(
    `${API_URL}/holiday-illnesses/get-holiday-illnesses-calendar-list`,
    data
  );
export const getAllHolidayIllnessesAPI = () =>
  apiGet(`${API_URL}/holiday-illnesses/get-all-holiday-illnesses`);
export const createHolidayIllnessAPI = (data) =>
  apiPost(`${API_URL}/holiday-illnesses`, data);
export const updateHolidayIllnessAPI = (id, data) =>
  apiPut(`${API_URL}/holiday-illnesses/${id}`, data);
export const confirmByManagerHolidayIllnessAPI = (id) =>
  apiPut(`${API_URL}/holiday-illnesses/${id}/confirm-by-manager`);
export const revertConfirmByManagerHolidayIllnessAPI = (id) =>
  apiPut(`${API_URL}/holiday-illnesses/${id}/revert-confirm-by-manager`);
export const deleteHolidayIllnessAPI = (id) =>
  apiDelete(`${API_URL}/holiday-illnesses/${id}`);
export const getHolidayIllnessDetailsAPI = (id) =>
  apiGet(`${API_URL}/holiday-illnesses/${id}`);

// Settings Api
export const getSettingDetailsAPI = () => apiGet(`${API_URL}/settings`);
export const getCountriesAPI = () => apiGet(`${API_URL}/settings/countries`);
export const getFederalStatesAPI = (data) =>
  apiPost(`${API_URL}/settings/federal-states`, data);
export const saveSettings = (data) => apiPost(`${API_URL}/settings`, data);
export const saveNationalHolidays = (data) =>
  apiPost(`${API_URL}/settings/national-holidays`, data);
export const checkLexofficeApiConnectionAPI = (data) =>
  apiPost(`${API_URL}/settings/check-lexoffice-connection`, data);
export const transferDataToLexofficeAPI = (data) =>
  apiPost(`${API_URL}/settings/transferdatatolexoffice`, data);
export const createInvoicePDFAPI = (data) =>
  apiPost(
    `${API_URL}/settings/create-invoice-pdf`,
    data,
    {},
    { responseType: "blob" }
  );
export const requestCloseSubscriptionAPI = () =>
  apiGet(`${API_URL}/settings/close-subscription`);
export const getSettinglexofficePayrollAPI = (data) =>
  apiPost(`${API_URL}/settings/getSettinglexofficePayroll`, data);
// Support Api
export const submitReferenceAPI = (data, headers) =>
  apiPost(`${API_URL}/support/submit-reference`, data, headers);

// Shift plan Api
export const insertSingleShiftPlanAPI = (data) =>
  apiPost(`${API_URL}/shift-plans/insertSingle`, data);
export const insertShiftPlanAPI = (data) =>
  apiPost(`${API_URL}/shift-plans/insert`, data);
export const getShiftPlansAPI = (data) =>
  apiPost(`${API_URL}/shift-plans`, data);
export const getWorkingEmployeesAPI = (data) =>
  apiGet(`${API_URL}/shift-plans/working-employees`, data);
export const getShiftPlanDetailsAPI = (id) =>
  apiGet(`${API_URL}/shift-plans/${id}`);
export const getAssignableShiftPlanEmployeesAPI = (id) =>
  apiGet(`${API_URL}/shift-plans/${id}/assignable-employees`);
export const getAssignableDropOutManagerEmployeesAPI = (id) =>
  apiGet(`${API_URL}/dropout-manager/${id}/assignable-employees`);
export const assignEmployeesToShiftPlanAPI = (id, data) =>
  apiPut(`${API_URL}/shift-plans/${id}/assign-employees`, data);
export const deleteShiftPlansAPI = (id, data) =>
  apiDelete(`${API_URL}/shift-plans/${id}`, data);
export const removeOffTimeEmployeesAPI = (id, data) =>
  apiPost(`${API_URL}/shift-plans/${id}/remove-offtime-employees`, data);
export const clearShiftPlansAPI = (data) =>
  apiDelete(`${API_URL}/shift-plans/clear-shift-plans`, data);
export const updateShiftPlanAPI = (id, data) =>
  apiPut(`${API_URL}/shift-plans/${id}/update`, data);
export const updateSingleShiftPlanAPI = (id, data) =>
  apiPut(`${API_URL}/shift-plans/update-single/${id}/update`, data);
export const getNationalHolidaysAPI = (data) =>
  apiPost(`${API_URL}/shift-plans/get-national-holidays`, data);
export const insertShiftPlanAutomaticallyAPI = () =>
  apiPost(`${API_URL}/shift-plans/insert-automatically`);

export const getDropoutHistoryAPI = () =>
  apiGet(`${API_URL}/dropout-manager/getAllDropHistory`);

//Todo List Api
export const getTodoListAPI = (data) => apiGet(`${API_URL}/todos`, data);
export const addNewTodoAPI = (todo) => apiPost(`${API_URL}/todos`, todo);
export const deleteTodoAPI = (id) => apiDelete(`${API_URL}/todos/${id}`);
export const updateTodoAPI = (data) => apiPut(`${API_URL}/todos/`, data);

//Notification API
export const getNotificationsAPI = () =>
  apiGet(`${API_URL}/notification/GetNotifications`);
export const makeReadNotificationAPI = (data) =>
  apiPut(`${API_URL}/notification/MakeReadNotification`, data);
