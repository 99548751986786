import React, { Fragment, useState, useEffect, useContext } from 'react';
import UserContext from '../../_helper/User';
import { Spinner } from '../../AbstractElements';

const Loader = (props) => {
  const [show, setShow] = useState(true);
  const { isLoading } = useContext(UserContext);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return (
    <Fragment>

      <div className={`loader-wrapper ${isLoading ? '' : 'loderhide'}`} style={{ background: '#333333aa' }}>
        <div className="loader-box">
          <Spinner attrSpinner={{ className: 'loader-3' }} />
        </div>
      </div>

      <div className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
        <div className='loader-index'>
          <span></span>
        </div>
        <svg>
          <defs></defs>
          <filter id='goo'>
            <fegaussianblur in='SourceGraphic' stdDeviation='11' result='blur'></fegaussianblur>
            <fecolormatrix in='blur' values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9' result='goo'></fecolormatrix>
          </filter>
        </svg>
      </div>

    </Fragment>
  );
};

export default Loader;
