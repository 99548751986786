import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Row, Col, Label, Input, FormGroup, InputGroup } from "reactstrap";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn, H6, Breadcrumbs } from "../../../AbstractElements";
import {
  getAllEmployeesListAPI,
  getAllWorkingAreasListAPI,
  insertShiftPlanAPI,
  insertSingleShiftPlanAPI,
  getShiftPlansAPI,
} from "../../../Library/apis";
import CustomSelect from "../../Common/CustomSelect";
import { WorkingAreaIntervals } from "../../../Constant";
import { useTranslation } from "react-i18next";
const formInitialValues = {
  selectedDate: "",
  endDate: "",
  startTime: "",
  endTime: "",
  interval: "1",
};

// Function to format hours or minutes with leading zero if needed
const formatWithLeadingZero = (value) => (value < 10 ? `0${value}` : value);

const SingleShiftPlanModal = ({
  isOpen,
  toggler,
  fetchShiftPlans,
  shiftPlan,
}) => {
  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [employeesOption, setEmployeesOption] = useState([]);
  const [workingAreasOption, setWorkingAreasOption] = useState([]);
  const { t } = useTranslation();

  const fetchEmployees = async () => {
    try {
      const employees = await getAllEmployeesListAPI();
      setEmployeesOption(
        employees?.map((item) => ({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const fetchWorkingAreas = async () => {
    try {
      const workingAreas = await getAllWorkingAreasListAPI();
      setWorkingAreasOption(
        workingAreas?.map((item) => ({ value: item._id, label: item.name }))
      );
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    fetchEmployees();
    fetchWorkingAreas();
    setInitialValues(formInitialValues);

    return () => {
      setInitialValues({});
      setEmployeesOption([]);
      setWorkingAreasOption([]);
    };
  }, []);

  const handleSave = async (values) => {
    Swal.fire({
      title: t("Adding Single Shift"),
      icon: "warning",

      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Ok"),
      cancelButtonText: t("Cancel"),
      input: "radio",
      inputOptions: {
        one: t("This event"),
      },
      inputValue: "one", // Set the default value to 'one'
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(values, "asdja");

        try {
          await insertSingleShiftPlanAPI({
            date: values.startDate,
            employeeId: values?.employee?.value,
            workingAreaId: values.workingArea.value,
            startTime: values.startTime,
            endTime: values.endTime,
            endDate: values.startDate,
            interval: "1",
          });

          fetchShiftPlans();
          toast.success(`Successfully saved!`, {
            autoClose: 2000,
          });
          toggler();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  useEffect(() => {
    resetForm();
  }, [isOpen]);

  const validationSchema = Yup.object().shape({
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-greater",
        "End time must be later than start time",
        function (value) {
          const { startTime } = this.parent;
          if (startTime && value) {
            return (
              new Date(`1970-01-01T${value}`) >
              new Date(`1970-01-01T${startTime}`)
            );
          }
          return true; // Allow validation to pass if start time or end time is not set yet
        }
      ),
    interval: Yup.string().required("Interval is required"),
    // ... other validations ...0
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        handleSave(values);
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSelectChange = (name, value) => {
    setFieldValue(name, value);

    // Manually mark the field as touched to trigger validation
    setFieldTouched(name, true, false);
  };

  const mainTitle = "Single Shift Plan";
  const maintTitleTran = t(mainTitle);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    handleChange,
    setFieldTouched,
    resetForm,
  } = formik;

  const duration =
    (new Date(`2023-01-01T${values.endTime}:00`) -
      new Date(`2023-01-01T${values.startTime}:00`)) /
    (60 * 60 * 1000);
  const { workingAreaId } = useParams();
  return (
    <Modal isOpen={isOpen} toggle={toggler} size="lg" centered>
      <ModalBody className="pt-5">
        <Breadcrumbs mainTitle={maintTitleTran} />
        <hr />
        <Row>
          <Col sm="4">
            <CustomSelect
              name="employee"
              options={employeesOption}
              label="Employee"
              onChange={handleSelectChange}
              errors={errors}
              touched={touched}
              value={values.employee}
              async
              required
            />
          </Col>
          <Col sm="4">
            <CustomSelect
              name="workingArea"
              options={workingAreasOption}
              label="Working Area"
              onChange={handleSelectChange}
              errors={errors}
              touched={touched}
              value={values.workingArea}
              async
              required
            />
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label className="required">{t("StartDate")}</Label>
              <Input
                className="form-control digits"
                type="date"
                name="startDate"
                value={values.startdate}
                onChange={handleChange}
                invalid={errors.startdate && touched.startdate}
              />
              {errors.startdate && touched.startdate && (
                <span className="mt-2 text-danger">{errors.startdate}</span>
              )}
            </FormGroup>
          </Col>
          {/* <Col sm="4">
                        <FormGroup>
                            <Label className="required">{t("EndDate")}</Label>
                            <Input
                                className="form-control digits"
                                hidden={true}
                                type="date"
                                name="endDate"
                                value={values.endDate}
                                onChange={handleChange}
                                invalid={errors.endDate && touched.endDate}
                            />
                            {errors.endDate && touched.endDate && (
                                <span className="mt-2 text-danger">{errors.endDate}</span>
                            )}
                        </FormGroup>
                    </Col> */}
          <Col sm="4">
            <FormGroup>
              <Label className="required">{t("Start")}</Label>
              <InputGroup
                className="date flex-nowrap"
                id="dt-minimum"
                data-target-input="nearest"
                style={{ width: 140 }}
              >
                <Input
                  className="form-control digits"
                  type="time"
                  name="startTime"
                  value={values.startTime}
                  min={values.startTime || "00:00"} // Set min time equal to the selected start time
                  onChange={handleChange}
                  invalid={errors.startTime && touched.startTime}
                />
              </InputGroup>
              {errors.startTime && touched.startTime && (
                <span className="mt-2 text-danger">{errors.startTime}</span>
              )}
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup>
              <Label className="required">{t("End")}</Label>
              <InputGroup
                className="date flex-nowrap"
                id="dt-minimum"
                data-target-input="nearest"
                style={{ width: 140 }}
              >
                <Input
                  className="form-control digits"
                  type="time"
                  name="endTime"
                  value={values.endTime}
                  min={values.endTime || "00:00"} // Set min time equal to the selected start time
                  onChange={handleChange}
                  invalid={errors.endTime && touched.endTime}
                />
              </InputGroup>
              {errors.endTime && touched.endTime && (
                <span className="mt-2 text-danger">{errors.endTime}</span>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          {/* <Col sm="4">
                        <Row className="align-items-center">
                            <Label className="col-sm-4 col-form-label required mb-3">
                                {t("Interval")}
                            </Label>
                            <Col sm="8">
                                <CustomSelect
                                    name="interval"
                                    options={WorkingAreaIntervals}
                                    onChange={handleSelectChange}
                                    errors={errors}
                                    touched={touched}
                                    value={WorkingAreaIntervals.find(
                                        (option) => option.value === values["interval"]
                                    )}
                                    required
                                />
                            </Col>
                        </Row>
                    </Col> */}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "secondary", onClick: toggler }}>
          {t("Cancel")}
        </Btn>
        <Btn attrBtn={{ color: "success", onClick: handleSubmit }}>
          {t("Save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default SingleShiftPlanModal;
