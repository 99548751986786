import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Media,
  FormGroup,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import { Breadcrumbs, H5, H6, Btn, UL, LI } from "../../../AbstractElements";
import {
  getSettingDetailsAPI,
  saveSettings,
  checkLexofficeApiConnectionAPI,
  getSettinglexofficePayrollAPI,
} from "../../../Library/apis";
import Avatar from "react-avatar";
import DataTable from "react-data-table-component";
import useDebounce from "../../../Hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import * as XLSX from "xlsx";
const generateFakeData = (numRows, t) => {
  const data = [];
  for (let i = 0; i < numRows; i++) {
    const startDate = `${String(Math.floor(Math.random() * 28) + 1).padStart(
      2,
      "0"
    )}.${String(Math.floor(Math.random() * 12) + 1).padStart(2, "0")}.2024`;

    const endDate = `${String(Math.floor(Math.random() * 28) + 1).padStart(
      2,
      "0"
    )}.${String(Math.floor(Math.random() * 12) + 1).padStart(2, "0")}.2024`;

    data.push({
      id: i + 2,
      month: 3 + 1,
      surename: `Surename${i + 1}`,
      lastname: `Lastname${i + 1}`,
      hours: Math.floor(Math.random() * 40 + 1),
      payedHoliday: `${startDate} - ${endDate} / ${2} days`,
      dateCreate: startDate,
      sickness: `${startDate} - ${endDate} / ${1} days`,
      sicknessChild: `${startDate} - ${startDate} / 1 day`,
    });
  }
  return data;
};

const LexOfficePayroll = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so we add 1

  const [settings, setSettings] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const { t, i18n } = useTranslation();

  const fetchSettingDetails = async () => {
    try {
      const response = await getSettingDetailsAPI();
      setSettings(response);
      setApiKey(response?.lexofficeApiKey);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const [data, setData] = useState(""); // generateFakeData(3, t)

  const fetchLexofficePayroll = async () => {
    try {
      const response = await getSettinglexofficePayrollAPI({
        selectedMonth,
        selectedYear,
      });
      if (response && response.data) {
        setData(response.data);
        console.log("response--------------=", response.data);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching payroll data:", error);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: t("ID"),
        selector: (row) => row.id,
        sortable: true,
        width: "80px",
        style: { fontWeight: "bold" },
      },
      {
        name: t("Month"),
        selector: (row) => row.month,
        sortable: true,
        width: "80px",
      },
      {
        name: t("Surename"),
        selector: (row) => row.surname,
        sortable: true,
        width: "120px",
      },
      {
        name: t("Lastname"),
        selector: (row) => row.lastname,
        sortable: true,
        width: "120px",
      },
      {
        name: t("Working hours"),
        selector: (row) => row.hours,
        sortable: true,
        width: "150px",
      },
      {
        name: t("Payed Holiday"),
        selector: (row) => row.payedHoliday,
        sortable: true,
        grow: 1,
        cell: (row) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            {Array.isArray(row.payedHoliday)
              ? row.payedHoliday.join("\n")
              : row.payedHoliday}
          </div>
        ),
      },
      {
        name: t("Sickness"),
        selector: (row) => row.sickness,
        sortable: true,
        grow: 1,
        cell: (row) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            {Array.isArray(row.sickness)
              ? row.sickness.join("\n")
              : row.sickness}
          </div>
        ),
      },
      {
        name: t("Sickness Child"),
        selector: (row) => row.sicknessChild,
        sortable: true,
        grow: 1,
        cell: (row) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "left",
            }}
          >
            {Array.isArray(row.sicknessChild)
              ? row.sicknessChild.join("\n")
              : row.sicknessChild}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  const handleSubmit = async () => {
    try {
      await saveSettings({ ...settings, lexofficeApiKey: apiKey });
      setIsEdit(false);
      toast.success("Successfully saved!", { autoClose: 2000 });
    } catch (error) {
      console.log(error.response.data);
      toast.error(error?.response?.data?.message || "Something went wrong!", {
        autoClose: 2000,
      });
    }
  };

  const handleCreateInvoice = (id) => {
    console.log("Create invoice for ID:", id);
    // Implement functionality to create a new invoice
  };

  const handleEdit = (id) => {
    console.log("Edit entry for ID:", id);
    // Implement functionality to edit an entry
  };

  const handleTransfer = (id) => {
    console.log("Transfer data for ID:", id);
    // Implement functionality to transfer data to LexOffice
  };

  const handleDelete = (id) => {
    console.log("Delete entry for ID:", id);
    // Implement functionality to delete an entry
  };

  const handleTestLexofficeConnection = async () => {
    try {
      const response = await checkLexofficeApiConnectionAPI({ apiKey });
      console.log(response);
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to connect to Lexoffice API!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  useEffect(() => {
    fetchSettingDetails();
    fetchLexofficePayroll();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        textAlign: "center",
      },
    },
  };
  const handleExportToExcel = () => {
    // Flatten the data to ensure nested arrays are properly handled
    const flattenedData = data.map((item) => ({
      ...item,
      payedHoliday: Array.isArray(item.payedHoliday)
        ? item.payedHoliday.join(", ")
        : item.payedHoliday,
      sickness: Array.isArray(item.sickness)
        ? item.sickness.join(", ")
        : item.sickness,
      sicknessChild: Array.isArray(item.sicknessChild)
        ? item.sicknessChild.join(", ")
        : item.sicknessChild,
    }));

    // Log the flattened data for debugging
    console.log(flattenedData);

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payroll Data");
    XLSX.writeFile(workbook, "PayrollData.xlsx");
  };

  const handleDownloadLogFile = () => {
    const logContent =
      "Sample log content with errors, success messages, and other details.";
    const blob = new Blob([logContent], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "logfile.txt";
    link.click();
  };

  const generateYearOptions = () => {
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
    return years;
  };

  const generateMonthOptions = () => {
    return [
      { value: "1", label: "January" },
      { value: "2", label: "February" },
      { value: "3", label: "March" },
      { value: "4", label: "April" },
      { value: "5", label: "May" },
      { value: "6", label: "June" },
      { value: "7", label: "July" },
      { value: "8", label: "August" },
      { value: "9", label: "September" },
      { value: "10", label: "October" },
      { value: "11", label: "November" },
      { value: "12", label: "December" },
    ];
  };
  const mainTitle = t("Lexoffice Payroll");
  return (
    <Fragment>
      <Breadcrumbs mainTitle={mainTitle} title={mainTitle} />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardBody>
              <H6>
                {t(
                  "Overview about all time trackings from Roster Rocket. Please select your month and load all datas."
                )}
                "
              </H6>
              <div className="d-flex align-items-center gap-5 mb-5 mt-5">
                <Btn
                  attrBtn={{
                    className: "me-2",
                    color: "primary",
                    onClick: handleDownloadLogFile,
                  }}
                >
                  {t("Show log files")}
                </Btn>
                <Btn
                  attrBtn={{
                    className: "me-2",
                    color: "primary",
                    onClick: handleExportToExcel,
                  }}
                >
                  {t("Export to Excel")}
                </Btn>
              </div>

              <div className="d-flex align-items-center gap-3">
                <Label for="yearSelect">{t("Year")}:</Label>
                <Input
                  type="select"
                  name="year"
                  id="yearSelect"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {generateYearOptions().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
                <Label for="monthSelect">{t("Month")}:</Label>
                <Input
                  type="select"
                  name="month"
                  id="monthSelect"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">Select Month</option>
                  {generateMonthOptions().map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Input>

                <Button
                  color="primary"
                  style={{ width: "400px", height: "30px" }}
                  onClick={fetchLexofficePayroll}
                >
                  {t("Set Month & Year")}
                </Button>
              </div>

              <div className={`custom-table${data.length ? "" : " no-data"}`}>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  striped
                  responsive
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
    </Fragment>
  );
};

export default LexOfficePayroll;
