import React, { useEffect, useState } from "react";
import _ from "lodash";
import Context from "./index";
import { getSettingDetailsAPI } from "../../Library/apis";

const initialValues = {
  allowedEnterOffTimes: false,
  activateFeatureTimeTracking: false,
  importNationalHolidays: false,
  activateLexoffice: false,
  shortNames: {
    holidayPayed: "",
    holidayUnpayed: "",
    illness: "",
    illnessChild: "",
    school: "",
    miscellaneous: "",
  },
  dropoutManagers: [],
  companyInfo: {
    companyName: "",
    firstName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    land: "",
    vat: "",
    telephone: "",
  },
};

const SettingProvider = (props) => {
  const [settingsInfo, setSettingsInfo] = useState(initialValues);
  const [login, setLogin] = useState(JSON.parse(localStorage.getItem("login")));
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem("token");

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));

    localStorage.setItem("authenticated", authenticated);
    localStorage.setItem("login", login);
    
  }, []);

  useEffect(() => {
    if (login || authenticated || jwt_token) {
      updateSettingsInfo();

    }
  }, []);

  const updateSettingsInfo = async () => {
    const response = await getSettingDetailsAPI();
    if (!_.isEqual(response, settingsInfo)) {
      setSettingsInfo(
        Object.keys(response).length === 0 ? initialValues : response
      );
    }
  };

  return (
    <Context.Provider
      value={{
        ...props,
        settingsInfo,
        setSettingsInfo,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default SettingProvider;
