import React, { Fragment,useEffect,useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from "../../../Library/constant";
import UserContext from "../../../_helper/User";
import { toast } from "react-toastify";

const AutomaticLogin = () => {

    const { token } = useParams();

    const navigate = useNavigate();
    const { setUserInfo } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            const payload ={"token":token};
          try {
            const response = await axios.put(`${API_URL}/auth/auto-login`,payload);
            const data = response.data; // Handle the response data
            data?.body?.userInfo && setUserInfo(data?.body?.userInfo);
            localStorage.setItem("authenticated", true);
            localStorage.setItem("login", true);
            localStorage.setItem("userInfo", JSON.stringify(data?.body?.userInfo));
            localStorage.setItem("token", data?.body?.token);
            navigate(`/dashboard`);
          } catch (error) {
            console.error('Error fetching data:', error);
            toast.error(error?.response?.data?.message, { autoClose: 2000 });
          }
        };
    
        fetchData();
      }, []);

    return null;
    
};

export default AutomaticLogin;