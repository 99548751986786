import React, { useContext, useState } from "react";
import SvgIcon from "../../../Components/Common/Component/SvgIcon";
import { CHECKALL, Notification } from "../../../Constant";
import UserContext from "../../../_helper/User";
import { getTimeDiff } from "../../../Library/custom_utils";
import { useNavigate } from "react-router";
import { makeReadNotificationAPI } from "../../../Library/apis";
import { toast } from "react-toastify";

const Notificationbar = () => {
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const { userNotifications, updateNotificationsInfo } =
    useContext(UserContext);
  const navigate = useNavigate();

  const updatedUserNotification = userNotifications.filter(
    (item) => item.isReaded === false
  );

  const handleMakeRead = (id) => {
    makeReadNotificationAPI({ id })
      .then(() => {
        updateNotificationsInfo();
      })
      .catch((e) => {
        toast.error("Error on make notifi as read", { autoClose: 5000 });
      });
  };

  const renderItem = (item, handleMakeRead) => {
    let status = "success";
    if (item.event.includes("Canceled")) status = "secondary";
    else if (item.event.includes("Deposited Fund")) status = "warning";
    else if (item.event.includes("Payment Verification")) status = "warning";
    else if (item.title == "") status = "primary";
    return (
      <div className="mb-2" onClick={() => handleMakeRead(item._id)}>
        <li className={`b-l-${status} border-4`}>
          <p>
            {item.title}{" "}
            <span className={`font-${status}`}>
              {getTimeDiff(item.createdAt)}
            </span>
          </p>
        </li>
      </div>
    );
  };

  const handleClickCheckAll = (event) => {
    event.preventDefault();
    navigate("/notifications");
  };

  return (
    <li className="onhover-dropdown">
      <div
        className="notification-box"
        onClick={() => setNotificationDropDown(!notificationDropDown)}
      >
        <SvgIcon iconId="notification" />
        <span className="badge rounded-pill badge-secondary">
          {updatedUserNotification?.length || ""}
        </span>
      </div>
      <div
        className={`notification-dropdown onhover-show-div ${
          notificationDropDown ? "active" : ""
        }`}
      >
        <h6 className="f-18 mb-0 dropdown-title">{Notification}</h6>
        <ul>
          {updatedUserNotification
            .slice(0, 5)
            .map((item) => renderItem(item, handleMakeRead))}
          <li>
            <a className="f-w-700" onClick={handleClickCheckAll}>
              {CHECKALL}
            </a>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Notificationbar;
