import React, { Fragment, useEffect, useState } from "react";
import { Col, Media, Label, Input, Collapse } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import { useTranslation } from 'react-i18next'
import {
  getHolidayIllnessCalendarListAPI,
  getAllEmployeesListAPI,
} from "../../../Library/apis";
import { H5, H6 } from "../../../AbstractElements";
import { HolidayIllnessStates, HolidayIllnessTypes } from "../../../Constant";


const DragCalendar = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState([]);
  const [expandFilterOptions, setExpandFilterOptions] = useState({});
  const [employeesOptions, setEmployeesOptions] = useState([]);

  const TypeOptions = [
    { value: "holiday-payed", label: t("Holiday Payed"), bgColor: "#54ba4a" },
    { value: "holiday-unpayed", label: t("Holiday Unpayed"), bgColor: "#3399ff" },
    { value: "illness", label: t("Illness"), bgColor: "#ff6666" },
    { value: "illness-child", label: t("Illness Child"), bgColor: "#9966cc" },
    { value: "school", label: t("School"), bgColor: "#99ccff" },
    { value: "miscellaneous", label: t("Miscellaneous"), bgColor: "#ffcc66" },
  ];
  
  // Define a mapping between event types and background colors
  const typeColorMapping = {
    "holiday-payed": "#54ba4a",
    "holiday-unpayed": "#3399ff",
    illness: "#ff6666",
    "illness-child": "#9966cc",
    school: "#99ccff",
    miscellaneous: "#ffcc66",
  };
  
  const StateOptions = [
    { value: "open", label: t("Open"), bgColor: "bg-info" },
    { value: "approved", label: t("Approved"), bgColor: "bg-success" },
    { value: "declined", label: t("Declined"), bgColor: "bg-danger" },
  ];
  const fetchCalendarData = async () => {
    try {
      const response = await getHolidayIllnessCalendarListAPI({
        types: filterTypes,
        states: filterStates,
        employees: filterEmployees,
      });
      const eventData = response.map((event) => {
        const backgroundColor = typeColorMapping[event.type] || "#999999"; // Default color if type not found

        return {
          title: `${event.fullName}`,
          start: `${event.startTime}`, // Combine date and start time
          end: `${event.endTime}`, // Combine date and end time
          backgroundColor,
          borderColor: backgroundColor,
          extendedProps: {
            description: event.description, // Optional: Add additional properties
            confirmedByManager: event.confirmedByManager,
            state: event.state,
            type: event.type,
          },
        };
      });
      setCalendarEvents(eventData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const employees = await getAllEmployeesListAPI();
      setEmployeesOptions(
        employees?.map((item) => ({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleFilterTypesChange = (value) => {
    // Check if the value is already in the array
    if (filterTypes.includes(value)) {
      // If it is, remove it
      setFilterTypes((prevTypes) => prevTypes.filter((type) => type !== value));
    } else {
      // If it's not, add it
      setFilterTypes((prevTypes) => [...prevTypes, value]);
    }
  };

  const handleFilterStatesChange = (value) => {
    // Check if the value is already in the array
    if (filterStates.includes(value)) {
      // If it is, remove it
      setFilterStates((prevTypes) =>
        prevTypes.filter((type) => type !== value)
      );
    } else {
      // If it's not, add it
      setFilterStates((prevTypes) => [...prevTypes, value]);
    }
  };

  const handleFilterEmployeesChange = (value) => {
    // Check if the value is already in the array
    if (filterEmployees.includes(value)) {
      // If it is, remove it
      setFilterEmployees((prevTypes) =>
        prevTypes.filter((type) => type !== value)
      );
    } else {
      // If it's not, add it
      setFilterEmployees((prevTypes) => [...prevTypes, value]);
    }
  };

  const handleExpandFilterOptionsChange = (value) => {
    setExpandFilterOptions((prev) => ({
      ...prev,
      [value]: !expandFilterOptions[value],
    }));
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    fetchCalendarData();
  }, [filterTypes, filterStates, filterEmployees]);

  const eventClick = (eventClick) => {
    Swal.fire({
      title: eventClick.event.title,
      html: `
      <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td>Employee</td>
                        <td><strong>${eventClick.event.title}</strong></td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td><strong>${moment(eventClick.event.start).format(
                          "YYYY-MM-DD HH:mm"
                        )}</strong></td>
                    </tr>
                    <tr>
                        <td>End Time</td>
                        <td><strong>${moment(eventClick.event.end).format(
                          "YYYY-MM-DD HH:mm"
                        )}</strong></td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td><strong>${
                          eventClick.event.extendedProps?.description
                        }</strong></td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td><strong>${
                          HolidayIllnessTypes[
                            eventClick.event.extendedProps.type
                          ]
                        }</strong></td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td><strong>${
                          HolidayIllnessStates[
                            eventClick.event.extendedProps.state
                          ]
                        }</strong></td>
                    </tr>
                    <tr>
                        <td>Confirmed by manager</td>
                        <td><strong>${
                          eventClick.event.extendedProps.confirmedByManager
                            ? "Yes"
                            : "No"
                        }</strong></td>
                    </tr>
                </tbody>
            </table>
      </div>            
        `,
      showCancelButton: false,
      confirmButtonColor: "#d33",
      confirmButtonText: "Close",
    }).then((result) => {
      if (result.value) {
        // eventClick.event.remove();
        // Swal.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };

  const eventContent = (event) => {
    return (
      <div>{`${event.event.title} - ${
        HolidayIllnessTypes[event.event.extendedProps.type]
      }`}</div>
    );
  };

  return (
    <Fragment>
      <Col xxl="3" className="box-col-12">
        <div className="md-sidebar">
          <div className={`md-sidebar-aside job-left-aside custom-scrollbar`}>
            <div className="email-sidebar">
              <div className="p-2">
                <H5 attrH5={{ className: "mb-4" }}>
                  <i className="fa fa-filter"></i> Filter
                </H5>
                <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <H6>
                      <i
                        className="fa fa-briefcase me-2"
                        style={{ width: 18 }}
                      ></i>{" "}
                      {t("Types")}
                    </H6>
                    <i
                      className={`fa fa-lg ${
                        !expandFilterOptions["filterTypes"]
                          ? "fa-angle-down"
                          : "fa-angle-right"
                      } cursor-pointer`}
                      onClick={() =>
                        handleExpandFilterOptionsChange("filterTypes")
                      }
                    ></i>
                  </div>
                  <Collapse isOpen={!expandFilterOptions["filterTypes"]}>
                    <div className="ms-2">
                      {TypeOptions.map((option) => (
                        <Media key={option.value}>
                          <div className="switch-sm">
                            <Label className="switch-sm switch">
                              <Input
                                type="checkbox"
                                checked={filterTypes.includes(option.value)}
                                onChange={() =>
                                  handleFilterTypesChange(option.value)
                                }
                              />
                              <span className="switch-state"></span>
                            </Label>
                          </div>
                          <Media body className="d-flex align-items-center">
                            <div
                              className="m-l-10  m-r-5 rounded-circle"
                              style={{
                                width: 10,
                                height: 10,
                                background: option.bgColor,
                              }}
                            ></div>
                            <Label className="col-form-label">
                              {option.label}
                            </Label>
                          </Media>
                        </Media>
                      ))}
                    </div>
                  </Collapse>
                </div>

                <div className="mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <H6>
                      <i
                        className="fa fa-check-circle me-2"
                        style={{ width: 18 }}
                      ></i>{" "}
                      {t("States")}
                    </H6>
                    <i
                      className={`fa fa-lg ${
                        !expandFilterOptions["filterStates"]
                          ? "fa-angle-down"
                          : "fa-angle-right"
                      } cursor-pointer`}
                      onClick={() =>
                        handleExpandFilterOptionsChange("filterStates")
                      }
                    ></i>
                  </div>
                  <Collapse isOpen={!expandFilterOptions["filterStates"]}>
                    <div className="ms-2">
                      {StateOptions.map((option) => (
                        <Media>
                          <div className="switch-sm">
                            <Label className="switch-sm switch">
                              <Input
                                type="checkbox"
                                checked={filterStates.includes(option.value)}
                                onChange={() =>
                                  handleFilterStatesChange(option.value)
                                }
                              />
                              <span className="switch-state"></span>
                            </Label>
                          </div>
                          <Media body className="d-flex align-items-center">
                            <div
                              className={`m-l-10  m-r-5 rounded-circle ${option.bgColor}`}
                              style={{
                                width: 10,
                                height: 10,
                              }}
                            ></div>
                            <Label className="col-form-label">
                              {option.label}
                            </Label>
                          </Media>
                        </Media>
                      ))}
                    </div>
                  </Collapse>
                </div>

                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <H6>
                      <i className="fa fa-users me-2" style={{ width: 18 }}></i>{" "}
                      {t("Employees")}
                    </H6>
                    <i
                      className={`fa fa-lg ${
                        !expandFilterOptions["filterEmployees"]
                          ? "fa-angle-down"
                          : "fa-angle-right"
                      } cursor-pointer`}
                      onClick={() =>
                        handleExpandFilterOptionsChange("filterEmployees")
                      }
                    ></i>
                  </div>
                  <Collapse isOpen={!expandFilterOptions["filterEmployees"]}>
                    <div className="ms-2">
                      {employeesOptions.map((option) => (
                        <Media>
                          <div className="switch-sm">
                            <Label className="switch-sm switch">
                              <Input
                                type="checkbox"
                                checked={filterEmployees.includes(option.value)}
                                onChange={() =>
                                  handleFilterEmployeesChange(option.value)
                                }
                              />
                              <span className="switch-state"></span>
                            </Label>
                          </div>
                          <Media body className="d-flex align-items-center">
                            <Label className="col-form-label m-l-10">
                              {option.label}
                            </Label>
                          </Media>
                        </Media>
                      ))}
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col xxl="9" className="box-col-12">
        <div className="demo-app-calendar" id="mycalendartest">
          <FullCalendar
            defaultView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            buttonText={{
              today: t('today'),
              month: t('month'),
              week: t('week'),
              day: t('day'),
            }}

            rerenderDelay={10}
            eventDurationEditable={false}
            editable={false}
            droppable={false}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            events={calendarEvents}
            eventClick={eventClick}
            eventDisplay="block"
            eventContent={eventContent}
          />
        </div>
      </Col>
    </Fragment>
  );
};
export default DragCalendar;
