import React, { Fragment } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";

import GreetingCard from "./GreetingCard";
import WidgetsWrapper from "./WidgetsWraper";
import TodoContain from "./Todo";
import BirthdayCard from "./BirthdayCard";
import { useTranslation } from "react-i18next";
const Dashboard = () => {
  const { t } = useTranslation();
  let dashboard = t("Dashboard");
  return (
    <Fragment>
      <Breadcrumbs mainTitle={dashboard} parent="Dashboard" title="Dashboard" />
      <Container fluid={true}>
        <Row className="widget-grid">
          <BirthdayCard />
          {/* <GreetingCard /> */}
          <WidgetsWrapper />
          {/* <RecentOrders /> */}
          <TodoContain />

          {/* <ActivityCard />
          <RecentSales />
          <TimelineCard />
          <PreAccountCard />
          <TotalUserAndFollower />
          <PaperNote /> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Dashboard;
