import React from "react";
import {
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  Modal,
  Spinner,
} from "reactstrap";

import { Btn } from "../../../../AbstractElements";
import { useTranslation } from 'react-i18next'
const FormModal = ({ skillModal, skillToggle, formik }) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
  } = formik;
  const { t } = useTranslation();
  return (
    <Modal isOpen={skillModal} toggle={skillToggle}>
      <ModalHeader toggle={skillToggle}>{t("Add skill")}</ModalHeader>
      <ModalBody>
        <Form className="form-bookmark" onSubmit={handleSubmit}>
          <div className="form-row">
            <FormGroup className="mb-3 col-md-12 my-0">
              <Input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                invalid={errors.name && touched.name}
              />
              {errors.name && touched.name && (
                <span className="mt-2 text-danger">{errors.name}</span>
              )}
            </FormGroup>
          </div>
          <Btn
            attrBtn={{
              color: "success",
              disabled: isSubmitting,
              type: "submit",
            }}
          >
            {isSubmitting && (
              <Spinner size="sm" color="light" className="me-1" />
            )}
            {t("Save")}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              className: "ms-2",
              onClick: skillToggle,
              disabled: isSubmitting,
            }}
          >
            {t("Cancel")}
          </Btn>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default FormModal;
