import React, { Fragment, useState } from "react";
import { CardGroup, CardImg, Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from 'react-bootstrap';
import { Btn, H4, P } from "../../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import { signUpAPI } from "../../../../Library/apis";
import { toast } from "react-toastify";
import paymentcard from "./paymentcard";
const RegisterAccount = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    // middleName: '',
    lastName: "",
    email: "",
    // phoneNumber: '',
    password: "",
    confirmPassword: "",
    // bankVerificationNumber: '',
    // referrerInfo: '',
    agreeToPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      setLoading(true);
      const data = await signUpAPI(formData);
      console.log("SignUp Result: ", data);
      toast.success(`Sent Verificaton code.`, { autoClose: 2000 });

      navigate("/auth/code-verification", {
        state: { userInfo: formData.email },
      });
    } catch (error) {
      console.log(JSON.stringify(error?.response?.data));
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    navigate("/home");
  };

  return (
    <div className="login-card">

A
    <CardGroup style={{ width: '1000px', height: '400px', margin : 'auto'}}>
    <Card>
      <CardImg
        alt="Card image cap"
        src="https://picsum.photos/318/180"
        top
        width="100%"
      />
      <CardBody>
        <CardTitle tag="h5">
          Paymentplan 1
        </CardTitle>
        <CardSubtitle
          className="mb-2 text-muted"
          tag="h6"
        >Employee 1-2
        </CardSubtitle>
        <CardText>
          free
        </CardText>
        <Button>
          SignUp
        </Button>
      </CardBody>
    </Card>
    <Card>
      <CardImg
        alt="Card image cap"
        src="https://picsum.photos/318/180"
        top
        width="100%"
      />
      <CardBody>
        <CardTitle tag="h5">
          Paymentplan 2
        </CardTitle>
        <CardSubtitle
          className="mb-2 text-muted"
          tag="h6"
        >Employee 1-2
        </CardSubtitle>
        <CardText>
          30$
        </CardText>
        <Button>
          SignUp
        </Button>
      </CardBody>
    </Card>
    <Card>
      <CardImg
        alt="Card image cap"
        src="https://picsum.photos/318/180"
        top
        width="100%"
      />
      <CardBody>
        <CardTitle tag="h5">
          Paymentplan 3
        </CardTitle>
        <CardSubtitle
          className="mb-2 text-muted"
          tag="h6"
        >Employee 10-30
        </CardSubtitle>
        <CardText>
          60$
        </CardText>
        <Button>
          SignUp
        </Button>
      </CardBody>
    </Card>
    <Card>
      <CardImg
        alt="Card image cap"
        src="https://picsum.photos/318/180"
        top
        width="100%"
      />
      <CardBody>
        <CardTitle tag="h5">
          Paymentplan 4
        </CardTitle>
        <CardSubtitle
          className="mb-2 text-muted"
          tag="h6"
        >Employee 30-50
        </CardSubtitle>
        <CardText>
          100$
        </CardText>
        <Button>
          SignUp
        </Button>
      </CardBody>
    </Card>
  </CardGroup>
  </div>
  );
};

export default RegisterAccount;
