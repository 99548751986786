import React, { useContext, useState } from "react";
import SvgIcon from "../../../Components/Common/Component/SvgIcon";
import CustomizerContext from "../../../_helper/Customizer";
import { useEffect } from "react";

const MoonLight = () => {
  const { addMixBackgroundLayout } = useContext(CustomizerContext);
  const [moonlight, setMoonlight] = useState(false);

  const MoonlightToggle = (light) => {
    if (light) {
      addMixBackgroundLayout("light-only");
      document.body.className = "light-only";
      setMoonlight(!light);
    } else {
      addMixBackgroundLayout("dark-only");
      document.body.className = "dark-only";
      setMoonlight(!light);
    }
  };

  useEffect(() => {
    const mixBackgroundLayout = localStorage.getItem("mix_background_layout");
    if (mixBackgroundLayout) {
      addMixBackgroundLayout(mixBackgroundLayout);
      document.body.className = mixBackgroundLayout;
      setMoonlight(mixBackgroundLayout === "light-only" ? false : true);
    }
  }, []);

  return (
    <li>
      <div
        className={`mode ${moonlight && "active"}`}
        onClick={() => MoonlightToggle(moonlight)}
      >
        <SvgIcon iconId={"moon"} />
      </div>
    </li>
  );
};

export default MoonLight;
