import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"; // Import Modal components
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import {
  Container,
  Col,
  Card,
  CardBody,
  Row,
  Label,
  Input,
  Media,
  FormGroup,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import {
  Breadcrumbs,
  H5,
  H6,
  Btn,
  UL,
  LI,
  H1,
  H4,
} from "../../../AbstractElements";
import {
  getSettingDetailsAPI,
  saveSettings,
  checkLexofficeApiConnectionAPI,
  getAllWorkingAreaLexofficeAPI,
  createInvoicePDFAPI,
  transferDataToLexofficeAPI,
} from "../../../Library/apis";
import Avatar from "react-avatar";
import DataTable from "react-data-table-component";
import useDebounce from "../../../Hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
const generateFakeData = (numRows, t) => {
  const data = [];
  const paymentMethods = ["Cash", "Card", "Online", "Check"];
  const states = ["Pending", "Completed", "Cancelled"];
  const workflows = ["Initiated", "InProgress", "Completed", "Failed"];
  const errors = ["None", "Payment Declined", "Expired Card", "Other"];

  for (let i = 0; i < numRows; i++) {
    data.push({
      id: i + 1,
      paymentMethod:
        paymentMethods[Math.floor(Math.random() * paymentMethods.length)],
      invoiceNr: `INV-${1000 + i}`,
      state: states[Math.floor(Math.random() * states.length)],
      workingArea: `Area ${Math.floor(Math.random() * 5) + 1}`,
      tax: `${Math.floor(Math.random() * 30)}%`,
      dateCreate: `${String(Math.floor(Math.random() * 28) + 1).padStart(
        2,
        "0"
      )}.${String(Math.floor(Math.random() * 12) + 1).padStart(2, "0")}.2023`,
      year: 2023,
      month: String(Math.floor(Math.random() * 12) + 1).padStart(2, "0"),
      sum: Math.floor(Math.random() * 500),
      hours: Math.floor(Math.random() * 40 + 1),
      workflowState: workflows[Math.floor(Math.random() * workflows.length)],
      error: errors[Math.floor(Math.random() * errors.length)],
      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            overflow: "visible", // Ensure that overflow is visible
          }}
        >
          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "success",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Edit"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-pencil"></i>
          </Btn>

          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "warning",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Transfer data to lexoffice"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-building-o"></i>
          </Btn>
        </div>
      ),
    });
  }
  return data;
};

const LexOfficeInvoice = () => {
  const [invoiceData, setInvoiceData] = useState({
    archived: false,
    voucherDate: "2023-02-22T00:00:00.000+01:00",
    address: {
      name: "Bike & Ride GmbH & Co. KG",
      supplement: "Gebäude 10",
      street: "Musterstraße 42",
      city: "Freiburg",
      zip: "79112",
      countryCode: "DE",
    },
    lineItems: [
      {
        type: "custom",
        name: "Energieriegel Testpaket",
        quantity: 1,
        unitName: "Stück",
        unitPrice: {
          currency: "EUR",
          netAmount: 5,
          taxRatePercentage: 0,
        },
        discountPercentage: 0,
      },
      {
        type: "text",
        name: "Strukturieren Sie Ihre Belege durch Text-Elemente.",
        description: "Das hilft beim Verständnis",
      },
    ],
    totalPrice: {
      currency: "EUR",
    },
    taxConditions: {
      taxType: "net",
    },
    paymentConditions: {
      paymentTermLabel: "10 Tage - 3 %, 30 Tage netto",
      paymentTermDuration: 30,
      paymentDiscountConditions: {
        discountPercentage: 3,
        discountRange: 10,
      },
    },
    shippingConditions: {
      shippingDate: "2023-04-22T00:00:00.000+02:00",
      shippingType: "delivery",
    },
    title: "Rechnung",
    introduction:
      "Ihre bestellten Positionen stellen wir Ihnen hiermit in Rechnung",
    remark: "Vielen Dank für Ihren Einkauf",
  });
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lexofficeIntroduction, setLexofficeIntroduction] = useState("");
  const [lexofficeFooter, setLexofficeFooter] = useState("");
  const [settings, setSettings] = useState({});
  const [apiKey, setApiKey] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [showOverview, setShowOverview] = useState(false); // State for overview card visibility
  const { t, i18n } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth.toString());
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [filteredData, setFilteredData] = useState([]);
  // Add these state variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lexofficeConnectionDetails, setLexofficeConnectionDetails] =
    useState(null);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const fetchSettingDetails = async () => {
    try {
      const response = await getSettingDetailsAPI();

      setSettings(response);

      setApiKey(response?.lexofficeApiKey);
      setLexofficeIntroduction(response?.lexofficeIntroduction || "");
      setLexofficeFooter(response?.lexofficeFooter || "");
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const fetchWorkingAreas = async () => {
    setLoading(true);
    try {
      const response = await getAllWorkingAreaLexofficeAPI({
        selectedmonth: selectedMonth,
        selectedyear: selectedYear,
      });

      const filterResponse = (response) =>
        response.filter((item) => parseFloat(item.sum) > 0);

      const filteredData = filterResponse(response);

      setData(filteredData); // Set the filtered data to state
      const filteredData_1 = filteredData.filter(
        (item) =>
          item.year === parseInt(selectedYear) &&
          item.month === parseInt(selectedMonth)
      );

      setFilteredData(filteredData_1);
      setLoading(false);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
      setLoading(false);
    }
  };
  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        textAlign: "center",
      },
    },
  };

  const columns = useMemo(
    () => [
      {
        name: t("ID"),
        selector: (row) => row.id,
        sortable: true,
        width: "50px",
        style: {
          fontWeight: "bold",
        },
      },
      {
        name: t("Payment Method"),
        selector: (row) => t(row.paymentMethod),
        sortable: true,
        width: "140px",
        grow: 2,
      },
      {
        name: t("Invoice Nr"),
        selector: (row) => row.invoiceNr,
        sortable: true,
        width: "140px",
        grow: 1,
      },
      {
        name: t("State"),
        selector: (row) => row.state,
        sortable: true,
        width: "100px",
        grow: 1,
      },
      {
        name: t("Working Area"),
        selector: (row) => row.workingArea,
        sortable: true,
        width: "150px",
        grow: 1,
      },
      {
        name: t("Tax"),
        selector: (row) => row.tax,
        sortable: true,
        width: "70px",
        grow: 1,
      },
      {
        name: t("Date Create"),
        selector: (row) => row.dateCreate,
        sortable: true,
        width: "100px",
        grow: 1,
      },
      {
        name: t("Year"),
        selector: (row) => row.year,
        sortable: true,
        width: "70px",
        grow: 1,
      },
      {
        name: t("Month"),
        selector: (row) => row.month,
        sortable: true,
        width: "70px",
        grow: 1,
      },
      {
        name: t("Hours"),
        selector: (row) => row.hours,
        sortable: true,
        width: "50px",
        grow: 1,
      },
      {
        name: t("Sum"),
        selector: (row) => row.sum,
        sortable: true,
        grow: 1,
        width: "100px",
        format: (row) => `${row.sum}€`,
      },
      {
        name: t("Workflow State"),
        selector: (row) => row.workflowState,
        sortable: true,
        width: "140px",
        grow: 1,
      },
      {
        name: t("Error"),
        selector: (row) => row.error,
        sortable: true,
        width: "170px",
        grow: 1,
      },
      {
        name: t("Actions"),
        cell: (row) => (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <Btn
              attrBtn={{
                className: "me-2 px-2 py-1",
                color: "success",
                size: "sm",
                "data-tooltip-id": "my-tooltip",
                "data-tooltip-content": t("Edit"),
                "data-tooltip-place": "top",
                onClick: () => handleEdit(row.id),
              }}
            >
              <i className="fa fa-pencil"></i>
            </Btn>
            <Btn
              attrBtn={{
                className: "me-2 px-2 py-1",
                color: "warning",
                size: "sm",
                "data-tooltip-id": "my-tooltip",
                "data-tooltip-content": t("Transfer data to lexoffice"),
                "data-tooltip-place": "top",
                onClick: () => handleTransfer(row.id),
              }}
            >
              <i className="fa fa-building-o"></i>
            </Btn>
            <Btn
              attrBtn={{
                className: "me-2 px-2 py-1",
                color: "success",
                size: "sm",
                "data-tooltip-id": "my-tooltip",
                "data-tooltip-content": t("Invoice Data View"),
                "data-tooltip-place": "top",
                onClick: () => handleInvoiceDataView(row.id),
              }}
            >
              <i className="fa fa-eye"></i>
            </Btn>
            <Btn
              attrBtn={{
                className: "me-2 px-2 py-1",
                color: "primary",
                size: "sm",
                "data-tooltip-id": "my-tooltip",
                "data-tooltip-content": t("Download Invoice as PDF"),
                "data-tooltip-place": "top",
                onClick: () => handleInvoicePDFDownload(row.id),
              }}
            >
              <i className="fa fa-paperclip"></i>
            </Btn>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ],
    [filteredData, i18n.language]
  );
  const handleSubmit = async () => {
    try {
      await saveSettings({
        ...settings,
        lexofficeApiKey: apiKey,
        lexofficeIntroduction: lexofficeIntroduction,
        lexofficeFooter: lexofficeFooter,
      });
      setIsEdit(false);
      toast.success(t("Successfully saved!"), {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error.response.data);
      toast.error(
        error?.response?.data?.message || t("Something went wrong!"),
        {
          autoClose: 2000,
        }
      );
    }
  };
  const handleCreateInvoice = (id) => {
    // Implement functionality to create a new invoice
  };
  const [modalIsOpen_edit, setModalIsOpen_edit] = useState(false);
  const [workingAreaName, setWorkingAreaName] = useState("");
  const handleEdit = (id) => {
    const invoice = filteredData.find((item) => item.id === id);
    if (invoice) {
      setWorkingAreaName(invoice.workingArea);
      setModalIsOpen_edit(true);
    }
    // Implement functionality to edit an entry
  };
  const handleSetMonthYear = () => {
    const filteredData = data.filter(
      (item) =>
        item.year === parseInt(selectedYear) &&
        item.month === parseInt(selectedMonth)
    );

    setFilteredData(filteredData);
  };
  const handleInvoiceDataView = async (id) => {
    const invoice = filteredData.find((item) => item.id === id);

    if (invoice) {
      const totalSum = invoice.subitems.reduce(
        (acc, subitem) => Number(acc) + Number(subitem.sum),
        0
      );
      setSelectedInvoice({ ...invoice, totalSum });

      setModalIsOpen(true);
    } else {
      // Handle the case where the invoice is not found or hours are not greater than 0
      console.log("Invoice not found or hours are not greater than 0");
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedInvoice(null);
  };
  const closeModal_edit = () => {
    setModalIsOpen_edit(false);
    setSelectedInvoice(null);
  };
  const handleInvoicePDFDownload = async (id) => {
    if (id === null || !filteredData || !filteredData[id - 1]) {
      toast.error(t("Invalid data or ID"));
      return;
    }

    const selectedData = filteredData[id - 1];

    const invoiceSendData = {
      selectedDate: `${t(
        months[Number(selectedMonth) - 1].label
      )} ${selectedYear}`,
      workingAreaContact: {
        name: selectedData.workingArea,
        description: selectedData.description,
        street: selectedData.street,
        zipCode: selectedData.zipCode,
        city: selectedData.city,
      },
      invoiceNr: selectedData.invoiceNr,
      lexofficeTax: selectedData.tax,
      lexofficeIntroduction: lexofficeIntroduction,
      lexofficeFooter: lexofficeFooter,
      lexofficeTableContent: selectedData.subitems.map((subitem) => ({
        articleName: subitem.articleName,
        description: subitem.description,
        amount: subitem.hours,
        price: subitem.price,
      })),
    };

    try {
      const response = await createInvoicePDFAPI({
        invoiceSendData: invoiceSendData,
        apiKey: apiKey,
      });

      const blob = response;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "invoice.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(`Error creating invoice: ${error.message}`);
    }
    // Implement functionality to transfer data to LexOffice
  };
  const handelTransferAllData = async () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to transfer invoices");
      return;
    }

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    for (const selectedData of filteredData) {
      if (!selectedData) {
        toast.error("Invalid data");
        continue;
      }
      if (selectedData.error !== "None") {
        continue;
      }
      if (selectedData.state === "Success") {
        continue;
      }
      const invoiceSendData = {
        selectedDate: `${t(
          months[Number(selectedMonth) - 1].label
        )} ${selectedYear}`,
        workingAreaContact: {
          name: selectedData.workingArea,
          description: selectedData.description,
          street: selectedData.street,
          zipCode: selectedData.zipCode,
          city: selectedData.city,
        },
        invoiceNr: selectedData.invoiceNr,
        lexofficeTax: selectedData.tax,
        lexofficeIntroduction: lexofficeIntroduction,
        lexofficeFooter: lexofficeFooter,
        lexofficePaymentType: t(selectedData.paymentMethod),
        lexofficeID: selectedData.lexofficeID,
        lexofficeTableContent: selectedData.subitems.map((subitem) => ({
          articleName: subitem.articleName,
          description: subitem.description,
          amount: subitem.hours,
          price: subitem.price,
        })),
      };

      try {
        const response = await transferDataToLexofficeAPI({
          invoiceSendData: invoiceSendData,
          apiKey: apiKey,
        });

        // Update the state immutably
        setFilteredData((prevData) =>
          prevData.map((item) =>
            item.id === selectedData.id ? { ...item, state: "Success" } : item
          )
        );

        toast.success(`Transfer data to lexoffice successfully!`);
      } catch (error) {
        // Update the state immutably
        setFilteredData((prevData) =>
          prevData.map((item) =>
            item.id === selectedData.id ? { ...item, state: "Failed" } : item
          )
        );

        toast.error(`Error transferring invoice: ${error.message}`);
      }

      // Wait for 30 seconds before the next iteration
      await delay(30000);
    }
  };

  const handleTransfer = async (id) => {
    if (id === null || !filteredData || !filteredData[id - 1]) {
      toast.error(t("Invalid data or ID"));
      return;
    }

    const selectedData = filteredData[id - 1];
    if (selectedData.error !== "None") {
      toast.error(
        t(
          "Cannot transfer invoice due to an error: Pleaes add this Working Area to your Lexoffice Account"
        )
      );
      return;
    }
    if (selectedData.state === "Success") {
      toast.error(t("Invoice is already sent to Lexoffice account!"));
      return;
    }
    const invoiceSendData = {
      selectedDate: `${t(
        months[Number(selectedMonth) - 1].label
      )} ${selectedYear}`,
      workingAreaContact: {
        name: selectedData.workingArea,
        description: selectedData.description,
        street: selectedData.street,
        zipCode: selectedData.zipCode,
        city: selectedData.city,
      },
      invoiceNr: selectedData.invoiceNr,
      lexofficeTax: selectedData.tax,
      lexofficeIntroduction: lexofficeIntroduction,
      lexofficeFooter: lexofficeFooter,
      lexofficePaymentType: t(selectedData.paymentMethod),
      lexofficeID: selectedData.lexofficeID,
      lexofficeTableContent: selectedData.subitems.map((subitem) => ({
        articleName: subitem.articleName,
        description: subitem.description,
        amount: subitem.hours,
        price: subitem.price,
      })),
    };

    try {
      const response = await transferDataToLexofficeAPI({
        invoiceSendData: invoiceSendData,
        apiKey: apiKey,
      });
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, state: "Success" } : item
        )
      );
      toast.success(`Transfer data to lexoffice Successfuly!`);
    } catch (error) {
      setFilteredData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, state: "Failed" } : item
        )
      );
      toast.error(`Error transfering invoice: ${error.message}`);
    }
  };

  const handleDelete = (id) => {
    console.log("Delete entry for ID:", id);
    // Implement functionality to delete an entry
  };
  const handleTestLexofficeConnection = async () => {
    try {
      const response = await checkLexofficeApiConnectionAPI({ apiKey: apiKey });
      setLexofficeConnectionDetails(response);
      toggleModal(); // Open the modal
      toast.success("Connection successful!");
    } catch (error) {
      const message =
        error?.response?.data?.message || "Failed to connect to Lexoffice API!";
      toast.error(message, { autoClose: 2000 });
    }
  };
  const createInvoiceAllPDFDownload = async () => {
    if (!filteredData || filteredData.length === 0) {
      toast.error("No data available to create invoices");
      return;
    }

    for (const selectedData of filteredData) {
      if (!selectedData) {
        toast.error("Invalid data");
        continue;
      }

      const invoiceSendData = {
        selectedDate: `${t(
          months[Number(selectedMonth) - 1].label
        )} ${selectedYear}`,
        workingAreaContact: {
          name: selectedData.workingArea,
          description: selectedData.description,
          street: selectedData.street,
          zipCode: selectedData.zipCode,
          city: selectedData.city,
        },
        invoiceNr: selectedData.invoiceNr,
        lexofficeTax: selectedData.tax,
        lexofficeIntroduction: lexofficeIntroduction,
        lexofficeFooter: lexofficeFooter,
        lexofficeTableContent: selectedData.subitems.map((subitem) => ({
          articleName: subitem.articleName,
          description: subitem.description,
          amount: subitem.hours,
          price: subitem.price,
        })),
      };

      try {
        const response = await createInvoicePDFAPI({
          invoiceSendData: invoiceSendData,
          apiKey: apiKey,
        });

        const blob = response;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `invoice_${selectedData.invoiceNr}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        toast.error(
          `Error creating invoice ${selectedData.invoiceNr}: ${error.message}`
        );
      }
    }
  };

  const handleInvoiceIntroductionSave = async () => {
    try {
      await saveSettings({
        lexofficeIntroduction: lexofficeIntroduction,
      });
      setIsEdit(false);
      toast.success("Successfully saved!", {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error.response.data);
      toast.error(error?.response?.data?.message || "Something went wrong!", {
        autoClose: 2000,
      });
    }
  };
  const handleInvoiceFooterSave = async () => {
    try {
      await saveSettings({
        lexofficeFooter: lexofficeFooter,
      });
      setIsEdit(false);
      toast.success("Successfully saved!", {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error.response.data);
      toast.error(error?.response?.data?.message || "Something went wrong!", {
        autoClose: 2000,
      });
    }
  };
  const calculateOverviewData = () => {
    const amountInvoices = data.length;
    const amountHours = data.reduce((acc, cur) => acc + Number(cur.hours), 0);
    const errors = data.filter((item) => item.error !== "None").length;
    return { amountInvoices, amountHours, errors };
  };
  const { amountInvoices, amountHours, errors } = calculateOverviewData();
  useEffect(() => {
    fetchSettingDetails();
    fetchWorkingAreas();
  }, [selectedMonth, selectedYear]);

  const handleExportToExcel = () => {
    // Define a new workbook and a new worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Working Areas");

    // Generate Excel file and trigger a download
    XLSX.writeFile(wb, "working_areas.xlsx");
  };
  const mainTitle = t("Lexoffice Invoice");
  return (
    <Fragment>
      <Breadcrumbs mainTitle={mainTitle} title={mainTitle} />
      <Container fluid={true}>
        <Col sm="12">
          <Card>
            <CardBody>
              {/* <div className="my-5 text-center">
                <H1>The last successfully data transfer was March 2024</H1>
              </div> */}

              <div className="d-flex align-items-center mb-4">
                <H5 attrH5={{ className: "mb-0" }}>{t("API Key")}</H5>
                <Input
                  type="text"
                  name="apiKey"
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  className="ms-5 w-25"
                  disabled={!isEdit}
                />
                <div className="ms-3">
                  <Btn
                    attrBtn={{
                      className: "me-2",
                      color: "success",
                      onClick: handleSubmit,
                    }}
                  >
                    {t("Save")}
                  </Btn>
                  <Btn
                    attrBtn={{
                      className: "me-2",
                      color: "info",
                      onClick: handleTestLexofficeConnection,
                    }}
                  >
                    {t("Test")}
                  </Btn>
                  <Btn
                    attrBtn={{
                      className: "me-2",
                      color: "primary",
                      onClick: () => setIsEdit(!isEdit),
                    }}
                  >
                    {t("Edit")}
                  </Btn>
                </div>
              </div>
              {/* 
              <div className="mb-5 fs-6">
                <H6>
                  Please write in details what is the problem? Our support will
                  answer you within 48 hours.
                </H6>
              </div> */}
              <div className=" d-flex align-items-center gap-5 mb-5">
                <div style={{ width: "100%" }}>
                  <H6>{t("Invoice text introduction")}</H6>
                  <Input
                    className="form-control digits mb-3"
                    type="textarea"
                    rows="3"
                    name="address"
                    value={lexofficeIntroduction}
                    onChange={(e) => setLexofficeIntroduction(e.target.value)}
                    disabled={!isEdit}
                  />
                  <Btn
                    attrBtn={{
                      className: "me-2",
                      color: "success",
                      onClick: handleInvoiceIntroductionSave,
                    }}
                  >
                    {t("Save")}
                  </Btn>
                </div>
                <div style={{ width: "100%" }}>
                  <H6>{t("Invoice text footer")}</H6>
                  <Input
                    className="form-control digits mb-3"
                    type="textarea"
                    rows="3"
                    name="address"
                    value={lexofficeFooter}
                    onChange={(e) => setLexofficeFooter(e.target.value)}
                    disabled={!isEdit}
                    style={{ width: "100%" }}
                  />
                  <Btn
                    attrBtn={{
                      className: "me-2",
                      color: "success",
                      onClick: handleInvoiceFooterSave,
                    }}
                  >
                    {t("Save")}
                  </Btn>
                </div>
                {!showOverview && (
                  <Card className="profile-box mt-2 text-center bg-success">
                    <CardBody>
                      <H5>{t("Overview")}</H5>
                      <UL>
                        <LI>
                          <H6>
                            {t("Amount Invoices")}: {amountInvoices}
                          </H6>
                        </LI>
                        <LI>
                          <H6>
                            {t("Amount Hours")}: {amountHours.toFixed(2)}
                          </H6>
                        </LI>
                        <LI>
                          <H6>
                            {t("Errors")}: {errors}
                          </H6>
                        </LI>
                      </UL>
                    </CardBody>
                  </Card>
                )}
              </div>

              <H6>
                {t(
                  "Overview about all working area shifts from Roster Rocket.Please select your month and load all datas."
                )}
              </H6>
              <div className=" d-flex align-items-center gap-5 mb-5 mt-5">
                <Btn
                  attrBtn={{
                    className: "me-2",
                    color: "primary",
                    onClick: handleExportToExcel,
                  }}
                >
                  {t("Export to excel")}
                </Btn>
                <Btn
                  attrBtn={{
                    className: "me-2",
                    color: "primary",
                    onClick: createInvoiceAllPDFDownload,
                  }}
                >
                  {t("Download all Invoices as PDF")}
                </Btn>
                <Btn
                  attrBtn={{
                    className: "me-2",
                    color: "primary",
                    onClick: () => handelTransferAllData(),
                    "data-tooltip-id": "transfer-tooltip",
                    "data-tooltip-content":
                      "Data is sent to lexoffice account every 30 s",
                  }}
                >
                  {t("Transfer all to lexoffice")}
                </Btn>
              </div>
              <Tooltip id="transfer-tooltip" place="top" effect="solid" />
              <div className="d-flex align-items-center gap-3">
                <Label for="monthSelect" className="me-2">
                  {t("Month")}:
                </Label>
                <Input
                  type="select"
                  name="month"
                  id="monthSelect"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {t(month.label)}
                    </option>
                  ))}
                </Input>
                <Label for="yearSelect" className="me-2">
                  {t("Year")}:
                </Label>
                <Input
                  type="select"
                  name="year"
                  id="yearSelect"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="me-3"
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>

                <Button
                  color="primary"
                  style={{ width: "400px", height: "30px" }}
                  onClick={handleSetMonthYear}
                >
                  {t("Set Month & Year")}
                </Button>
              </div>
              <div className={`custom-table${data.length ? "" : " no-data"}`}>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  paginationPerPage={100} // Set default items per page to 100
                  paginationRowsPerPageOptions={[100, 200, 500]} // Set dropdown options
                  striped
                  responsive
                  highlightOnHover
                  customStyles={customStyles}
                />
              </div>
              {/* <div>
                <Btn
                  attrBtn={{
                    className: "me-2", // Removed "w-webkit-fill-available" if it's not needed
                    color: "info",
                    onClick: () => setIsEdit(!isEdit),
                    style: { width: "100%" }, // Adding inline style for width
                  }}
                >
                  Process Button 10%--100%
                </Btn>
              </div> */}
            </CardBody>
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {t("Lexoffice API Connection Details")}
        </ModalHeader>
        <ModalBody>
          {lexofficeConnectionDetails && (
            <div>
              <p>
                <strong>{t("Organization ID")}:</strong>{" "}
                {lexofficeConnectionDetails.organizationId}
              </p>
              <p>
                <strong>{t("Company Name")}:</strong>{" "}
                {lexofficeConnectionDetails.companyName}
              </p>
              <p>
                <strong>{t("Created By")}:</strong>{" "}
                {lexofficeConnectionDetails.created.userName}
              </p>
              <p>
                <strong>{t("User Email")}:</strong>{" "}
                {lexofficeConnectionDetails.created.userEmail}
              </p>
              <p>
                <strong>{t("Connection ID")}:</strong>{" "}
                {lexofficeConnectionDetails.connectionId}
              </p>
              <p>
                <strong>{t("Business Features")}:</strong>{" "}
                {lexofficeConnectionDetails.businessFeatures.join(", ")}
              </p>
              <p>
                <strong>{t("Tax Type")}:</strong>{" "}
                {lexofficeConnectionDetails.taxType}
              </p>
              <p>
                <strong>{t("Distance Sales Principle")}:</strong>{" "}
                {lexofficeConnectionDetails.distanceSalesPrinciple}
              </p>
              <p>
                <strong>{t("Small Business")}:</strong>{" "}
                {lexofficeConnectionDetails.smallBusiness ? "Yes" : "No"}
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Invoice Details"
        style={{
          maxWidth: "650px",
          height: "800px",
          borderRadius: "0.5rem",
        }}
        className="d-flex flex-column"
      >
        {selectedInvoice && (
          <div>
            <ModalHeader
              className="justify-content-center"
              style={{ borderBottom: "none" }}
            >
              <div style={{ marginBottom: "0px" }}>
                <H4>
                  {selectedInvoice.workingArea}:::{selectedInvoice.invoiceNr}
                </H4>
              </div>
            </ModalHeader>
            <ModalBody className="flex-grow-1 d-flex flex-column">
              <div style={{ flex: 1, overflowY: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ padding: "8px" }}>{t("Pos")}</th>
                      <th style={{ padding: "8px" }}>{t("Product/Service")}</th>
                      <th style={{ padding: "8px" }}>{t("Hours")}</th>
                      <th style={{ padding: "8px" }}>{t("Price")}</th>
                      <th style={{ padding: "8px" }}>{t("Tax")}</th>
                      <th style={{ padding: "8px" }}>{t("Sum")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedInvoice.subitems.map((item, index) => (
                      <tr key={index} style={{ marginBottom: "12px" }}>
                        <td style={{ padding: "8px" }}>{index + 1}</td>
                        <td style={{ padding: "8px", whiteSpace: "pre-line" }}>
                          <span>{t(item.articleName)}</span>
                          <br />
                          <span style={{ opacity: 0.7 }}>
                            {item.description}
                          </span>
                        </td>
                        <td style={{ padding: "8px" }}>{item.hours}</td>
                        <td style={{ padding: "8px" }}>{item.price}</td>
                        <td style={{ padding: "8px" }}>{item.tax}</td>
                        <td style={{ padding: "8px" }}>{item.sum}€</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ModalBody>
            <ModalFooter>
              <div>
                <h5>
                  {t("Total Sum")}: {selectedInvoice.totalSum.toFixed(2)}€
                </h5>
                <Button color="secondary" onClick={closeModal}>
                  {t("Close")}
                </Button>
              </div>
            </ModalFooter>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={modalIsOpen_edit}
        toggle={closeModal_edit}
        style={{
          maxWidth: "700px",
          height: "600px",
          borderRadius: "0.5rem",
        }}
      >
        <ModalHeader toggle={closeModal_edit}>{workingAreaName}</ModalHeader>
        <ModalBody>
          {modalIsOpen_edit && (
            <div>
              <div style={{ marginBottom: "50px" }}>
                <H5>
                  {t(
                    "1. If you want to edit some article items (Description, Price, Tax, Amount), then you can do it directly in working area template for lexoffice "
                  )}
                  <Link
                    to={`/working-areas/list?workingAreaName=${workingAreaName}`}
                    target="_blank"
                  >
                    {t(
                      "(Link directly to the working area lexoffice template)"
                    )}
                  </Link>
                  {t(
                    " or you can do it in lexoffice after uploading the invoice."
                  )}
                </H5>
              </div>
              <div style={{ marginBottom: "50px" }}>
                <H5>
                  {t(
                    "2. If you want to change the hours of one article item, then you can do it directly in menu time trackings for the specific working area "
                  )}
                  <Link
                    to={`${
                      process.env.PUBLIC_URL
                    }/time-trackings/list?workingAreaName=${workingAreaName}&selectedMonth=${selectedYear}-${String(
                      selectedMonth
                    ).padStart(2, "0")}`}
                    target="_blank"
                  >
                    {t(
                      "(link to menu time trackings with filter, that only display the specific working area and all time trackings in this selected month)"
                    )}
                  </Link>
                  {t(
                    " or you can do it in lexoffice after uploading the invoice."
                  )}
                </H5>
              </div>
              <div style={{ marginBottom: "50px" }}>
                <H5>
                  {t(
                    "3. If you want to delete this invoice, you can delete all time trackings or you can do it easy directly in lexoffice after upload the invoice to lexoffice."
                  )}
                </H5>
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <H5>
                  {t(
                    "4. In menu notification, you can see all system informations "
                  )}
                  <a
                    href={`${process.env.PUBLIC_URL}/notifications`}
                    target="_blank"
                  >
                    {t("(link to notification)")}
                  </a>
                </H5>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal_edit}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default LexOfficeInvoice;
