import React, { useEffect } from "react";
import { useParams } from "react-router";
import {
  Form,
  FormGroup,
  Input,
  ModalBody,
  ModalHeader,
  Modal,
  Spinner,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import {
  createEmployeeAttributeAPI,
  createWorkingAreaAttributeAPI,
} from "../../../Library/apis";
import { Btn } from "../../../AbstractElements";

const FormModal = ({ attributeModal, attributeToggle, fetchAttributes }) => {
  const { employeeId, workingAreaId } = useParams();

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // ... other validations ...
  });

  const formik = useFormik({
    initialValues: { name: "" },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        if (employeeId) {
          await createEmployeeAttributeAPI(values);
        }
        if (workingAreaId) {
          await createWorkingAreaAttributeAPI(values);
        }

        toast.success(`Successfully added!`, { autoClose: 2000 });
        attributeToggle();
        fetchAttributes();
      } catch (error) {
        console.log(error.response.data);
        if (
          error.response &&
          (error.response.data?.includes(
            "E11000 duplicate key error collection: roster_rocket.workingareaattributes index: name_1_companyId_1 dup key"
          ) ||
            error.response.data?.includes(
              "E11000 duplicate key error collection: roster_rocket.employeeattributes index: name_1_companyId_1 dup key"
            ))
        ) {
          // Handle the duplicate name error
          setFieldError("name", "This name is already taken");
        }

        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    resetForm();
  }, [attributeModal]);
  const { t } = useTranslation();
  return (
    <Modal isOpen={attributeModal} toggle={attributeToggle}>
      <ModalHeader toggle={attributeToggle}>{t("Add attribute")}</ModalHeader>
      <ModalBody>
        <Form className="form-bookmark" onSubmit={handleSubmit}>
          <div className="form-row">
            <FormGroup className="mb-3 col-md-12 my-0">
              <Input
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                invalid={errors.name && touched.name}
              />
              {errors.name && touched.name && (
                <span className="mt-2 text-danger">{errors.name}</span>
              )}
            </FormGroup>
          </div>
          <Btn
            attrBtn={{
              color: "success",
              disabled: isSubmitting,
              type: "submit",
            }}
          >
            {isSubmitting && <Spinner size="sm" color="light" />}
            {t("Save")}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              className: "ms-2",
              onClick: attributeToggle,
              disabled: isSubmitting,
            }}
          >
            {t("Cancel")}
          </Btn>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default FormModal;
