import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Spinner,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import { Breadcrumbs, Btn, UL, LI, H5 } from "../../../AbstractElements";
import {
  getEmployeeAttributesListAPI,
  deleteEmployeeAttributeAPI,
  getEmployeeDetailsAPI,
  updateEmployeeAPI,
  getWorkingAreaDetailsAPI,
  updateWorkingAreaAPI,
  getWorkingAreaAttributesListAPI,
  deleteWorkingAreaAttributeAPI,
} from "../../../Library/apis";
import FormModal from "./FormModal";

const Attributes = () => {
  const { employeeId, workingAreaId } = useParams();

  const [details, setDetails] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [attributeModal, setAttributeModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [search, setSearch] = useState("");

  const attributeToggle = () => {
    setAttributeModal(!attributeModal);
  };

  const fetchAttributes = async () => {
    setLoading(true);

    try {
      let response;
      if (employeeId) {
        response = await getEmployeeAttributesListAPI();
      }
      if (workingAreaId) {
        response = await getWorkingAreaAttributesListAPI();
      }

      setAttributes(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    setLoadingDetails(true);
    try {
      let response;
      if (employeeId) {
        response = await getEmployeeDetailsAPI(employeeId);
      }

      if (workingAreaId) {
        response = await getWorkingAreaDetailsAPI(workingAreaId);
      }
      setDetails(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        setIsDeleting(true);

        try {
          let response;
          if (employeeId) {
            response = await deleteEmployeeAttributeAPI(id);
          }
          if (workingAreaId) {
            response = await deleteWorkingAreaAttributeAPI(id);
          }

          toast.success(response.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchAttributes();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleInsert = (attribute) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      attributes: [...prevDetails.attributes, attribute],
    }));
  };

  const handleRevert = (attributeId) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      attributes: prevDetails.attributes.filter(
        (item) => item._id !== attributeId
      ),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);

    try {
      if (employeeId) {
        await updateEmployeeAPI(employeeId, details);
      }

      if (workingAreaId) {
        await updateWorkingAreaAPI(workingAreaId, details);
      }

      handleBack();
      toast.success("Successfully updated!", { autoClose: 2000 });
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchAttributes();

    if (employeeId || workingAreaId) {
      fetchDetails();
    }
  }, [employeeId, workingAreaId]);

  const filteredAttributes = attributes?.filter((attribute) =>
    attribute?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const detailsAttributeIds = details?.attributes?.map((item) => item._id);

  const notSelectedAttributes = filteredAttributes?.filter(
    (attribute) => !detailsAttributeIds?.includes(attribute._id)
  );
  const { t } = useTranslation();
  const mainTitle = "Attributes";
  const maintTitleTran = t(mainTitle);
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} title="Attributes" />
      <Container fluid={true}>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <Card className="position-relative">
                <Btn
                  attrBtn={{
                    className: "position-absolute left-0",
                    style: { zIndex: 1, top: 10 ,left:10},
                    color: "light",
                    onClick: handleBack,
                  }}
                >
                  {t("Back")}

                </Btn>
                <br></br>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H5>
                    {employeeId
                      ? `${t('Employee')}: ${
                          !loadingDetails
                            ? `${details.firstName} ${details.lastName}`
                            : ""
                        }`
                      : ""}
                    {workingAreaId
                      ? `${t('Working Area')}: ${
                          !loadingDetails ? `${details.name}` : ""
                        }`
                      : ""}
                  </H5>
                  <Btn
                    attrBtn={{
                      color: "success",
                      onClick: handleSave,
                      disabled: isSaving,
                    }}
                  >
                    {isSaving && (
                      <Spinner size="sm" color="light" className="me-1" />
                    )}
                    {t("Save")}
                  </Btn>
                </CardHeader>

                <CardBody>
                  <UL attrUL={{ className: "simple-list border-0 mb-4" }}>
                    {details?.attributes?.map((item) => (
                      <LI
                        key={item._id}
                        attrLI={{
                          className: "border-0 p-2 list-group-item-action",
                        }}
                      >
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "px-3 py-1",
                            onClick: () => handleRevert(item._id),
                          }}
                        >
                          {item.name}
                          <i className="fa fa-arrow-right ms-2 cursor-pointer" />
                        </Btn>
                      </LI>
                    ))}
                  </UL>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardHeader className="d-flex align-items-center justify-content-between">
                  <H5 attrH5={{ style: { textTransform: "none" } }}>
                    {t("List of all attributes in the system")}
                  </H5>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      onClick: attributeToggle,
                    }}
                  >
                    <i className="fa fa-plus"></i> {t("New attribute")}
                  </Btn>
                </CardHeader>

                <CardBody>
                  <InputGroup style={{ width: 250 }} className="mb-2">
                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder= {t("Search by name...")}
                      name="search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                    {search && (
                      <InputGroupText>
                        <i
                          className="fa fa-times cursor-pointer"
                          onClick={() => setSearch("")}
                        ></i>
                      </InputGroupText>
                    )}
                  </InputGroup>
                  <UL attrUL={{ className: "simple-list border-0" }}>
                    {notSelectedAttributes?.map((item) => (
                      <LI
                        key={item._id}
                        attrLI={{
                          className: "border-0 p-2 list-group-item-action",
                        }}
                      >
                        <Btn
                          attrBtn={{
                            color: "primary mr-1",
                            className: "px-3 py-1 me-2",
                            onClick: () => handleInsert(item),
                            disabled: isDeleting,
                          }}
                        >
                          <i className="fa fa-arrow-left me-2 cursor-pointer" />
                          {item.name}
                        </Btn>

                        <Btn
                          attrBtn={{
                            color: "light mr-1",
                            className: "px-2 py-1",
                            onClick: () => handleDelete(item._id),
                            disabled: isDeleting,
                          }}
                        >
                          {isDeleting ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            <i className="fa fa-close cursor-pointer" />
                          )}
                        </Btn>
                      </LI>
                    ))}
                  </UL>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Container>

      <FormModal
        attributeModal={attributeModal}
        attributeToggle={attributeToggle}
        fetchAttributes={fetchAttributes}
      />
    </Fragment>
  );
};

export default Attributes;
