import React, { useEffect, useState } from "react";
import _ from "lodash";
import Context from "./index";

const DropProvider = (props) => {
  const [holidaySelect, setholidaySelect] = useState({
    employee: "",
    type: "",
    state: "",
    startTime: "",
    endTime: "",
    description: "",
  });

  useEffect(() => {

    updateHolidayInfo();
  }, [holidaySelect]);

  const updateHolidayInfo = () => {

  };

  return (
    <Context.Provider
      value={{
        ...props,
        holidaySelect,
        setholidaySelect: setholidaySelect,
        updateHolidayInfo: updateHolidayInfo,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default DropProvider;
