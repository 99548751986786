import React, { Fragment, useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Label,
  Input,
  FormGroup,
  InputGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn } from "../../../AbstractElements";

import {
  getHolidayIllnessDetailsAPI,
  updateHolidayIllnessAPI,
  createHolidayIllnessAPI,
  getAllEmployeesListAPI,
} from "../../../Library/apis";
import CustomSelect from "../../Common/CustomSelect";
import DropContext from "../Dropout";
import UserContext from "../../../_helper/User";

const formInitialValues = {
  employee: "",
  type: "holiday-payed",
  state: "open",
  startTime: "",
  endTime: "",
  description: "",
};

const Form = () => {
  const { setholidaySelect } = useContext(DropContext);
  const { t } = useTranslation();
  const { userNotifications, updateNotificationsInfo } =
    useContext(UserContext);
  const typeOptions = [
    { value: "holiday-payed", label: t("Holiday Payed") },
    { value: "holiday-unpayed", label: t("Holiday Unpayed") },
    { value: "illness", label: t("Illness") },
    { value: "illness-child", label: t("Illness Child") },
    { value: "school", label: t("School") },
    { value: "miscellaneous", label: t("Miscellaneous") },
  ];

  const stateOptions = [
    { value: "open", label: t("Open") },
    { value: "declined", label: t("Declined") },
    { value: "approved", label: t("Approved") },
  ];

  const { holidayIllnessId } = useParams();

  const [initialValues, setInitialValues] = useState(formInitialValues);
  const [employeesOption, setEmployeesOption] = useState([]);

  const fetchEmployees = async () => {
    try {
      const employees = await getAllEmployeesListAPI();
      setEmployeesOption(
        employees?.map((item) => ({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        }))
      );
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  // Schema for form validation
  const validationSchema = Yup.object().shape({
    employee: Yup.object().required("Employee is required"),
    type: Yup.string().required("Type is required"),
    state: Yup.string().required("State is required"),
    startTime: Yup.string().required("Start time is required"),
    endTime: Yup.string()
      .required("End time is required")
      .test(
        "is-greater",
        "End time must be later than start time",
        function (value) {
          const { startTime } = this.parent;
          if (startTime && value) {
            return new Date(value) > new Date(startTime);
          }
          return true; // Allow validation to pass if start time or end time is not set yet
        }
      ),
  });

  const removeOffset = (dateString) => {
    const date = new Date(dateString);

    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (values.state === "approved") values.confirmedByManager = true;
      if (values.state === "declined") values.confirmedByManager = false;
      try {
        const formData = {
          ...values,
          employee: values.employee?.value,
        };
        const {
          confirmedByManager,
          description,
          employee,
          endTime,
          startTime,
          state,
          type,
        } = formData;

        let newStart = removeOffset(startTime);
        let newEnd = removeOffset(endTime);

        let result = {
          endTime: newEnd,
          startTime: newStart,
          employee,
          description,
          confirmedByManager,
          state,
          type,
        };

        holidayIllnessId
          ? await updateHolidayIllnessAPI(holidayIllnessId, result)
          : await createHolidayIllnessAPI(result);

        toast.success(
          `Successfully ${holidayIllnessId ? "updated" : "added"}!`,
          {
            autoClose: 2000,
          }
        );
        updateNotificationsInfo();
        setholidaySelect(formData);

        handleBack();
      } catch (error) {
        console.log(error.response.data);
        toast.error(error?.response?.data?.message || "Something went wrong!", {
          autoClose: 2000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchHolidayIllness = async () => {
    try {
      const timeTracking = await getHolidayIllnessDetailsAPI(holidayIllnessId);
      const { employee, ...other } = timeTracking;
      const updatedValues = {
        ...other,
        employee: {
          value: employee._id,
          label: `${employee.firstName} ${employee.lastName}`,
        },
      };

      setInitialValues(updatedValues);

      // Manually update formik values
      Object.entries(updatedValues).forEach(([key, value]) => {
        formik.setFieldValue(key, value, false);
      });
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message, { autoClose: 2000 });
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    handleChange,
  } = formik;

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    fetchEmployees();

    setInitialValues(formInitialValues);
    if (holidayIllnessId) {
      fetchHolidayIllness();
    }

    return () => {
      setInitialValues({});
      setEmployeesOption([]);
    };
  }, [holidayIllnessId]);

  const handleSelectChange = (name, value) => {
    setFieldValue(name, value);
    setFieldTouched(name, true, false);
  };

  const maintTitle = holidayIllnessId ? t("Edit off Time") : t("Add Off Time");
  const maintTitleTran = t(maintTitle);
  return (
    <Fragment>
      <Breadcrumbs
        mainTitle={maintTitleTran}
        title={`${holidayIllnessId ? "Edit" : "Add"} off time`}
      />
      <Container fluid={true}>
        <Col sm="6">
          <Card className="position-relative">
            <Btn
              attrBtn={{
                className: "position-absolute left-0",
                style: { zIndex: 1, top: 10, left: 10 },
                color: "light",
                onClick: handleBack,
              }}
            >
              {t("Back")}
            </Btn>
            <br></br>
            <CardBody>
              <Row>
                <Col sm="4">
                  <CustomSelect
                    name="employee"
                    options={employeesOption}
                    label="Employee"
                    onChange={handleSelectChange}
                    errors={errors}
                    touched={touched}
                    value={values["employee"]}
                    async={true}
                    required
                  />
                </Col>
                <Col sm="4">
                  <CustomSelect
                    name="type"
                    options={typeOptions}
                    label="Type"
                    onChange={handleSelectChange}
                    errors={errors}
                    touched={touched}
                    value={typeOptions.find(
                      (option) => option.value === values["type"]
                    )}
                    required
                  />
                </Col>
                <Col sm="4">
                  <CustomSelect
                    name="state"
                    options={stateOptions}
                    label="State"
                    onChange={handleSelectChange}
                    errors={errors}
                    touched={touched}
                    value={stateOptions.find(
                      (option) => option.value === values["state"]
                    )}
                    required
                  />
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label className="required">{t("Start")}</Label>
                    <InputGroup
                      className="date flex-nowrap"
                      id="dt-minimum"
                      data-target-input="nearest"
                    >
                      <Input
                        className="digits"
                        type="datetime-local"
                        name="startTime"
                        value={
                          values.startTime
                            ? moment
                                .utc(values.startTime)
                                .format("YYYY-MM-DDTHH:mm")
                            : ""
                        }
                        min={
                          values.startTime
                            ? moment
                                .utc(values.startTime)
                                .format("YYYY-MM-DDTHH:mm")
                            : "00:00"
                        }
                        onChange={(e) => {
                          // Convert the new date to UTC
                          const newDate = e.target.value;

                          // Update the form value
                          setFieldValue("startTime", newDate);
                        }}
                        invalid={errors.startTime && touched.startTime}
                      />
                    </InputGroup>
                    {errors.startTime && touched.startTime && (
                      <span className="mt-2 text-danger">
                        {errors.startTime}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label className="required">{t("End")}</Label>
                    <InputGroup
                      className="date flex-nowrap"
                      id="dt-minimum"
                      data-target-input="nearest"
                    >
                      <Input
                        className="digits"
                        type="datetime-local"
                        name="endTime"
                        value={
                          values.endTime
                            ? moment
                                .utc(values.endTime)
                                .format("YYYY-MM-DDTHH:mm")
                            : ""
                        }
                        min={
                          values.endTime
                            ? moment
                                .utc(values.endTime)
                                .format("YYYY-MM-DDTHH:mm")
                            : "00:00"
                        }
                        onChange={(e) => {
                          // Convert the new date to UTC
                          const newDate = e.target.value;

                          // Update the form value
                          setFieldValue("endTime", newDate);
                        }}
                        invalid={errors.endTime && touched.endTime}
                      />
                    </InputGroup>
                    {errors.endTime && touched.endTime && (
                      <span className="mt-2 text-danger">{errors.endTime}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label>{t("Description")}</Label>
                    <InputGroup>
                      <Input
                        className="digits"
                        type="textarea"
                        rows="3"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>

              <Btn
                attrBtn={{
                  color: "success",
                  disabled: isSubmitting,
                  onClick: handleSubmit,
                }}
              >
                {isSubmitting && <Spinner size="sm" color="light" />}
                {t("Save")}
              </Btn>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </Fragment>
  );
};

export default Form;
