import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const IndustriesFooter = () => {

    const sectionsData = [
        { title: 'Hotel', backgroundImage: `url(${'https://hotelchantelle.com/wp-content/uploads/2023/09/2747.webp'})` },
        { title: 'Resturant', backgroundImage: `url(${'https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIyLTA1L3Vwd2s2MTY2MTU3Ny13aWtpbWVkaWEtaW1hZ2Uta293YXBlZWouanBn.jpg'})` },
        { title: 'Production', backgroundImage: `url(${'https://navigossearch.com/Data/Sites/1/media/b%C3%A0i-seo/quy-trinh-quan-ly-san-xuat-chuan-nhat.jpg'})` },
        { title: 'Transport', backgroundImage: `url(${'https://img.freepik.com/premium-photo/transportation-logistics-container-cargo-ship-cargo-plane-3d-rendering-illustration_37416-487.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1710892800&semt=ais'})` },
        // { title: 'Commerce', backgroundImage:`url(${'https://imageio.forbes.com/specials-images/imageserve/6419b4476af64ebf89bdef19/e-commerce/960x0.jpg?format=jpg&width=960'})` },
        { title: 'Service', backgroundImage:`url(${'https://www.cleo.com/sites/default/files/web-services.jpg'})` },
        { title: 'Cleaning', backgroundImage:`url(${'https://ecoglowcleaning.com/wp-content/uploads/2022/11/Cleaning-service-employees-wit.jpg'})` }
      ];

    return(
        <div>

<Container className="fourth-section" >
      <h1  className="font64_outfit text-dark"  style= {{ textAlign: "center", margin: "20px 0 40px 0" }} >
      Ideal for Your Industry 
      </h1>
    </Container>

    <Container fluid>
      <Row>
        {sectionsData.map((section, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3}>
            <div
              className="section"
              style={{
                backgroundImage: section.backgroundImage,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                textAlign: 'center',
                color: 'white',
                padding: '50px 0',
                borderRadius: '10px', // Normal border radius
                width: '100%',
                height: '250px', // Square shape
                overflow: 'hidden', // Ensure content does not overflow
                boxShadow: '0px 4px 10px rgba(0, 0, 255, 0.5)', // Blue shadow
                marginBottom:'15px'
              }}
            >
              <h2
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  margin: 0,
                  fontWeight: 'bold', // Make title bold
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' // Box shadow for title
                }}
              >
                {section.title}
              </h2>
            </div>
          </Col>
        ))}
      </Row>
    </Container>

    
        </div>
    )
}

export default IndustriesFooter;