import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H5, UL, LI, H4 } from "../../../AbstractElements";
import {
  Cancelled,
  Delivered,
  LastMonth,
  profit,
  profit2,
  RecentOrdersTitle,
  WeeklyMonDropdown,
} from "../../../Constant";
import { RecentOrderChart } from "../../../Data/DefaultDashboard/Chart";
import DropdownCommon from "../../Common/Dropdown";
import { getDropoutHistoryAPI } from "../../../Library/apis";
import { useTranslation } from "react-i18next";
const RecentOrders = () => {
  const { t } = useTranslation();
  const [counts, setCounts] = useState({ declined: 0, approved: 0, open: 0 });
  useEffect(() => {
    getDropoutHistoryAPI()
      .then((resultArray) => {
        // Initialize counts
        let declined = 0,
          approved = 0,
          open = 0;

        // Iterate through the array to count states
        resultArray.forEach((item) => {
          if (item.state === "declined") {
            declined += item.conflictEmployees.length; // Assuming you want to count the number of conflictEmployees
          } else if (item.state === "approved") {
            approved += item.conflictEmployees.length; // Adjust based on your actual data structure
          } else if (item.state === "open") {
            open += item.conflictEmployees.length; // Adjust based on your actual data structure
          }
        });

        // Update the component's state with the new counts
        setCounts({ declined, approved, open });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // Empty dependency array means this effect runs once after the initial render
  const approvedPercentage =
    counts.approved + counts.declined === 0
      ? 0
      : Math.round(
          (counts.approved / (counts.approved + counts.declined)) * 100
        );

  const seriesData = [approvedPercentage];
  return (
    <Col xxl="4" xl="7" md="6" sm="5" className="box-col-6">
      <Card className="height-equal">
        <CardHeader className="card-no-border">
          <div className="header-top">
            <H5>{t(RecentOrdersTitle)}</H5>
            <div className="card-header-right-icon">
              <DropdownCommon
                dropdownMain={{
                  className: "icon-dropdown",
                  direction: "start",
                }}
                options={WeeklyMonDropdown}
                iconName="icon-more-alt"
                btn={{ tag: "span" }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="recent-wrapper">
            <Col xl="6">
              <div className="recent-chart">
                <ReactApexChart
                  type="radialBar"
                  height={290}
                  options={RecentOrderChart.options}
                  series={seriesData}
                />
              </div>
            </Col>
            <Col xl="6">
              <UL attrUL={{ className: "order-content" }}>
                <LI>
                  <span className="recent-circle bg-primary"> </span>
                  <div>
                    <span className="f-light f-w-500">{t(Cancelled)} </span>
                    <H4 attrH4={{ className: "mt-1 mb-0" }}>
                      {counts.declined}
                      <span className="f-light f-14 f-w-400 ms-1">
                        ({t(LastMonth)}){" "}
                      </span>
                    </H4>
                  </div>
                </LI>
                <LI>
                  <span className="recent-circle bg-info" />
                  <div>
                    <span className="f-light f-w-500">{t(Delivered)}</span>
                    <H4 attrH4={{ className: "mt-1 mb-0" }}>
                      {counts.approved}
                      <span className="f-light f-14 f-w-400 ms-1">
                        ({t(LastMonth)})
                      </span>
                    </H4>
                  </div>
                </LI>
              </UL>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RecentOrders;
