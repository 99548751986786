import React, { useRef, useState } from "react";
import { Row, Col, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import { TypeAnimation } from "react-type-animation";
import { useNavigate } from "react-router";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

import { Btn, H1, H2, H3, H5, Image, P } from "../../../AbstractElements";

import TopHeader from "./TopHeader";
import Footer from "./Footer";

import landing_bg from "../../../assets/images/landing/landing_section1.jpg";
import landing_card1 from "../../../assets/images/landing/landing_card_1.png";
import landing_card2 from "../../../assets/images/landing/landing_card_2.png";
import landing_card3 from "../../../assets/images/landing/landing_card_3.png";
import landing_section4 from "../../../assets/images/landing/landing_section4.png";

import landing_section4_card1 from "../../../assets/images/landing/landing_section4_card1.svg";
import landing_section4_card2 from "../../../assets/images/landing/landing_section4_card2.svg";
import landing_section4_card3 from "../../../assets/images/landing/landing_section4_card3.svg";

import landing_section5 from "../../../assets/images/landing/landing_section5.png";
import landing_section5_apple from "../../../assets/images/landing/landing_section5_apple.png";
import landing_section5_google from "../../../assets/images/landing/landing_section5_google.png";

import landing_section1_shield from "../../../assets/images/landing/landing_shield.svg";
import landing_section1_women from "../../../assets/images/landing/landing_section1_women.png";
import ben_niess_V2 from "../../../assets/ben_niess_V2.mp4";
import "./index.css";
import StandardPricingcard from "./StandardProcingCard";
import { HelpCircle } from "react-feather";

const SectionData = {
  Section1: {
    text1: "Optimize Your Workforce with Efficient Shift Planning",
    text2:
      "Our Employee Shift Plan Management System is designed to streamline your scheduling process, ensuring optimal workforce allocation and increased productivity.",
    text3:
      "Experience hassle-free scheduling with a focus on flexibility and employee satisfaction",
    button: "Get Started",
  },
  Section2: {
    text1: "Create Custom Shift Plans for Any Operational Need",
    text2:
      "From managing part-time schedules to adapting to unexpected changes, our system offers flexible solutions. Ensure your business operates smoothly with effective shift planning.",
    text3: "Test it one month for free!",
  },
  Section3: {
    text1: "Explore Our Shift Planning Features",
    text2:
      "Discover the tools and functionalities that make shift planning seamless. Explore our range of features designed for efficient scheduling and time management.",
    card: [
      {
        image: landing_card1, // Update image to reflect shift planning
        title: "Automated Scheduling",
        description:
          "Automate your scheduling process to save time and reduce errors.",
      },
      {
        image: landing_card2, // Update image to reflect shift planning
        title: "Real-Time Adjustments",
        description:
          "Make real-time changes to accommodate unexpected staffing needs.",
      },
      {
        image: landing_card3,
        title: "Employee Self-Service",
        description:
          "Empower employees with the ability to view and swap shifts as needed.",
      },
    ],
  },
  Section4: {
    text1: "Simplify Your Scheduling with Our User-Friendly System",
    text2:
      "Start optimizing your workforce today. Use our intuitive system to manage employee shifts, reduce labor costs, and improve operational efficiency.",
  },
  Section5: {
    text1: "Our Commitment to Clients",
    card: [
      {
        image: landing_section4_card1,
        title: "Reliability",
        description:
          "Count on a system that's always up and running, ensuring your scheduling never skips a beat.",
      },
      {
        image: landing_section4_card2,
        title: "24/7 Support",
        description:
          "Our dedicated support team is always here to help you with any issues or questions.",
      },
      {
        image: landing_section4_card3,
        title: "Intuitive Design",
        description:
          "Enjoy a seamless scheduling experience with our straightforward and easy-to-navigate interface.",
      },
    ],
  },
  Section6: {
    text1: "Client Testimonials",
    text2: "Hear from our satisfied users",
    users: [
      {
        name: "Jordan Smith",
        role: "Operations Manager",
        description:
          "This shift planning system has transformed our operational efficiency. It's intuitive and adaptable to our complex scheduling needs.",
      },
      // Add more testimonials as needed
    ],
  },
  SessionFooter: {
    address: {
      title: "Roster Rocket",
      data: ["123 Corporate Blvd", "Industrial Park", "City, State, Zip"],
    },
    parts: [
      {
        title: "Our Company",
        data: ["About Us", "FAQs"],
      },
      {
        title: "Solutions",
        data: ["Shift Planning"],
      },
      {
        title: "Legal",
        data: ["Privacy Policy", "Terms of Service"],
      },
      {
        title: "Contact Us",
        data: ["Email: support@rosterrocket.com"],
      },
    ],
  },
};

const faqSessionData = [
  {
    title: "What is the Roster Rocket?",
    text: "Our system is a comprehensive tool designed to facilitate the creation, management, and optimization of employee shift schedules. It helps streamline operations, reduce labor costs, and improve workforce satisfaction.",
  },
  {
    title: "How does the system simplify shift planning?",
    text: "By automating the scheduling process, providing real-time updates, and allowing for easy adjustments, our system ensures that managing employee shifts is straightforward and efficient.",
  },
  {
    title: "Is my data secure with the system?",
    text: "Absolutely. We prioritize your data's security using advanced encryption and security protocols to ensure all information is protected.",
  },
  {
    title: "What makes our system unique?",
    text: "Our system stands out due to its user-friendly interface, customizable features, and robust support, all designed to make shift planning as efficient as possible.",
  },
  {
    title: "Can I customize shift plans according to my business needs?",
    text: "Yes, our system is highly flexible, allowing you to create and modify shift plans according to the specific needs and demands of your business.",
  },
  {
    title: "Are there any fees associated with using the system?",
    text: "We offer various pricing plans to fit different business sizes and needs. Our pricing is transparent, and there are no hidden fees.",
  },
  {
    title: "How do I contact customer support?",
    text: "Our customer support team is available 24/7. You can reach out to us through email, live chat, or phone as listed in the 'Contact Us' section of our platform.",
  },
  {
    title: "Can I integrate the system with other tools?",
    text: "Yes, our system is designed to integrate smoothly with various business and HR tools, enhancing your overall workflow and efficiency.",
  },
  {
    title: "Can I export data for reporting purposes?",
    text: "Yes, you can easily export data from our system for further analysis, reporting, or record-keeping in multiple formats.",
  },
  {
    title: "Can I delete my account?",
    text: "If you need to delete your account, please contact our support team for assistance. Please note that this action is irreversible and will result in the loss of all stored data.",
  },
];

const LandingPage = () => {
  const navigate = useNavigate();
  const handleGetStarted = () => navigate("/login");

  const [isActivity, setIsActivity] = useState([]);
  const handelChange = (e) => {
    let collapseCopy = [...isActivity];
    collapseCopy[e] = !collapseCopy[e];
    setIsActivity(collapseCopy);
  };

  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="landing-page" style={{ position: "relative" }}>
      <TopHeader scrollToFooter={scrollToFooter} />
      <div
        className="first-section container-fluid"
        id={"home"}
        style={{ backgroundImage: `url(${landing_bg})`, paddingTop: "250px" }}
      >
        <Row>
          <Col md="6">
            <div
              style={{
                zIndex: 5,
                //  width: "800px"
              }}
            >
              <div
                style={{
                  maxWidth: "1050px",
                  // marginTop: "250px",
                  minHeight: "300px",
                  zIndex: 4,
                }}
              >
                <TypeAnimation
                  sequence={[
                    SectionData.Section1.text1,
                    "",
                    SectionData.Section1.text1,
                    2000,
                  ]}
                  className="font96_outfit text-light mt-10"
                  cursor={false}
                  speed={200}
                  repeat={Infinity}
                />
              </div>
              <H5
                attrH5={{
                  className: "font20_outfit text-light",
                  style: { maxWidth: "800px", marginTop: 40, marginBottom: 40 },
                }}
              >
                {SectionData.Section1.text2}
              </H5>
              <Fade left style={{ zIndex: 4 }}>
                <Col xs={12} className="store-container">
                  <Image
                    attrImage={{ src: landing_section5_apple, alt: "section4" }}
                  />
                  <Image
                    attrImage={{
                      src: landing_section5_google,
                      alt: "section4",
                    }}
                  />
                </Col>
                {/* <Btn attrBtn={{ className: 'font36_outfit text-light', color: 'secondary', style: { width: '245px', height: '110px', borderRadius: '100px' }, onClick: handleGetStarted }}>Get started</Btn> */}
                <Row className="p-t-40 p-l-15">
                  <Image
                    attrImage={{
                      className: "p-0",
                      src: landing_section1_shield,
                      alt: "shield",
                      style: { width: 41 },
                    }}
                  />
                  <P
                    attrPara={{
                      className: "font-outfit font18 text-light mb-4 mt-4",
                      style: {
                        maxWidth: "50%",
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    {SectionData.Section1.text3}
                  </P>
                </Row>
              </Fade>
            </div>
          </Col>
          <Col
            sm="12"
            md="6"
            className="d-flex justify-content-md-end align-items-center"
          >
            <div className="women-container" style={{ zIndex: 1 }}>
              <iframe
                width="100%"
                height="475"
                src="https://www.youtube.com/embed/O5EKQXuLMQk?si=qaVW7hDrVixdFcj8&autoplay=1"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                referrerPolicy="strict-origin-when-cross-origin"
                className="iframe-no-border banner-video"
              ></iframe>
            </div>
          </Col>
        </Row>

        {/* <div className="women-container" style={{ zIndex: 1 }}>
        
          <video controls width="100%" height="auto">
            <source src={ben_niess_V2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
      </div>

      <Fade right>
        <Row className="second-section">
          <H1
            attrH1={{
              className: "font80_outfit text-dark text-center",
              style: { maxWidth: "70%", marginTop: "150px" },
            }}
          >
            {SectionData.Section2.text1}
          </H1>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <H1
              attrH1={{
                className: "font20_outfit text-center",
                style: {
                  maxWidth: "750px",
                  marginTop: "50px",
                  lineHeight: 1.5,
                },
              }}
            >
              {SectionData.Section2.text2}
            </H1>
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <H1
              attrH1={{
                className: "font30_outfit  text-center",
                style: {
                  maxWidth: "750px",
                  marginTop: "50px",
                  lineHeight: 1.5,
                  color: "#FF0000",
                },
              }}
            >
              {SectionData.Section2.text3}
            </H1>
          </Col>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center m-t-50"
          >
            <Btn
              attrBtn={{
                className: "font14_outfit text-light",
                onClick: handleGetStarted,
                color: "secondary",
                style: {
                  width: "186px",
                  height: "64px",
                  borderRadius: "100px",
                },
              }}
            >
              Get Started
            </Btn>
          </Col>
        </Row>
      </Fade>

      <Fade right>
        <Row className="second-section">
          <H1
            attrH1={{
              className: "font80_outfit text-dark text-center",
              style: { maxWidth: "70%", marginTop: "150px" },
            }}
          >
            {SectionData.Section3.text1}
          </H1>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center"
          >
            <H1
              attrH1={{
                className: "font20_outfit text-dark text-center",
                style: {
                  maxWidth: "750px",
                  marginTop: "50px",
                  lineHeight: 1.5,
                },
              }}
            >
              {SectionData.Section3.text2}
            </H1>
          </Col>
        </Row>
      </Fade>

      <div id="savings">
        <Row className="second-section">
          {SectionData.Section3.card.map(
            (
              item,
              index // Corrected variable names
            ) => (
              <Fade right key={index}>
                <div className="w-100 d-flex justify-content-center">
                  <Col xs={10}>
                    <Card body>
                      <Row className="m-4">
                        <Col
                          xxl={3}
                          xl={3}
                          xs={12}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Image
                            attrImage={{
                              src: item.image,
                              alt: item.title,
                              style: { height: "200px" },
                            }}
                          />
                        </Col>
                        <Col xxl={9} xl={9} xs={12}>
                          <Row className="card-section">
                            <H1
                              attrH1={{ className: "font64_outfit text-dark" }}
                            >
                              {item.title}
                            </H1>
                            <Col xs={12}>
                              <H5
                                attrH5={{
                                  className: "font20_outfit text-dark",
                                }}
                              >
                                {item.description}
                              </H5>
                            </Col>
                            <Col xs={12}>
                              <Btn
                                attrBtn={{
                                  className: "font14_outfit text-light",
                                  onClick: handleGetStarted,
                                  color: "secondary",
                                  style: {
                                    width: "186px",
                                    height: "64px",
                                    borderRadius: "100px",
                                  },
                                }}
                              >
                                Get Started
                              </Btn>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </div>
              </Fade>
            )
          )}
        </Row>
      </div>

      <div id={"features"}>
        <Fade right>
          <Row className="fourth-section">
            <Col
              xxl={4}
              lg={12}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="section4_card">
                <Image
                  attrImage={{
                    src: landing_section4,

                    alt: "section4",
                    style: { height: "300px", width: "600px" },
                  }}
                />
              </div>
            </Col>
            <Col xxl={8} lg={12}>
              <Row className="card-section">
                <H1
                  attrH1={{
                    className: "font60_outfit text-dark",
                    style: { maxWidth: "800px", marginTop: "90px" },
                  }}
                >
                  {SectionData.Section4.text1}
                </H1>
                <Col xs={12}>
                  <H5 attrH5={{ className: "font20_outfit text-dark" }}>
                    {SectionData.Section4.text2}
                  </H5>
                </Col>
                <Col xs={12}>
                  <Btn
                    attrBtn={{
                      className: "font14_outfit text-light",
                      onClick: handleGetStarted,
                      color: "secondary",
                      style: {
                        width: "186px",
                        height: "64px",
                        borderRadius: "100px",
                      },
                    }}
                  >
                    Get Started
                  </Btn>
                </Col>
              </Row>
            </Col>
          </Row>
        </Fade>
      </div>

      <Row className="fifth-section">
        <H1
          attrH1={{
            className: "font64_outfit text-light",
            style: { textAlign: "center", margin: "20px 0 40px 0" },
          }}
        >
          {SectionData.Section5.text1}
        </H1>
        <Col xxl={6} lg={6}>
          <Zoom>
            <div className="d-flex justify-content-center align-items-center m-t-50">
              <Image
                attrImage={{
                  src: landing_section5,
                  alt: "section5", // updated alt text for clarity
                  style: { height: "600px", width: "350px" },
                }}
              />
            </div>
          </Zoom>
        </Col>
        <Col xxl={4} lg={6} xl={5} className="m-t-50">
          {SectionData.Section5.card.map((item, index) => (
            <Fade right cascade big key={index}>
              <Card body>
                <Image
                  attrImage={{
                    src: item.image,
                    alt: item.title, // ensure this is dynamically based on item
                    style: { height: "40px", width: "40px" },
                  }}
                />
                <Row className="card-section" style={{ gap: 0, marginTop: 5 }}>
                  <H5
                    attrH5={{
                      className: "font20_outfit text-dark",
                      style: { fontWeight: 700 },
                    }}
                  >
                    {item.title}
                  </H5>
                  <Col xs={12} className="mt-0">
                    <P
                      attrPara={{
                        className: "font16_dmsans text-dark m-t-5",
                        style: { fontWeight: 500, lineHeight: "20px" },
                      }}
                    >
                      {item.description}
                    </P>
                  </Col>
                </Row>
              </Card>
            </Fade>
          ))}
        </Col>
      </Row>

      <Row className="fourth-section">
        <div id={"pricing"}>
          <H1
            attrH1={{
              className: "font64_outfit text-dark",
              style: { textAlign: "center", margin: "20px 0 40px 0" },
            }}
          >
            {"pricing"}
          </H1>
          <StandardPricingcard />
        </div>
      </Row>

      <Row className="fourth-section">
        <div id={"faqs"}>
          <H2
            attrH2={{
              className: "m-4 font40-bold font-outfit text-dark text-center",
            }}
          >
            {"Frequently Asked Questions (FAQs) - Roster Rockets"}
          </H2>
          <Row className="about-section" style={{ margin: "40px" }}>
            <Col xl="8" lg="6" md="7" className="box-col-8 xl-60">
              {faqSessionData &&
                faqSessionData.map((item, i) => (
                  <Fade right key={i}>
                    <Card
                      className="font-outfit pt-4 pb-4"
                      onClick={() => handelChange(i)}
                      style={{ borderRadius: 8 }}
                    >
                      <CardHeader
                        className="pb-0 mb-0 pt-0"
                        style={{ borderBottomWidth: 0 }}
                      >
                        <div
                          className="d-flex"
                          style={{ alignItems: "center", gap: 15 }}
                        >
                          <HelpCircle size={28} />
                          <H3
                            attrH3={{
                              className: "mb-0 font-bold text-grey font-outfit",
                            }}
                          >
                            {item.title}
                          </H3>
                        </div>
                      </CardHeader>
                      <Collapse isOpen={isActivity[i]}>
                        <CardBody>
                          <H5 attrH5={{ className: "font-outfit" }}>
                            {item.text}
                          </H5>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </Fade>
                ))}
            </Col>
          </Row>
        </div>
      </Row>

      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
