import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Widgets1 from "../../Common/CommonWidgets/Widgets1";
import Widgets2 from "../../Common/CommonWidgets/Widgets2";
import {
  getAllEmployeesListAPI,
  getDropoutHistoryAPI,
  getHolidayIllnessesListAPI,
  getShiftPlanDetailsAPI,
  getWorkingEmployeesAPI,
  getTodoListAPI,
} from "../../../Library/apis";
const calculateDaysWithinMonth = (month, startTime, endTime) => {
  const startDate = new Date(startTime);
  startDate.setHours(0, 0, 0, 0); // Round down to the start of the day
  const endDate = new Date(endTime);
  endDate.setHours(23, 59, 59, 999); // Round up to the end of the day

  const monthStart = new Date(startDate.getFullYear(), month, 1);
  const monthEnd = new Date(
    startDate.getFullYear(),
    month + 1,
    0,
    23,
    59,
    59,
    999
  );

  const start = startDate < monthStart ? monthStart : startDate;
  const end = endDate > monthEnd ? monthEnd : endDate;

  const difference = Math.round((end - start) / (1000 * 60 * 60 * 24));

  return difference > 0 ? difference : 0;
};
const WidgetsWrapper = () => {
  const selectedMonth = new Date(2024, 2); // February 2024, for example
  const [employeeCount, setEmployeeCount] = useState({
    title: "Employees All",
    title_1: "Employees extern",
    total: 0,
    total_1: 0,
    color: "secondary",
    icon: "customers",
  });

  const [plannedHours, setPlannedHours] = useState({
    title: "Planned this month",
    title_1: "Contract this month",
    total: 0,
    total_1: 0,
    color: "secondary",
    icon: "stroke-contact",
  });

  const [holidayData, setholidayData] = useState({
    title: "Planned holidays",
    title_1: "",
    total: 0,

    color: "success",
    icon: "fill-calender",
  });

  const [illnessData, setillnessData] = useState({
    title: "Illness this month in days",
    title_1: "",
    total: 0,

    color: "success",
    icon: "fill-email",
  });

  const [workingEmployee, setWorkingEmployee] = useState({
    title: "Working Employees",
    title_1: "",
    total: 0,

    color: "success",
    icon: "stroke-bx_account",
  });

  const [dropOutData, setDropOutData] = useState({
    title: "Dropouts this month",
    title_1: "Successful managed",
    title_2: "",
    total: 0,
    total_1: 0,
    total_2: 0,
    color: "primary",
    icon: "stroke-task",
  });
  const fetchDatas = async () => {
    try {
      const employees = await getAllEmployeesListAPI();
      const fullTimeEmployees = employees.filter((employee) =>
        employee.jobTypes.includes("extern")
      );
      setEmployeeCount((prevState) => ({
        ...prevState, // Spread the previous state to keep other values
        total: employees.length, // Update total
        total_1: fullTimeEmployees.length, // Update total_1
      }));

      const planneddata = await getAllEmployeesListAPI();
      const planneddataEmployees = planneddata.filter(
        (employee) => employee.activeShiftPlan === true
      );
      const totalContractHours = planneddata.reduce(
        (sum, employee) => sum + Number(employee.contractHoursWeek) * 4,
        0
      );

      const totalPlannedHours = planneddataEmployees.reduce(
        (sum, employee) => sum + Number(employee.contractHoursWeek),
        0
      );

      setPlannedHours((prevState) => ({
        ...prevState, // Spread the previous state to keep other values
        total: totalPlannedHours, // Update total
        total_1: totalContractHours, // Update total_1
      }));

      const response = await getHolidayIllnessesListAPI();
      let total = 0;
      const month = 2; // February (0-indexed)
      response.holidayIllnesses.forEach((illness) => {
        total += calculateDaysWithinMonth(
          month,
          illness.startTime,
          illness.endTime
        );
      });
      setholidayData((prevState) => ({
        ...prevState, // Spread the previous state to keep other values
        total: total, // Update total
      }));

      const response_illness = await getHolidayIllnessesListAPI();
      const illnessDays = calculateIllnessDays(
        response_illness.holidayIllnesses
      );
      setillnessData((prevState) => ({
        ...prevState, // Spread the previous state to keep other values
        total: illnessDays, // Update total
      }));

      const workingEmployees = await getWorkingEmployeesAPI();
      setWorkingEmployee((prevState) => ({
        ...prevState, // Spread the previous state to keep other values
        total: workingEmployees.totalAssignedEmployees, // Update total
      }));

      getDropoutHistoryAPI()
        .then((resultArray) => {
          // Initialize counts
          let declined = 0,
            approved = 0,
            open = 0;

          // Iterate through the array to count states
          resultArray.forEach((item) => {
            if (item.state === "declined") {
              declined += item.conflictEmployees.length; // Assuming you want to count the number of conflictEmployees
            } else if (item.state === "approved") {
              approved += item.conflictEmployees.length; // Adjust based on your actual data structure
            } else if (item.state === "open") {
              open += item.conflictEmployees.length; // Adjust based on your actual data structure
            }
          });
          setDropOutData((prevState) => ({
            ...prevState, // Spread the previous state to keep other values
            total: declined + approved + open, // Update total
            total_1: approved, // Update total,
          }));
          // Update the component's state with the new counts
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDatas();
  }, []);
  const isWithinSelectedMonth = (date, month) => {
    const d = new Date(date);
    return d.getMonth() === month;
  };
  const calculateIllnessDays = (data) => {
    const illnessDaysPerEmployee = {};
    let daysSum = 0; // Use let instead of const
    data.forEach((illness) => {
      if (illness.type === "illness" || illness.type === "illness-child") {
        const { startTime, endTime, fullName } = illness;
        let start = new Date(startTime);
        let end = new Date(endTime);

        // Normalize start and end to the beginning of the day
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        // Adjust start and end dates to fit within the selected month
        const firstDayOfMonth = new Date(
          selectedMonth.getFullYear(),
          selectedMonth.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          selectedMonth.getFullYear(),
          selectedMonth.getMonth() + 1,
          0
        );

        if (start < firstDayOfMonth) start = firstDayOfMonth;
        if (end > lastDayOfMonth) end = lastDayOfMonth;

        // Check if the period is within the selected month after adjustment
        if (start <= end) {
          const days = (end - start) / (1000 * 60 * 60 * 24) + 1; // +1 to include both start and end dates

          if (!illnessDaysPerEmployee[fullName]) {
            illnessDaysPerEmployee[fullName] = 0;
          }

          illnessDaysPerEmployee[fullName] += days;
          daysSum += Number(days);
        }
      }
    });
    return daysSum;
  };
  return (
    <>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="13">
            <Widgets1 data={employeeCount} />
          </Col>
          <Col xl="13">
            <Widgets1 data={illnessData} />
          </Col>
        </Row>
      </Col>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="13">
            <Widgets1 data={plannedHours} />
          </Col>
          <Col xl="13">
            <Widgets1 data={workingEmployee} />
          </Col>
        </Row>
      </Col>
      <Col xxl="auto" xl="3" sm="6" className="box-col-6">
        <Row>
          <Col xl="13">
            <Widgets1 data={dropOutData} />
          </Col>
          <Col xl="13">
            <Widgets1 data={holidayData} />
          </Col>
        </Row>
      </Col>
      {/* <Col xxl="auto" xl="12" sm="6" className="box-col-6">
        <Row>
          <Col xxl="12" xl="6" className="box-col-12">
            <Widgets2 data={Widgets2Data} />
          </Col>
          <Col xxl="12" xl="6" className="box-col-12">
            <Widgets2 chartClass="profit-chart " data={Widgets2Data2} />
          </Col>
        </Row>
      </Col> */}
    </>
  );
};

export default WidgetsWrapper;
