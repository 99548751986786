import ComingSimple from "../Components/Pages/ComingSoon/ComingSimple";
import CreatePwd from "../Components/Pages/Auth/CreatePwd";
import ForgetPwd from "../Components/Pages/Auth/ForgetPwd";
import LoginOne from "../Components/Pages/Auth/LoginOne";
import LoginTwo from "../Components/Pages/Auth/LoginTwo";
import LoginSweetalert from "../Components/Pages/Auth/LoginSweetalert";
import LoginTooltip from "../Components/Pages/Auth/LoginTooltip";
import LoginValidation from "../Components/Pages/Auth/LoginValidation";
import Maintenance from "../Components/Pages/Auth/Maintenance";
import RegisterBgImg from "../Components/Mothrift/Auth/SignUp/RegisterBgImg";
import RegisterSimple from "../Components/Pages/Auth/RegisterSimple";
import RegisterVideo from "../Components/Pages/Auth/RegisterVideo";
import UnlockUser from "../Components/Pages/Auth/UnlockUser";
import ErrorPage1 from "../Components/Pages/ErrorPages/ErrorPage400";
import ErrorPage2 from "../Components/Pages/ErrorPages/ErrorPage401";
import ErrorPage3 from "../Components/Pages/ErrorPages/ErrorPage403";
import ErrorPage4 from "../Components/Pages/ErrorPages/ErrorPage404";
import LoginForm from "../Components/Pages/Auth/LoginForm";
import ComingBgImg from "../Components/Pages/ComingSoon/ComingBgImg";
import ComingBgVideo from "../Components/Pages/ComingSoon/ComingBgVideo";
import Error500 from "../Components/Pages/ErrorPages/ErrorPage500";
import Error503 from "../Components/Pages/ErrorPages/ErrorPage503";
import CodeVerification from "../Components/Mothrift/Auth/CodeVerification";
import LogInMain from "../Components/Mothrift/Auth/LogIn/Login";
import ForgetPwdMain from "../Components/Mothrift/Auth/ForgotPassword";
import LandingPage from "../Components/Mothrift/LandingPage";
import RegisterAccount from "../Components/Mothrift/Auth/Register";
import TermsPage from "../Components/Mothrift/LandingPage/Terms";
import PrivacyPage from "../Components/Mothrift/LandingPage/Privacy";
import AutomaticLogin from "../Components/Pages/Auth/AutomaticLogin";
export const authRoutes = [
  { path: "/login", Component: <LogInMain /> },
  { path: "/home", Component: <LandingPage /> },
  { path: "/privacy", Component: <PrivacyPage /> },
  { path: "/terms", Component: <TermsPage /> },
  { path: "/auth/sign-up", Component: <RegisterBgImg /> },
  { path: "/auth/code-verification", Component: <CodeVerification /> },
  { path: "/auth/forget-pwd", Component: <ForgetPwdMain /> },
  { path: "/auth/register-account", Component: <RegisterAccount /> },
  { path: "/pages/authentication/login-simple", Component: <LoginForm /> },
  { path: "/pages/authentication/login-img", Component: <LoginOne /> },
  { path: "/pages/authentication/login-bg-img", Component: <LoginTwo /> },
  {
    path: "/pages/authentication/login-validation",
    Component: <LoginValidation />,
  },
  { path: "/pages/authentication/login-tooltip", Component: <LoginTooltip /> },
  {
    path: "/pages/authentication/login-sweetalert",
    Component: <LoginSweetalert />,
  },

  {
    path: "/pages/authentication/register-simple",
    Component: <RegisterSimple />,
  },
  {
    path: "/pages/authentication/register-bg-img",
    Component: <RegisterBgImg />,
  },
  {
    path: "/pages/authentication/register-video",
    Component: <RegisterVideo />,
  },
  { path: "/pages/authentication/unlock-user", Component: <UnlockUser /> },
  { path: "/pages/authentication/forget-pwd", Component: <ForgetPwd /> },
  { path: "/pages/authentication/create-pwd", Component: <CreatePwd /> },
  { path: "/pages/authentication/maintenance", Component: <Maintenance /> },

  //Coming-soon
  { path: "/pages/comingsoon/comingsoon", Component: <ComingSimple /> },
  { path: "/pages/comingsoon/coming-bg-img", Component: <ComingBgImg /> },
  { path: "/pages/comingsoon/coming-bg-video", Component: <ComingBgVideo /> },

  //Error
  { path: "/pages/errors/error400", Component: <ErrorPage1 /> },
  { path: "/pages/errors/error401", Component: <ErrorPage2 /> },
  { path: "/pages/errors/error403", Component: <ErrorPage3 /> },
  { path: "/pages/errors/error404", Component: <ErrorPage4 /> },
  { path: "/pages/errors/error500", Component: <Error500 /> },
  { path: "/pages/errors/error503", Component: <Error503 /> },
  { path: "/auto-login/:token", Component: <AutomaticLogin /> },
];
