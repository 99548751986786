import React from "react";

import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import UserProvider from "./_helper/User/UserProvider";
import SettingProvider from "./_helper/Setting/SettingProvider";
import DropProvider from "./Components/Mothrift/Dropout/DropProvider";
import TodoProvider from "./_helper/Todo/TodoProvider";
const App = () => (
  <div className="App">
    <UserProvider>
      <CustomizerProvider>
        <TodoProvider>
          <DropProvider>
            <AnimationThemeProvider>
              <SettingProvider>
                <Routers />
              </SettingProvider>
            </AnimationThemeProvider>
          </DropProvider>
        </TodoProvider>
      </CustomizerProvider>
    </UserProvider>
  </div>
);

export default App;
