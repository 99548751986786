import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Select from "react-select";
import DatePicker from "react-datepicker";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";

import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  getHolidayIllnessesListAPI,
  deleteHolidayIllnessAPI,
  confirmByManagerHolidayIllnessAPI,
  revertConfirmByManagerHolidayIllnessAPI,
  getAllHolidayIllnessesAPI,
} from "../../../Library/apis";
import useDebounce from "../../../Hooks/useDebounce";
import { HolidayIllnessStates, HolidayIllnessTypes } from "../../../Constant";
import CalendarView from "./CalendarView";
import CustomizerContext from "../../../_helper/Customizer";



function formatDate(dateStr) {
  // Extract the date and time part, ignoring the offset
  let datePart = dateStr.substring(0, 19);
  // Parse the date part into a JavaScript Date object
  let date = new Date(datePart);
  // Format the date and time as requested
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
  let year = date.getFullYear();
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  // Construct the formatted date string
  let formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`;
  return formattedDate;
}



const RenderItem = (
  item,
  handleConfirmByManager,
  handleRevertConfirmByManager,
  handleDelete,
  t
) => {
  

  return {
    name: <span>{item?.fullName}</span>,
    type: <span>{t(HolidayIllnessTypes[item.type])}</span>,
    startTime: (
      <span>{formatDate(item?.startTime)}</span>
    ),
    endTime: <span>{formatDate(item?.endTime)}</span>,
    state: <span>{t(HolidayIllnessStates[item.state])}</span>,
    confirmedByManager: item?.confirmedByManager ? (
      <span className="f-w-700 font-success">
        <i className="icofont icofont-tick-mark"></i>
      </span>
    ) : (
      <span className="f-w-700 font-danger">
        <i className="icofont icofont-close"></i>
      </span>
    ),
    actions: (
      <Fragment>
        <Btn
          attrBtn={{
            className: "me-2 px-2 py-1",
            color: item.confirmedByManager ? "primary" : "success",
            size: "sm",
            onClick: () =>
              item.confirmedByManager
                ? handleRevertConfirmByManager(item._id)
                : handleConfirmByManager(item._id),
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": item.confirmedByManager
              ? t("Revert")
              : t("Confirm"),
            "data-tooltip-place": "top",
          }}
        >
          {item.confirmedByManager ? (
            <i className="fa fa-undo"></i>
          ) : (
            <i className="fa fa-magic"></i>
          )}
        </Btn>
        <Link to={`/holiday-illnesses/${item._id}/edit`}>
          <Btn
            attrBtn={{
              className: "me-2 px-2 py-1",
              color: "info",
              size: "sm",
              "data-tooltip-id": "my-tooltip",
              "data-tooltip-content": t("Edit"),
              "data-tooltip-place": "top",
            }}
          >
            <i className="fa fa-pencil"></i>
          </Btn>
        </Link>
        <Btn
          attrBtn={{
            className: "px-2 py-1",
            color: "danger",
            size: "sm",
            onClick: () => handleDelete(item._id),
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": t("Delete"),
            "data-tooltip-place": "top",
          }}
        >
          <i className="fa fa-trash"></i>
        </Btn>
      </Fragment>
    ),
  };
};

const HolidayIllnesses = () => {
  const { mixLayout } = useContext(CustomizerContext);
  const { t } = useTranslation();
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? "#80bdff" // Set focused border color
        : mixLayout
        ? provided.borderColor
        : "#1d1e26", // Use default border
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    container: (provided, state) => ({
      ...provided,
      borderColor: mixLayout ? "#ffffffaa" : "#1d1e26",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e6",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e29",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterState, setFilterState] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [listView, setListView] = useState(true);

  const debouncedSearchTerm = useDebounce(search, 500); // 500 ms delay

  const fetchHolidayIllnesses = async () => {
    setLoading(true);

    try {
      const response = await getHolidayIllnessesListAPI({
        page: currentPage,
        pageSize: perPage,
        search: search,
        sortField: sortField,
        sortOrder: sortOrder,
        type: filterType,
        state: filterState,
        startDate,
        endDate,
      });

      setData(response.holidayIllnesses);
      setTotalRows(response.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHolidayIllnesses();
  }, [
    debouncedSearchTerm,
    currentPage,
    perPage,
    sortField,
    sortOrder,
    filterType,
    filterState,
    startDate,
    endDate,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSortField(column.pseudoField);
    setSortOrder(sortDirection);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: t("Are you sure to delete?"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"), // Translate the Cancel button text,
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await deleteHolidayIllnessAPI(id);
          toast.success(res.message || "Successfully deleted!", {
            autoClose: 2000,
          });
          fetchHolidayIllnesses();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleConfirmByManager = (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You can revert this, if you want!"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, confirm it!"),
      cancelButtonText:t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await confirmByManagerHolidayIllnessAPI(id);
          toast.success(res.message || "Successfully confirmed!", {
            autoClose: 2000,
          });
          fetchHolidayIllnesses();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleRevertConfirmByManager = (id) => {
    Swal.fire({
      title: t("Are you sure?"),
      text: t("You can confirm this, if you want!"),
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, revert it!"),
      cancelButtonText:t("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // If user confirmed, continue deleting the account
        try {
          const res = await revertConfirmByManagerHolidayIllnessAPI(id);
          toast.success(res.message || "Successfully reverted!", {
            autoClose: 2000,
          });
          fetchHolidayIllnesses();
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong!";
          toast.error(message, { autoClose: 2000 });
        }
      }
    });
  };

  const handleExportHolidayIllnesses = async () => {
    try {
      // Make a request to the server endpoint that generates the Excel file
      const response = await getAllHolidayIllnessesAPI();
      const ws = XLSX.utils.json_to_sheet(response);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, "holiday-illness.xlsx");
    } catch (error) {
      console.error("Error during exportma:", error);
      // Handle any errors that occur during the request
    }
  };

  const handleResetDate = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const columns = useMemo(
    () => [
      {
        name: "Employee",
        selector: (row) => row["name"],
        sortable: true,
        pseudoField: "employee",
      },
      {
        name: "Type",
        selector: (row) => row["type"],
        sortable: true,
        pseudoField: "type",
      },
      {
        name: "Start",
        selector: (row) => row["startTime"],
        sortable: true,
        pseudoField: "startTime",
      },
      {
        name: "End",
        selector: (row) => row["endTime"],
        sortable: true,
        pseudoField: "endTime",
      },
      {
        name: "State",
        selector: (row) => row["state"],
        sortable: true,
        pseudoField: "state",
      },
      {
        name: "Confirmed by manager",
        selector: (row) => row["confirmedByManager"],
        sortable: true,
        pseudoField: "confirmedByManager",
      },
      {
        name: "Actions",
        selector: (row) => row["actions"],
      },
      // Add more columns as needed
    ],
    []
  );

  const mainTitle = "Holiday & Illness";
  const maintTitleTran = t(mainTitle);
  const viewText = listView ? "Calendar View" : "List View";
  const viewTextTran = t(viewText);
  const filterStateOptions = [
    { value: "", label: t("All State") },
    { value: "open", label: t("Open") },
    { value: "declined", label: t("Declined") },
    { value: "approved", label: t("Approved") },
  ];
  const filterTypeOptions = [
    { value: "", label: t("All Type") },
    { value: "holiday-payed", label: t("Holiday Payed") },
    { value: "holiday-unpayed", label: t("Holiday Unpayed") },
    { value: "illness", label: t("Illness") },
    { value: "illness-child", label: t("Illness Child") },
    { value: "school", label: t("School") },
    { value: "miscellaneous", label: t("Miscellaneous") },
  ];
  return (
    <Fragment>
      <Breadcrumbs mainTitle={maintTitleTran} title="Holiday & Illness" />
      <Container fluid={true} className="calendar-basic">
        <Col sm="12">
          <Card>
            <div className="d-flex justify-content-end align-items-center">
              <Btn
                attrBtn={{
                  className: "px-2 py-1 me-2",
                  color: "primary",
                  onClick: handleExportHolidayIllnesses,
                }}
              >
                {t("Export all to excel")}
              </Btn>

              <Btn
                attrBtn={{
                  className: "px-2 py-1",
                  color: "primary",
                  onClick: () => setListView(!listView),
                }}
              >
                {viewTextTran}
              </Btn>
            </div>
          </Card>
        </Col>

        <Col sm="12">
          <Card>
            {listView && (
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <InputGroup style={{ width: 250 }}>
                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                      <Input
                        type="text"
                        placeholder={t("Search by name...")}
                        name="search"
                        value={search}
                        onChange={handleSearchChange}
                      />
                      {search && (
                        <InputGroupText>
                          <i
                            className="fa fa-times cursor-pointer"
                            onClick={() => setSearch("")}
                          ></i>
                        </InputGroupText>
                      )}
                    </InputGroup>
                  </div>
                  <div className="d-flex align-items-center">
                    <Link to="/holiday-illnesses/create">
                      <Btn
                        attrBtn={{
                          className: "me-2 px-2 py-1",
                          color: "primary",
                        }}
                      >
                        <i className="fa fa-plus"></i> {t("New off time")}
                      </Btn>
                    </Link>

                    <div className="d-flex align-items-center me-2">
                      <div style={{ width: 120 }}>
                        <DatePicker
                          className="form-control digits"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                      ~
                      <div style={{ width: 120 }}>
                        <DatePicker
                          className="form-control digits"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                        />
                      </div>
                      {(startDate || endDate) && (
                        <i
                          className="fa fa-times ms-1 cursor-pointer"
                          onClick={handleResetDate}
                        ></i>
                      )}
                    </div>

                    <div style={{ width: 220 }} className="me-2">
                      <Select
                        options={filterTypeOptions.map((option) => ({
                          ...option,
                          label: t(option.label), // Translate the label
                        }))}
                        styles={customSelectStyles}
                        className="js-example-basic-single col-sm-12"
                        onChange={(item) => setFilterType(t(item.value))}
                        // defaultValue={
                        //   filterTypeOptions.find((option) => option.value === "") || // Original default option
                        //   filterTypeOptions.find((option) => t(option.label) === t("")) // Translated default option
                        // }
                      />
                    </div>

                    <div style={{ width: 220 }} className="me-2">
                      <Select
                        options={filterStateOptions.map((option) => ({
                          ...option,
                          label: t(option.label), // Translate the label
                        }))}
                        styles={customSelectStyles}
                        className="js-example-basic-single col-sm-12"
                        onChange={(item) => setFilterState(item.value)}
                        // defaultValue={filterStateOptions.find(
                        //   (option) => option.value === ""
                        // )}
                      />
                    </div>
                  </div>
                </div>
              </CardHeader>
            )}

            {listView ? (
              <div className={`custom-table${data.length ? "" : " no-data"}`}>
                <DataTable
                  columns={columns.map((column) => ({
                    ...column,
                    name: t(column.name), // Translate the column name
                  }))}
                  data={data?.map((item) =>
                    RenderItem(
                      item,
                      handleConfirmByManager,
                      handleRevertConfirmByManager,
                      handleDelete,
                      t
                    )
                  )}
                  striped={true}
                  center={true}
                  // progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationPerPage={perPage}
                  paginationDefaultPage={currentPage}
                  paginationComponentOptions={{
                    rowsPerPageText: "",
                    rangeSeparatorText: "of",
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                  }}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  onSort={handleSort}
                  sortServer
                />
              </div>
            ) : (
              <div className="basic-calendar">
                <Row>
                  <CalendarView />
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Container>
      <Tooltip id="my-tooltip" />
    </Fragment>
  );
};

export default HolidayIllnesses;
