import React, { useContext } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
import CustomizerContext from "../../_helper/Customizer";
import { useTranslation } from 'react-i18next'
const CustomSelect = ({
  name = "",
  options,
  label = "",
  onChange = () => {},
  errors = {},
  touched = {},
  value = "",
  async = false,
  isMulti = false,
  required = false,
  isFromDb = false,
  ...props
}) => {
  const { mixLayout } = useContext(CustomizerContext);
  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? "#80bdff" // Set focused border color
        : errors[state.selectProps.name] && touched[state.selectProps.name]
        ? "#dc3545" // Set invalid border color
        : mixLayout
        ? provided.borderColor
        : "#1d1e26", // Use default border
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      ...(errors[state.selectProps.name] && touched[state.selectProps.name]
        ? {
            paddingRight: "calc(1.5em + 0.75rem)",
            backgroundImage:
              'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right calc(0.375em + 0.1875rem) center",
            backgroundSize: "calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)",
          }
        : {}),
    }),

    menu: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "white" : "#1d1e26",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    container: (provided, state) => ({
      ...provided,
      borderColor: mixLayout ? "#ffffffaa" : "#1d1e26",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e6",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: mixLayout ? "#ffffffaa" : "#1d1e29",
      color: mixLayout ? "#1d1e26" : "#ffffffaa",
    }),
  };
  const { t } = useTranslation();
  const translatedOptions = options.map((option) => ({
    ...option,
    label: t(option.label), // Translate the option label
  }));
  return (
    
    <FormGroup>
      {label && <Label className={required ? "required" : ""}>{t(label)}</Label>}
      <Select
        options={translatedOptions}
        className={`js-example-basic-single col-sm-12 ${
          touched[name] && errors[name] ? "is-invalid" : ""
        }`}
        name={name}
        onChange={(option) =>
          onChange(name, async ? option : option?.value, isFromDb)
        }
        value={value}
        
        styles={customSelectStyles}
        isMulti={isMulti}
        {...props}
      />
      {errors[name] && touched[name] && (
        <span className="mt-2 text-danger">{errors[name]}</span>
      )}
    </FormGroup>
  );
};

export default CustomSelect;
